import moment from "moment";
/**
 * A functional component that renders a single goal row.
 *
 * @function GoalRow
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.goal - The goal object to be rendered.
 * @param {string} props.title - The title of the goal.
 * @param {Date} props.dateSet - The date when the goal was set.
 * @param {string} props.reviewDate - The date when the goal should be reviewed.
 * @param {boolean} props.isCompleted - Indicates whether the goal is completed.
 * @param {Function} props.onClick - A function to handle goal completion.
 * @param {Function} props.onEdit - A function to handle goal editing.
 * @param {boolean} props.isDashboard
 * @returns {JSX.Element} - The JSX element representing the goal row.
 */
const GoalRow = ({
  goal,
  title,
  dateSet,
  reviewDate,
  isCompleted,
  onClick,
  onEdit,
}) => {
  return (
    <div className="row prggoals header-data-row align-items-center">
      <div className="col col-wide black-txt font-16 fw700 font-qbold">
        {title}
      </div>
      <div className="col font-qmedium ash-txt font-16 fw500 text-right">
        {moment(dateSet).format("MMM DD, YYYY")}
      </div>

      <div className="col font-qmedium ash-txt font-16 fw500 text-right">
        {moment(reviewDate).format("MMM DD, YYYY")}
      </div>

      <div className="col text-right">
        {isCompleted ? (
          <a
            href="javascript:void(0)"
            onClick={() => onClick(goal, false)}
            className="completed-link font-14 font-qbold fw600 black-txt achived-btn "
          >
            <img
              src="/images/dashboard/tick.svg"
              className="img-fluid align-baseline"
              alt="tick image"
            />
            Achieved
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={() => onClick(goal, true)}
            className="completed-button font-14 font-qbold fw600 bg-lightblue achived-btn "
          >
            Mark as done
          </a>
        )}
      </div>
      <div className="col">
        <a
          href="javascript:void(0)"
          className="font-14 font-semibold fw600 black-txt text-right"
          onClick={() => onEdit(goal)}
        >
          <img
            src="/images/goals/edit-button.svg"
            className="img-fluid"
            alt="Edit image"
          />
        </a>
      </div>
    </div>
  );
};
export default GoalRow;
