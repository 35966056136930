import React from "react";

const ResourcesListComponent = ({ items, activeIndex, onItemClick }) => {
  return (
    <div className="col-12 col-md-4 col-lg-4 text-left list-populartags list-container">
      <ol style={{ listStyle: "none" }}>
        {items.map((item, index) => (
          <li
            key={index}
            className={`font-14 font-semibold fw600 black-txt ${
              index === activeIndex ? "active-list" : "inactive-list"
            }`}
            onClick={() => onItemClick(index)}
          >
            <span>
              {index + 1}. {item.title}
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ResourcesListComponent;
