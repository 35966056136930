import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie-consent";
import PhoneInput from 'react-phone-input-2'
import Postcoder from "react-address-lookup";
import Autocomplete from "react-google-autocomplete";

import {
    saveEmergencyContact,
    updateUserdataRedex
} from "../../actions";
import * as constand from "../../constant";
import { toast } from "react-toastify";
const google = window.google;

class EmergencyContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRead: false,
            // userEmergencyContact: {
            emergencyContact: {
                name: '',
                phoneNumber: '',
                type: 'emergencyContact'
            },
            primaryCare: {
                name: '',
                phoneNumber: '',
                type: 'primaryCareDoctor'
            },
            primaryCareConsent: false,
            emergencyContactConsent: false,
            permission_contact: false,
            isUpdate: false,
            //}
            loading: false
        };
        this.autocomplete = null;
    }
    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
        this.setState({
            country: this.props.logged_userData.country,
            countryCode: this.props.logged_userData.Country?this.props.logged_userData.Country.countryCode.toLowerCase():"",
            phoneNumber: this.props.logged_userData.phoneNumber ? this.props.logged_userData.phoneNumber.toString() : '',
            permission_contact: this.props.logged_userData.permission_contact,
            address1: this.props.logged_userData.address1,
            address2: this.props.logged_userData.address2,
            city: this.props.logged_userData.city,
            countryState: this.props.logged_userData.countryState,
            postcode: this.props.logged_userData.postcode
        });
        var authData = this.props.logged_userData;
        console.log('authData', authData)
        if (this.props.logged_userData && this.props.logged_userData.UserEmergencyContacts && this.props.logged_userData.UserEmergencyContacts.length > 0) {
            console.log('didmouyntauthData', authData)
            var emergency = authData.UserEmergencyContacts[0];
            emergency.phoneNumber = emergency.phoneNumber.toString();
            var primaryCare = authData.UserEmergencyContacts[1];
            primaryCare.phoneNumber = primaryCare.phoneNumber.toString();

            this.setState({
                isUpdate: true,
                emergencyContact: emergency,
                primaryCareConsent: (primaryCare.UserConsents.length > 0) ? primaryCare.UserConsents[0].consentGiven : false,
                primaryCare: primaryCare,
                emergencyContactConsent: (emergency.UserConsents.length > 0) ? emergency.UserConsents[0].consentGiven : false,
            })
        }
    }

    onCloseModal = () => { }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if (name == 'country' && value == constand.ukCountry) {
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
            this.autocomplete.addListener("place_changed", this.handlePlaceSelect)

        }
    }
    handleEmergencyChange = (e) => {
        const { name, value } = e.target;
        var temp = this.state.emergencyContact;
        temp[name] = value
        this.setState({ emergencyContact: temp });
    }
    handlePrimaryChange = (e) => {
        const { name, value } = e.target;
        var temp = this.state.primaryCare;
        temp[name] = value
        this.setState({ primaryCare: temp });
    }
    handleCheckboxChange = (e) => //for twoway binding checkbox
    {
        const { name, value } = e.target;
        this.setState({ [name]: !this.state[name] });
    }
    handlePlaceSelect = () => {
        let addressObject = this.autocomplete.getPlace()
        let address = addressObject.address_components
        console.log('handlePlaceSelectaddress', address)
        /* this.setState({
          name: addressObject.name,
          street_address: `${address[0].long_name} ${address[1].long_name}`,
          city: address[4].long_name,
          state: address[6].short_name,
          zip_code: address[8].short_name,
          googleMapLink: addressObject.url
        }) */
    }

    submitEmergencyDetails = () => {
        this.setState({ submitted: true });
        const { emergencyContact, primaryCare, phoneNumber, country, address2, city, countryState, postcode, permission_contact, emergencyContactConsent, primaryCareConsent } = this.state;

        // stop here if form is invalid
        if (!(phoneNumber && country && address2 && city && countryState && postcode && emergencyContact.name && emergencyContact.phoneNumber && primaryCare.name && primaryCare.phoneNumber && permission_contact && primaryCareConsent && emergencyContactConsent)) {
            return;
        }
        var params = this.state;
        this.setState({ loading: true });

        this.props.saveEmergencyContact(params).then(response => {
            var newValue = {};
            newValue['hasEmergencyContact'] = true;
            this.setState({ loading: false });

            this.props.updateUserdataRedex(newValue);
            this.props.successAction();
            this.props.closeModel();
            toast.success(response.message);
        }, error => {
            toast.error(error);
            this.props.closeModel();
        })

    }
    logAddress = (addr) => {
        console.log(addr);
    }
    render() {
        const { submitted, loading, phoneNumber, country, emergencyContact, addressFinder, address1, address2, city, countryState, postcode, permission_contact, primaryCare, primaryCareConsent, emergencyContactConsent, countryCode } = this.state;
        console.log('countryCode', countryCode)

        return (
            <Modal
                className="removebbg-popup"
                open={this.props.is_model_open}
                onClose={this.onCloseModal}
                center
            >
                <div
                    className="modal-dialog common_design modal-width--custom live-classes--popup"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header header-styling  border-0">
                            <h5
                                className="modal-title1 text-center white-txt col-md-12 p-0 font-book font-24"
                                id="exampleModalLabel "
                            >
                                Just one more thing…
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={this.props.closeModel}
                                >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body body-padding--value pb-0">
                            <div>
                                <div name="login" onSubmit={this.loginSubmit}>

                                    <div className="col-md-12  m-b-30 mr-auto ml-auto">
                                        <p className="font-18 font-qregular black-txt text-center">At Beam, we’re committed to the safety of our members. Thank you for providing additional contact information that will be used in the event of an emergency during physio-led classes only.  </p>
                                        <p className="font-18 font-qregular black-txt text-center">
                                            Our members' privacy is very important to us, and this information will not be used for any other purposes. For more information about our safety procedures <a href="/blog/Help" title="_blank" className="black-txt"><u>follow this link</u></a>.</p>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !phoneNumber ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Your phone number <span className="text-danger">*</span>
                                            </label>
                                            <PhoneInput
                                                defaultCountry={countryCode}
                                                country={phoneNumber ? '' : countryCode}
                                                //country={countryCode}
                                                value={phoneNumber}
                                                onChange={phoneNumber => this.setState({ phoneNumber })}
                                                inputClass="w-100"
                                            />
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                id="phoneNumber"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="phoneNumber"
                                                value={phoneNumber}
                                                onChange={this.handleChange}
                                            /> */}
                                            {submitted && !phoneNumber && (
                                                <div className="text-danger">This information is required</div>
                                            )}
                                        </div>

                                        <div className="form-group mx-auto">
                                            <label htmlFor="country" className="text-label font-semibold">
                                                Your country <span className="text-danger">*</span>
                                            </label>
                                            <div className="dropdown">
                                                <select name="country"
                                                    value={country}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                >
                                                    <option className="pointer">Select country</option>
                                                    {this.props.countries_list.map((item, key) => {
                                                        return (
                                                            <option className="pointer" key={"country_" + item.id} value={item.id} selected={this.props.logged_userData.country == item.id}>
                                                                {item.countryName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            {submitted && !country && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        {country == constand.ukCountry && country == 0 &&
                                            <div
                                                className={
                                                    "form-group "
                                                    //+ (country == constand.ukCountry ? '' : 'd-none')
                                                }
                                            >
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="font-14 font-semibold black-txt"
                                                >
                                                    Address finder
                                                </label>
                                                {<input
                                                    type="text"
                                                    className="form-control"
                                                    // id="addressFinder"
                                                    id="autocomplete"
                                                    aria-describedby="emailHelp"
                                                    placeholder=""
                                                    name="addressFinder"
                                                    value={addressFinder}
                                                    ref="input"
                                                //  onChange={this.handleChange}
                                                />}
                                                {/* <input id="autocomplete"
                                                    className="input-field"
                                                    ref="input"
                                                    type="text" /> */}
                                                {/* <Autocomplete
                                                    //apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                                    onPlaceSelected={(place) => {
                                                        console.log(place);
                                                    }}
                                                />; */}
                                                {/* <Postcoder
                                                    apiKey="PCWBR-2YXFN-WVX7X-MKZ7K"
                                                    addressSelectedCallback={this.logAddress}
                                                /> */}

                                            </div>
                                        }
                                        {country == constand.ukCountry && country == 0 &&
                                            <p className="font-semibold font-14"> Or enter address manually...</p>
                                        }
                                        <div
                                            className={
                                                "form-group"
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Flat, Apt, Suite etc. {address1}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="address1"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="address1"
                                                value={address1}
                                                onChange={this.handleChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !address2 ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Street address <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="address2"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="address2"
                                                value={address2}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !address2 && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !city ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                City <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="city"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="city"
                                                value={city}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !city && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !countryState ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                County or State <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="countryState"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="countryState"
                                                value={countryState}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !countryState && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !postcode ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Postcode or Zipcode <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="postcode"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="postcode"
                                                value={postcode}
                                                onChange={this.handleChange}
                                            />
                                            {submitted && !postcode && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>

                                        <div className="toggle-btns float-left w-100 m-b-30">
                                            <span className="font-14 font-qregular black-txt">I consent to being contacted by any employee or contractor of Beam in an emergency situation.</span>
                                            <label className="switch m-l-10 pull-right" htmlFor="permission_contact">
                                                <input type="checkbox" name="permission_contact" id="permission_contact" checked={permission_contact} onChange={(this.handleCheckboxChange)} value={permission_contact} />
                                                <div className="slider round"></div>
                                            </label>
                                            {submitted && !permission_contact && (
                                                <p className="help-block text-danger">
                                                    We require this consent to allow you to sign up to this class.  If you would rather not share this information please feel free to check out our on-demand classes instead.
                                                </p>
                                            )}

                                        </div>
                                        <hr className="row m-11 mx-auto col-md-11 bg-turquoise-blue ht-05" />
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !emergencyContact.name ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Name of your emergency contact <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                aria-describedby="name"
                                                placeholder=""
                                                name="name"
                                                value={emergencyContact.name}
                                                onChange={this.handleEmergencyChange}
                                            />
                                            {submitted && !emergencyContact.name && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !emergencyContact.phoneNumber ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Phone number of your emergency contact <span className="text-danger">*</span>
                                            </label>
                                            <PhoneInput
                                                defaultCountry={countryCode}
                                                country={emergencyContact.phoneNumber ? '' : countryCode}
                                                value={emergencyContact.phoneNumber}
                                                onChange={phoneNumber => {
                                                    var temp = this.state.emergencyContact;
                                                    temp.phoneNumber = phoneNumber
                                                    this.setState({ emergencyContact: temp });
                                                }}
                                                inputClass="w-100"
                                            />
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                id="phoneNumber"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="phoneNumber"
                                                value={emergencyContact.phoneNumber}
                                                onChange={this.handleEmergencyChange}
                                            /> */}
                                            {submitted && !emergencyContact.phoneNumber && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div className="toggle-btns float-left w-100 m-b-30">
                                            <span className="font-14 font-qregular black-txt">I consent to this person being contacted by any employee or contractor of Beam in an emergency situation, disclosing health information I have submitted on Beam should they deem it necessary.</span>
                                            <label className="switch m-l-10 pull-right" htmlFor="emergencyContactConsent">
                                                <input type="checkbox" name="emergencyContactConsent" id="emergencyContactConsent"
                                                    checked={emergencyContactConsent}
                                                    onChange={() => {
                                                        var emergencyContact = this.state.emergencyContact;
                                                        if (emergencyContact.UserConsents && emergencyContact.UserConsents.length > 0) {
                                                            emergencyContact.UserConsents[0].consentGiven = !emergencyContact.UserConsents[0].consentGiven;
                                                            this.setState({ emergencyContact: emergencyContact, emergencyContactConsent: !emergencyContactConsent })
                                                        } else {
                                                            this.setState({ emergencyContactConsent: !emergencyContactConsent });
                                                        }
                                                    }}
                                                    value={emergencyContactConsent} />
                                                <div className="slider round"></div>
                                            </label>
                                            {submitted && !emergencyContactConsent && (
                                                <p className="help-block text-danger">
                                                    We require this consent to allow you to sign up to this class.  If you would rather not share this information please feel free to check out our on-demand classes instead.
                                                </p>
                                            )}
                                        </div>
                                        <hr className="row m-11 mx-auto col-md-11 bg-turquoise-blue ht-05" />
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !primaryCare.name ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Name of your GP or primary care doctor <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="name"
                                                value={primaryCare.name}
                                                onChange={this.handlePrimaryChange}
                                            />
                                            {submitted && !primaryCare.name && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "form-group" +
                                                (submitted && !primaryCare.phoneNumber ? " has-error" : "")
                                            }
                                        >
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="font-14 font-semibold black-txt"
                                            >
                                                Phone number of your GP or primary care doctor *
                                            </label>
                                            <PhoneInput
                                                defaultCountry={countryCode}
                                                country={primaryCare.phoneNumber ? '' : countryCode}
                                                value={primaryCare.phoneNumber}
                                                onChange={phoneNumber => {
                                                    var temp = this.state.primaryCare;
                                                    temp.phoneNumber = phoneNumber
                                                    this.setState({ primaryCare: temp });
                                                }}
                                                inputClass="w-100"
                                            />
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                id="phoneNumber"
                                                aria-describedby="emailHelp"
                                                placeholder=""
                                                name="phoneNumber"
                                                value={primaryCare.phoneNumber}
                                                onChange={this.handlePrimaryChange}
                                            /> */}
                                            {submitted && !primaryCare.phoneNumber && (
                                                <p className="help-block text-danger">
                                                    This information is required
                                                </p>
                                            )}

                                        </div>
                                        <div className="toggle-btns float-left w-100 m-b-30">
                                            <span className="font-14 font-qregular black-txt">I consent to this person being contacted by any employee or contractor of Beam in an emergency situation, disclosing health information I have submitted on Beam should they deem it necessary.</span>
                                            <label className="switch m-l-10 pull-right" htmlFor="primaryCareConsent">
                                                <input type="checkbox" name="primaryCareConsent" id="primaryCareConsent" checked={primaryCareConsent} onChange={() => {
                                                    var primaryCare = this.state.primaryCare;
                                                    if (primaryCare.UserConsents && primaryCare.UserConsents.length > 0) {
                                                        primaryCare.UserConsents[0].consentGiven = !primaryCare.UserConsents[0].consentGiven;
                                                        this.setState({ primaryCare: primaryCare, primaryCareConsent: !primaryCareConsent })
                                                    } else {
                                                        this.setState({ primaryCareConsent: !primaryCareConsent })
                                                    }
                                                }}
                                                    value={primaryCareConsent} />
                                                <div className="slider round"></div>
                                            </label>
                                            {submitted && !primaryCareConsent && (
                                                <p className="help-block text-danger">
                                                    We require this consent to allow you to sign up to this class.  If you would rather not share this information please feel free to check out our on-demand classes instead.
                                                </p>
                                            )}
                                        </div>
                                        <div className=" w-100">
                                            <p className="font-18 font-qregular text-center">
                                                For your own safety and security please make sure you keep these details up-to-date on your account page.
                                            </p>
                                        </div>
                                        {/* submitted && (!primaryCareConsent || !permission_contact || !emergencyContactConsent) && (
                                            <p className="help-block text-danger">
                                                No users can sign up to live class without all three consents as true
                                            </p>
                                        ) */}
                                    </div>

                                    <div className="buttons-read w-100 float-left text-center pointer">
                                        <a className="btn btn-beam-blue-inverse font-14 m-r-20 pointer col-md-5 m-t-20"
                                            onClick={this.props.closeModel}
                                        >
                                            Go back
                                        </a>
                                        <button className="btn btn-beam-blue font-14 col-md-6 m-t-20"
                                            onClick={this.submitEmergencyDetails}
                                            disabled={loading}
                                        >
                                            Go to class
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        countries_list: state.register.countries_list
    };
};
const mapDispatchToProps = {
    saveEmergencyContact,
    updateUserdataRedex
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmergencyContactModal);
