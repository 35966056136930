import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getInstructorDetails, joingroup } from "../../actions";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import { commonService } from "../../_services";
import WorkoutSaveComponent from "../WorkoutsPage/workoutSaveComponent";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";

class InstructorDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructor_id: this.props.match.params.id,
      Loader: true,
      instructor_data: {},
      instructor_ondemand_paging: [],
      show_more_cnt: constand.INSTRUCTOR_ONDEMAND_CNT
    };
    this.scheduleModelOpen = this.scheduleModelOpen.bind(this);
    this.updateSchedule = this.updateSchedule.bind(this);
    this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);
    this.goback = this.goback.bind(this);
    this.condition = "cystic-fibrosis";
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getInstructorDetails(this.state.instructor_id).then(
      response => {
        this.setState({
          Loading: false,
          instructor_data: this.props.instructor_detail
            ? this.props.instructor_detail
            : {}
        });
        this.incrementShowmoreCnt();
      },
      error => {
        this.setState({ Loading: false });
        toast.error(error);
      }
    );
  }
  incrementShowmoreCnt() {
    if (
      this.state.instructor_data.Workouts &&
      this.state.instructor_data.Workouts.length > 0
    ) {
      var Insturctor_overviewData = this.state.instructor_data.Workouts;
      var paginatedData = this.state.instructor_ondemand_paging;
      for (
        var i = this.state.show_more_cnt - constand.INSTRUCTOR_ONDEMAND_CNT;
        i < this.state.show_more_cnt &&
        i < this.state.instructor_data.Workouts.length;
        i++
      ) {
        paginatedData.push(Insturctor_overviewData[i]);
      }
      this.setState({
        show_more_cnt:
          this.state.show_more_cnt + constand.INSTRUCTOR_ONDEMAND_CNT,
        instructor_ondemand_paging: paginatedData
      });
    } else {
      this.setState({ instructor_ondemand_paging: [] });
    }
  }
  // updateSaveStatus(dataObj){
  //     if(this.state.instructor_data && this.state.instructor_data.Workouts)
  //     {
  //       var instructorData = this.state.instructor_data;
  //       var instructor_workout = instructorData.Workouts;
  //       const current_index = instructor_workout.map(function(el) {
  //           return el.id;
  //         }).indexOf(dataObj.workout_id);
  //         instructorData.Workouts[current_index].isSaved = dataObj.flag;
  //       this.setState({instructor_data:instructorData});
  //     }
  //   }
  scheduleModelOpen() {
    if (this.props.is_auth) {
      this.props.scheduleModelOpen(true);
    } else {
      this.props.loginModelOpen(true);
    }
  }
  updateSchedule(scheduleData) { }
  //-----Join group-------------
  submitRequest(item) {
    if (item.type === "closed") {
      // private group
      this.joinrequest(item);
    } else {
      // public group
      this.joingroup(item);
    }
  }
  joingroup(item) {
    if (this.props.is_auth) {
      //this.props.start_loader();
      this.props.joingroup(item.id).then(
        response => {
          //this.props.stop_loader();
          toast.success(response.message);
          const { from } = {
            from: {
              pathname:
                "/group/about/" + item.community_name + "/" + this.condition
            }
          };
          this.props.history.push(from);
        },
        error => {
          // this.props.stop_loader();
          toast.error(error);
        }
      );
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  joinrequest(item) {
    if (this.props.is_auth) {
      //this.props.start_loader();
      this.props.joinrequest(item.id).then(
        response => {
          this.setState({ offset: 0, groupList: [] }, function () {
            this.getGroupList();
          });
          toast.success(response.message);
        },
        error => {
          //this.props.stop_loader();
          toast.error(error);
        }
      );
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  gotoGroupDetailPage(item) {
    const { from } = {
      from: {
        pathname: "/group/about/" + item.community_name + "/" + this.condition
      }
    };
    this.props.history.push(from);
  }
  goback() {
    if (this.props.prevPath.indexOf('/liveClass/') != -1)
      window.history.back();
    else {
      //window.location.href = '/instructor/' + ((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition).toLowerCase().replace(/ /g, '-'))
      const { from } = { from: { pathname: '/instructor/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false)) } };
      this.props.history.push(from);
    }
  }
  renderMetaTags(Instructor_profileData, Instructor_profileImg, desc) {
    return (
      <Helmet>
        <title>{Instructor_profileData.name}{" "}{Instructor_profileData.lastName}{constand.INSTRUCTOR_DETAIL_TITLE}{constand.BEAM}</title>
        <meta property="og:title" content={Instructor_profileData.name + ' ' + Instructor_profileData.lastName + constand.INSTRUCTOR_DETAIL_TITLE + constand.BEAM} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={Instructor_profileImg} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="Beam" />
        <meta name="twitter:image:alt" content={Instructor_profileData.name + ' ' + Instructor_profileData.lastName + constand.INSTRUCTOR_DETAIL_PAGE_IMAGE_ALT} />
      </Helmet>
    );
  }
  render() {
    const Instructor_profileData =
      this.state.instructor_data && this.state.instructor_data.User
        ? this.state.instructor_data.User
        : {};
    const Insturctor_overviewData = this.state.instructor_data
      ? this.state.instructor_data
      : {};
    const Instructor_profileImg = constand.USER_IMAGE_PATH + Insturctor_overviewData.img;
    console.log('Instructor_profileData.name', Instructor_profileData.name)
    console.log('loader', this.state.Loader)
    return (
      <section className="instructor-content m-t-80">
        {Instructor_profileData.name && this.renderMetaTags(Instructor_profileData, Instructor_profileImg, Insturctor_overviewData.bio)}
        <div className="instructor_section-1">
          <div className="container-fluid mx-auto w-75">
            <div className="col-md-12 p-t-30">
              <div className=" demand-back">
                <a
                  href="javascript:void(0)"
                  onClick={this.goback}
                  className="font-15 font-book m-b-10 font-normal black-txt"
                >
                  {" "}
                  <i className="fa fa-chevron-left"></i> {(this.props.prevPath.indexOf('/liveClass/') != -1) ? "back to class" : "back to instructors"}
                </a>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3  m-t-20 m-b-20">
                  <div className=" user-profile">

                    <ImageTag
                      src={Instructor_profileImg}
                      className="img-fluid rounded-circle"
                      thumb={constand.WEB_IMAGES + "instructor-placeholder.png"}
                    />
                    <p className="font-semibold font-15 instru_head m-t-10">
                      {Instructor_profileData.name}{" "}
                      {Instructor_profileData.lastName}
                    </p>
                    <div className="font-qmedium font-13 in_subhead">
                      <i
                        className="fa fa-map-marker orangefont m-r-5 medium-text"
                        aria-hidden="true"
                      ></i>
                      {Insturctor_overviewData.location}
                    </div>
                    <div className="m-t-10">
                      {Insturctor_overviewData.twitterLink && (
                        <a
                          href={Insturctor_overviewData.twitterLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10"
                        >
                          <i className="fa fa-twitter twitter-color"></i>
                        </a>
                      )}
                      {Insturctor_overviewData.facebookLink && (
                        <a
                          href={Insturctor_overviewData.facebookLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10 facebook-color"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      )}
                      {Insturctor_overviewData.instagramLink && (
                        <a
                          href={Insturctor_overviewData.instagramLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10"
                        >
                          <i className="fa fa-instagram black-txt"></i>
                        </a>
                      )}
                      {Insturctor_overviewData.linkedinLink && (
                        <a
                          href={Insturctor_overviewData.linkedinLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10"
                        >
                          <i className="fa fa-linkedin black-txt"></i>
                        </a>
                      )}
                      {Insturctor_overviewData.tiktokLink && (
                        <a
                          href={Insturctor_overviewData.tiktokLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10"
                        >
                          <img src={constand.WEB_IMAGES + "tiktok.png"} className="tiktok" />
                        </a>
                      )}
                      {Insturctor_overviewData.webLink && (
                        <a
                          href={Insturctor_overviewData.webLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=" p-r-10"
                        >
                          <i className="fa fa-globe black-txt"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-lg-9 p-0">
                  <div className="col-md-11 offset-md-1 col-lg-11 offset-lg-1 m-t-20">
                    {Insturctor_overviewData.speciality &&
                      <p className="border-bottom row p-t-10 p-b-10">
                        <span className="col-md-4  p-l-0 font-semibold  font-15 black-txt">
                          Health area of expertise
                        </span>
                        <span className="col-md-8 p-l-0 black-txt font-14 font-qregular">
                          {Insturctor_overviewData.speciality}
                        </span>
                      </p>
                    }
                    {commonService.returnLabelData("conditionConnection", Insturctor_overviewData.InstructorTags) != "None" &&
                      <p className="border-bottom row p-t-10 p-b-10">
                        <span className="col-md-4  p-l-0 font-semibold  font-15 black-txt">
                          Connection to condition
                        </span>
                        <span className="col-md-8 p-l-0 black-txt font-14 font-qregular">
                          {commonService.returnLabelData(
                            "conditionConnection",
                            Insturctor_overviewData.InstructorTags
                              ? Insturctor_overviewData.InstructorTags
                              : []
                          )}
                        </span>
                      </p>
                    }
                    {commonService.returnLabelData("discipline", Insturctor_overviewData.InstructorTags) != 'None' &&
                      <p className="border-bottom row p-t-10 p-b-10">
                        <span className="col-md-4  p-l-0 font-semibold  font-15 black-txt">
                          Movement area of expertise
                        </span>
                        <span className="col-md-8 p-l-0 black-txt capitalize_text font-14 font-qregular">
                          {commonService.returnLabelData(
                            "discipline",
                            Insturctor_overviewData.InstructorTags
                              ? Insturctor_overviewData.InstructorTags
                              : []
                          )}
                        </span>
                      </p>
                    }
                    {Insturctor_overviewData.qualifications &&
                      <p className="border-bottom row p-t-10 p-b-10">
                        <span className="col-md-4  p-l-0 font-semibold  font-15 black-txt">
                          Qualification
                        </span>
                        <span className="col-md-8 p-l-0 black-txt font-14 font-qregular">
                          {Insturctor_overviewData.qualifications}
                        </span>
                      </p>
                    }
                    {Insturctor_overviewData.feelGoodFormula &&
                      <p className="border-bottom row p-t-10 p-b-10">
                        <span className="col-md-4  p-l-0 font-semibold  font-15 black-txt">
                          {Instructor_profileData.name}'s feel good formula
                        </span>
                        <span className="col-md-8 p-l-0 black-txt font-14 font-qregular">
                          {Insturctor_overviewData.feelGoodFormula}
                        </span>
                      </p>
                    }
                    <div className="instruct--about row m-b-10">
                      <h4 className="font-medium font-21 orangefont m-t-30 m-b-15 w-100">
                        About
                      </h4>
                      <p
                        className="list black-txt font-14 font-qregular"
                        dangerouslySetInnerHTML={{
                          __html: Insturctor_overviewData.bio
                        }}
                      ></p>
                      <hr className="w-100" />
                    </div>
                    {(Insturctor_overviewData.group && Insturctor_overviewData.group.length > 0) &&
                      <div className="instruct--about2 row m-b-10">
                        <h4 className="font-medium font-21 purplefont m-b-15">
                          {Instructor_profileData.name}'s Groups
                      </h4>
                        <div className="col-md-12 m-b-20">
                          <div className="row">
                            {Insturctor_overviewData.group &&
                              Insturctor_overviewData.group.map((item, key) => {
                                return (
                                  <div className="col-sm-12 col-md-6 p-l-0 m-b-20" key={key}>
                                    <div className="card-design" >
                                      <div className="blog-photo">
                                        <ImageTag
                                          className="img-fluid"
                                          src={
                                            item.banner_img
                                              ? constand.GROUP_IMAGE_PATH +
                                              item.banner_img
                                              : constand.WEB_IMAGES + "small_banner_img.png"
                                          }
                                        />
                                      </div>
                                      <div className="blog-content clearfix">
                                        <div className="blog-top clearfix">
                                          <h5 className="float-left font-semibold text-left black-txt font-15 blog_head">
                                            <Link
                                              to={
                                                "/group/about/" +
                                                item.community_name +
                                                "/" +
                                                this.condition
                                              }
                                            >
                                              {item.community_name}
                                            </Link>
                                          </h5>
                                          <h5 className="float-left font-semibold text-left black-txt font-15 blog_head">
                                            {item.Members && (
                                              <span className="">
                                                {item.Members.length} Members
                                              </span>
                                            )}
                                          </h5>
                                          {!this.props.is_auth && (
                                            <button
                                              onClick={() =>
                                                this.submitRequest(item)
                                              }
                                              className="btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                            >
                                              Join{" "}
                                              {item.type === "closed" && (
                                                <i className="fa fa-lock last-lock"></i>
                                              )}
                                            </button>
                                          )}
                                          {this.props.is_auth &&
                                            (!item.Members ||
                                              item.ismember === false) && (
                                              <React.Fragment>
                                                {item.Notifications &&
                                                  item.Notifications.length ===
                                                  0 && (
                                                    <button
                                                      onClick={() =>
                                                        this.submitRequest(item)
                                                      }
                                                      className="btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                                    >
                                                      Join{" "}
                                                      {item.type === "closed" && (
                                                        <i className="fa fa-lock last-lock"></i>
                                                      )}
                                                    </button>
                                                  )}
                                                {item.Notifications &&
                                                  item.Notifications.length > 0 && (
                                                    <button className="btn float-right dblog_btn font-14 btn-purple position-relative">
                                                      Requested{" "}
                                                      {item.type === "closed" && (
                                                        <i className="fa fa-lock last-lock"></i>
                                                      )}
                                                    </button>
                                                  )}
                                              </React.Fragment>
                                            )}
                                          {this.props.is_auth &&
                                            item.ismember === true && (
                                              <React.Fragment>
                                                <button
                                                  onClick={() =>
                                                    this.gotoGroupDetailPage(item)
                                                  }
                                                  className="btn float-right dblog_btn font-14 button-lightblue position-relative"
                                                >
                                                  Member{" "}
                                                  {item.type === "closed" && (
                                                    <i className="fa fa-lock last-lock"></i>
                                                  )}
                                                </button>
                                              </React.Fragment>
                                            )}
                                        </div>
                                        <div className="blog-bottom clearfix m-t-15 m-b-10">
                                          <ImageTag
                                            className="img-fluid rounded-circle"
                                            src={
                                              constand.PROFILE_IMAGE_PATH +
                                              Instructor_profileData.profilePic
                                            }
                                            width="50"
                                            height="50"
                                            thumb={constand.WEB_IMAGES + "instructor-placeholder.png"}
                                          />
                                          <span className="font-book font-14 m-l-5 light-grey">
                                            {commonService.bindUsername(
                                              Instructor_profileData
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="col-md-7 row"></div>
                        </div>
                        <hr className="w-100" />
                      </div>
                    }<div className="instruct--about3 row m-b-20">
                      {this.state.instructor_ondemand_paging.length > 0 &&
                        <h4 className="font-medium font-21 darkblue-text m-b-35">
                          Take a session with {Instructor_profileData.name}
                        </h4>
                      }
                      <div className="col-sm-12 p-0">
                        <div className="row">
                          {this.state.instructor_ondemand_paging && this.state.instructor_ondemand_paging.map(item => {
                            return (
                              <div
                                className="col-lg-6 col-md-12 col-sm-12 mb-4"
                                key={item.id}
                              >
                                <div className="card h-100">
                                  <Link
                                    to={"/detail/" + item.id + '/' + this.props.match.params.condition}
                                    className="position-relative"
                                  >
                                    <ImageTag
                                      className="card-img-top img-fluid"
                                      src={
                                        constand.WORKOUT_IMG_PATH +
                                        item.id +
                                        "-img.png"
                                      }
                                    />
                                    <span className="position-absolute vid_time">
                                      <span>{item.length}.00</span>
                                    </span>
                                  </Link>
                                  <div className="card-body">
                                    <div className="w-100 float-left m-b-10">
                                      <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                        <h3 className="demand_head black-txt font-14 font-semibold font-15 capitalize_text float-left w-75">
                                          {item.title}
                                        </h3>
                                        <div className="bookmark-left float-right font-14 text-right w-25">
                                          <WorkoutScheduleComponent
                                            updateSchedule={this.updateSchedule}
                                            ondemand_data={item}
                                            location={this.props.location}
                                            history={this.props.history}
                                          />
                                          <span className="m-l-10">
                                            <WorkoutSaveComponent
                                              page="instructor_detail"
                                              workoutData={item}
                                            />
                                          </span>
                                        </div>
                                        <div className="bookmark-left m-l-10 float-left"></div>
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <div className="col-12 float-left m-b-10 p-0">
                                        <div className="left-image  p-0 border-0 float-left ">
                                          <ImageTag
                                            className="img-fluid rounded-circle"
                                            src={Instructor_profileImg}
                                            thumb={constand.WEB_IMAGES + "instructor-placeholder.png"}
                                          />
                                        </div>
                                        <p className="float-left black-txt font-14 font-qregular p-l-10 p-t-5">
                                          {Instructor_profileData.name}{" "}
                                          {Instructor_profileData.lastName}
                                        </p>
                                      </div>
                                      <div className="col-12 float-left p-0">
                                        <div className="font-medium w-100">
                                          <div className="col-md-12 col-sm-12 float-left p-0">
                                            <span className="w-40 font-14 float-left black-txt font-qregular">
                                              Discipline:
                                            </span>
                                            <span className="font-14 font-qregular orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                              {(item.WorkoutTags) ? commonService.returnTag(
                                                "discipline", item.WorkoutTags, item.id
                                              ) : 'None'}
                                            </span>
                                          </div>
                                          <div className="col-md-12 col-sm-12 float-left p-0">
                                            <span className="w-40 font-14 float-left black-txt font-qregular">
                                              Difficulty:
                                            </span>
                                            <span className="font-14 font-qregular orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                              {(item.WorkoutTags) ? commonService.returnTag(
                                                "level", item.WorkoutTags, item.id
                                              ) : 'None'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {(this.state.instructor_data.Workouts && (this.state.instructor_ondemand_paging.length < this.state.instructor_data.Workouts.length)) &&
                            <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                              <button
                                className="btn btn-orange font-14 m-t-40 m-b-40 font-medium"
                                onClick={this.incrementShowmoreCnt}
                              >
                                View more sessions by{" "}
                                {Instructor_profileData.name}
                              </button>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {((Object.keys(Instructor_profileData).length === 0 && Instructor_profileData.constructor === Object) && (
                    <div className="text-center w-100">
                      {(!this.state.Loader) ? ("Instructor detail not found!") : ''}
                      {/* (<i className="fa fa-spinner fa-spin fa-2x text-info m-b-20" aria-hidden="true"></i>) */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    instructor_detail: state.instructor.instructor_detail,
    prevPath: state.workout.prevPath
  };
};

const mapDispatchToProps = {
  getInstructorDetails,
  joingroup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstructorDetailComponent);
