import React from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import * as constand from "../../constant";
import MultiSelectComponent from "../../_layoutComponents/MultiSelectComponent";
import _ from 'lodash';
import {
    getInstTags,
    getBeamUser,
    groupCMSOpen,
    createNewTag,
    removeTag,
    imageCropOpenPopup,
    getGroupLeader,
    createGroup
} from "../../actions";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie-consent";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import ImageCropperComponent from "../Common/ImageCropperComponent";
import { commonService } from "../../_services";
import RichTextEditor from 'react-rte';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Option = props => {
    return (
        <div>
            <div className={props.data.tagCount == 0 && props.selectProps.isRemoveable ? "pull-left optionWidth" : "pull-left w-100"}>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label} {props.value != '*' && props.selectProps.isRemoveable && <span className="black-txt font-semibold">({props.data.tagCount})</span>}</label>
                </components.Option>
            </div>
            {props.data.tagCount === 0 && <button onClick={() => props.selectProps.removeTag(props.value, props.selectProps.name)} className="btn pull-right orangefont removeTagBtn">X</button>}
        </div>
    );
};

const allOption = {
    label: "Select all",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}`;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All is selected";
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};
const animatedComponents = makeAnimated();
const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};
class GroupCMSComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorValue: RichTextEditor.createEmptyValue(),
            submitted: false,
            loading: false,
            isCropped: false,
            error: "",
            group: {
                community_name: '',
                isLive: 1,
                type: null
            },
            conditionOptions: [],
            userOptions: [],
            optionSelected: {
                'userid': [],
                'condition': [],
            },
            videoUrlError: false,
            crop: {
                unit: "px", // default, can be 'px' or '%'
                x: 0,
                y: 0,
                width: 200,
                height: 200,
                aspect: 1,
                disabled: true,
                locked: true
            },
            groupId: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
        this.handleBeamUserChange = this.handleBeamUserChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onCloseModel = this.onCloseModel.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.removeTag = this.removeTag.bind(this);
    }
    componentDidMount() {
        var groups = this.props.group_about_details;
        console.log('cmsdidmount-groups.endDate', groups.endDate)
        //new Date(moment(groups.endDate).utc().format('YYYY-MM-DD'))
        groups.endDate = (groups.endDate && groups.endDate != '0000-00-00') ? moment(moment(groups.endDate).utcOffset('').format('YYYY-MM-DD')).toDate() : null;
        console.log('cmsdidmount-groups.endDate-after', (groups.endDate))
        
        var groupId = this.props.group_about_details ? this.props.group_about_details.id : '';
        var UserId = this.props.group_about_details ? this.props.group_about_details.UserId : '';
        this.setState({ group: groups, groupId: groupId })
        this.props.getGroupLeader(commonService.replaceChar(Cookies.get('condition'), true), '').then(
            response => {
                if (response) {
                    if (groupId) {
                        var editUser = response.data.editUser;
                        var newOptions = this.state.optionSelected;
                        newOptions['userid'] = { label: groups.User.name + ' ' + groups.User.lastName, value: groups.OwnerId };
                        this.setState({
                            optionSelected: newOptions
                        });
                    }
                    this.setState({ userOptions: response.data.users })
                }
            },
            error => {
            }
        )

        this.props.getInstTags('condition', '').then(
            response => {
                if (response) {
                    if (response.data.editTags.length > 0) {
                        var editTags = response.data.editTags;
                        var newOptions = this.state.optionSelected;
                        editTags.forEach(element => {
                            newOptions['condition'] = element.Tag;
                        });
                        this.setState({
                            optionSelected: newOptions
                        });
                    }
                    // if (!groupId) {
                    var newOptions = this.state.optionSelected;
                    var newArra = _.filter(response.data.tags, function (condition) {
                        return condition.label === commonService.replaceChar(Cookies.get('condition'), true);
                    });
                    newOptions['condition'] = newArra;
                    this.setState({ optionSelected: newOptions })
                    //}
                    this.setState({ conditionOptions: response.data.tags })
                }
            },
            error => {
            })
    }
    componentWillReceiveProps(nextProps) {
        let { group } = this.state;

        if (this.props.cropped_image != nextProps.cropped_image && nextProps.cropped_image) {
            console.log('CMS-componentWillReceiveProps', nextProps)
            this.setState({
                isCropped: true,
                group: {
                    ...group,
                    img: nextProps.cropped_image,
                    postFile: nextProps.cropped_file
                }
            })
        } else {
            this.setState({
                //isCropped: true,
                group: {
                    ...group,
                    img: nextProps.cropped_image,
                    postFile: nextProps.cropped_file
                }
            })
        }
    }
    onCloseModel() { }
    removeTag(item, type) {
        console.log('removeTags', item);
        console.log('type', type);
        var data = { type: type, tag: item }
        this.props.removeTag(data).then(
            response => {
                if (response.status) {
                    var newOptions = this.state.optionSelected;
                    var newArra = _.filter(newOptions[type], function (condition) {
                        return condition.value != item;
                    });
                    newOptions[type] = newArra;
                    this.setState({ optionSelected: newOptions });

                    var me = this;
                    switch (type) {
                        case 'condition':
                            var newOptions = _.filter(me.state.conditionOptions, function (condition) {
                                return condition.value != item;
                            });
                            me.setState({ conditionOptions: newOptions })
                            break;
                        case 'discipline':
                            var newOptions = _.filter(me.state.disciplineOptions, function (condition) {
                                return condition.value != item;
                            });
                            me.setState({ disciplineOptions: newOptions })
                            break;

                    }
                }
            },
            error => { })
    }

    handleChange(e) {
        console.log('handleChange', e.target)
        const { name, value } = e.target;
        let { group } = this.state;
        switch (name) {
            case 'twitterLink':
                this.setState({ twitterLinkError: false });
                break;
            case 'facebookLink':
                this.setState({ facebookLinkError: false });
                break;
            case 'linkedinLink':
                this.setState({ linkedinLinkError: false });
                break;
            case 'instagramLink':
                this.setState({ instagramLinkError: false });
                break;
            case 'webLink':
                this.setState({ webLinkError: false });
                break;
            case 'tiktokLink':
                this.setState({ tiktokLinkError: false });
                break;
        }

        this.setState({
            videoLengthError: false,
            videoUrlError: false,
            group: {
                ...group,
                [name]: value,
            },
        });
    }
    handleMultiSelectChange(selected, type) {
        var newOptions = this.state.optionSelected;
        console.log('handleMultiSelectChange', selected)
        if (selected) {
            newOptions[type] = selected;
        }
        else {
            newOptions[type] = [];
        }
        this.setState({
            optionSelected: newOptions
        });
    };
    handleBeamUserChange(selected, type) {
        var newOptions = this.state.optionSelected;
        newOptions[type] = selected;
        this.setState({
            optionSelected: newOptions
        });
    }
    loadOptions(search, callback) {
        if (search != '') {
            this.props.getGroupLeader(commonService.replaceChar(Cookies.get('condition'), true), search).then(
                response => {
                    if (response) {
                        this.setState({ userOptions: response.data.users })
                        callback(this.state.userOptions)
                    }
                },
                error => {
                }
            )
        } else {
            console.log('LoadoptionsElse', this.state.userOptions)
            callback(this.state.userOptions)
        }
    }
    createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });


    clearState() {
        this.setState({
            submitted: false,
            loading: false
        });
    }

    closeModel() { //for close the login model
        this.clearState();
        this.props.groupCMSOpen(false);
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            this.setState({ imageModel: true });
            this.props.imageCropOpenPopup(true);
            var Pic = URL.createObjectURL(event.target.files[0]);
            let { group } = this.state;

            this.setState({
                group: {
                    ...group,
                    postFile: event.target.files[0],
                    img: Pic
                },
            });

        }
    };
    onSubmit() { //for submit group
        console.log('onSubmit', this.state)
        var conditionName = commonService.replaceChar(Cookies.get('condition'), true);
        var banner_img = conditionName.substring(
            conditionName.lastIndexOf(" ") + 1
        ).toLowerCase();
        banner_img = banner_img.charAt(0).toUpperCase() + banner_img.slice(1) + '_Group_Banner.png';
        console.log('banner_img', banner_img)

        this.setState({ submitted: true });
        const { optionSelected, group } = this.state;
        console.log('optionSelected-before', optionSelected.condition.length)

        // stop here if form is invalid
        if (!group.community_name || optionSelected['userid'].length == 0 || optionSelected['condition'].length == 0) {
            return;
        }
        this.setState({ loading: true });
        var endDate = moment(this.state.group.endDate).format('YYYY-MM-DD'); //moment(this.state.group.endDate).utc().format('YYYY-MM-DD');
        var params = {
            community_name: this.state.group.community_name,
            UserId: JSON.stringify(this.state.optionSelected['userid'].value),
            condition: JSON.stringify(this.state.optionSelected['condition'][0].value),
            banner_img: banner_img,
            endDate: endDate,
        }
        if (this.state.groupId) {
            params.id = this.state.groupId;
        }
        console.log('this.state.params', params);
        this.props.createGroup(params).then(response => {
            if (response) {
                var resdata = response;
                console.log('response', resdata);
                //this.closeModel()
                if (resdata.status) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }

                var selectedCondition = commonService.replaceChar(this.state.optionSelected['condition'][0].label, false);
                Cookies.set('condition', selectedCondition)
                window.location.href = '/groups/' + selectedCondition;
                this.closeModel()
            } else {
                this.setState({ loading: false });
                this.closeModel()
            }
         
        }, error => {
            toast.error(error);
            this.setState({ error: error, loading: false });
        })
    }
    handleChangeDate = date => {
        console.log('handleChangeDate' + date)
        let { group } = this.state;
        this.setState({
            group: {
                ...group,
                endDate: date,
            },
        });
        /*  var comb = this.formatDate(date) + ' ' + (liveclass.whentime) + ' ' + (liveclass.whenam);
         this.calculateZoneBasedDate(comb) */
    };
    render() {
        console.log('this.state.group', this.state.group)
        const ExampleCustomInput = function ({ value, onClick }) {
            console.log('value==>', value)
            value = value ? moment(value).format('dddd DD MMMM')  : null; //moment().format('dddd D MMMM');
            console.log('ExampleCustomInput', value)
            return (
                <div>
                    <input className="form-control popup-txt float-left w-90" value={value} type="text" name="endDate" onClick={onClick} />
                    <span className="float-right"><i onClick={onClick} aria-hidden="true" className="fa fa-calendar"></i></span>
                </div>
            )
        };
        const { submitted, loading } = this.state;
        var imageModel = false;
        return (
            <React.Fragment>
                {this.state.imageModel &&
                    <ImageCropperComponent crop={this.state.crop} aspectRatio={1 / 1} width={200} height={200} src={this.state.group.img} text="Upload Class Thumbnail" />
                }
                <Modal
                    className="removebbg-popup"
                    open={this.props.is_group_cms_open}
                    onClose={this.onCloseModel}
                    center
                >
                    <div className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) ? "modal-dialog modal-width--custom cms-model instructor-cms m-t-80" : "modal-dialog modal-width--custom cms-model instructor-cms m-t-80"} role="document">
                        <div className="modal-content">
                            <div className="modal-header header-styling">
                                <h5
                                    className="modal-title text-left col-md-11 p-0 font-semibold"
                                    id="exampleModalLabel font-medium"
                                >
                                    {this.state.groupId ? 'Modify ' : 'Add'} Group
                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={this.closeModel}>
                                        &times;
                  </span>
                                </button>
                            </div>
                            <div className="modal-body body-padding--value pb-0">
                                <div
                                    className={
                                        "form-group" +
                                        (submitted && !this.state.group.community_name ? " has-error" : "")
                                    }
                                >
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="font-semibold black-txt text-width"
                                    >
                                        Group Name <span className="orangefont">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        aria-describedby="emailHelp"
                                        placeholder=""
                                        name="community_name"
                                        value={this.state.group.community_name}
                                        onChange={this.handleChange}
                                        maxLength={constand.Input_Text_Limit}
                                    />
                                    {this.state.group.title ? (
                                        <div className="pull-right font-13 twitter-color mt-1">
                                            Characters Left: { constand.Input_Text_Limit - this.state.group.community_name.length}
                                        </div>
                                    ) : null}
                                    {submitted && !this.state.group.community_name && (
                                        <div className="text-danger">This is required</div>
                                    )}
                                </div>

                                <div
                                    className={
                                        "form-group" +
                                        (submitted && this.state.optionSelected.userid.length == 0 ? " has-error" : "")
                                    }
                                >
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="font-semibold black-txt"
                                    >
                                        Select Group Leader <span className="orangefont">*</span>
                                    </label>
                                    <MultiSelectComponent
                                        className="select-container text-capitalize"
                                        placeholder=""
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option,
                                            MultiValue,
                                            ValueContainer,
                                            animatedComponents
                                        }}
                                        onChange={this.handleBeamUserChange}
                                        allowSelectAll={false}
                                        creatable={false}
                                        isAsync={true}
                                        value={this.state.optionSelected['userid']}
                                        name="userid"
                                        loadOptions={this.loadOptions}
                                        defaultOptions={this.state.userOptions}
                                    />
                                    {submitted && this.state.optionSelected.userid.length == 0 && (
                                        <div className="text-danger">This is required</div>
                                    )}
                                </div>
                                {(Cookies.get('condition')).toLowerCase().includes('research') &&
                                    <div
                                        className={
                                            "form-group m-b-0 " +
                                            (submitted && this.state.optionSelected.userid.length == 0 ? " has-error" : "")
                                        }
                                    >
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="font-semibold black-txt"
                                        >
                                            Intervention End Date
                                    </label>

                                    </div>
                                }
                                {(Cookies.get('condition')).toLowerCase().includes('research') &&
                                    <div className="form-group">
                                        <DatePicker
                                        //dateFormat="MM-DD-YYYY"
                                            selected={this.state.group.endDate}
                                            onChange={this.handleChangeDate}
                                            //minDate={new Date()}
                                            customInput={<ExampleCustomInput value={this.state.endDate} />}
                                        />
                                    </div>
                                }
                                <div className="col-md-12 text-center p-0 m-b-15">
                                    <div className="row">
                                        <div className="offset-8 col-md-4">
                                            <button
                                                disabled={loading}
                                                onClick={this.onSubmit}
                                                className=" btn btn-block mybtn btn-login tx-tfm font-book font-14"
                                            >Save Changes </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        is_model_open: state.header.is_loginModelOpen,
        init_condition: state.auth.initial_condition,
        all_condition: state.register.healthcondition_list,
        is_cms_model: state.workout.is_cms_model,
        cms_model_type: state.workout.cms_model_type,
        group_detail: state.group.group_detail,
        cropped_image: state.workout.cropped_image,
        cropped_file: state.workout.cropped_file,
        is_group_cms_open: state.group.is_group_cms_open,
        group_about_details: state.group.group_about_details,
    };
};

const mapDispatchToProps = {
    getInstTags,
    getBeamUser,
    groupCMSOpen,
    createNewTag,
    removeTag,
    imageCropOpenPopup,
    getGroupLeader,
    createGroup
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupCMSComponent);
