import React from 'react';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { teensOnBeam } from "../../actions"
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { RichText } from 'prismic-reactjs';
import { ImageTag, TextWrap } from "../../tags";
var _ = require('lodash');

class TeensOnBeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teensOnBeamDetails: {},
      Loading: false,
      horizontal_section: []
    };
  }

  componentDidMount() {
    this.setState({ Loading: true })
    let teensOnBeamDetails = this.props.teens_on_beam_details &&
      this.props.teens_on_beam_details.length > 0 ? this.props.teens_on_beam_details :
      JSON.parse(localStorage.getItem('teensOnBeam'));
    if (teensOnBeamDetails && teensOnBeamDetails.length > 0) {
      this.setTeensOnBeamDetails(teensOnBeamDetails)
    }
    window.scrollTo(0, 0)
  }

  setTeensOnBeamDetails = (teensOnBeamDetails) => {
    let horizontal_section = teensOnBeamDetails[0].data && teensOnBeamDetails[0].data.horizontal_section
    horizontal_section = horizontal_section && horizontal_section.sort((a, b) => parseFloat(a.order_on_page) - parseFloat(b.order_on_page));
    this.setState({
      teensOnBeamDetails: teensOnBeamDetails[0].data,
      horizontal_section: horizontal_section,
      Loading: false
    })
  }


  imageSection(item, key) {
    var image = (item && item.horizontal_section_image) ? item.horizontal_section_image.url : '';
    return (
      <figure className="position-relative image-text-blog m-t-20 text-center">
        <ImageTag className="img-fluid" src={image} />
      </figure>);
  }
  bodyTextSection(item) {
    var data = (item.items && item.items.length > 0) ? item.items[0].general_page_text : '';
    if (data && data.length > 0) {
      let renderHtml = <p class="w-80 mx-auto p-t-20 font-21 black-txt p-b-10 font-medium">
        {RichText.asText(data)}
      </p>
      return renderHtml;
    }
  }
  titleSection(item) {
    var data = (item && item.horizontal_section_title.length > 0) ? item.horizontal_section_title : '';
    let renderHtml = <>{RichText.asText(data)}</>;
    return renderHtml;
  }
  textSection(item) {
    var data = (item && item.horizontal_section_text.length > 0) ? item.horizontal_section_text : '';
    let renderHtml = <>{RichText.asText(data)}</>;
    return renderHtml;
  }
  colourSelection(item) {
    var data = (item && item.horizontal_section_colour.length > 0) ? item.horizontal_section_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  colourText(item) {
    var data = (item && item.horizontal_section_text_colour) ? item.horizontal_section_text_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  sectionAlignment(item) {
    var data = (item && item.horizontal_section_alignment.length > 0) ? item.horizontal_section_alignment : '';
    let renderHtml = data;
    return renderHtml;
  }

  videoSection(item) {
    var video = (item && item.horizontal_section_video) ? item.horizontal_section_video.html : '';
    let renderHtml = <div class="text-center justify-content-center p-t-40 h-100 " dangerouslySetInnerHTML={{ __html: video }} />;
    return renderHtml;
  }

  htmlSection(item) {
    let header_html_code = (item.items && item.items.length > 0) ? item.items[0].header_html_code : [];
    let content_html_code = (item.items && item.items.length > 0) ? item.items[0].content_html_code : [];
    let renderHtml = <div><div dangerouslySetInnerHTML={{ __html: RichText.asText(header_html_code) }} /><div dangerouslySetInnerHTML={{ __html: RichText.asText(content_html_code) }} /></div>;
    return renderHtml;
  }

  buttonTextSection(item) {
    let data = (item && item.button_text.length > 0) ? item.button_text : '';
    let renderHtml = <>{RichText.asText(data)}</>;
    return renderHtml;
  }

  buttonTextColor(item) {
    let data = (item && item.button_text_colour) ? item.button_text_colour : '';
    let renderHtml = data;
    return renderHtml;
  }

  buttonBackgroundColor(item) {
    let data = (item && item.button_background_colour) ? item.button_background_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  goToButtonUrl = (item) => {
    let data = (item && item.button_url.length > 0) ? item.button_url : '';
    let returnData = data.length > 0 ? data[0].text : '#';
    return returnData
  }
  buttonAlignment = (item) => {
    let data = (item && item.button_alignment) ? item.button_alignment : '';
    if (data == "centre") {
      data = "center"
    }
    return data
  }

  buttonTextFormat = (item) => {
    let data = (item && item.button_text_format) ? item.button_text_format : '';
    return data
  }

  buttonLocation = (item) => {
    let data = (item && item.button_location) ? item.button_location : '';
    return data
  }

  renderButton = (item) => {
    return (
      <>
        <div style={{
          textAlign: item.button_alignment && this.buttonAlignment(item)
        }}>
          <button className="dynamic_button font-medium" style={{
            backgroundColor: item.button_background_colour && this.buttonBackgroundColor(item),
            fontStyle: item.button_text_format && this.buttonTextFormat(item),
            fontWeight: item.button_text_format && this.buttonTextFormat(item),
            textDecoration: item.button_text_format && this.buttonTextFormat(item)
          }} >
            <a className="dynamic_link" style={{ color: item.button_text_colour && this.buttonTextColor(item) }} href={this.goToButtonUrl(item)} > {this.buttonTextSection(item)}</a>
          </button>

        </div>
      </>
    )
  }


  render() {

    return (

      <>
        <section className="homecontent h-auto ">
          <div className="section1-bg teenonbeams_sec">
            <div class="container-fluid mx-auto w-75">
              <div class="section-header text-center p-t-30 p-b-30">
                <h3 class="darkblue-text font-37 font-medium"><RichText className="black-txt font-qregular m-b-10" render={this.state.teensOnBeamDetails['title']} /></h3>
                {this.state.teensOnBeamDetails.body && this.state.teensOnBeamDetails.body.map((item, key) => {
                  return (<React.Fragment key={key}>
                    {item.items.length > 0 && (item.slice_type === 'textsection') && this.bodyTextSection(item)}
                  </React.Fragment>);
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="section1-bg teenonbeams_sec">

          {/* horizontal_section start */}
          <div>
            {this.state.horizontal_section && this.state.horizontal_section.map((item, key) => {
              return (<React.Fragment key={key}>
                <div className="row" style={{ backgroundColor: item.horizontal_section_colour && this.colourSelection(item) }}>
                  <div className="container-fluid mx-auto w-75 video_height">
                    <div className="row p-t-60 p-b-30">
                      {/* Button whole above section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                        {((Object.keys(item.horizontal_section_image).length !== 0) || (Object.keys(item.horizontal_section_video).length !== 0)) && (Object.keys(item.horizontal_section_text).length !== 0) && <>
                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "whole_section" && <div className="text-center p-b-20">{this.renderButton(item)}</div>}</>
                        }
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                        <div className="row p-0">
                          {/* image section */}
                          {(Object.keys(item.horizontal_section_image).length !== 0) && (Object.keys(item.horizontal_section_text).length !== 0) && (Object.keys(item.horizontal_section_title).length !== 0) &&
                            <>
                              <div className={'col-12 col-md-6 col-lg-6 text-center onlyimgsec float' + item.image_align}>
                                {/* button section */}
                                {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}
                                {item.horizontal_section_image && this.imageSection(item, key)}
                                {/* button section */}
                                {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'under_image' && <div className="p-b-10 p-t-30">{this.renderButton(item)}</div>
                                }

                              </div>
                            </>
                          }

                          {/* video section */}
                          {(Object.keys(item.horizontal_section_video).length !== 0) &&
                            <>
                              <div className={'col-12 col-md-6 col-lg-6 text-center video_bottom_mb float' + item.image_align}>
                                {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>
                                }
                                {(item.horizontal_section_video.type == "video") && this.videoSection(item)}
                                {/* button section */}
                                {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>
                                }
                              </div>

                            </>
                          }

                          {/* video content section */}
                          {(Object.keys(item.horizontal_section_video).length !== 0) &&
                            <div className={'col-12 col-md-6 col-lg-6 text-left float' + item.text_align}>
                              {/* Title section */}
                              <h4 style={{ color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                                {item.horizontal_section_title && this.titleSection(item)}
                              </h4>
                              {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "under_text" && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}
                              {/* text seslection */}
                              <div className="black-txt font-qregular m-b-10"
                                style={{
                                  color: item.horizontal_section_text_colour &&
                                    this.colourText(item)
                                }}
                              >
                                {item.horizontal_section_text && this.textSection(item)}
                              </div>
                              {/* button section */}
                              {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "under_text" && <div className="p-t-30">{this.renderButton(item)}</div>}

                            </div>
                          }

                          {/* image content section */}
                          {(Object.keys(item.horizontal_section_image).length !== 0) &&
                            <div className={'col-12 col-md-6 col-lg-6 text-left float' + item.text_align}>

                              {/* Title section */}
                              <h4 style={{ color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                                {item.horizontal_section_title && this.titleSection(item)}
                              </h4>
                              {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "under_text" && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}

                              {/* text selection */}
                              <div className="black-txt font-qregular m-b-10"
                                style={{
                                  color: item.horizontal_section_text_colour &&
                                    this.colourText(item),
                                }}
                              >
                                {item.horizontal_section_text && this.textSection(item)}
                              </div>
                              {/* button section */}
                              {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "under_text" && <div className="p-t-30">{this.renderButton(item)}</div>}
                            </div>
                          }
                        </div>
                      </div>
                      {/* Button whole below section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                        {((Object.keys(item.horizontal_section_image).length !== 0) || (Object.keys(item.horizontal_section_video).length !== 0)) && (Object.keys(item.horizontal_section_text).length !== 0) &&
                          <>
                            {/* button section */}
                            {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "whole_section" && <div className="text-center p-t-20">{this.renderButton(item)}</div>}
                          </>
                        }
                      </div>

                      {/* if not image and video  */}
                      {(Object.keys(item.horizontal_section_image).length === 0) && (Object.keys(item.horizontal_section_video).length === 0) &&
                        <div className="w-75 mx-auto">

                          {/* Title section */}
                          <h4 style={{ textAlign: "center", color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                            {item.horizontal_section_title && this.titleSection(item)}
                          </h4>
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'whole_section' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}
                          {/* text selection */}
                          <div className="black-txt font-qregular"
                            style={{
                              color: item.horizontal_section_text_colour &&
                                this.colourText(item), textAlign: "center"
                            }}
                          >
                            {item.horizontal_section_text && this.textSection(item)}
                          </div>
                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'whole_section' && <div className="p-t-30">{this.renderButton(item)}</div>}
                        </div>
                      }
                      {/* Only image section */}
                      {(Object.keys(item.horizontal_section_text).length === 0) && (Object.keys(item.horizontal_section_title).length === 0) && (Object.keys(item.horizontal_section_video).length === 0) &&
                        <div className="w-75 mx-auto text-center">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "above" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                          {item.horizontal_section_image && this.imageSection(item, key)}
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "below" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                        </div>

                      }

                      {/* Only video section */}
                      {(Object.keys(item.horizontal_section_text).length === 0) && (Object.keys(item.horizontal_section_title).length === 0) && (Object.keys(item.horizontal_section_image).length === 0) &&
                        <div className="w-75 mx-auto text-center">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "above" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                          {item.horizontal_section_video.type == "video" && this.videoSection(item, key)}
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "below" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </React.Fragment>);
            })}
          </div>
          {/* horizontal_section end */}

          <div className="text-center w-100">
            {(this.state.Loading) && (<AnimateLoaderComponent />)}
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    teens_on_beam_details: state.header.teens_on_beam_details,
  };
};

const mapDispatchToProps = {
  teensOnBeam
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeensOnBeam);
