import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import * as constand from "../../constant";
import { commonService } from "../../_services";
import {
    saveUserActivity, deleteUserActivity, setSliderValue, setDatepickerValue,
    setDateValue,
    setTimeValue, updateJsonFormData
} from "../../actions";
import Form from "@rjsf/core";
import DatePickerWidget from "../Common/DatePickerWidget";
import RangeSliderWidget from "../Common/RangeSliderWidget";
import SchemaFormComponent from "../Common/SchemaFormComponent";

class ActivityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            isShowDesc: true,
            selectedTime: Object.keys(this.props.formData).length ? this.props.formData.time:'',
            selectedDay: Object.keys(this.props.formData).length ? new Date(this.props.formData.date) : new Date(),
            formType: 'Add',
            horizontal: Object.keys(this.props.formData).length ? this.props.formData.exertion : '',
            isShowDesc: Object.keys(this.props.formData).length && this.props.formData.exertion ? false : true,
            isDeleteConfirmation: false
        };
        this.submitForm = this.submitForm.bind(this);
        this.transformErrors = this.transformErrors.bind(this);
        this.customFieldTemplate = this.customFieldTemplate.bind(this);
        this.exertionFieldTemplate = this.exertionFieldTemplate.bind(this);
        this.CustomDatePicker = this.CustomDatePicker.bind(this);
        this.CustomSlider = this.CustomSlider.bind(this);
        this.deleteActivity = this.deleteActivity.bind(this);
        this.changedFormData = this.changedFormData.bind(this);

        this.uiSchema =
        {
            activityType: {
                classNames: "col-md-12",
            },
            title: {
                classNames: "col-md-12",
            },
            date: {
                classNames: "col-md-12",
            },
            duration: {
                "ui:placeholder": "mins",
                classNames: "col-md-12",
            },
            distance: {
                classNames: "col-md-3",
            },
            distanceUnits: {
                "ui:options": {
                    label: false,
                },
                classNames: "col-md-3 font-book",
            },
            exertion: {
                classNames: "col-md-12",
                "ui:widget": this.CustomSlider,
            },
            'Very light': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            },
            'Light': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            },
            'Moderate': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            },
            'Vigorous': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            },
            'Very hard': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            },
            'Max effort': {
                classNames: "col-md-12",
                "ui:FieldTemplate": this.exertionFieldTemplate,
            }
        }
        this.widgets = {
            DateTimeWidget: this.CustomDatePicker,
        };
        this.exertionText = ['Very light', 'Light', 'Moderate', 'Vigorous', 'Very hard', 'Max effort'];
    };

    log = (type) => console.log.bind(console, type);
    componentDidMount() {
        if (this.props.activityId) {
            var comb = commonService.formatDateFromString(this.props.formData.date) + ' ' + commonService.formatTimeFromSelector(this.props.formData.time);
            console.log('didmount-comb', comb)
            var dateObj = new Date(comb);
            var datetime = new Date(dateObj).toISOString();
            var formLocal = this.props.formData;
            formLocal.date = datetime;
            this.setState({
                formData: formLocal,
                formType: this.props.activityId ? 'Edit' : 'Add',
                selectedDay: new Date(this.props.formData.date),
                selectedTime: this.props.formData.time
            });
            this.props.setSliderValue(this.props.formData.exertion)
        } else {
            var comb = commonService.formatDateFromString(this.state.selectedDay) + ' ' + commonService.formatTimeFromSelector(this.state.selectedTime);
            console.log('didmount-comb', comb)
            var dateObj = new Date(comb);
            var datetime = new Date(dateObj).toISOString();
            var formData = this.state.formData;
            formData.date = datetime;
            this.setState({
                formData: formData
            })
        }
    }
    onCloseModal() { }

    transformErrors(errors) {
        console.log('transformErrors', errors)
        var schema = this.props.modelData;
        return errors.map(error => {
            var propertyName = error.property.replace('.', '')
            // use error messages from JSON schema if any
            if (schema.properties[propertyName].messages && schema.properties[propertyName].messages[error.name]) {
                console.log('message', schema.properties[propertyName].messages[error.name])
                return {
                    ...error,
                    message: schema.properties[propertyName].messages[error.name]
                };
            }
            return error;
        });
    }

    customFieldTemplate(props) {
        console.log('customFieldTemplate-props', props)
        const { id, classNames, label, help, required, description, errors, children, rawErrors, displayLabel } = props;
        return (
            <div className={classNames + ' float-left'}>
                {id != 'root' && displayLabel &&
                    <label htmlFor={id} className="font-medium black-txt">{label} <span className="orangefont">{required ? " *" : null}</span></label>
                }
                {id != 'root' && !displayLabel && <label htmlFor={id} className="font-medium black-txt">&nbsp;</label>
                }
                {description}
                <span className="orangefont float-right"> {rawErrors} </span>
                {children}
                {help}
            </div>
        );
    }

    exertionFieldTemplate(props) {
        console.log('exertionFieldTemplate-props', props)
        const { id, classNames, label, rawDescription } = props;
        return (
            <div className={classNames + ' float-left'}>
                <p htmlFor={id} className=" m-t-10 font-14 font-qregular content_shrink"><span className="font-semibold black-txt font-14">{label}: </span><span>{rawDescription}</span></p>
            </div>
        );
    }

    submitForm(submittedData) {
        console.log('submitForm', submittedData.formData);
        var params = submittedData.formData;
        console.log('selectedTimeFrom', moment.utc(params.date).format('HH:mm:ss'));
        params.time = params.date.includes('T') ? moment.utc(params.date).format('HH:mm:ss') : null;
        params.id = this.props.activityId || 0;
        params.timezone = commonService.localTimeZoneName();
        params.exertionText = this.exertionText;
        console.log('params', params)
        this.props.saveUserActivity({ formData: params }).then(response => {
            toast.success(response.message);
            this.props.modelClose();
            this.setState({ formData: {} })
        }, error => {
            toast.error(error)
            this.props.modelClose();
            this.setState({ formData: {} })
        })
    }

    changedFormData(formDetails) {
        console.log('formDetails', formDetails)
        //var formData = formDetails.formData;
        //this.props.updateJsonFormData(formDetails.formData)
    }

    deleteActivity() {
        this.props.deleteUserActivity({ activityId: this.props.activityId }).then(response => {
            toast.success(response.message);
            this.props.modelClose();
            this.setState({ formData: {} })
        }, error => {
            toast.error(error)
            this.props.modelClose();
            this.setState({ formData: {} })
        })
    }

    //custom date picker
    CustomDatePicker(props) {
        return (
            <div>
                <DatePickerWidget
                    formProps={props}
                    defaultSelectedDay={this.state.selectedDay}
                    defaultSelectedTime={this.state.selectedTime}
                    onChange={this.handleDateChanged}
                />
            </div>
        );
    };

    //custom slider
    CustomSlider(props) {
        const labels = {
            1: 'Very light',
            5: 'Moderate',
            10: 'Max effort'
        }
        return (
            <div>
                <RangeSliderWidget
                    //value={this.state.horizontal}
                    formProps={props}
                    fieldSchema={props.schema}
                    labels={labels}
                    isShowDesc={this.state.isShowDesc}
                    descClass="font-14 font-medium"
                    onChange={this.handleChangeHorizontal} />
            </div>
        );
    };

    //on change slider value
    handleChangeHorizontal = (value, props) => {
        props.onChange(value);
        this.props.setSliderValue(value)
    };

    //on change date changed
    handleDateChanged = (type, value, props) => {
        console.log('handleDateChanged=', value)
        props.onChange(value);

    };

    render() {
        const item = this.props.modelData;
        console.log('render-form')
        return (
            <div className="activityModal">
                <Modal
                    className="removebbg-popup"
                    open={this.props.is_model_open}
                    onClose={this.onCloseModal}
                    center
                >
                    <div
                        className="activityModal modal-dialog common_design modal-width--custom live-classes--popup m-t-50 w-100"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0">
                                <h5
                                    className="text-center white-txt col-md-12 p-0 font-book font-24"
                                    id="exampleModalLabel "
                                >
                                    {this.state.isDeleteConfirmation ? "Are you sure?" : (this.state.formType) + " an activity"}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() => this.props.modelClose()}
                                    >
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body body-padding--value pb-0">
                                {/* <SchemaFormComponent
                                schema={item}
                                onChange={this.log("changed")}
                                onSubmit={(params) => { this.submitForm(params) }}
                                onError={this.log("errors")}
                               // noHtml5Validate='true'
                               // transformErrors={this.transformErrors}
                                //showErrorList={false}
                                uiSchema={this.uiSchema}
                                FieldTemplate={this.customFieldTemplate}
                                widgets={this.widgets}
                                formData={this.state.formData}
                               // omitExtraData={true}
                               // liveOmit={true}
                            /> */}
                                {!this.state.isDeleteConfirmation &&
                                    <Form
                                        safeRenderCompletion={true}
                                        schema={item}
                                        onChange={(params) => { this.changedFormData(params) }}
                                        onSubmit={(params) => { this.submitForm(params) }}
                                        onError={this.log("errors")}
                                        noHtml5Validate='true'
                                        transformErrors={this.transformErrors}
                                        showErrorList={false}
                                        uiSchema={this.uiSchema}
                                        FieldTemplate={this.customFieldTemplate}
                                        widgets={this.widgets}
                                        formData={this.state.formData}
                                        omitExtraData={true}
                                        liveOmit={true}
                                    >
                                        {this.props.activityId &&
                                            <div className="buttons-read w-100 m-t-20 float-left text-center col-lg-12 col-xs-12 col-md-12">
                                                <button className="btn btn-blue-inverse font-14 read-now col-xs-8 col-lg-4 m-t-20 m-r-20 col-md-4" type="button" onClick={() => { this.setState({ isDeleteConfirmation: true }) }}>
                                                    Delete Activity
                                                </button>
                                                <button className="btn btn-beam-blue font-14 read-later pointer col-xs-8 m-t-20 col-lg-6 col-md-6" type="submit" >
                                                    Save Changes
                                                </button>
                                            </div>
                                        }
                                        {!this.props.activityId &&
                                            <div className="w-100 m-t-20 float-left text-center pointer">
                                                <button type="submit" className="btn btn-beam-blue">
                                                    Add Activity
                                                </button>
                                            </div>
                                        }
                                    </Form>
                                }
                                {this.state.isDeleteConfirmation &&
                                    <div>
                                        <p className="text-center">Are you sure you want to delete this activity?</p>
                                        <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                                            <a className="btn btn-blue-inverse font-14 read-now m-r-20" onClick={() => { this.setState({ isDeleteConfirmation: false }) }}>
                                                No, go back
                                            </a>
                                            <a className="btn btn-blue-inverse font-14 read-later m-l-20 pointer" onClick={() => this.deleteActivity()}>
                                                Yes, do it!
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        selectedDate: state.dashboard.selectedDate,
        selectedTimeFrom: state.dashboard.selectedTime,
        json_form_data: state.dashboard.json_form_data
    };
};

const mapDispatchToProps = {
    saveUserActivity,
    deleteUserActivity,
    setSliderValue,
    setDatepickerValue,
    setDateValue,
    setTimeValue,
    updateJsonFormData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityModal);