import React from "react";
import { connect } from "react-redux";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import {
  fetchSavedList,
  fetchSavedBlogList,
  saveBlog,
  loginModelOpen,
  unSaveVideo,
  start_loader,
  stop_loader,
  saveSession
} from "../../actions";
import { commonService } from "../../_services";
import { Link } from "react-router-dom";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import Modal from "react-responsive-modal";
import { ImageTag, TextWrap } from "../../tags";
import { Helmet } from "react-helmet";

class SavedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: false,
      offset: 0,
      count: 0,
      savedList: [],
      limit: constand.CONSTSIX,
      is_model_open: false,
      removedItem: {},
      condition: this.props.params.condition,
      savedBlogsCount: 0,
      savedBlogs: [],
      offsetBlog: 0,
      limitBlog: constand.CONSTTHREE,
      savedSessionList: []

    };
    this.closeModel = this.closeModel.bind(this);
  }
  /** fetch live class list on page did mount */
  componentDidMount() {
    var condition = commonService.replaceChar(this.state.condition, true);
    this.setState({ condition: condition }, () => {
      this.fetchSavedList();
      this.fetchSavedBlogList();
    });
  }
  componentWillReceiveProps(prevProps) {
    if (
      this.props.params &&
      prevProps.params.condition &&
      this.props.params.condition !== prevProps.params.condition
    ) {
      var condition = commonService.replaceChar(prevProps.params.condition, true);
      this.setState({ condition: condition }, () => {
        this.fetchSavedList();
      });
    }
  }
  /**
   * fetch user data
   */
  fetchSavedList() {
    this.props.start_loader();
    this.setState({ disableButton: true });
    var dataObj = {
      offset: this.state.offset,
      limit: this.state.limit,
      condition: this.state.condition
    };
    this.props.fetchSavedList(dataObj).then(
      response => {
        this.props.stop_loader();
        var list = response.list;

        this.setState({
          disableButton: false,
          count: list.count,
          savedList: list.saved,
          savedSessionList: list.savedSession || []
        });
      },
      error => {
        this.props.stop_loader();
        this.setState({
          Loading: false,
          disableButton: false
        });
        //toast.error(error);
      }
    );
  }
  /**
   * loadMoreVideos
   */
  loadMoreVideos(isNext) {
    var offset = isNext
      ? this.state.offset + this.state.limit
      : this.state.offset - this.state.limit;
    this.setState(
      {
        offset: offset
      },
      () => {
        this.fetchSavedList();
      }
    );
  }
  /**
   * fetch saved blogs
   */
  fetchSavedBlogList() {
    this.props.start_loader();
    this.setState({ disableButton: true });
    var dataObj = {
      offset: this.state.offsetBlog,
      limit: this.state.limitBlog
    };
    this.props.fetchSavedBlogList(dataObj).then(
      response => {
        this.props.stop_loader();
        var list = response.list;
        this.setState({ savedBlogs: list.blogs, savedBlogsCount: response.count, disableButton: false });
      },
      error => {
        this.props.stop_loader();
        this.setState({
          Loading: false,
          disableButton: false
        });
        //toast.error(error);
      }
    );
  }
  /**
   * loadMoreSavedBlogs
   */
  loadMoreSavedBlogs(isNext) {
    var offset = isNext
      ? this.state.offsetBlog + this.state.limitBlog
      : this.state.offsetBlog - this.state.limitBlog;
    this.setState(
      {
        offsetBlog: offset
      },
      () => {
        this.fetchSavedBlogList();
      }
    );
  }
  saveBlog(key) {
    //single method for save and unsave
    this.props.start_loader();
    var item = this.state.savedBlogs[key];
    var dataObj = { blogId: item.uid };
    this.props.saveBlog(dataObj, true).then(
      response => {
        if (response) {
          var buffer = [...this.state.savedBlogs];
          buffer[key].isSaved = false;
          this.setState({ offsetBlog: 0 });
          this.fetchSavedBlogList();
          toast.success(response.message);
          this.props.stop_loader();
        }
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );

  }
  /**
   *
   * @param {*} item
   */
  renderActionComponent(item) {
    return (
      <div>
        <WorkoutScheduleComponent
          location={this.props.location}
          history={this.props.history}
          className="m-l-10"
          page="savedlist"
          ondemand_data={item.Workout}
        />
        <span className="m-l-10">
          <em
            className="fa fa-bookmark pointer fill-orange pointer"
            onClick={() => {
              this.removeVideoFromList(item);
            }}
          ></em>
        </span>
      </div>
    );
  }
  /**
   *
   * render video list
   */
  renderSavedVideo() {
    return this.state.savedList.filter(e => (e.Workout)).map((item, index) => (
      <div className="col-lg-4 col-md-4 col-sm-12 float-left" key={index}>
        <div className="card h-100">
          <Link
            className="position-relative"
            to={"/detail/" + item.Workout.id + "/" + (commonService.replaceChar(this.props.params.condition, false))}
          >
            <img
              className="card-img-top img-fluid"
              alt=""
              src={
                constand.WORKOUT_IMG_PATH +
                item.Workout.id +
                "-img.png"
              }
            />
            <span className="position-absolute vid_time">
              <span>{item.Workout.length} mins</span>
            </span>
          </Link>
          <div className="card-body p-l-10 p-r-10">
            <div className="w-100 float-left m-b-10">
              <div className="col-md-10 col-lg-10 col-sm-10 col-10 float-left pl-0">
                <h3 className="heading_3 text-body font-medium text-uppercase">
                  {item.Workout.title}
                </h3>
              </div>
              <div className="col-md-2 col-lg-2 col-sm-2 col-2 float-left pl-0 pr-0">
                {this.renderActionComponent(item)}
              </div>
            </div>
            <div className="w-100">
              <div className="col-md-6 col-lg-6 col-sm-6 col-6 float-left m-b-10 p-0">
                <div className="left-image col-md-1 col-lg-1 col-sm-1 p-0 border-0 float-left">
                  <img
                    className="img-fluid rounded-circle"
                    src={
                      item.Workout &&
                        item.Workout.Instructor &&
                        item.Workout.Instructor.img
                        ? constand.USER_IMAGE_PATH +
                        item.Workout.Instructor.img
                        : constand.WEB_IMAGES + "no-image.png"
                    }
                    alt=""
                  />
                </div>
                <p className="float-left p-l-10">
                  {commonService.bindUsername(item.Workout.Instructor.User)}
                </p>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6  col-6 float-left pr-0 p-l-5">
                <div className="font-medium w-100 font-light--weight">
                  <div className="col-md-12 col-sm-12 float-left p-0">
                    <span className="w-40 float-left">Discipline:</span>
                    <span className="redtext w-60 float-left font-medium capitalize_text">
                      {(item.Workout && item.Workout.WorkoutTags) ? commonService.returnTag(
                        "discipline", item.Workout.WorkoutTags, item.Workout.id
                      ) : 'None'}
                    </span>
                  </div>
                  <div className="col-md-12 col-sm-12 float-left p-0">
                    <span className="w-40 float-left">Difficulty:</span>
                    <span className="redtext w-60 float-left font-medium capitalize_text">
                      {" "}
                      {(item.Workout && item.Workout.WorkoutTags) ? commonService.returnTag(
                        "level", item.Workout.WorkoutTags, item.Workout.id
                      ) : 'None'}
                    </span>
                  </div>
                  {/* <div className="col-md-12 col-sm-12 float-left p-0"><span className="w-40 float-left">Language:</span><span className="w-60 float-left"> <img className="img-fluid" src="/images/flag.png" alt="" /></span></div>
                   */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  /**
   * un save video
   */
  removeVideoFromList(item) {
    var dataObj = {
      workoutId: item.Workout.id
    };
    this.props.unSaveVideo(dataObj).then(
      response => {
        let handler = [...this.state.savedList];
        var index = handler.findIndex(x => x.id === item.id);
        if (index > -1) {
          handler.splice(index, 1);
        }
        this.setState({ savedList: handler });
        toast.success(response.message);
        // this.setState({ removedItem:item})
        // this.openModalPopup();
      },
      error => {
        this.setState({
          Loading: false
        });
        toast.error(error);
      }
    );
  }
  /**
   * to open modal popup
   */
  openModalPopup() {
    this.setState({
      is_model_open: true
    });
  }

  closeModel() { //for close the login model
    this.setState({
      is_model_open: false
    });
  }
  onCloseModal() { }
  /**
   * render Modal popup for success message
   */
  renderModalPopup() {
    if (this.state.is_model_open) {
      return (
        <Modal
          className="removebbg-popup"
          open={this.state.is_model_open}
          onClose={this.onCloseModal}
          center
        >
          <div
            className="modal-dialog schedule_block modal-width--custom"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header header-styling  border-0">
                <h5
                  className="modal-title text-center col-md-11 p-0"
                  id="exampleModalLabel font-medium"
                >
                  <span> Yay Congrats! </span>
                  <span>You have un saved this video </span>
                  <span className="text-center w-100 small-txt pull-left ">
                    {this.state.removedItem.Workout.title}
                  </span>
                </h5>
                <div className="col-md-6 col-lg-6 col-sm-12 float-left m-b-10 p-0">
                  <div className="left-image col-md-1 col-lg-1 col-sm-1 p-0 border-0 float-left">
                    <img
                      className="img-fluid"
                      src={
                        this.state.removedItem.Workout &&
                          this.state.removedItem.Workout.Instructor &&
                          this.state.removedItem.Workout.Instructor.img
                          ? constand.USER_IMAGE_PATH +
                          this.state.removedItem.Workout.Instructor.img
                          : constand.WEB_IMAGES + "no-image.png"
                      }
                      alt=""
                    />
                  </div>
                  <p className="float-left p-l-10">
                    {this.state.removedItem.User.name}{" "}
                    {this.state.removedItem.User.lastName}
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={this.closeModel}>
                    &times;
                  </span>
                </button>
                <button
                  disabled={this.state.loading}
                  className="btn btn-login w-100 m-t-20 m-b-20"
                  onClick={() => {
                    this.refreshList();
                  }}
                >
                  {" "}
                  Continue{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  }
  /**
   * refresh list
   */
  refreshList() {
    this.setState(
      {
        is_model_open: false
      },
      () => {
        this.fetchSavedList();
      }
    );
  }
  removeSavedSession = (item) => {
    var dataObj = {
      sessionId: item.sessionId,
      savedSessionId: item.id,
      isSaved: 1
    };
    this.props.saveSession(dataObj).then(
      response => {
        let handler = [...this.state.savedSessionList];
        var index = handler.findIndex(x => x.id === item.id);
        if (index > -1) {
          handler.splice(index, 1);
        }
        this.setState({ savedSessionList: handler || [] });
        toast.success(response.message);
      },
      error => {
        this.setState({
          Loading: false
        });
        toast.error(error);
      }
    );
  }
  /**
   *
   * render saved sessions
   */
  renderSavedSession() {
    return this.state.savedSessionList.filter(e => (e.session)).map((item, index) => (
      <div className="col-lg-4 col-md-4 col-sm-12 float-left" key={index}>
        <div className="card h-100">
          <Link
            className="position-relative"
            to={"programmes/session/" + (commonService.replaceChar(this.props.params.condition, false)) + "/" + item.session.Program.id + "/" + item.session.id}
          >
            <img
              className="card-img-top img-fluid w-100"
              alt=""
              src={constand.S3_URL + item?.session?.imageUrl}
            />

          </Link>
          <div className="card-body p-l-10 p-r-10">
            <div className="w-100 float-left m-b-10">
              <div className="col-md-10 col-lg-10 col-sm-10 col-10 float-left pl-0">
                <h3 className="heading_3 text-body font-medium text-uppercase">
                  Session {item.session.sessionOrder}: {item.session.title}
                </h3>
              </div>
              <div className="col-md-2 col-lg-2 col-sm-2 col-2 float-left pl-0 pr-0">
                {//this.renderFavAction(item)
                  <span className="m-l-10 pointer" onClick={() => {
                    this.removeSavedSession(item);
                  }}>
                    <img
                      src={"/images/dashboard/liked.svg"}
                      className={`img-fluid`}
                      alt="Like"
                    />
                  </span>
                }
              </div>
            </div>
            {/* <div className="w-100">
              <div className="col-md-6 col-lg-6 col-sm-6 col-6 float-left m-b-10 p-0">
               <div className="left-image col-md-1 col-lg-1 col-sm-1 p-0 border-0 float-left">
                  <img
                    src="/images/dashboard/calendar.svg"
                    className="img-fluid"
                    alt="calendar"
                  />
                </div>
                <p className="float-left p-l-10">
                {item.session.formattedDate}
                </p> 

              </div>


            </div> */}
          </div>
        </div>
      </div>
    ));
  }
  //main render
  render() {
    return (
      <div className="videoclasses ">
        <Helmet>
          <title>{constand.DASHBOARD_SAVED_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.DASHBOARD_SAVED_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.DASHBOARD_SAVED_DESC} />
          <meta property="og:image" content={constand.DASHBOARD_SAVED_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.DASHBOARD_SAVED_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="videos-first-section m-t-40 m-b-50 w-100">
              <h3 className="text-body font-medium p-l-10">Videos</h3>
              <div
                id="carousel2_indicator"
                className="carousel slide carousel-fade"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="w-100 m-b-10 float-left">
                      {this.state.savedList &&
                        this.state.savedList.length > 0 && (
                          <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                            {this.renderSavedVideo()}
                          </div>
                        )}

                    </div>
                  </div>
                  <div className="carousel-item"></div>
                  <div className="carousel-item"></div>
                </div>
                {((this.state.savedList.filter(e => (e.Workout))).length === 0) && (
                  <h3 className="col-md-12 col-lg-12 col-sm-12 p-1 text-center demand-msg">
                    No on-demand videos saved
                  </h3>
                )}
                {this.state.offset >= this.state.limit &&
                  this.state.count > this.state.limit && (
                    <a
                      className="carousel-control-prev items-slider pointer"
                      onClick={() => {
                        if (!this.state.disableButton) {
                          this.loadMoreVideos(false);
                        }
                      }}
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="fa fa-angle-left"
                        aria-hidden="true"
                      ></span>
                    </a>
                  )}
                {this.state.offset < 1 && this.state.count > this.state.limit && (
                  <a
                    className="carousel-control-next items-slider pointer"
                    onClick={() => {
                      if (!this.state.disableButton) {
                        this.loadMoreVideos(true);
                      }
                    }}
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="fa fa-angle-right"
                      aria-hidden="true"
                    ></span>
                  </a>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="videos-first-section m-t-40 m-b-50 w-100">
              <h3 className="text-body font-medium p-l-10">Saved Sessions</h3>
              <div
                id="carousel2_indicator"
                className="carousel slide carousel-fade"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="w-100 m-b-10 float-left">
                      {this.state.savedSessionList &&
                        this.state.savedSessionList.length > 0 && (
                          <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                            {this.renderSavedSession()}
                          </div>
                        )}

                    </div>
                  </div>
                  <div className="carousel-item"></div>
                  <div className="carousel-item"></div>
                </div>
                {((this.state.savedSessionList && this.state.savedSessionList.filter(e => (e.session)))?.length === 0) && (
                  <h3 className="col-md-12 col-lg-12 col-sm-12 p-1 text-center demand-msg">
                    No programme session saved
                  </h3>
                )}

              </div>
            </div>
            <hr></hr>
            {!this.props.logged_userData.isStudyParticipant && constand.SL_MENU.includes('blog') &&
              <h3 className="text-body font-medium p-l-10">Blog Posts</h3>
            }
            {!this.props.logged_userData.isStudyParticipant && constand.SL_MENU.includes('blog') &&
              <div
                id="carousel2_indicator"
                className="carousel slide carousel-fade"
                data-ride="carousel"
                data-interval="false"
              >
                <div className="carousel-inner savedBlogs">
                  <div className="carousel-item active">
                    <div className="col-md-12 p-0 mx-auto m-b-20 p-0 clearfix">
                      {this.state.savedBlogs
                        .filter(e => e.data)
                        .map((item, key) => {
                          return (
                            <div className="col-lg-4 col-md-4 col-sm-12 float-left" key={key}>
                              <div className="card h-100 ">
                                <Link to={"/blogs/" + item.uid}>
                                  <ImageTag
                                    className="card-img-top"
                                    src={
                                      (item.data.thumbnail_img && item.data.thumbnail_img.url)
                                        ? item.data.thumbnail_img.url
                                        : constand.WEB_IMAGES + "no-image.png"
                                    }
                                    height="286px"
                                  />
                                </Link>
                                <div className="card-body">
                                  <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                    <p className="dblog_head text-body small-txt font-qregular float-left ">
                                      {item.tags.toString().replace(",", "/ ")}
                                    </p>
                                    <div className="bookmark-left float-right text-right">
                                      <em
                                        className={
                                          "fa fa-bookmark-o pointer orangefont"
                                        }
                                        onClick={() => this.saveBlog(key)}
                                      ></em>
                                    </div>
                                    <p className="dblog_title font-semibold medium-text black-txt pull-left text-left m-b-5">
                                      <Link to={"/blogs/" + item.uid}>
                                        <TextWrap
                                          text={
                                            item.data.title &&
                                              item.data.title.length > 0
                                              ? item.data.title[0].text
                                              : ""
                                          }
                                          limit={constand.BLOGLIST_TITLE_LIMIT}
                                        />
                                      </Link>
                                    </p>
                                    <p className="dblog_head black-txt font-16 font-qregular float-left capitalize_text">
                                      <span> Author:</span><span className="p-l-10">{item.data.author &&
                                        item.data.author.length > 0
                                        ? item.data.author[0].text
                                        : ""}</span>
                                    </p>
                                    <p className="font-qregular dblog_text m-b-0 pull-left text-left small-txt">
                                      <TextWrap
                                        text={
                                          item.data.preview_text &&
                                            item.data.preview_text.length > 0
                                            ? item.data.preview_text[0].text
                                            : ""
                                        }
                                        limit={constand.BLOGLIST_MSG_LIMIT}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* <div className="carousel-item"></div>
                <div className="carousel-item"></div> */}
                  {((this.state.savedBlogs.filter(e => e.data)).length === 0) &&
                    <div className="row">
                      <h3 className="col-md-12 col-lg-12 col-sm-12 text-center">
                        No blogs saved
                      </h3></div>
                  }
                </div>
                {this.state.offsetBlog >= this.state.limitBlog &&
                  this.state.savedBlogsCount > this.state.limitBlog && (
                    <a
                      className="carousel-control-prev items-slider pointer"
                      role="button"
                      data-slide="prev"
                      onClick={() => {
                        if (!this.state.disableButton) {
                          this.loadMoreSavedBlogs(false);
                        }
                      }}
                    >
                      <span className="fa fa-angle-left" aria-hidden="true"></span>
                    </a>
                  )}
                {this.state.offsetBlog < 1 && this.state.savedBlogsCount > this.state.limitBlog && (
                  <a
                    className="carousel-control-next items-slider pointer"
                    role="button"
                    data-slide="next"
                    onClick={() => {
                      if (!this.state.disableButton) {
                        this.loadMoreSavedBlogs(true);
                      }
                    }}
                  >
                    <span className="fa fa-angle-right" aria-hidden="true"></span>
                  </a>
                )}
              </div>
            }
          </div>
        </div>
        {this.renderModalPopup()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logged_userData: state.header.logged_userData
  };
};

const mapDispatchToProps = {
  fetchSavedList,
  fetchSavedBlogList,
  saveBlog,
  loginModelOpen,
  unSaveVideo,
  start_loader,
  stop_loader,
  saveSession
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedComponent);
