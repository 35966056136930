import React, { Component } from "react";
import { connect } from "react-redux";
import { saveDisclaimer, updateUserdataRedex } from "../../actions";
import { Cookies } from "react-cookie-consent";
import * as constand from "../../constant";
import Autosuggest from "react-autosuggest";

// Your list of suggestions
const other_suggestions = [
    {
        name: "Other",
    },
];
class OndemandSearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      value: "",
      suggestions: [],
      selectedOptions: [],
      List_cli_name: [],
      showSuggestions: true
    };
  }
  componentDidMount() {
    let get_value = [];
    if(this.props.searchSuggestion) {
      this.props.searchSuggestion.map((item, key) => {
        get_value.push(item.name);
      });
    }

    this.setState({
      List_cli_name: get_value,
    });
  }
  handleOnCleardddd = () => {
    this.setState({ isDropdownOpen: false });
    this.props.backToSearch();
  };
  //autosuggest
  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    // return inputLength === 0
    //   ? []
    //   : this.props.searchSuggestion.filter(
    //       (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
    //     );

    return this.props.searchSuggestion.filter(
      (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
    ).length === 0 && this.props.pageFrom == "clinic"
      ? other_suggestions
      : this.props.searchSuggestion.filter(
          (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
       
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {suggestion.name}
        </span>
      </>
    </div>
  );
  onSuggestionSelected = (event, { suggestion }) => {
    // Handle the selection action here
    // For example, you can set the selected value in your state
    this.setState({
        value: suggestion.name, // Assuming suggestion has a "name" property
        showSuggestions: false,
        suggestions: [] // Clear the suggestions after a selection
      });
  };
  onChange = (event, { newValue }) => {
    if (newValue) {
      this.setState({ value: newValue });
      this.props.onSearchInput(newValue);
    } else {
      this.setState({ value: "" });
      this.props.backToSearch();
    }
  };
  onKeyDown = (event) => {
    if (event.keyCode == 13) {
      this.props.searchFunction();
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    // this.setState({
    //   suggestions: this.getSuggestions(value),
    // });
    return( value.length===0?this.setState({
        suggestions: this.getSuggestions(value),
        showSuggestions:true
        
      }):this.setState({
        suggestions: this.getSuggestions(value),
       showSuggestions:false
        
      }))
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      showSuggestions: true
    });
  }

  renderAutoSearch() {
    const { value, suggestions, showSuggestions } = this.state;
    let placeholder = this.props.options
      ? this.props.options.value
      : "what exercise are you looking for?";
    let placeholderFontColor = this.props.options
      ? this.props.options.valueColour
      : "";
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder,
      value: this.props.searchData,
      type: "search",
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      style: { color: placeholderFontColor },
    };

    var currentCondition = Cookies.get("condition");
    if (this.props.isMobileView) {
      return (
        <div
          className={
            "input-group search-sm-btn col-sm-12 col-12 m-t-10 p-0 justify-content-center" +
            (this.state.isDropdownOpen ? "dropdown-open" : "")
          }
        >
          <header className="App-header">
            <div
              className="search-auto-complete"
              style={{ width: "100%", height: "100px" }}
            >
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                focusInputOnSuggestionClick={false}
              />
            </div>
          </header>
          <span className="input-group-append">
            <button
              disabled={this.props.loader}
              className="btn btn-outline-secondary border-left-0 border"
              type="button"
              onClick={this.props.searchFunction}
            >
              <i className="fa fa-search"></i>
            </button>
          </span>
        </div>
      );
    }
    if (this.props.pageFrom && this.props.pageFrom == "clinic") {
      return (
        <div className="form-group" style={{ height: "100px" }}>
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            alwaysRenderSuggestions={showSuggestions} // This prop ensures suggestions are always rendered
        />
        </div>
      );
    } else {
      return (
        <div
          className={
            "input-group mx-auto w-100 search-btn justify-content-center " +
            (this.state.isDropdownOpen ? "dropdown-open" : "")
          }
        >
          <header className="App-header">
            <div
              className="search-auto-complete"
              style={{ width: "600px", height: "100px" }}
            >
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
              />
            </div>
          </header>
          <div className="input-group-append">
            <button
              disabled={this.props.loader}
              className="btn btn-secondary font-14 font-medium"
              type="button"
              onClick={this.props.searchFunction}
            >
              Search
            </button>
          </div>
        </div>
      );
    }
  }

  renderNormalSearch = () => {
    var currentCondition = Cookies.get("condition");
    if (this.props.isMobileView) {
      return (
        <div className="input-group search-sm-btn col-sm-12 col-12 m-t-10 p-0">
          <input
            className="form-control  py-2 border-right-0 font-qregular font-14 border"
            type="search"
            name="search_data"
            value={this.props.search_data}
            onKeyUp={this.props.searchBarEnter}
            onChange={this.props.handleChange}
            placeholder="What exercise are you looking for?"
          />
          <span className="input-group-append">
            <button
              disabled={this.props.loader}
              className="btn btn-outline-secondary border-left-0 border"
              type="button"
              onClick={this.props.searchFunction}
            >
              <i className="fa fa-search"></i>
            </button>
          </span>
        </div>
      );
    }
    return (
      <div className="input-group mx-auto w-75 search-btn">
        <input
          type="text"
          className="form-control font-14 font-qregular"
          name="search_data"
          value={this.props.search_data}
          onKeyUp={this.props.searchBarEnter}
          onChange={this.props.handleChange}
          placeholder={"What exercise are you looking for?"}
        />
        <div className="input-group-append">
          <button
            disabled={this.props.loader}
            className="btn btn-secondary font-14 font-medium"
            type="button"
            onClick={this.props.searchFunction}
          >
            Search
          </button>
        </div>
      </div>
    );
  };

  render = () => {
    let currentCondition = Cookies.get("condition");
    console.log(currentCondition,'=Searchprops debugging=',this.props);

    return (
      (((currentCondition &&
        currentCondition.toLocaleLowerCase().includes("research")) ||
        this.props.pageFrom == "group") &&
        this.renderNormalSearch()) ||
      this.renderAutoSearch()
    );
    // return this.props.isAutoSearch ? this.renderAutoSearch() : this.renderNormalSearch();
  };
}

const mapStateToProps = (state) => {
  return {
    clinic_list: state.register.clinic_list,
    is_auth: state.auth.is_auth,
    logged_userData: state.header.logged_userData,
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OndemandSearchComponent);
