import React from 'react';
import * as constand from "../../constant";
import { Helmet } from "react-helmet";

class TermsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <section className="terms_condition  m-t-80">
        <Helmet>
          <title>{constand.TERMS_TITLE}{constand.BEAM}</title>
          <meta property="og:title" content={constand.TERMS_TITLE + constand.BEAM} />
          <meta property="og:description" content={constand.TERMS_DESC} />
          <meta property="og:image" content={constand.TERMS_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.TERMS_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="terms-bg text-center p-t-10 p-b-90" id="Terms">
          <div className="container">
            <h3 className="font-medium font-37 black-txt p-t-30 p-b-100">Terms and Conditions</h3>
          </div>
        </div>
        <div class="terms_text">
          <div class="container-fluid mx-auto w-65 terms-container">
            <div class="clearfix p-t-50">
              <div class="row">
                <div class="col-md-12">
                  <div class="m-b-50">
                    <p class="conditions_txt m-b-20 font-qregular">
                      Hello there and welcome to Beam, a social exercise platform for people with specific health needs. The Beam website and service including (without limitation) all websites, mobile applications and
                      other interactive properties through which the service is delivered (collectively, the "Service") are owned, operated and distributed by Workout Online Ltd. (trading as Beam) a company registered
                      in  England and Wales at 25 Newmarket Street, Usk, Monmouthshire NP15 1AU with registration number 9981576 (referred to in these Terms of Service as "Beam" or "we" and through similar words
                      such as "us," "our," etc.). By accessing any part of the Service, you are agreeing to the terms and conditions described below (these "Terms of Service"), the terms and conditions of our privacy
                      policy (the "Privacy Policy") and any other legal notices published by us on the Service (collectively, the "Governing Documents"). If you do not agree to any of these terms, you should not use the
                      Service. These Terms of Service apply to all users, including both users who are simply viewing content available via the Service and users who have registered as members of Beam.
                      We may, at our sole discretion, modify these Terms of Service at any time. By accessing the Service at any time after such modifications, you are agreeing to such modifications. These Terms of
                      Service were last modified as of October 2022.
                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Description of the Service</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      The Beam Service allows people to discover and watch exercise videos ("Workouts") and take part in live exercise classes ("Workouts") online led by vetted physiotherapists and fitness
                      professionals ("Content Providers").<br />
                      We reserve the right to refuse to provide the Service to any person for any reason and/or to discontinue the Service in whole or in part at any time, with or without prior notice.

                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Copyright, licenses and user submissions</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      The entire contents of the Service are protected by international copyright law and other proprietary rights. The owners of the copyrights are Beam, its affiliates and/or other third party licensors.
                      You may not modify, copy, reproduce, republish, post, upload, transmit or distribute the material on the service, including text, graphics, code and/or software. You may print and download
                      portions of material from the different areas of the Service solely for your own non-commercial use provided that you agree not to change or delete any copyright or proprietary notices from the
                      materials, and not to share any material that you paid for with anyone else who has not also purchased a license for that material.
                      You may not use any robots, spiders, or similar data mining, data gathering or extraction tools or manual processes to collect, gather or copy any content or data on or related to the Service in a
                      manner not authorised by Beam in writing. You may not engage in practices of "screen scraping," "database scraping" or any other practice or activity the purpose of which is to obtain lists of
                      users, portions of a database, or other lists or information from the Service, in any manner and any quantities not authorised by Beam in writing. You may not frame or utilise framing techniques
                      to enclose any trademark, logo or other proprietary information (including images, text, page layout or form) of Beam or its affiliates without express written consent. You may not use meta tags
                      or any other "hidden text" utilising the Beam name or trademarks without the express written consent of Beam.
                      Content submitted by users for inclusion on the Service (including, without limitation, any information submitted on message boards, forums or other public areas of the Service) is sometimes
                      referred to in these Terms of Service as "User Submissions." Whether or not any User Submission is published, it will be subject to these Terms of Service. Beam does not guarantee any
                      confidentiality with respect to a User Submission, regardless of whether or not it is published. You are solely responsible for your own User Submissions and the consequences of posting or
                      publishing them. You represent and warrant that you own or have the necessary licenses, rights, consents and permissions to your User Submissions (and all content included therein), including the
                      right to authorise Beam to use the User Submissions in the manner contemplated by the Service and these Terms of Service.
                      You hereby grant to Beam a non-exclusive, royalty-free, worldwide, perpetual license, with the right to sub-license, to reproduce, distribute, transmit, create derivative works of, publicly display and
                      publicly perform any User Submissions or any other materials or information (including, without limitation, ideas for new or improved products or services) you communicate to Beam by all
                      means and in any media now known or hereafter developed. You also grant to Beam the right to use your name in connection with the submitted materials and other information as well as in
                      connection with all advertising, marketing and promotional material related thereto. You agree that you shall have no recourse against Beam for any alleged or actual infringement or
                      misappropriation of any proprietary right in your communications to Beam.
                      Beam will not tolerate violations of intellectual property rights on the Service. If you are a copyright owner and believe that any User Submission or other content infringes upon your copyrights,
                      please email us at hello@beamfeelgood.com.
                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Account terms</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      The following terms apply to your use of the Service and any account that you may open or attempt to open via the Service:
                    </p>
                    <p class="conditions_txt terms_conditions_lists m-b-20 p-l-20 font-qregular">
                      <ol>
                        <li>If you are signing up on behalf of a child under the age of 16...
                          <ul>
                            <li>You agree to accepting these terms and conditions on behalf of your child and take full responsibility for your child's compliance with these terms and conditions.</li>
                            <li>You agree to supervising your child during their use of the Service, including during any Workout.</li>
                          </ul>
                        </li>

                        <li>You must be 16 years or older to create an account on Beam and to use the Service unsupervised.</li>
                        <li>You must be a human to open an account. Accounts registered by "bots" or other automated methods are not permitted.You must provide your legal full name, valid email address and any other information requested in order to complete the signup process.</li>
                        <li>You are responsible for choosing a secure password.  We recommend that you create a password by combining three random words together, do not use the same password as you use for any other account you have and do not write down your password.  Use of a password manager is at your own discretion.  Beam will not be liable for any loss or damage from your failure to comply with this security obligation or our recommendations.</li>
                        <li>You agree not to upload any copyrighted, trademarked, or proprietary materials on the Service without the expressed permission of the owner.</li>
                        <li>You agree to use the Service only for lawful purposes and agree to not use the Service in any way that will infringe upon the use or rights of any other user.</li>
                      </ol>



                    </p>
                  </div>
                  <div class="m-b-50">

                    <h3 class="font-medium font-21 purplefont">Fees</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      We reserve the right, at any time, to change any fees or charges for using any services provided on the Service (or to begin charging fees for any free service), provided that such changes will not
                      apply to fees or charges paid prior to the time of the change.
                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Conduct on service</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      Your use of the Service is subject to all applicable laws and regulations, and you are solely responsible for the substance of your communications through the Service. You agree that you will not
                      upload, share, post, or otherwise distribute or facilitate distribution of any User Submission — including text, communications, software, images, sounds, data, or other information — that:<br /></p>
                    <p class="conditions_txt m-b-20 font-qregular">
                      1. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, contains explicit or graphic descriptions or accounts of sexual
                      acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies;<br /><br />

                      2. victimises, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;<br /><br />

                      3. infringes on any patent, trademark, trade secret, copyright, right of publicity, or other intellectual property or proprietary right of any party;<br /><br />

                      4. constitutes unauthorised or unsolicited advertising, junk or bulk email (also known as "spamming"), chain letters, any other form of unauthorised solicitation, or any form of lottery or
                      gambling;<br /><br />

                      5. contains software viruses or any other computer code, files, or programmes that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or
                      telecommunications equipment or to damage or obtain unauthorised access to any data or other information of any third party; or<br /><br />

                      6. impersonates any person or entity, including any of our employees or representatives.<br />
                    </p>
                    <p class="conditions_txt m-b-20 font-qregular">

                      We neither endorse nor assume any liability for the contents of any User Submission. We generally do not pre-screen, monitor, or edit User Submissions. However, we and our agents have the right
                      at their sole discretion to remove any User Submission or other content that, in our judgment, does not comply with these Terms of Service and any other rules of user conduct for the Service, or is
                      otherwise harmful, objectionable, or inaccurate. We are not responsible for any failure or delay in removing any such User Submission or other content. You hereby consent to such removal and
                      waive any claim against us arising out of such removal of any User Submission, whether it is your own or another user's. See "Copyrights, Licenses and User Submissions" above for a description
                      of the procedures to be followed in the event that any party believes that a User Submission infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right
                      of any party.</p>
                    <p class="conditions_txt m-b-20 font-qregular">
                      You may not use your account to breach security of another account or attempt to gain unauthorised access to another network or server. Not all parts of the Service may be available to you or
                      other authorised users of the Service. You shall not interfere with anyone else's use and enjoyment of the Service. Users who violate systems or network security may incur criminal or civil liability.
                      You agree that we may at any time, and at our sole discretion, terminate your membership, account, or other affiliation with the Service without prior notice to you for violating any of the above
                      provisions. In addition, you acknowledge that we will cooperate fully with investigations of violations of systems or network security at other sites, including cooperating with law enforcement
                      authorities in investigating suspected criminal violations.
                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Exercising on Beam</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      Taking part in any form of exercise on Beam is at the user's own risk.
                    </p>
                    <p class="conditions_txt m-b-20 font-qregular">
                      Your taking part in a Workout is at your sole risk. Beam does not supervise Workouts and is not involved in any way with the actions of any individuals (whether users or Content Providers) of any
                      Workout. As a result, we have no control over the identity or actions of the individuals who are present in these Workouts. You acknowledge and agree that by participating in any Workout you
                      may be exposed to a variety of risks and hazards, which may or may not be foreseen, including (without limitation) personal injury, property damage and death. You are solely responsible for all
                      costs and/or risks associated with your participation in any workout or exercise.
                    </p>
                    <p class="conditions_txt m-b-20 font-qregular">
                      TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE BEAM AND EACH OF THE OTHER BEAM PARTIES FROM ANY CLAIMS OR DAMAGES OF ANY KIND OR NATURE, KNOWN OR
                      UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR UNDISCLOSED, RELATING TO YOUR PARTICIPATION IN ANY EVENT, WHETHER AS A USER OR INSTRUCTOR. YOU AGREE AND
                      UNDERSTAND AND INTEND THAT THIS ASSUMPTION OF RISK AND RELEASE IS BINDING UPON YOU AND YOUR HEIRS, EXECUTORS, AGENTS, ADMINISTRATORS AND ASSIGNS.
                    </p>
                    <p class="conditions_txt m-b-50 font-qregular">
                      If at any time you feel you are exercising beyond your current fitness abilities or feel discomfort, you should discontinue exercising. <br /><br />

                      Not all exercises are suitable for everyone, and this or any other exercise programme may result in injury.<br /><br />

                      Any user of the exercise programme assumes the risk of injury from performing the exercises shown.<br /><br />

                      The instructions and advice on this video are not intended to replace medical advice or treatment by your physician.<br /><br />

                      All questions and concerns regarding your health, weight, diet and physical activity should be directed to your physician.<br /><br />

                      In no way will the creators, authors and publishers of this video or any persons associated with the foregoing be held responsible for any injuries or problems that occur due to the use of the video
                      or the advice contained within.<br /><br />

                      The creators, authors and publishers of this video disclaim any liabilities in connection with the exercise and nutritional advice contained herein.

                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Payment and Fees</h3>
                    <p class="conditions_txt m-b-20 font-qregular">The membership fee will be billed on the beginning of the paying portion of your membership and each month or year thereafter unless and until you cancel your membership. We automatically
                      bill your Payment Method each month on the calendar day corresponding to the commencement of your paying membership. We reserve the right to change the timing of our billing, in particular,
                      as indicated below, if your Payment Method has not successfully settled. In the event your paying membership began on a day not contained in a given month, we may bill your Payment Method
                      on a day in the applicable month or such other day as we deem appropriate. For example, if you started your Premium Membership or became a paying member on March 31st, your next
                      payment date is likely to be April 30th, and your Payment Method would be billed that date.</p>
                    <p class="conditions_txt m-b-20 font-qregular">Payments are nonrefundable and there are no refund or credits for partially used periods. Following any cancellation, however, you will continue to have the benefits of your Beam Membership
                      through the end of your current billing period.</p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">
                      Cancellations</h3>
                    <p class="conditions_txt m-b-20 font-qregular">You may cancel your Beam Membership at any time for any or no reason. To cancel your recurring subscription, please visit your dashboard and Account Info tab. If you have canceled your Beam
                      Membership, you will lose access to all content.</p></div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Free Trials and Paid Subscriptions</h3>
                    <p class="conditions_txt m-b-20 font-qregular">From time to time, we or others on our behalf may offer trials of paid subscriptions for our Beam Membership for a specified period without payment or at a reduced rate (a "Trial"). Beam
                      reserves the right, in its absolute discretion, to determine your eligibility for a Trial, and, subject to applicable laws, to withdraw or to modify a Trial at any time without prior notice and with no
                      liability, to the greatest extent permitted under the law.</p></div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Price changes</h3>
                    <p class="conditions_txt m-b-20 font-qregular">We reserve the right to adjust pricing for our service, or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise
                      expressly provided for in these Terms of Use, any price changes to your service will take effect following email notice to you.</p></div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Disclaimer of Warranties</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      THE SERVICE AND ALL MATERIALS INCLUDED THEREIN ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                      BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE
                      FOREGOING, WE MAKE NO WARRANTY THAT (A) THE SERVICE AND MATERIALS WILL MEET YOUR REQUIREMENTS, (B) THE SERVICE AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE,
                      OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY
                      WORKOUT THAT YOU ATTEND VIA THE SERVICE, OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE SERVICE FROM US OR OUR AFFILIATES
                      WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.<br /><br />

                      THE SERVICE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE SERVICE AND ANY OF THE CONTENT OR
                      FEATURES INCLUDED THEREIN, INCLUDING THE PRICES AND ACCESS RULES FOR ANY FUNCTIONALITY, AT ANY TIME WITHOUT NOTICE. THE CONTENT INCLUDED ON THE SERVICE MAY BE OUT
                      OF DATE, AND WE MAKE NO COMMITMENT TO UPDATE SUCH CONTENT<br /><br />

                      THE USE OF THE SERVICE OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND WITH YOUR
                      AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.<br /><br />

                      Through your use of the Service, you may have the opportunities to engage in commercial transactions with other users and vendors, including (without limitation) users and Content Providers.
                      You acknowledge that all transactions relating to any Workout or other merchandise or services offered by any party, including, but not limited to the purchase terms, payment terms, warranties,
                      guarantees, maintenance and delivery terms relating to such transactions, are agreed to solely between the seller or purchaser of such merchandise and services and you.<br /><br />

                      WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED THROUGH, OR IN CONNECTION WITH THE SERVICE, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS
                      ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY WORKOUT, PRODUCTS, SERVICES, MATERIALS, OR INFORMATION AVAILABLE
                      ON OR THROUGH THE SERVICE FROM A THIRD PARTY IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER OF OUR AFFILIATES.<br /><br />

                      Content available through the Service or presented at any Workout represents the opinions and judgments of an information provider, user, or other person or entity not connected with us. We do
                      not endorse, nor are we responsible for the accuracy or reliability of, any opinion, advice, or statement made by anyone other than an authorised Beam spokesperson speaking in his/her official
                      capacity. Please refer to the specific editorial policies posted on various sections of the Service for further information, which policies are incorporated by reference into these Terms of Service.
                      You understand and agree that temporary interruptions of the Service may occur as normal events. You further understand and agree that we have no control over third party networks you may
                      access in the course of the use of the Service, and therefore, delays and disruption of other network transmissions are completely beyond our control.
                      You understand and agree that the Service is provided "AS IS" and that we assume no responsibility for the timeliness, deletion, mis-delivery or failure to store any user communications or
                      personalisation settings.<br /><br />

                      SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                    </p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Limitation of Liability</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      IN NO EVENT SHALL WE OR ANY OTHER BEAM PARTY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY
                      KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICE OR OF ANY WEB SITE REFERENCED OR LINKED TO
                      FROM THE SERVICE.<br /><br />

                      FURTHER, NEITHER WE,NOR ANY OTHER BEAM PARTY, SHALL BE LIABLE IN ANY WAY FOR ANY WORKOUT OR OTHER THIRD PARTY GOODS AND SERVICES OFFERED THROUGH THE SERVICE
                      OR FOR ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS WITH OTHER USERS OF THE SITE, INCLUDING WITHOUT LIMITATION ANY TRANSACTION BETWEEN A USER AND
                      INSTRUCTOR.<br /><br />

                      SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                    </p>
                  </div>
                  <div className='m-b-50'>
                    <h3 class="font-medium font-21 purplefont">Accessibility Statement</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      At Beam we understand the importance of making our technology accessible to all.
                      The current Beam website (www.beamfeelgood.com) aims to comply with all of the
                      UK guidelines for public sector websites, including achieving compliance with the
                      Web Content Accessibility Guidelines (WCAG) 2.1, Level AA, and to maintain this
                      standard as a minimum.<br/><br/>
                      
                      We are currently working towards compliance with the WCAG 2.1, Level AA, 
                      with a long-term aim of achieving W3C WCAG 2.1, Level AAA.
                    </p>

                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Indemnification</h3>
                    <p class="conditions_txt m-b-20 font-qregular">Upon a request by us, you agree to defend, indemnify, and hold us and the other Beam Parties harmless from all liabilities, claims, and expenses, including attorneys' fees, that arise from your
                      violation of these Terms of Service or any of the other Governing Documents, or other negligent or wrongful conduct. We reserve the right to assume the exclusive defense and control of any
                      matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.</p>
                  </div>
                  <div class="m-b-50">
                    <h3 class="font-medium font-21 purplefont">Miscellaneous</h3>
                    <p class="conditions_txt m-b-20 font-qregular">
                      You agree that the Service shall be deemed a passive interactive service based solely in UK and shall not give rise to personal jurisdiction over Beam, either specific or general, in jurisdictions other
                      than UK. These Terms of Service, together with the Privacy Policy and the other Governing Documents, shall all be governed and construed in accordance with the internal laws of the UK, without
                      regard to conflicts of law principles. You agree that any legal action or proceeding between Beam and you for any purpose concerning these Terms of Service, the Privacy Policy, the other
                      Governing Documents or your use of the Service shall be brought exclusively in a federal or state court of competent jurisdiction sitting in UK.<br /><br />

                      Any cause of action or claim you may have with respect to any of the foregoing matters must be commenced within one (1) year after the claim or cause of action arises or such claim or cause of
                      action is barred.<br /><br />

                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO CLAIM UNDER THESE TERMS OF SERVICE, THE PRIVACY POLICY OR THE OTHER GOVERNING DOCUMENTS SHALL BE JOINED TO
                      ANY OTHER CLAIM, INCLUDING ANY CLAIM INVOLVING ANY OTHER CURRENT OR FORMER USER OF THE SERVICE, AND NO CLASS ACTION PROCEEDINGS SHALL BE PERMITTED.<br /><br />

                      These Terms of Service, together with the Privacy Policy and the other Governing Documents, constitute the entire agreement between you and Beam concerning the Service and the services
                      provided by Beam. If any provision of any such document is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining
                      provisions thereof, which shall remain in full force and effect. Beam's failure to insist upon or enforce strict performance of any provision of these Terms of Service or any of the other Governing
                      Documents shall not be construed as a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice shall act to modify any provision of these Terms of
                      Service. Beam may assign its rights and duties under these Terms of Service or any of the other Governing Documents to any party at any time without notice to you. Most communication
                      between Beam and you will be sent and received electronically. You agree that all electronic communication between Beam and you shall satisfy any legal requirements that such communications
                      be in writing.<br /><br />

                      Any rights of Beam not expressly granted herein are reserved.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </section>

    );
  }
}

export { TermsComponent };
