import React, { useEffect, useState } from "react";

import * as constand from "../../../constant";
import { ImageTag } from "../../../tags";

function isEmptyObject(obj) {
  return Object.keys(obj).length == 0;
}
const CustomFieldTemplate = (props) => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    schema,
  } = props;

  const [otherText, setOtherText] = useState("");
  const [otherInputOpen, setOtherInputOpen] = useState(false);
  const handleOtherTextChange = (event) => {
    setOtherText(event.target.value);
    if (!event.target.value) return props.onChange(null);
    props.onChange(["Other:" + event.target.value]);
  };
  useEffect(() => {
    // Check if the initial value contains "Other:" and set the state accordingly
    if (Array.isArray(props.formData)) {
      const otherOption = props.formData.find((item) =>
        item.startsWith("Other:")
      );
      if (otherOption) {
        setOtherInputOpen(true);
        setOtherText(otherOption.replace("Other:", ""));
      }
    }
    if (
      typeof props.formData === "string" &&
      props.formData.startsWith("Other:")
    ) {
      setOtherInputOpen(true);
      setOtherText(props.formData.replace("Other:", ""));
    }
  }, [props.formData]);
  const renderErrors = () => <div className="ml-4"> {errors}</div>;
  const renderRadioOptions = () => {
    return (
      <div className="survey-options">
        <p className="font-14 fw600 font-semibold">
          {label} {required ? "*" : null}
        </p>
        <div className=" clmgap">
          <div className="radiocheck">
            {schema.enum &&
              schema.enum.map((option, index) => {
                if (option == "Other") return null;
                return (
                  <div key={index} className="form-check ">
                    <span className="font-qbold option-head d-flex justify-content-between">
                      <div className="lradio " style={{ flex: 4 }}>
                        <input
                          id={`${id}-${index}`}
                          name={id}
                          type="radio"
                          checked={props.formData === option}
                          onChange={() => {
                            if (otherInputOpen) {
                              setOtherInputOpen(false);
                              setOtherText("");
                            }
                            props.onChange(option);
                          }}
                        />
                        <label
                          htmlFor={`${id}-${index}`}
                          className="fontpx-14 fw500 radio-label mb-0"
                        >
                          {schema.embedurl
                            ? schema.embedurl[index] && (
                                <a
                                  className="fontpx-14 fw500 mb-0"
                                  style={{ color: "#000000" }}
                                  href={
                                    schema.embedurl && schema.embedurl[index]
                                  }
                                  target="_blank"
                                >
                                  {option}
                                </a>
                              )
                            : option}
                        </label>
                      </div>
                      {props.formData &&
                        props.formData === option &&
                        schema.correctAnswer &&
                        renderValidationMessage(
                          schema.correctAnswer.includes(props.formData)
                        )}
                    </span>
                  </div>
                );
              })}
            {schema.enum &&
              schema.enum.includes("Other") &&
              renderOtherRadioOption()}
            {renderErrors()}
          </div>
        </div>
      </div>
    );
  };
  const renderOtherRadioOption = () => {
    return (
      <div key={id} className="form-check">
        <span className="font-qbold ">
          <div className="lradio">
            <input
              id={`${id}-other-radio`}
              name={id}
              type="radio"
              checked={otherInputOpen}
              onChange={() => {
                setOtherInputOpen(true);
                props.onChange("");
              }}
            />
            <label
              htmlFor={`${id}-other-radio`}
              className="radio-label fontpx-14"
            >
              Other
            </label>
            {otherInputOpen && (
              <input
                type="text"
                className="form-control mt-2"
                value={otherText}
                onChange={(event) => {
                  setOtherText(event.target.value);
                  props.onChange("Other:" + event.target.value);
                }}
              />
            )}
          </div>
        </span>
      </div>
    );
  };
  const renderCheckboxOptions = () => {
    return (
      <div className="survey-options">
        <p className="font-14 fw600 font-semibold">
          {label} {required ? "*" : null}
        </p>
        <div className="d-flex clmgap">
          <div className="radiocheck">
            {schema.items.enum &&
              schema.items.enum.map((option, index) => {
                if (option == "Other") return null;

                return (
                  <div key={index} className="form-check">
                    <span className="font-qbold ">
                      <div className="lcheckbox">
                        <input
                          id={`${id}-${index}`}
                          name={id}
                          type="checkbox"
                          checked={
                            Array.isArray(props.formData) &&
                            props.formData.includes(option)
                          }
                          onChange={(event) => {
                            if (otherInputOpen) {
                              setOtherInputOpen(false);
                              return props.onChange([option]);
                            }
                            const newValue = Array.isArray(props.formData)
                              ? props.formData.includes(option)
                                ? props.formData.filter(
                                    (value) => value !== option
                                  )
                                : [...props.formData, option]
                              : [option];

                            props.onChange(
                              newValue.length == 0 ? null : newValue
                            );
                          }}
                        />
                        <label
                          htmlFor={`${id}-${index}`}
                          className="checkbox-label fontpx-14"
                        >
                          {schema.items.embedurl
                            ? schema.items.embedurl[index] && (
                                <a
                                  className="fontpx-14 fw500 mb-0"
                                  style={{ color: "#000000" }}
                                  href={
                                    schema.items.embedurl &&
                                    schema.items.embedurl[index]
                                  }
                                  target="_blank"
                                >
                                  {option}
                                </a>
                              )
                            : option}
                        </label>
                      </div>
                    </span>
                  </div>
                );
              })}
            {schema.items.enum &&
              schema.items.enum.includes("Other") &&
              renderOtherOption()}
            {props.formData?.length > 0 &&
              schema.correctAnswer &&
              renderValidationMessage(
                props.formData.every((answer) =>
                  schema.correctAnswer.includes(answer)
                )
              )}
            {renderErrors()}
          </div>
        </div>
      </div>
    );
  };
  const renderImages = () => {
    return (
      <div className="survey-options">
        <p className="font-14 fw600 font-semibold">
          {label} {required ? "*" : null}
        </p>
        <div className="d-flex flex-wrap">
          {schema.items.enum &&
            schema.items.enum.map((option, index) => (
              <div key={index} className="image-tile form-check">
                <input
                  id={`${id}-${index}`}
                  name={id}
                  type="radio"
                  checked={props.formData === option}
                  onChange={() => {
                    props.onChange(option);
                  }}
                  // className="d-none"
                />
                <label
                  htmlFor={`${id}-image-${index}`}
                  className="checkbox-label"
                >
                  {schema.items.embedurl ? (
                    schema.items.embedurl[index] && (
                      <a
                        href={schema.items.embedurl[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ImageTag
                          src={constand.S3_URL + option}
                          alt={option}
                          className="img-fluid"
                          thumb={
                            constand.WEB_IMAGES + "ondemand-placeholder.png"
                          }
                        />
                      </a>
                    )
                  ) : (
                    <ImageTag
                      src={constand.S3_URL + option}
                      alt={option}
                      className="img-fluid"
                      thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
                    />
                  )}
                </label>
              </div>
            ))}
        </div>
        {props.formData &&
          schema.correctAnswer &&
          renderValidationMessage(
            schema.correctAnswer.includes(props.formData)
          )}
        {renderErrors()}
      </div>
    );
  };

  const renderMultiImages = () => {
    return (
      <div className="survey-options">
        <p className="font-14 fw600 font-semibold">
          {label} {required ? "*" : null}
        </p>
        {schema.src.map((option) => {
          return (
            <ImageTag
              className="img-fluid"
              src={constand.S3_URL + option}
              alt={option}
              thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
            />
          );
        })}{" "}
      </div>
    );
  };
  const renderOtherOption = () => {
    return (
      <div key={id} className="form-check">
        <span className="font-qbold ">
          <div className="lcheckbox">
            <input
              id={`${id}` + "Other"}
              name={id}
              type="checkbox"
              checked={otherInputOpen}
              onChange={() => {
                setOtherInputOpen(true);
                props.onChange(null);
              }}
            />
            <label htmlFor={`${id}-other`} className="checkbox-label fontpx-14">
              Other
            </label>
            {otherInputOpen && (
              <input
                type="text"
                className="form-control mt-2"
                value={otherText}
                onChange={handleOtherTextChange}
              />
            )}
          </div>
        </span>
      </div>
    );
  };

  const renderValidationMessage = (isValid) => {
    if (isValid) {
      return (
        <>
          <div className="radiopara">
            <p className="font-14 fw600 font-semibold">
              {schema.correctValidationMessage}
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="radiopara ">
            <p className="font-14 fw600 font-semibold text-danger">
              {schema.incorrectValidationMessage}
            </p>
          </div>
        </>
      );
    }
  };

  return (
    <div className={classNames}>
      {schema.type === "string" && schema.enum && renderRadioOptions()}
      {schema.type === "array" &&
        schema.items &&
        schema.items.type == "string" &&
        renderCheckboxOptions()}
      {schema.type === "string" &&
        schema.items &&
        schema.items.type == "string" &&
        renderImages()}
      {/* {schema.enum && schema.enum.includes("Other") && renderOtherOption()} */}
      {/* {schema.correctAnswer &&
        renderValidationMessage(props.formData === schema.correctAnswer)} */}
      {help}
      {schema.src && renderMultiImages()}
      {(schema.type !== "string" || !schema.enum) &&
        (schema.type !== "string" || !schema.items) &&
        (schema.type !== "array" || !schema.items.enum) &&
        schema.type !== "null" && (
          <>
            <label htmlFor={id}>
              {label}
              {required ? "*" : null}
            </label>
            {children}
            {renderErrors()}
          </>
        )}
    </div>
  );
};

export default CustomFieldTemplate;
