import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { fetchDashboardPrograms, loginModelOpen, start_loader, stop_loader, joinClass, cancelClass, joinToProgram, isPlayOndemand, ProgrammeDetailWorkouts, updateUserProgramList, programDetailData, isOpenProgramSurveyModel } from "../../actions";
import moment from 'moment';
import { commonService } from "../../_services";
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import EventCalendarComponent from "./EventCalendarComponent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";
import { Cookies } from "react-cookie-consent";
import { floor } from 'mathjs';
import _ from 'lodash';
import PlayOndemand from "../Common/PlayOndemand";

class ProgramComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            offset: 0,
            count: 0,
            isShowAll: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            program_list: [],
            getProgramDetail: []
        };

    }

    /** fetch live class list on page did mount */
    componentDidMount() {
        console.log('this.props.condition', this.props)
        this.setState({ condition: this.props.params.condition }, () => {
            this.fetchProgramList();
        })
    }
    componentWillReceiveProps(prevProps) {
        if (this.props.params && prevProps.params.condition && this.props.params.condition !== prevProps.params.condition) {
            var condition = commonService.replaceChar(prevProps.params.condition, true)
            this.setState({ condition: condition, getProgramDetail: prevProps.program_detail }, () => {
                this.fetchProgramList();
            })
        }
    }
    /**
     * fetch class list data
     */
    fetchProgramList = () => {
        this.setState({ Loading: true, disableButton: true });
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset,
            "isShowAll": this.state.isShowAll,
            "limit": constand.CONSTEIGHT,
            "condition": condition,
        };
        this.props.fetchDashboardPrograms(dataObj).then(
            response => {
                if (response) {
                    /* var list = response.list;
                    if (list && list.liveClasses) {
                        this.groupListResult(list.liveClasses);
                    } */
                    this.setState({
                        Loading: false,
                        count: response.totalCount,
                        disableButton: false,
                        //  total_tags: (response.list.tags) ? response.list.tags : [],
                        //  total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });

                }
            },
            error => {
                this.setState({
                    Loading: false,
                    disableButton: false
                });
                // toast.error(error);
            }
        );
    }

    startNextClass = (programId = 0, item, programWorkoutsRecent, keyIndex, isCompletedType) => {
        this.props.programDetailData(item.Program);
        var index = 0;
        if (!programId) {
            var index = item.completed_workout_count || 0;
        }

        console.log('programWorkoutsRecent-index', index)
        console.log('programWorkoutsRecent', programWorkoutsRecent)
        console.log('isPlayOndemand-index-data', programWorkoutsRecent[index])
        var workout = { workout: programWorkoutsRecent[index].Workout };
        console.log('isPlayOndemand-workout', workout)
        this.setState({
            programId: programWorkoutsRecent[index].programId,
            workoutId: programWorkoutsRecent[index].workoutId,
            ondemand_detail_data: workout,
            keyIndex: keyIndex,
            programDetails: item,
            isCompletedType: isCompletedType,

        }, function () {
            // this.props.isPlayOndemand(true)
            console.log("program-mybeam",this.props)
            if (this.props.program_detail && this.props.program_detail.preSurvey && programWorkoutsRecent[index].programId && programWorkoutsRecent[0].workoutId == programWorkoutsRecent[index].workoutId)
            {
                this.props.isOpenProgramSurveyModel(true)
            }else{
                this.props.isPlayOndemand(true)
            }
        })
    }

    joinProgram = (programId, item, programWorkoutsRecent, index, isCompletedType) => {
        if (this.props.is_auth) {
            var params = { programId: programId }
            this.props.joinToProgram(params).then(
                response => {
                    this.setState({ isJoinedNow: true })
                    console.log('response', response)
                    if (response.status) {
                        this.setState({ userProgramId: response.data.id })
                        this.startNextClass(programId, item, programWorkoutsRecent, index, isCompletedType)
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                }, error => {
                    this.setState({ isJoinedNow: false })
                    toast.error(error);
                });
        } else {
            this.props.loginModelOpen(true);
        }
    }


    /**
     * render live class list
     */
    renderListView(data, isCompletedType) {

        return (data.length > 0 && data.map((item, index) => {
            console.log('renderListViewitem', item)
            var desc = item.shortDesc ? item.shortDesc.substr(0, (this.state.charLimit[index] || this.state.charStaticLimit)) : '';
            if (item.shortDesc && (item.shortDesc.length > (this.state.charLimit[index] || this.state.charStaticLimit))) {
                desc = desc.concat('...');
            }

            let programWorkoutsRecent = _.sortBy(item.Program.ProgramWorkouts, (e) => {
                return e.workoutNum
            });
            var progressPercent = floor((item.completed_workout_count * 100) / item.Program.numWorkouts)

            return (
                <div className="list-group-item liveclass-listing mybeamlisting_Set" key={index}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="media media_for_mobile col-md-3 col-lg-3 leftabs_img">
                                <div className="mobile_view_show"><h3 class="heading_prgs"><Link
                                    className="flow-text"
                                    to={"/programmes/detail/" + this.props.params.condition + "/" + item.Program.id}
                                > {item.Program.title}</Link></h3></div>
                                <figure className="pull-left position-relative wid_ht">
                                    <Link to={"/liveClass/" + this.props.params.condition + "/"} >
                                        <ImageTag
                                            className="media-object image-size img-rounded img-fluid"
                                            src={constand.PROGRAM_IMG_PATH + item.Program.img}
                                            thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}
                                        />
                                    </Link>
                                    <Link className="flow-text" to={"/programmes/detail/" + this.props.params.condition + "/" + item.Program.id}>
                                    <span class="position-absolute vid_time">
                                        <div className="row w-100">
                                            {item.completedDate &&
                                                <h3 className="orange_pos_text w-100 text-center">
                                                    Completed {item.completed_times} times
                                                </h3>
                                                ||

                                                <div class="line-bar font-16 progressbar_star prg_bar_line w-100">
                                                    <div class="progress">
                                                        <div className={"progress-bar p-l-10 p-r-10 " + (progressPercent == 0 ? "empty-progress" : "")} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: (progressPercent == 0) ? '15%' : progressPercent + '%' }}>
                                                            {progressPercent > 50 && <span class="font-semibold font-18">{item.completed_workout_count} out of {item.Program.numWorkouts} classes completed</span>
                                                            }
                                                            {progressPercent <= 50 &&
                                                                <span class="font-semibold font-18">{progressPercent}%</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </span>
                                </Link>
                                </figure>
                                <div className="mobile_view_show">
                                    <p className="font-16 font-semibold black-txt p-l-5 text-center orangefont">
                                        {item.Program.numWorkouts} Classes
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-7 col-lg-7">
                                <div className="w-100 row m-0">
                                    <h3 class="heading_prgs mobile_view_hide"><Link
                                        className="flow-text"
                                        to={"/programmes/detail/" + this.props.params.condition + "/" + item.Program.id}
                                    > {item.Program.title}</Link>
                                        <span className="orange-txt">{item.Program.numWorkouts} Classes</span>
                                    </h3>
                                    <div className="desc_para_set" dangerouslySetInnerHTML={{
                                        __html: item.Program.shortDesc
                                    }}></div>
                                    {/* <div className="desc_para_set">{item.Program.shortDesc}</div> */}
                                </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                                {(item.completed_workout_count == item.Program.numWorkouts) &&
                                    //item.completedDate &&
                                    <button onClick={() => this.joinProgram(item.programId, item, programWorkoutsRecent, index, isCompletedType)}
                                        className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 pr-1 pl-1"
                                    >
                                        Restart
                                    </button>
                                }
                                {(item.completed_workout_count < item.Program.numWorkouts) &&
                                    <Link
                                        //to={"/detail/" + item.Program.ProgramWorkouts[item.completed_workout_count].workoutId + '/' + this.props.params.condition + '/' + item.id}
                                        className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 pr-1 pl-1 startnewclass_prl"
                                        onClick={() => { this.startNextClass(0, item, programWorkoutsRecent, index, isCompletedType) }}
                                    >
                                        Start next class
                                    </Link>
                                }
                                <Link
                                    className="btn btn-purple-inverse w-100 font-medium m-b-10 pr-1 pl-1"
                                    to={"/programmes/detail/" + this.props.params.condition + "/" + item.id}
                                >Learn more </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }));
    }


    /**
    * render list
    */
    renderListData() {
        console.log('this.props.renderListData', this.props)
        if (this.props?.program_list && Object.keys(this.props?.program_list)?.length > 0 && (this.props?.program_list.completedProgramList?.length > 0 || this.props?.program_list.progressProgramList?.length > 0)) {
            return (
                <div id="products" className="container programs_page_tab programs_page_tab_new">
                    <div className="list-group">
                        {this.props.program_list.progressProgramList.length &&
                            <h3 className="font-24 font-semibold text-center mt-3">Your active programmes</h3>
                        }
                        {this.renderListView(this.props.program_list.progressProgramList, false)}
                        {this.props.program_list.completedProgramList.length &&
                            <h3 className="font-24 font-semibold text-center mt-3">Your completed programmes</h3>}
                        {this.renderListView(this.props.program_list.completedProgramList, true)}
                    </div>
                    {this.state.count > 8 &&
                        <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                            <span
                                onClick={() => {
                                    if (!this.state.disableButton) {
                                        this.incrementShowmoreCnt()
                                    }
                                }}
                                className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                            >
                                Show all
                            </span>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div className="row p-b-30 p-t-30">
                    <h3 className="col-sm-12 text-center">
                        Did you know that we offer a range of fantastic programmes tailor made for your condition?
                    </h3>
                    <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                        <Link to={'/programmes/' + this.props.params.condition}
                            className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                        >
                            Check out programmes
                        </Link>
                    </div>
                </div>
            );
        }
    }
    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            isShowAll: 1
        }, function () {
            this.fetchProgramList();
        });
    }

    updateWatchedVideoDetails = (index, data, isCompleted) => {

        console.log('data', data)
        if (isCompleted) {
            //completed
            this.setState({
                offset: 0,
                isShowAll: 0,
            }, function () {
                this.fetchProgramList();
            });
        } else {
            //active
            var tempData = this.props.program_list.progressProgramList;
            tempData[index].completed_times = data.completedPrograms;
            tempData[index].completed_workout_count = data.completedClass;
            this.props.updateUserProgramList(tempData)
        }

    }

    updateProgramDetails = () => {
        var data = { programId: this.state.programId }
        console.log('programDetails', this.state.programDetails)
        if (this.state.programDetails) {
            data.userProgramId = this.state.programDetails.id;
        }
        this.props.ProgrammeDetailWorkouts(data).then(
            response => {
                if (response) {
                    this.updateWatchedVideoDetails(this.state.keyIndex, response, this.state.isCompletedType)
                }
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
            }
        );
    }

    //main render
    render() {
        return (
            <div className=""
            >
                <ResearchStudyPollPopup
                    is_model_open={this.state.openResearchModel}
                    closeResearchModel={this.closeResearchModel}
                    classType="Live"
                    beforeReview={this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                />
                <Helmet>
                    <title>{constand.DASHBOARD_SCHEDULE_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.DASHBOARD_SCHEDULE_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.DASHBOARD_SCHEDULE_DESC} />
                    <meta property="og:image" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE_ALT} />
                </Helmet>
                <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div>
                {(!this.state.Loading) && this.renderListData()}
                <PlayOndemand
                    condition={this.props.params.condition}
                    programId={this.state.programId}
                    workoutId={this.state.workoutId}
                    history={this.props.history}
                    ondemand_detail_data={this.state.ondemand_detail_data}
                    closeWithProgram={() => { this.updateProgramDetails() }}

                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        program_list: state.dashboard.program_list,
        program_detail: state.programme.program_detail,
    };
};

const mapDispatchToProps = {
    fetchDashboardPrograms, loginModelOpen, start_loader, stop_loader, joinClass, cancelClass, joinToProgram, isPlayOndemand, ProgrammeDetailWorkouts, updateUserProgramList, programDetailData, isOpenProgramSurveyModel
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgramComponent);
