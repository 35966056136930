import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchResources } from "../../../actions";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";
import ResourcesListComponent from "./ResourcesListComponent";
import ResourcesCardComponent from "./ResourcesCardComponent";
import { toast } from "react-toastify";
import DynamicDashboardHeader from "../../dynamicDashboard/dynamicDashboarHeader/dynamicDashboardHeader";
import * as constand from "../../../constant";
function ResourcesComponent(props) {
  const dispatch = useDispatch();
  const [programId] = useState(props.match.params.programId);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resourcesList, setResourcesList] = useState([]);
  const isScrollEnabledRef = useRef(true);
  const cardRefs = useRef([]);
  const cardsContainerRef = useRef(null);

  async function fetchResourcesList() {
    try {
      setLoading(true);
      const response = await dispatch(fetchResources(programId));
      if (!response.success) throw new Error(`Failed to fetch resources`);
      const newList = response.resources;
      setResourcesList(newList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchResourcesList();
  }, []);
  const handleScroll = useCallback(() => {
    if (!isScrollEnabledRef.current || !cardsContainerRef.current) return;

    const container = cardsContainerRef.current;
    const containerTop = container.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const cardElements = cardRefs.current;

    if (!cardElements) return;

    let newActiveIndex = activeIndex;
    cardElements.forEach((card, index) => {
      if (card) {
        const rect = card.getBoundingClientRect();
        if (
          rect.top >= containerTop &&
          rect.top < containerTop + windowHeight / 2
        ) {
          newActiveIndex = index;
        }
      }
    });

    if (newActiveIndex !== activeIndex) {
      setActiveIndex(newActiveIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    const container = cardsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, cardsContainerRef.current]);

  function onClickDownload(resource) {
    window.open(constand.S3_URL + resource);
  }

  return (
    <React.Fragment>
      <DynamicDashboardHeader />

      {loading ? (
        <div className="text-center w-100">
          <AnimateLoaderComponent />
        </div>
      ) : (
        <section
          id="resourcespage-section"
          className="resourcespage-section mt-50 mb-80"
        >
          <div className="container-fluid mx-auto w-100 resources-container">
            {!resourcesList.length && (
              <div className=" align-items-center">
                <div className="col col-wide black-txt font-16 fw700 font-qbold text-center">
                  No resources found
                </div>
              </div>
            )}
            {!!resourcesList.length && (
              <div className="row justify-content-center">
                <ResourcesListComponent
                  items={resourcesList}
                  activeIndex={activeIndex}
                  onItemClick={(index) => {
                    setActiveIndex(index);
                    isScrollEnabledRef.current = false; // Disable scroll handling
                    const element = cardRefs.current[index];
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      });
                    }
                    // Re-enable scroll handling after a delay
                    setTimeout(() => {
                      isScrollEnabledRef.current = true;
                    }, 1000);
                  }}
                />
                <div
                  ref={cardsContainerRef}
                  className="col-12 col-md-8 col-lg-8 text-left list-popularcontent cards-container"
                  style={{
                    // top: "100px",
                    height: window.screen.height,
                    overflow: "auto",
                  }}
                >
                  {resourcesList.map((data, index) => (
                    <ResourcesCardComponent
                      key={index}
                      index={index}
                      ref={(el) => (cardRefs.current[index] = el)}
                      title={data.title}
                      description={data.description}
                      image={data.image}
                      onClickDownload={() => onClickDownload(data.file)}
                      isActive={index === activeIndex}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default ResourcesComponent;
