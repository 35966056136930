import React, { forwardRef } from "react";
import * as constand from "../../../constant";
const ResourcesCardComponent = forwardRef(
  ({index, title, description, image, onClickDownload, isActive }, ref) => {
    return (
      <div
        ref={ref}
        className={` ${
          isActive ? "active-card" : "inactive-card"
        } list-card-datas`}
      >
        <div className="d-flex justify-content-between justify-content-center align-items-center mb-2">
          <h6 className="font-qbold font-30 color-black fw600">
            {" "}
            {index + 1}. {title}
          </h6>
          <a
            href="#"
            className=" download-button font-14 font-qbold fw600 bg-lightblue"
            onClick={onClickDownload}
          >
            Download
          </a>
        </div>
        <p className="font-qmedium fw500 font-16 mb-32">{description}</p>
        <figure className="mb-0">
          <img
            src={constand.S3_URL + image}
            className="img-fluid"
            alt="Chart"
          />
        </figure>
      </div>
    );
  }
);

export default ResourcesCardComponent;
