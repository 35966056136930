import React, { useEffect } from "react";
import SurveyForms from "./SurveyForms";
import VideoContent from "../../dynamicDashboard/VideoContent";
import { VideoMultiContent } from "../../dynamicDashboard/VideoMultiContent";
import SurveyNavigation from "./SessionNavBar";
import SessionSummary from "../../dynamicDashboard/SessionSummary";

/**
 * A React functional component that renders a survey card with interactive activity.
 *
 * @function SurveyCard
 * @param {Object} props - The component's props.
 * @param {Object} props.SurveySchema - The schema for the survey form.
 * @returns {JSX.Element} - The rendered survey card component.
 */
export default function SurveyCard({
  sessionOrder,
  title,
  description,
  sessionStep,
  sessionLength,
  currentSession,
  handleChangeSession,
  getSessionStep,
}) {
  const renderSurveyNavigation = (isSurvey) => (
    <SurveyNavigation
      isSurvey={isSurvey}
      currentSession={currentSession}
      sessionLength={sessionLength}
      handleChangeSession={handleChangeSession}
      isCompleted={sessionStep?.isMandatory && !sessionStep?.isCompleted}
    />
  );

  const renderContent = (val) => {
    switch (val?.contentType) {
      case "video":
        return (
          <VideoContent
            key={val.id}
            videoContent={val?.videoData}
            getSessionStep={getSessionStep}
          />
        );
      case "form":
        return (
          <SurveyForms
            key={val.id}
            SurveySchema={JSON.parse(val?.formData)}
            handleChangeSession={handleChangeSession}
            currentSession={currentSession}
            formId={val.foreignId}
            SurveyNavigation={renderSurveyNavigation(
              val?.contentType === "form"
            )}
          />
        );
      default:
        return <SessionSummary key={val.id} summary={val?.summary} />;
    }
  };
return (
  <section id="sectionthree-survey" className="sectionOne-survey mt-50 mb-80">
    <div className="container-fluid mx-auto w-60">
      <div className="row justify-content-center">
        <div
          className={`col-12 col-md-12 col-lg-${
            sessionStep?.stepContents?.length > 1 ? "12" : "10"
          } text-left smart-goals`}
        >
          <p className="font-16 colorblue fw500 font-medium mb-1 session-head">
            Session {sessionOrder}
          </p>
          <h1 className="black-txt font-semibold font-30 fw600 mb-32 session-mainhead">
            {title}
          </h1>

          {sessionStep?.stepContents.length == 0 &&
            renderSurveyNavigation(false)}
          {sessionStep?.stepContents?.length > 1 ? (
            <>
              <VideoMultiContent
                videoContent={sessionStep?.stepContents}
                getSessionStep={getSessionStep}
              />
              {renderSurveyNavigation()}
            </>
          ) : (
            sessionStep?.stepContents?.map((val) => (
              <React.Fragment key={val.id}>
                {renderContent(val)}
                {val?.contentType != "form" && renderSurveyNavigation(false)}
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </div>
  </section>
);
}
