import {
    GET_BLOG_TITLES,WELCOME_CONDITION_MODEL_BY_BLOG
} from '../utilities';
import _ from 'lodash';

const INITIAL_STATE = {
    blog_titles:[],
    is_condition_model_open_by_blog:false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BLOG_TITLES:
            console.log('blogs-action.payload',action.payload)
            return {
                ...state,
                blog_titles: action.payload
            };
        case WELCOME_CONDITION_MODEL_BY_BLOG:
                return {
                    ...state,
                    is_condition_model_open_by_blog: action.payload,
                    pageUrl:'resources/all'
                }
        default:
            return state;
    }
};
