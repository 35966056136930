import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { toast } from "react-toastify";
import { fetchDashboardSchedule, loginModelOpen, start_loader, stop_loader, joinClass, cancelClass } from "../../actions";
import moment from 'moment';
import { commonService } from "../../_services";
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import EventCalendarComponent from "./EventCalendarComponent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";
import { Cookies } from "react-cookie-consent";

class ScheduleListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            liveClassList: [],
            displayLayout: false,
            condition: this.props.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            total_levels: [],
            openResearchModel: false,
            current_attendee: {},
            redirectUrl: '',
            workoutId: ''
        };
        this.submitStartNow = this.submitStartNow.bind(this);
        this.modelClose = this.modelClose.bind(this);
        this.joinClassService = this.joinClassService.bind(this);
        this.fetchLiveClassList = this.fetchLiveClassList.bind(this);
        this.closeResearchModel = this.closeResearchModel.bind(this);
        this.viewPrepoll = this.viewPrepoll.bind(this);
        this.beforeReview = this.beforeReview.bind(this);
        console.log('schedule view props',props)
    }

    closeResearchModel() {
        this.setState({ openResearchModel: false });
    }

    beforeReview() {
        window.open(this.state.redirectUrl);
    }

    viewPrepoll(live) {
        console.log('prepoll', live.Attendees[0].reference);
        this.setState({ openResearchModel : true, redirectUrl: live.Attendees[0].reference, current_attendee: live.Attendees[0], workoutId: live.WorkoutId})
        console.log('url',this.state.redirectUrl)
    }

    submitStartNow(item) {
        if (this.props.is_auth) {
            this.joinClassService(item);
        } else {
            this.props.loginModelOpen(true);
        }
    }

    joinClassService(item) {
        var dataObj = { "roomId": item.id };
        this.props.start_loader();
        this.props.joinClass(dataObj).then(
            response => {
                this.props.stop_loader();
                if (response) {
                    this.setState({
                        modelState: true,
                        modelData: item
                    });
                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    modelState: false,
                    modelData: {}
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        this.setState({ modelState: false, modelData: {} });
    }
    /** fetch live class list on page did mount */
    componentDidMount() {
        this.setState({ condition: this.props.condition }, () => {
            this.fetchLiveClassList();
        })
    }
    componentWillReceiveProps(prevProps) {
        if (this.props.params && prevProps.params.condition && this.props.params.condition !== prevProps.params.condition) {
            var condition = commonService.replaceChar(prevProps.params.condition, true)
            this.setState({ condition: condition }, () => {
                this.fetchLiveClassList();
            })
        }
    }
    /**
     * fetch class list data
     */
    fetchLiveClassList() {
        this.setState({ Loading: true, disableButton: true });
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition
        };
        this.props.fetchDashboardSchedule(dataObj).then(
            response => {
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {
                        this.groupListResult(list.liveClasses);
                    }
                    this.setState({
                        Loading: false,
                        count: response.count,
                        disableButton: false,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });

                }
            },
            error => {
                this.setState({
                    Loading: false,
                    disableButton: false
                });
                // toast.error(error);
            }
        );
    }
    /**
     * get day name
     */
    // getDayNameFromDate(date){
    //     var dt = moment(date, "YYYY-MM-DD")
    //     var day = moment(date).format('dddd');
    //     var monthName = moment(date).format('MMMM');
    //     var weekDay = moment(date).format('DD');
    //     return day + '  ' + monthName +'  '+ weekDay;
    // }
    /**
   * render start now or go to class
   */
    renderSchedule(item) {
        // var currentDateTime = moment().format('YYYY-MM-DD hh:mm::ss');
        // var scheduleTime = moment(item.scheduledFor).format('YYYY-MM-DD hh:mm::ss');
        // if (scheduleTime < currentDateTime){
        //     return (
        //         <a
        //             className="btn btn-purple w-100 font-medium m-b-10"
        //             href={item.reference}
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // }else{
        //     return (
        //         <a onClick={() => this.submitStartNow(item)}
        //         className="btn btn-purple w-100 font-medium m-b-10"
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // }
        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn-purple-inverse pad_list_btn clearfix"> {item.Attendees[0].reference || '#'} </a>
            );
        } else {
            var tagCondition =  commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();
            if (item.Signedup && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition)) {
                return (
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={()=> this.viewPrepoll(item)} > Go to class </a>                   
                );
            } else if ((item.Signedup && !this.props.logged_userData.isStudyParticipant) || (item.Signedup && this.  props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition)))  {
                return (
                    // <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={item.Attendees[0].reference || '#'} target="_blank" > Go to class List view </a>   
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={()=> this.viewPrepoll(item)} > Go to class</a>                
                );
            }
            else {
                return (
                    <a onClick={() => this.submitStartNow(item)} className="btn btn-purple-inverse pad_list_btn clearfix pointer" > Sign up </a>
                );
            }
        }
    }
    //cancel class
    cancelCalss(item, index, key) {
        if (!this.state.cancelLoading) {
            this.setState({ cancelLoading: true });
            var dataObj = {
                "roomId": item.id
            };
            this.props.cancelClass(dataObj).then(
                response => {
                    if (response) {
                        toast.success(response.message);
                    }

                    var array = [...this.state.liveClassList];
                    array[index].splice(key, 1);
                    var filtered = array.filter(function (el) {
                        return el.length > 0;
                    });
                    this.setState({
                        liveClassList: filtered,
                        cancelLoading: false
                    });
                },
                error => {
                    this.setState({
                        cancelLoading: false
                    });
                }
            );
        }
    }
    /**
     * render item list
     */
    renderSubItemList(data, key) {
        var tagCondition =  commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();
        return data.map((item, index) => (
            <div className="list-group-item" key={index}>
                <div className="col-md-12 desktop-view-live">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">

                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}> {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                        {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (commonService.replaceChar(this.props.condition, false))} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Discipline:
                            </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "discipline", this.state.total_tags, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Difficulty:
                          </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "level", this.state.total_levels, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {(item.type == 'Live' && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition)) ?                                
                                    <a target="_blank" className="btn btn-purple-inverse w-100 font-medium m-b-10" onClick={()=> this.viewPrepoll(item)}>Go To Class </a>
                                    : 
                                    ((item.type == 'Live' && !this.props.logged_userData.isStudyParticipant) || (item.type == 'Live' && this.  props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition))) 
                                    ? 
                                    <a target="_blank" className="btn btn-purple-inverse w-100 font-medium m-b-10" href={item.Attendees[0].reference || '#'}>Go To Class </a>
                                    :
                                    <a className="btn btn-purple-inverse w-100 font-medium m-b-10" href={"/detail/" + item.Workout.id + "/" + (commonService.replaceChar(this.props.params.condition, false))} onClick={() => { localStorage.setItem('scheduleRoomId', item.id); }}>Start Now</a>
                                }
                                {(item.Signedup) &&
                                    <a href="javascript:void(0)"
                                        className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(item, key, index)}
                                    >
                                        Oops, I can't make it
                                    </a>}
                                <span
                                    className="btn btn-default-list-blue clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                {item.type == 'Live' && <span className="btn btn-blue-inverse w-100 btn-white redtext bg-white font-medium" >Live session</span>}
                                <EventCalendarComponent item={item} type={item.type} title={item.Workout.title} length={item.Workout.length} />

                            </div>
                        </div>
                    </div>
                </div>
                {/* mobile view */}
                <div className="col-md-12 mobile-view-live">
                    <div className="row">
                        <div className="media col-12 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 float-left p-0 ">
                                <div className="col-12  float-left p-0">
                                    <div className="p-0 border-0 float-left w-100 m-t-5 m-b-5">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.condition + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>{item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                        {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + (commonService.replaceChar(this.props.condition, false))} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium col-12 p-0 m-b-10 float-left ">
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Discipline:
                                </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "discipline", this.state.total_tags, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Difficulty:
                                </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "level", this.state.total_levels, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    {/* <div className="col-md-4 col-sm-4 float-left p-0">
                                <span className="w-40 float-left font-13 font-qregular black-txt">
                                    Language:
                                    </span>
                                <span className="w-60 float-left">
                                    {" "}
                                    <img
                                        className="img-fluid p-l-5 p-b-10"
                                        src="/images/flag.png"
                                        alt=""
                                    />
                                </span>
                            </div> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-12 float-left p-0 text-center">
                            {this.renderSchedule(item)}
                            {(item.Signedup) &&
                                <a href="javascript:void(0)"
                                    className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(item)}
                                >
                                    Oops, I can't make it
                            </a>}
                            <span
                                className="btn btn-default-list-blue clearfix pad_list_btn"
                            >
                                {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                            </span>
                            {item.type == 'Live' && <span
                                className="btn btn-default-list-orange clearfix pad_list_btn"
                            >
                                Live Session
                        </span>}
                            <EventCalendarComponent item={item} type={item.type} title={item.Workout.title} length={item.Workout.length} />
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
    /**
     * render live class list
     */
    renderListView() {
        return this.state.liveClassList.map((item, index) => (
            <div key={index}>
                <p className="section_header font-semibold">
                    {commonService.getDayNameFromDate(item[0].scheduledFor)}
                </p>
                {this.renderSubItemList(item, index)}
            </div>
        ));
    }
    /**
     * render load more option
     */
    renderLoadMore() {
        if (this.state.liveClassList && this.state.count > this.state.liveClassList.length) {
            return (
                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                    <span
                        onClick={() => {
                            if (!this.state.disableButton) {
                                this.incrementShowmoreCnt()
                            }
                        }}
                        className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                    >
                        Show more schedule list
                    </span>
                </div>
            );
        }
    }

    /**
    * render list
    */
    renderListData() {
        if (this.state.liveClassList && this.state.liveClassList.length > 0) {
            return (
                <div>
                    {this.renderListComponent()}
                </div>
            );
        } else {
            return (
                <div className="row p-b-30 p-t-30">
                    <h3 className="col-sm-12 text-center">
                        Oh no - you have no classes scheduled.  Why not schedule an on-demand class right now?
                    </h3>
                </div>
            );
        }
    }
    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT,
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {
        // let classList = [];
        // let existingData = this.state.liveClassList;
        // let newData = originalList;
        // var helper = {};
        // let results = [];
        // newData.map(function (item, key) {
        //     if (existingData && existingData.length > 0 && newData && item && existingData[existingData.length - 1][0].scheduledFor === item.scheduledFor) {
        //         existingData[existingData.length - 1].push(item);
        //         classList = existingData;
        //     } else {
        //         var key = item.scheduledFor;
        //         if (!helper[key]) {
        //             helper[key] = [];
        //             helper[key].push(item)
        //             results.push(helper[key]);
        //         }
        //         else {
        //             helper[key].push(item)
        //         }
        //         classList = [...existingData, ...results];
        //     }
        // });
        let classList = [];
        let existingData = this.state.liveClassList;
        let newData = originalList;
        var helper = {};
        let results = [];
        newData.map(function (item, key) {
            if (existingData && existingData.length > 0 && newData && item && moment(existingData[existingData.length - 1][0].scheduledFor).format("DD/MM/YYYY") === moment(item.scheduledFor).format("DD/MM/YYYY")) {
                existingData[existingData.length - 1].push(item);
                classList = existingData;
            } else {
                var key = moment(item.scheduledFor).format("DD/MM/YYYY");
                if (!helper[key]) {
                    helper[key] = [];
                    helper[key].push(item)
                    results.push(helper[key]);
                }
                else {
                    helper[key].push(item)
                }
                classList = [...existingData, ...results];
            }
        });
        this.setState({ liveClassList: classList })
    }
    /**
     * renderListComponent
     */
    renderListComponent() {
        if (!this.state.displayLayout) {
            return (
                <div id="products" className="row">
                    <div className="list-group">
                        {this.renderListView()}
                    </div>
                    {this.renderLoadMore()}
                </div>
            );
        }
    }

    /**
     * change Layout
     */
    changeViewLayout() {
        var layout = this.state.displayLayout;
        this.setState({
            displayLayout: !layout
        })
    }


    //main render
    render() {
        return (
            <div className=""
            >
                <ResearchStudyPollPopup
                    is_model_open={this.state.openResearchModel}
                    closeResearchModel={this.closeResearchModel}
                    classType="Live"
                    beforeReview = {this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                />
                <Helmet>
                    <title>{constand.DASHBOARD_SCHEDULE_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.DASHBOARD_SCHEDULE_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.DASHBOARD_SCHEDULE_DESC} />
                    <meta property="og:image" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.DASHBOARD_SCHEDULE_PAGE_IMAGE_ALT} />
                </Helmet>
                <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div>
                {(!this.state.Loading) && this.renderListData()}
                <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth
    };
};

const mapDispatchToProps = {
    fetchDashboardSchedule, loginModelOpen, start_loader, stop_loader, joinClass, cancelClass
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleListView);
