import React, { useEffect } from "react";
import * as constand from "../../constant";
import { Player } from "video-react";

const WelcomePopup = ({
    isDisplay,
    getStartedCb,
    content
}) => {
  useEffect(() => {
    document.getElementById('trailer-popup-desc').innerHTML = content?.dashBoardContent?.description;
  }, [content])

  const handleOnWelcomePopup = () => {
    getStartedCb();
  }
  return (
    <React.Fragment>
      {/*modal*/}
      <a
        href="#"
        className="completed-button black-txt bg-white font-16 font-semibold fw600 bg-lightblue"
        data-toggle="modal"
        data-target="#welcomeModal"
      >
        Welcome Popup
      </a>
      {isDisplay ? <div className="modal-backdrop fade show"></div> : null}
      <div
        className="modal fade show"
        style={{
          display: isDisplay ? "block" : "hidden",
          // overflow: "auto",
        }}
        id="welcomeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="welcomeModal"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-width--custom modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div
              className="modal-body body-padding--value p-32"
              id="register-box"
            >
                 <div className=" video-container">
         
              {content?.dashBoardContent?.trailerURL ? <Player
                    id="myVideo"
                    autoPlay={false}
                    className="w-100 border-0 mb-5 "
                    height="400"
                    playsInline
                    poster={constand.S3_URL + content?.dashBoardContent?.img ? constand.PROGRAM_IMG_PATH + content?.dashBoardContent?.img : constand.WEB_IMAGES + "ondemand-placeholder.png"}
                    src={constand.S3_URL + content?.dashBoardContent?.trailerURL}
                ></Player> : null}
                 </div>
              <h5 className="black-txt font-qbold font-20 fw500 mobile-font18 mb-2 text-center">
                Welcome to {content?.dashBoardContent?.title}
              </h5>
              <p className="font-16 ash-txt fw500 font-qmedium text-center" id="trailer-popup-desc">
                  {content?.dashBoardContent?.description}
              </p>
              <div class="cancel-save-btn">
                <button
                  type="submit"
                  class="bluebutton1 btn savebtn font-qbold font-16 fw500"
                  onClick={() => handleOnWelcomePopup()}
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomePopup;
