import React from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie-consent";
import * as constand from "../../constant";
import Autosuggest from "react-autosuggest";
class AutoSearch extends React.Component {
    constructor() {
        super();

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: []
        };
    }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        console.log('this.props.searchSuggestion', this.props.searchSuggestion)
        return inputLength === 0 ? [] : this.props.searchSuggestion.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => suggestion.name;

    // Use your imagination to render suggestions.
    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );


    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (e, suggestionsData) => {
        console.log('onSuggestionSelected', suggestionsData.suggestionValue)
        this.props.onSearchInput(suggestionsData.suggestionValue, true);
    }

    onChange = (event, { newValue }) => {
        if (newValue) {
            this.setState({ value: newValue });
            this.props.onSearchInput(newValue);
        } else {
            this.setState({ value: "" });
            this.props.onSearchInput("");
        }
    };

    onKeyDown = (event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
            this.props.searchFunction();
        }
    };

    render() {
        const { value, suggestions } = this.state;
        let placeholder = this.props.options
            ? this.props.options.value
            : "Searching...";
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: placeholder,
            value: this.props.searchingWord,
            type: "search",
            onChange: this.onChange,
            onKeyDown: this.onKeyDown,
            // style: { color: placeholderFontColor },
        };
        
        // Finally, render it!
        return (
            <Autosuggest
                theme={this.props.theme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
                alwaysRenderSuggestions={true}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
    };
};
const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoSearch);
