import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as constand from "../../constant";
import ReactGA from 'react-ga';
import { setStep, onboardingUserTrack } from "../../actions";

class PasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: this.props.registerFormvalues.password,
            confirm_password: this.props.registerFormvalues.password,
            password_error: true,
            error_msg: null,
            submitted: false,
            isShowPassword: false,
            isShowConfirmPassword: false,
            passwordLengthErr: false,
            passwordLowerCaseErr: false,
            passwordUpperCaseErr: false,
            passwordNumberErr: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.strongPasswordValidation = this.strongPasswordValidation.bind(this);
        this.registerSubmit_2 = this.registerSubmit_2.bind(this);
    }
    componentDidMount() {
        this.strongPasswordValidation();
        this.passwordValidationErrorList();
    }
    handleChange(e) //for twoway binding
    {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.props.registerFormvalues[name] = value;
        this.strongPasswordValidation();
        this.passwordValidationErrorList();
    }
    strongPasswordValidation() {
        let password = this.props.registerFormvalues.password;
        if (password !== "" && password === this.props.registerFormvalues.confirm_password) {
            if (password && password.length < 8) {
                this.setState({ error_msg: "Password must contain at least eight characters", password_error: true });
                return;
            }
            if (password == this.props.registerFormvalues.username) {
                this.setState({ error_msg: "Password must be different from Username", password_error: true });
                return;
            }
            let number_regx = /[0-9]/;
            if (!number_regx.test(password)) {
                this.setState({ error_msg: "Password must contain at least one number (0-9)", password_error: true });
                return;
            }
            let lowercase_regx = /[a-z]/;
            if (!lowercase_regx.test(password)) {
                this.setState({ error_msg: "password must contain at least one lowercase letter (a-z)", password_error: true });
                return;
            }
            let uppercase_regx = /[A-Z]/;
            if (!uppercase_regx.test(password)) {
                this.setState({ error_msg: "password must contain at least one uppercase letter (A-Z)", password_error: true });
                return;
            }
            this.setState({ error_msg: null, password_error: false });
            return;
        } else {
            this.setState({ error_msg: "Please check that you've entered and confirmed your password", password_error: true });
            return;
        }
    }

    registerSubmit_2() {
        this.setState({ submitted: true });
        this.strongPasswordValidation();
        if (!this.state.password_error) {
            this.props.registerformUpdate(this.props.registerFormvalues);
            this.onboardingUserTrack()
            ReactGA.event({
                category: "User Acquisition",
                action: "Sign up process",
                label: "Password creation"
            })
        }
    }
    showHidePassword = () => {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }
    showHideConfirmPassword = () => {
        this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword })
    }

    passwordValidationErrorList = () => {
        // at least 8 char 
        let password = this.props.registerFormvalues.password;
        if (password && password.length > 7) {
            this.setState({ passwordLengthErr: true });
        } else {
            this.setState({ passwordLengthErr: false });
        }

        //at least one number
        let number_regx = /[0-9]/;
        if (password && number_regx.test(password)) {
            this.setState({ passwordNumberErr: true });
        } else {
            this.setState({ passwordNumberErr: false })
        }

        //at least one lowercase
        let lowercase_regx = /[a-z]/;
        if (password && lowercase_regx.test(password)) {
            this.setState({ passwordLowerCaseErr: true })
        } else {
            this.setState({ passwordLowerCaseErr: false })
        }

        //at least one uppercase 
        let uppercase_regx = /[A-Z]/;
        if (password && uppercase_regx.test(password)) {
            this.setState({ passwordUpperCaseErr: true })
        } else {
            this.setState({ passwordUpperCaseErr: false })
        }

    }

    onboardingUserTrack = () => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            this.props.setStep(4, 'forward')  //go to name screen
        })
    }
    render() {
        return (
            <div className="step4">
                <h3 className="text-center "><a className="pull-left" href="javascript:void(0)" onClick={this.props.prevStep}><img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} /></a>Let’s keep your details secure<Link
                    to="/home"
                    className="close-register orangefont"
                >
                    X
                </Link></h3>
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-6 m-t-10">
                        <div className="form-group">
                            <label htmlFor="password" className="text-label">Enter password</label>
                            <div class="passwordeye_align">
                                <input type={this.state.isShowPassword ? "text" : "password"} autocomplete="off" name="password" id="password" className="form-control input-control" onChange={this.handleChange} value={this.state.password} />
                                <div class="input-group-addon">
                                    <button onClick={() => this.showHidePassword()} >
                                        <i class={this.state.isShowPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm_password" className="text-label">Re-enter password</label>
                            <div class="passwordeye_align">
                                <input type={this.state.isShowConfirmPassword ? "text" : "password"} autocomplete="off" name="confirm_password" id="confirm_password" className="form-control input-control" onChange={this.handleChange} value={this.state.confirm_password} />
                                <div class="input-group-addon">
                                    <button onClick={() => this.showHideConfirmPassword()} >
                                        <i class={this.state.isShowConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {this.state.submitted && this.state.error_msg && <p className="text-danger">{this.state.error_msg}</p>}
                        </div>
                        <div>
                            <p className="font-14 font-qmedium black-txt m-t-0 m-b-0" style={{ color: this.state.passwordLengthErr ? "green" : "red" }}>At least eight characters.</p>
                            <p className="font-14 font-qmedium black-txt m-t-0 m-b-0" style={{ color: this.state.passwordUpperCaseErr ? "green" : "red" }}>At least one uppercase character.</p>
                            <p className="font-14 font-qmedium black-txt m-t-0 m-b-0" style={{ color: this.state.passwordLowerCaseErr ? "green" : "red" }}>At least one lowercase character.</p>
                            <p className="font-14 font-qmedium black-txt m-t-0 m-b-0" style={{ color: this.state.passwordNumberErr ? "green" : "red" }}>At least one number.</p>
                        </div>
                        <div id="register-link" className=" w-100 m-t-80 m-b-20 float-left">
                            <a href="javascript:void(0)" onClick={this.registerSubmit_2} className="bluebtn float-left w-100 text-center">Next</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    setStep, onboardingUserTrack
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordComponent);
