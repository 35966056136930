import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";
import {
  CREATE_UPDATE_GOALS_LIST,
  IS_GOAL_MODAL_OPEN,
  SET_DASHBOARD_PROGRAM_GOALS_LIST,
  SET_PROGRAM_GOALS_LIST,
} from "../utilities";
import { convertToQueryString } from "../_components/dynamicDashboard/utlity";

/* create new goal */
export function createUpdateGoal(data) {
  return (dispatch, getState) => {
    const requestOptions = postData(data);
    return fetch(
      constand.BACKEND_URL + "/api/programmes/goal/saveEditGoal",
      requestOptions
    )
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (response.success) {
          dispatch({
            type: CREATE_UPDATE_GOALS_LIST,
            payload: true,
          });
        }
        return response;
      });
  };
}

/**
 * To complete the goal
 *
 * @param {Object} params - The parameters for handling the assignment type.
 * @param {Array} params.goalId - goal id
 * @param {Array} params.isCompleted - boolean
 */
export function completeGoal(goalId, isCompleted) {
  return (dispatch, getState) => {
    const params = { goalId: goalId, isCompleted: isCompleted };
    const requestOptions = postData(params);
    return fetch(
      constand.BACKEND_URL + "/api/programmes/goal/complete",
      requestOptions
    )
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        // if (response.success) {
        //   dispatch({
        //     type: CREATE_UPDATE_GOALS_LIST,
        //     payload: true,
        //   });
        // }
        return response;
      });
  };
}
/**
 * Fetches the list of goals for a specific program.
 *
 * @function fetchGoals
 * @param {string} programID - The ID of the program for which to fetch the goals.
 * @param {number} limit - The maximum number of goals to fetch.
 * @param {number} offset - The number of goals to skip before fetching.
 **/
export function fetchGoals(payload, isDashboard = false) {
  return (dispatch, getState) => {
    const requestOptions = getData();

    let url =
      constand.BACKEND_URL +
      `/api/programmes/goal/${payload.id}?${payload.queryString}`;
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        if (isDashboard) {
          dispatch({
            type: SET_DASHBOARD_PROGRAM_GOALS_LIST,
            payload: response ? response.goals : [],
          });
        }
        // else {
        // dispatch({
        //   type: SET_PROGRAM_GOALS_LIST,
        //   payload: response ? response : [],
        // });
        // }
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
export function goalsModalOpen(flag, goal) {
  return (dispatch, getState) => {
    dispatch({
      type: IS_GOAL_MODAL_OPEN,
      payload: flag,
      goal: goal,
    });
  };
}