import React, { useState, useEffect, useRef } from "react";
import { Player } from "video-react";
import Modal from "react-responsive-modal";
import * as constand from "../../constant";

const DashboardPlayer = ({ videoContent, isPlay, onCloseModal }) => {
  const [timeSpent, setTimeSpent] = useState([]);
  const playerRef = useRef(null);
  useEffect(() => {
    const secondInterval = setInterval(() => {
      if (isPlay && playerRef.current) {
        const { player } = playerRef.current.getState();
        if (player.ended) {
          submitTime();
          handleCloseModal()
          return;
        }
        if (!player.waiting && !player.paused) {
          const duration = parseInt(player.duration);
          if (timeSpent.length === 0) {
            setTimeSpent(new Array(duration).fill(false));
          }
          recordTimeSpent(player.currentTime);
        }
      }
    }, 1000);

    return () => clearInterval(secondInterval);
  }, [isPlay, timeSpent]);

  // useEffect(() => {
  //   const minuteInterval = setInterval(() => {
  //     console.log('~~~~ 1-minute Interval');
  //       submitTime();
  //   }, 10000); // 60000 ms = 1 minute

  //   return () => clearInterval(minuteInterval);
  // }, [timeSpent]);

  const recordTimeSpent = (currentTime) => {
    setTimeSpent((prevTimeSpent) => {
      const updatedTimeSpent = [...prevTimeSpent];
      updatedTimeSpent[parseInt(currentTime)] = true;
      return updatedTimeSpent;
    });
  };

  const submitTime = () => {
    const totalTimeSpentInSeconds = timeSpent.filter(Boolean).length;

    const spendTimeInMinutes = Math.floor(totalTimeSpentInSeconds / 60);

    const videoLengthInMinutes = Math.floor(videoContent.length);

    const spendTime = Math.min(spendTimeInMinutes, videoLengthInMinutes);

    const percentage = (spendTime / videoLengthInMinutes) * 100;

    const showAfterReview = percentage >= constand.VIDEO_PERCENTAGE;

  };

  const handleCloseModal = () => {
    // submitTime();
    const totalTimeSpentInSeconds = timeSpent.filter(Boolean).length;

    const spendTimeInMinutes = Math.floor(totalTimeSpentInSeconds / 60);

    const videoLengthInMinutes = Math.floor(videoContent.length);

    const spendTime = Math.min(spendTimeInMinutes, videoLengthInMinutes);

    const percentage = (spendTime / videoLengthInMinutes) * 100;

    const showAfterReview = percentage >= constand.VIDEO_PERCENTAGE;
    onCloseModal({spendTime, showAfterReview});
  };
  return (
    <Modal
      // classNames={{ modal: "custom-modal-styling" }}
      open={isPlay}
      onClose={handleCloseModal}
      center
    >
      <div id="welcomeModal">
        <div className="modal-dialog modal-width--custom" role="document">
          <div className="modal-content">
            <div className="modal-header header-styling border-0 bg-white p-0">
              <button
                type="button"
                className="close white-txt"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">
                  <img
                    src="/images/dashboard/close.svg"
                    className="img-fluid"
                    alt="Close Icon"
                  />
                </span>
              </button>
            </div>
            <div className="modal-body body-padding--value p-32 video-container">
              <Player
                ref={playerRef}
                id="myVideo"
                autoPlay
                className="w-100 border-0 mb-3"
                height="400"
                playsInline
                src={constand.S3_URL + videoContent?.videoUrl}
              ></Player>
              <h5 className="black-txt font-qbold font-20 fw500 mobile-font18 text-center">
                {videoContent?.title}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardPlayer;
