import { useState } from "react";
import EmojiIcon from "../../../assets/images/messages/emoji.svg";
import EmojiPicker from "emoji-picker-react";

/**
 * A form component for sending messages.
 *
 * @param {Object} props - The component's props.
 * @param {Boolean} props.isUserActivated - user activated
 * @param {Function} props.onSendMessage - A callback function to handle sending messages.
 * @param {Boolean} props.showSearchPopup - A flag indicating whether to show a search popup.
 *
 * @returns {JSX.Element} - The SendMessageForm component.
 */
const SendMessageForm = ({ isUserActivated,onSendMessage, showSearchPopup }) => {
  const [text, setText] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text.trim()) return;
    setShowPicker(false);
    onSendMessage(text);
    setText("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setShowPicker(false);
      handleSubmit(e);
    }
  };
  const handleEmojiClick = (event, emojiObject) => {
    setText((prevText) => prevText + event.emoji);
  };

  let placeholderText = "Send a message";
  if (showSearchPopup) {
    placeholderText = "Search message here";
  }
  if (!isUserActivated) {
    placeholderText = "You can continue messaging in a few minutes.";
  }
  return (
    <>
      <form className="send-message-form" onSubmit={handleSubmit}>
        <div
          className="input-container"
          style={{ border: showSearchPopup ? "1.5px solid #456eb0" : "" }}
        >
          <textarea
            className="textbox-input"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={placeholderText}
            rows={70}
            cols={5}
            style={{ resize: "none" }}
            disabled={!isUserActivated}
          />
          <div className="mt-auto mb-2">
            <button
              type="button"
              className="icon-button"
              onClick={() => setShowPicker(!showPicker)}
            >
              <img src={EmojiIcon} alt="emoji" />
            </button>
            <button
              className="button ml-2"
              type="submit"
              disabled={!isUserActivated}
            >
              {showSearchPopup ? "Search" : "Send"}
            </button>
          </div>
        </div>
        {showPicker && (
          <div className="emoji-picker-popup">
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              searchDisabled={true}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default SendMessageForm;
