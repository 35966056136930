import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";
import { SET_PROGRAM_RESOURCES_LIST } from "../utilities";

/**
 * To get goal list
 * @param programID
 */
export function fetchResources(programID) {
  return (dispatch, getState) => {
    const requestOptions = getData();
    let url = constand.BACKEND_URL + `/api/programmes/resources/${programID}`;
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
        dispatch({
          type: SET_PROGRAM_RESOURCES_LIST,
          payload: response.resources.length > 0 ? response.resources : [],
        });
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  };
}
