import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { commonService } from "../../_services";
import { ImageTag } from "../../tags";
import { fetchGroupMembers, start_loader, stop_loader } from "../../actions";
import * as constand from "../../constant";
import InviteModal from './InviteModal';
class MemberComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            membersList: [],
            memberDetails: {},
            group: this.props.params.group,
            modelState: false,
        };
        this.modelClose = this.modelClose.bind(this);
        this.bindUsername = this.bindUsername.bind(this);
    }

    componentWillMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        if (!this.props.is_auth) {
            const { from } = { from: { pathname: "/home" } };
            this.props.history.push(from);
        } else if (this.props.history.location.pathname && (!this.props.groupState.isMember && (!authData || (authData && !authData.isCreator && !authData.isStudyLeader))) && !this.props.groupState.canEdit) {
            const { from } = { from: { pathname: "/group/about/" + this.props.groupState.group + "/" + this.props.groupState.condition } };
            this.props.history.push(from);
        }
    }

    componentDidMount() {
        this.fetchGroupMembers();
    }
    /**
     * fetch details of Group
     */
    fetchGroupMembers() {
        this.props.start_loader();
        var data = { group: this.state.group }
        this.props.fetchGroupMembers(data).then(
            response => {
                if (response) {
                    var members = response.groups.Members;
                    this.setState({
                        membersList: members,
                        memberDetails: response.groups,
                    })
                }
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        this.setState({
            modelState: false
        });
    }
    /* return logged user name */
    bindUsername(user = {}) {
        var returnName = "";
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        if (user) {
            if (authData.isStudyLeader || this.props.groupState.canEdit) {
                //all admins
                returnName = user.name && user.lastName ? user.name + ' ' + user.lastName : ''; //+ ' ' + user.lastName;
                if (user.nickname && user.nickname.trim() && (user.nickname.trim() !== 'null')) {
                    returnName = returnName + " (" + user.nickname + ")";
                }
            } else {
                //members
                if (user.nickname && user.nickname.trim() && (user.nickname.trim() !== 'null')) {
                    returnName = user.nickname;
                } else {
                    returnName = user.name && user.lastName ? user.name + ' ' + user.lastName : ''; //+ ' ' + user.lastName;
                }
            }
        }
        return returnName;
    }
    render() {
        return (
            <div>
                <div className="col-md-10 col-lg-8 col-sm-12 m-auto row">
                    {(this.props.logged_userData.isStudyLeader || this.props.logged_userData.isGroupLeader) && (this.props.is_group_mode || this.props.is_create_mode) &&
                        <div className="col-md-4 text-right mb-2">
                            <span onClick={() => { this.setState({ modelState: true }) }}
                                className={"btn button-filter close-btn font-medium font-14 pointer w-100 btn-purple"}
                            > Invite Members </span>
                        </div>
                    }
                </div>
                <div className="col-md-10 col-lg-8 col-sm-12 m-auto row">
                    {this.state.membersList.map((item, key) => {
                        return (
                            <div key={key} className="col-md-6 col-lg-6 col-sm-12 ">
                                <div className="card p-l-20 style-card  m-b-20">
                                    <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                        <div className="border-0 float-left">
                                            <ImageTag
                                                className="img-fluid rounded-circle"
                                                src={item.User && item.User.profilePic ? constand.PROFILE_IMAGE_PATH + item.User.profilePic : constand.WEB_IMAGES + 'no-profile-pic.png'}
                                                width="50" height="50"
                                            />
                                        </div>
                                        <p className="float-left m-t-5 font-14 font-qregular black-txt p-l-10">
                                            {this.bindUsername(item.User)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <InviteModal is_model_open={this.state.modelState} modelClose={this.modelClose} group={this.state.group} groupState={this.props.groupState} />
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        is_group_mode: state.header.is_group_mode,
        user_data: state.header.logged_userData,
        is_create_mode: state.header.is_create_mode,
    };
};

const mapDispatchToProps = {
    fetchGroupMembers, start_loader, stop_loader
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberComponent);
