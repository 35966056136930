import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { HtmlBindTag } from '../../tags';
import { fetchAboutGroup, start_loader, stop_loader } from "../../actions";
class AboutComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: this.props.params.group,
            aboutGroup: {},
            groupLeader: {}
        };
    }
    componentDidMount() {
        this.fetchAboutGroup();
        console.log('this.prs.about', this.props)
    }
    /**
     * fetch details of Group
     */
    fetchAboutGroup() {
        this.props.start_loader();
        var data = { group: this.state.group }
        this.props.fetchAboutGroup(data).then(
            response => {
                if (response) {
                    this.setState({
                        aboutGroup: response.groups,
                        groupLeader: response.groups.User
                    })
                }
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
            }
        );
    }

    render() {
        return (
            <div className="card col-md-8 col-lg-8 col-sm-10 m-auto">
                {!this.props.params.condition.toLowerCase().includes('research') &&
                    <div className="card-body">
                        <h5 className="m-b-20 font-medium font-24"> About Us </h5>
                        {(this.state.aboutGroup && this.state.aboutGroup.long_desc) ?
                            <HtmlBindTag className="font-qregular font-15" htmlData={this.state.aboutGroup.long_desc}>
                            </HtmlBindTag> : <p className="font-qregular font-15"></p>}

                    </div>
                }
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    fetchAboutGroup, start_loader, stop_loader
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutComponent);




