import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ImageTag, TextWrap } from "../../tags";
import {
  ondemandGroupList,
  start_loader,
  stop_loader,
  joinrequest,
  joingroup,
  loginModelOpen,
  joinModelOpen,
  showMoreGroups,
  groupCMSOpen,
  fetchAboutGroup,
  clearAboutDetails
} from "../../actions";
import * as constand from "../../constant";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import JoinModalComponent from "../GroupPage/JoinModalComponent";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { commonService } from "../../_services";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GroupCMSComponent from "../GroupPage/GroupCMSComponent";
import AdminBanner from "../Common/AdminBanner";

class OnDemandGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      groupList: [],
      groupPermissionList: [],
      show_more_cnt: constand.ONDEMAND_GROUP_COUND,
      list_count: 0,
      total_count: 0,
      search_key: "",
      offset: 0,
      isFullRecord: 0,
      groupsLimit: {
        'mygroup': {
          limit: constand.CONSTTHREE,
          offset: 0,
          countNext: 1,
          isFullRecord: 0,
        },
        'allgroup': {
          limit: constand.CONSTTHREE,
          offset: 0,
          countNext: 1,
          isFullRecord: 0,
        }
      },
      aboutGroup: {}
    };
    this.countNext = 1;
    this.getGroupList = this.getGroupList.bind(this);
    this.incrementShowmoreCnt = this.incrementShowmoreCnt.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchFucntion = this.searchFucntion.bind(this);
    this.joingroup = this.joingroup.bind(this);
    this.joinrequest = this.joinrequest.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.gotoGroupDetailPage = this.gotoGroupDetailPage.bind(this);
    this.returnInGroup = this.returnInGroup.bind(this);
    this.reCallGroupList = this.reCallGroupList.bind(this);
    this.showMore = this.showMore.bind(this);
    this.showMoreIncrement = this.showMoreIncrement.bind(this);
    this.checkUserIsMember = this.checkUserIsMember.bind(this);
    this.renderCreateGroupTemp = this.renderCreateGroupTemp.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.lastState = this.props.params.condition;

  }
  componentWillMount() {
    this.getGroupList();
    this.props.joinModelOpen(false)
  }

  componentWillReceiveProps(props) {
    console.log('componentWillReceiveProps')
    if (this.lastState != props.params.condition) {
      this.lastState = props.params.condition;
      this.setState(
        {
          typeFilter: props.params.condition
        },
        function () {
          this.reCallGroupList();
        }
      );
    }
  }

  incrementShowmoreCnt() {
    this.setState(
      {
        offset: this.state.offset + this.state.show_more_cnt
      },
      function () {
        this.getGroupList();
      }
    );
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  searchFucntion() {

    this.setState({ offset: 0, groupList: [], groupPermissionList: [], total_count: 0 }, function () {
      this.getGroupList();
    });
  }
  getGroupList() {
    this.setState({ Loading: true });
    var dataObj = {
      offset: this.state.offset,
      limit: constand.CONSTTHREE, //this.state.show_more_cnt,
      search: this.state.search_key
    };
    //this.props.start_loader();
    var typeFilter = commonService.replaceChar(this.props.params.condition, true);
    this.props.ondemandGroupList(dataObj, typeFilter).then(
      response => {
        this.setState({
          Loading: false
        });
        if (response) {
          var list_data = response.groups ? response.groups : [];
          console.log('list_data', list_data);
          var { groupsLimit } = this.state;
          Object.keys(list_data.instructorGroups).map((mapGroups, mapKey) => {
            groupsLimit[mapGroups] = {
              limit: constand.CONSTTHREE,
              offset: 0,
              countNext: 1,
              isFullRecord: 0
            }
          });
          //var permission_data = response.list.isMember ? response.list.isMember : [];
          //var new_list = [...this.state.groupList, list_data];
          // var new_permissions = [...this.state.groupPermissionList, ...permission_data];
          this.setState({
            groupList: list_data,
            groupsLimit: groupsLimit
            //groupPermissionList: new_permissions,
            //list_count: response.list.count,
            //total_count: response.count
          });

        }
        //this.props.stop_loader();
      },
      error => {
        this.setState({
          Loading: false
        });
        //this.props.stop_loader();
        //toast.error(error);
      }
    );
  }
  returnInGroup(id) {
    var returnData = false;
    var groupData = this.state.groupPermissionList
      .filter(e => e.id === parseInt(id))
      .map((value, index) => {
        return value.isMember;
      });
    if (groupData && groupData.length > 0) {
      returnData = groupData[0];
    }
    return returnData;
  }
  submitRequest(item) {
    if (this.props.is_auth) {
      if (item.type === "closed") {
        this.props.joinModelOpen(true, item)
        // private group
        //this.joinrequest(item);
      } else {
        // public group
        this.joingroup(item);
      }
    } else {
      this.props.loginModelOpen(true);
    }
  }
  joingroup(item) {
    if (this.props.is_auth) {
      this.setState({ Loading: true }, function () {
        this.props.joingroup(item.id).then(
          response => {
            this.setState({ Loading: false });
            toast.success(response.message);
            const { from } = {
              from: {
                pathname:
                  "/group/about/" +
                  item.community_name +
                  "/" +
                  this.props.params.condition
              }
            };
            this.props.history.push(from);
          },
          error => {
            this.setState({ Loading: false });
            toast.error(error);
          }
        )
      });
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  reCallGroupList() {
    this.setState({ offset: 0, groupList: [], groupPermissionList: [] });
    this.getGroupList();
  }
  joinrequest(item) {
    if (this.props.is_auth) {
      this.setState({ Loading: true }, function () {
        this.props.joinrequest(item.id).then(
          response => {
            this.setState({ offset: 0, groupList: [], groupPermissionList: [], Loading: false }, function () {
              this.getGroupList();
            });
            toast.success(response.message);
          },
          error => {
            this.setState({ Loading: false });
            toast.error(error);
          }
        )
      });
    } else {
      //not authorized
      this.props.loginModelOpen(true);
    }
  }
  gotoGroupDetailPage(item) {
    var pagename = 'about';
    if (this.props.params.condition.toLowerCase().includes('research')) {
      pagename = 'feed';
    }

    const { from } = {
      from: {
        pathname:
          "/group/" + pagename + "/" + item.community_name + "/" + this.props.params.condition
      }
    };
    this.props.history.push(from);
  }
  /**
   * on enter search
   * @param {*} e
   */
  searchBarEnter(e) {
    if (e.key === "Enter") {
      this.searchFucntion();
    }
  }
  showMoreDecrement() {
    var tempGroupLimit = this.state.groupsLimit;
    tempGroupLimit['mygroup'].countNext = tempGroupLimit['mygroup'].countNext - 1;
    this.setState({ groupsLimit: tempGroupLimit })
    console.log('showMoreDecrement', tempGroupLimit['mygroup'].countNext)

  }
  showMoreIncrement() {
    var tempGroupLimit = this.state.groupsLimit;
    tempGroupLimit['mygroup'].countNext = tempGroupLimit['mygroup'].countNext + 1;
    this.setState({ groupsLimit: tempGroupLimit })
    console.log('showmoreIncrement', tempGroupLimit['mygroup'].countNext)

    if (tempGroupLimit['mygroup'].countNext == this.state.groupList.myGroups.total - 1) {
      this.showMore('mygroup', '')
    }
  }
  showMore(type, groupType, isAll=false) {
    let groupList = { ...this.state.groupList };
    let offset = this.state.offset + constand.CONSTTHREE;
    let isFullRecord = this.state.isFullRecord;
    let limit = constand.CONSTTHREE;
    let params = { type, groupType, offset, limit, isFullRecord };
    let typeFilter = commonService.replaceChar(this.props.params.condition, true);
    let me = this;
    let groupsTempLimit = { ...this.state.groupsLimit };
    console.log('groupsTempLimit', groupsTempLimit);
    if (groupType) {
      let offset = groupsTempLimit[groupType].offset + constand.CONSTTHREE;
      let isFullRecord = groupsTempLimit[groupType].isFullRecord + groupList.instructorGroups[groupType].total;
      params.offset = offset;
      groupsTempLimit[groupType].offset = offset;
      params.isFullRecord = isFullRecord;
      groupsTempLimit[groupType].isFullRecord = isFullRecord;
      params.limit = constand.CONSTTHREE;
    } else {
      let offset = groupsTempLimit[type].offset + constand.CONSTTHREE;
      let isFullRecord = groupsTempLimit[type].isFullRecord + groupList.myGroups.total;
      if (isAll) {
        //show all RS community 
        isFullRecord = groupsTempLimit[type].isFullRecord + groupList.allgroups.total;
        offset = groupsTempLimit['allgroup'].offset + constand.CONSTTHREE;
      }
      params.offset = offset;
      params.limit = isAll ? 0 : constand.CONSTTHREE;
      params.isFullRecord = isFullRecord;
      groupsTempLimit[type].offset = offset;
      groupsTempLimit[type].isFullRecord = isAll ? isFullRecord : 0;
    }
    this.setState({ groupsLimit: groupsTempLimit })
    this.props.showMoreGroups(params, typeFilter).then(function (response) {
      let responseData = response.data;
      console.log("response_data", responseData);
      if (type == 'mygroup') {
        if (responseData.groups.community.length > 0) {
          let temp = { ...me.state.groupList };
          temp.myGroups.community = [...temp.myGroups.community, ...responseData.groups.community];
          me.setState({ groupList: temp })
        }
      } else if (type == 'additional') {
        if (responseData.groups.community.length > 0) {
          let temp = { ...me.state.groupList };
          temp.instructorGroups[groupType].community = [...temp.instructorGroups[groupType].community, ...responseData.groups.community];
          me.setState({ groupList: temp })
        }
      } else if (type == 'allgroup') {
        if (responseData.groups.community.length > 0) {
          let temp = { ...me.state.groupList };
          temp.allgroups.community = [...temp.allgroups.community, ...responseData.groups.community];
          me.setState({ groupList: temp })
        }
      } 
    })
  }
  checkUserIsMember(group) {
    if (this.props.is_auth) {
      if (this.props.logged_userData && this.props.logged_userData.id) {
        let handler = [...group.Members];
        var findUser = handler.findIndex(x => x.UserId === this.props.logged_userData.id);
        console.log('findUser', findUser)
        if (findUser != -1)
          return true;
        return false;
      }
    }
  }
  editGroup(groupName) {
    var data = { group: groupName };
    this.props.clearAboutDetails();
    this.props.fetchAboutGroup(data).then(
      response => {
        if (response) {
          this.props.groupCMSOpen(true);
        }
      },
      error => {
        this.setState({ Loading: false });
      }
    );

  }

  renderMyGroups() {
    return (
      <div className="">
        {this.state.groupList.myGroups && (!this.props.logged_userData.isStudyLeader && !this.props.logged_userData.isGroupLeader && !this.props.logged_userData.isStudyInstructor && !this.props.logged_userData.isStudyUser) &&
          <h3 className="font-book font-24 purplefont p-b-20 row">
            My Groups
          </h3>
        }
        {/* Web view */}
        <div className="">
          <div className="web-view row">
            {(this.state.groupList.myGroups && this.state.groupList.myGroups.community.length > 0) && this.state.groupList.myGroups && this.state.groupList.myGroups.community.map((item, key) => {
              return (
                <div
                  className="col-sm-12 col-md-6 col-lg-4 p-l-0 m-b-20"
                  key={key}
                >
                  <div className="card-design">
                    <div className="blog-photo">
                      <ImageTag
                        className="img-fluid"
                        src={
                          item.banner_img
                            ? constand.GROUP_IMAGE_PATH +
                            item.banner_img
                            : constand.WEB_IMAGES + "orangeblock.png"
                        }
                      />
                    </div>
                    <div className="row p-l-10 p-r-10 p-t-10">
                      <div className="col-6 h-3">
                        <h5 className="float-left font-book font-15 blog_head">
                          <Link
                            to={
                              "/group/" + (this.props.params.condition.toLowerCase().includes('research') ? "feed/" : "about/") +
                              item.community_name +
                              "/" +
                              this.props.params.condition
                            }
                          >
                            {item.community_name}
                          </Link>
                        </h5>
                      </div>
                      <div className="col-6">
                        <React.Fragment>
                          <button
                            onClick={() =>
                              this.gotoGroupDetailPage(item)
                            }
                            className="w-100 btn float-right dblog_btn font-14 button-lightblue position-relative"
                          >
                            Member{" "} {item.type === "closed" && (<i className="fa fa-lock last-lock"></i>)}
                          </button>
                        </React.Fragment>
                        {this.props.is_auth && this.props.is_create_mode &&
                          <div className="col-1 float-right top-30 p-r-40 pointer" onClick={() => { this.editGroup(item.community_name) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} /></div>
                        }
                      </div>
                    </div>

                    <div className="row m-l-10 p-t-10 p-b-10">
                      <img
                        className="img-fluid rounded-circle"
                        src={
                          item.User.profilePic
                            ? constand.PROFILE_IMAGE_PATH +
                            item.User.profilePic
                            : constand.WEB_IMAGES + "no-profile-pic.png"
                        }
                        onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                      />
                      <span className="font-book font-14 m-l-10 m-t-10 light-grey">
                        {item.User
                          ? item.User.name + " " + item.User.lastName
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {((this.state.groupList.myGroups && this.state.groupList.myGroups.community.length > 0) && (this.state.groupList.myGroups.community.length < this.state.groupList.myGroups.total) || this.props.is_creatorP) && (
              <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-t-40 m-b-40">
                <button
                  onClick={() => { this.showMore('mygroup', '') }}
                  className="btn btn-orange m-t-40 m-b-40 font-book"
                >
                  Show All Groups
                </button>
              </div>
            )}
          </div>
          <div className="mobile-view">
            <div class="carousel-wrapper">
              <Carousel showArrows={false} showIndicators={false} showStatus={false} centerMode={true} showThumbs={false} onChange={(e) => { this.CarouselChange(e, '', false) }}>
                {this.state.groupList.myGroups && this.state.groupList.myGroups.community.map((item, key) => {
                  return (
                    <div className="w-100 m-b-10 float-left">
                      <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 float-left"
                          key={key}
                        >
                          <div className="card h-100">
                            <div className="blog-photo">
                              <ImageTag
                                className="img-fluid"
                                src={
                                  item.banner_img
                                    ? constand.GROUP_IMAGE_PATH +
                                    item.banner_img
                                    : constand.WEB_IMAGES + "orangeblock.png"
                                }
                              />
                            </div>
                            <div className="row p-l-10 p-r-10 p-t-10">
                              <div className="col-12 h-3">
                                <h5 className="float-left font-book font-15 blog_head text-left group_name_overflow">
                                  <Link
                                    to={
                                      "/group/" + (this.props.params.condition.toLowerCase().includes('research') ? "feed/" : "about/") +
                                      item.community_name +
                                      "/" +
                                      this.props.params.condition
                                    }
                                  >
                                    {item.community_name}
                                  </Link>
                                </h5>
                              </div>
                            </div>
                            <div className="row m-l-10 p-t-10 p-b-10">
                              <img
                                className="img-fluid rounded-circle p-0"
                                src={
                                  item.User.profilePic
                                    ? constand.PROFILE_IMAGE_PATH +
                                    item.User.profilePic
                                    : constand.WEB_IMAGES + "no-profile-pic.png"
                                }
                                onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                              />
                              <div className="col-9 h-3">
                                <span className="font-book font-14 light-grey text-left group_name_overflow">
                                  {item.User
                                    ? item.User.name + " " + item.User.lastName
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="row m-l-10 p-t-10 p-b-10">
                              <div className="col-10 mx-auto">
                                <React.Fragment>
                                  <button
                                    onClick={() =>
                                      this.gotoGroupDetailPage(item)
                                    }
                                    className="w-100 btn float-right dblog_btn font-14 button-lightblue position-relative"
                                  >
                                    Member{" "} {item.type === "closed" && (<i className="fa fa-lock last-lock"></i>)}
                                  </button>
                                </React.Fragment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {
          (!this.props.logged_userData.isGroupLeader && !this.props.logged_userData.isStudyLeader && !this.props.logged_userData.isStudyInstructor && !this.props.logged_userData.isStudyUser && this.state.groupList.myGroups && !this.state.groupList.myGroups.community.length && !this.state.Loading) &&
          <div className="row"><div
            className="text-center w-100"
          >You are not yet a member of any groups!  Please use the search bar or navigate through the groups below to find one or more that pique your interest!  Note, the lock icon on the Join button indicates that your group membership will be approved by the group owner whereas no lock icon means you become an immediate member!!</div></div>
        }
      </div>
    );
  }
  CarouselChange(e, mapGroups, type = 0) {
    if (type == 1) { //instructor
      if (this.state.groupList.instructorGroups[mapGroups].community.length - 1 == e && this.state.groupList.instructorGroups[mapGroups].community.length < this.state.groupList.instructorGroups[mapGroups].total) {
        this.showMore('additional', mapGroups);
      }
    } else if (type == 2) { //allgroups
      if (this.state.groupList.allgroups && this.state.groupList.allgroups.community.length - 1 == e && this.state.groupList.allgroups.community.length < this.state.groupList.allgroups.total) {
        this.showMore('allgroup', '')
      }
    }
    else { //mygroups
      if (this.state.groupList.myGroups.community.length - 1 == e && this.state.groupList.myGroups.community.length < this.state.groupList.myGroups.total) {
        this.showMore('mygroup', '')
      }
    }
  }
  renderAdditionalGroups() {
    var groupsTempLimit = { ...this.state.groupsLimit };
    return (
      <div className="">

        {this.state.groupList.instructorGroups && Object.keys(this.state.groupList.instructorGroups).map((mapGroups, mapKey) => {
          if (this.state.groupList.instructorGroups[mapGroups].community.length > 0) {
            return (
              <div className="">
                <h3 className="font-book font-24 purplefont p-b-20 row">
                  {mapGroups} Groups</h3>
                <div className="web-view row">

                  {this.state.groupList.instructorGroups[mapGroups] && this.state.groupList.instructorGroups[mapGroups].community.map((item, key) => {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-4 p-l-0 m-b-20"
                        key={key}
                      >
                        <div className="card-design">
                          <div className="blog-photo">
                            <ImageTag
                              className="img-fluid"
                              src={
                                item.banner_img
                                  ? constand.GROUP_IMAGE_PATH +
                                  item.banner_img
                                  : constand.WEB_IMAGES + "orangeblock.png"
                              }
                            />
                          </div>
                          <div className="row p-l-10 p-r-10 p-t-10">
                            <div className="col-6 h-3">
                              <h5 className="float-left font-book font-15 blog_head">
                                <Link
                                  to={
                                    "/group/" + (this.props.params.condition.toLowerCase().includes('Research') ? "feed/" : "about/") +
                                    item.community_name +
                                    "/" +
                                    this.props.params.condition
                                  }
                                >
                                  {item.community_name}
                                </Link>
                              </h5>
                            </div>

                            <div className="col-6">
                              {!this.props.is_auth && (
                                <button disabled={this.state.Loading}
                                  onClick={() => this.submitRequest(item)}
                                  className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                >
                                  Join{" "}
                                  {item.type === "closed" && (
                                    <i className="fa fa-lock last-lock"></i>
                                  )}
                                </button>
                              )}
                              {this.props.is_auth &&
                                (!item.Members ||
                                  (this.returnInGroup(item.id) === false)) && (
                                  <React.Fragment>
                                    {item.Notifications && item.Notifications.length === 0 && (
                                      <button disabled={this.state.Loading}
                                        onClick={() => this.submitRequest(item)}
                                        className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                      >
                                        Join{" "}
                                        {item.type === "closed" && (
                                          <i className="fa fa-lock last-lock"></i>
                                        )}
                                      </button>
                                    )}
                                    {item.Notifications && item.Notifications.length > 0 && (
                                      <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                                        Requested{" "}
                                        {item.type === "closed" && (
                                          <i className="fa fa-lock last-lock"></i>
                                        )}
                                      </button>
                                    )}
                                  </React.Fragment>
                                )}
                              {this.props.is_auth && this.props.is_create_mode &&
                                <div className="col-1 float-right top-30 p-r-40 pointer" onClick={() => { this.editGroup(item.community_name) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} /></div>
                              }
                            </div>

                          </div>

                          <div className="row m-l-10 p-t-10 p-b-10">
                            <img
                              className="img-fluid rounded-circle"
                              src={
                                item.User.profilePic
                                  ? constand.PROFILE_IMAGE_PATH +
                                  item.User.profilePic
                                  : constand.WEB_IMAGES + "no-profile-pic.png"
                              }
                              onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                            />
                            <span className="font-book font-14 m-l-10 m-t-10 light-grey">
                              {item.User
                                ? item.User.name + " " + item.User.lastName
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {this.state.groupList.instructorGroups[mapGroups].community.length < this.state.groupList.instructorGroups[mapGroups].total && (
                    <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-b-40">
                      <button
                        onClick={() => { this.showMore('additional', mapGroups) }}
                        className="btn btn-orange m-t-40 m-b-40 font-book"
                      >
                        Show All Groups
                      </button>
                    </div>
                  )}
                </div>
                <div className="mobile-view">
                  <div class="carousel-wrapper">
                    <Carousel showArrows={false} showIndicators={false} showStatus={false} centerMode={true} showThumbs={false} onChange={(e) => { this.CarouselChange(e, mapGroups, true) }}>
                      {this.state.groupList.instructorGroups[mapGroups] && this.state.groupList.instructorGroups[mapGroups].community.map((item, key) => {
                        return (
                          <div>
                            <div className="w-100 m-b-10 float-left">
                              <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                                <div
                                  className="col-lg-12 col-md-12 col-sm-12 float-left"
                                  key={key}
                                >
                                  <div className="card h-100">
                                    <div className="blog-photo">
                                      <ImageTag
                                        className="img-fluid"
                                        src={
                                          item.banner_img
                                            ? constand.GROUP_IMAGE_PATH +
                                            item.banner_img
                                            : constand.WEB_IMAGES + "orangeblock.png"
                                        }
                                      />
                                    </div>
                                    <div className="row p-l-10 p-r-10 p-t-10">
                                      <div className="col-12 h-3">
                                        <h5 className="float-left font-book font-15 blog_head text-left group_name_overflow">
                                          <Link
                                            to={
                                              "/group/" + (this.props.params.condition.toLowerCase().includes('research') ? "feed/" : "about/") +
                                              item.community_name +
                                              "/" +
                                              this.props.params.condition
                                            }
                                          >
                                            {item.community_name}
                                          </Link>
                                        </h5>
                                      </div>
                                    </div>

                                    <div className="row m-l-10 p-t-10 p-b-10">
                                      <img
                                        className="img-fluid rounded-circle p-0"
                                        src={
                                          item.User.profilePic
                                            ? constand.PROFILE_IMAGE_PATH +
                                            item.User.profilePic
                                            : constand.WEB_IMAGES + "no-profile-pic.png"
                                        }
                                        onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                                      />
                                      <div className=" col-9 h-3">
                                        <span className="font-book font-14 light-grey text-left group_name_overflow">
                                          {item.User
                                            ? item.User.name + " " + item.User.lastName
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row p-t-10 p-b-10">
                                      <div className="col-10 mx-auto">
                                        {!this.props.is_auth && (
                                          <button disabled={this.state.Loading}
                                            onClick={() => this.submitRequest(item)}
                                            className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                          >
                                            Join{" "}
                                            {item.type === "closed" && (
                                              <i className="fa fa-lock last-lock"></i>
                                            )}
                                          </button>
                                        )}
                                        {this.props.is_auth &&
                                          (!item.Members ||
                                            (this.returnInGroup(item.id) === false)) && (
                                            <React.Fragment>
                                              {item.Notifications && item.Notifications.length === 0 && (
                                                <button disabled={this.state.Loading}
                                                  onClick={() => this.submitRequest(item)}
                                                  className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                                >
                                                  Join{" "}
                                                  {item.type === "closed" && (
                                                    <i className="fa fa-lock last-lock"></i>
                                                  )}
                                                </button>
                                              )}
                                              {item.Notifications && item.Notifications.length > 0 && (
                                                <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                                                  Requested{" "}
                                                  {item.type === "closed" && (
                                                    <i className="fa fa-lock last-lock"></i>
                                                  )}
                                                </button>
                                              )}
                                            </React.Fragment>
                                          )}

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>

                </div>

              </div>
            );
          }
        })}
      </div>
    );
  }
  renderCreateGroupTemp() {
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

    return (
      <div className="col-sm-12 col-md-6 col-lg-4 p-l-0 m-b-20">
        <div className="card h-100" >
          <div onClick={((e) => this.handlePosition(e))}>
            <div
              className="position-relative"
            >
              <ImageTag ref={node => { this.wrapperRef = node; }}
                className="card-img-top img-fluid"
                src={
                  constand.WEB_IMAGES + "no-image.png"
                }
              />

            </div>
          </div>
          <div className="card-body p-2">
            <div className="w-100 float-left">
              <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                <h3 className="black-txt font-16 font-semibold capitalize_text float-left w-75">
                  <TextWrap text='New Beam Group' />
                </h3>
                <div className="bookmark-left float-right text-right w-25">
                </div>
                <div className="bookmark-left m-l-10 float-left"></div>
              </div>
            </div>
            <div className="w-100 float-left">
              <div className="col-12 float-left p-0 font-medium">
                <p className="float-left font-14 black-txt font-qregular m-b-0">
                  Click the plus to create a new Group!
                </p>
                {!authData.isStudyLeader &&
                  <p className="float-left font-14 black-txt font-qregular m-b-5 w-100">
                    You will need...
                  </p>
                }
              </div>
              <div className="col-11 float-left p-0">

                <div className="font-medium w-100">
                  <ul className="col-md-12 col-sm-12 float-left black-txt p-l-20">
                    {!authData.isStudyLeader &&
                      <li>
                        <span className="w-40 font-14 font-qregular float-left">
                          Beam Group Setup Form Response
                        </span>
                      </li>
                    }
                  </ul>

                </div>

              </div>
              {this.props.is_auth && this.props.is_create_mode &&
                <div className="col-1 float-left top-10 p-0 pl-2 pointer" onClick={() => { this.props.clearAboutDetails(); this.props.groupCMSOpen(true) }}>
                  <img src={constand.WEB_IMAGES + "add-plus.png"} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderAllGroups() {
    return (
      <div className="">
        {/* <h3 className="font-book font-24 purplefont p-b-20 row">All Groups</h3> */}
        <div className="web-view row">

          {this.state.groupList.allgroups && this.state.groupList.allgroups.community && this.state.groupList.allgroups.community.map((item, key) => {
            return (
              <div
                className="col-sm-12 col-md-6 col-lg-4 p-l-0 m-b-20"
                key={key}
              >
                <div className="card-design">
                  <div className="blog-photo">
                    <ImageTag
                      className="img-fluid"
                      src={
                        item.banner_img
                          ? constand.GROUP_IMAGE_PATH +
                          item.banner_img
                          : constand.WEB_IMAGES + "orangeblock.png"
                      }
                    />
                  </div>
                  <div className="row p-l-10 p-r-10 p-t-10">
                    <div className="col-6 h-3">
                      <h5 className="float-left font-book font-15 blog_head">
                        <Link
                          to={
                            "/group/" + (this.props.params.condition.toLowerCase().includes('research') ? "feed/" : "about/") +
                            item.community_name +
                            "/" +
                            this.props.params.condition
                          }
                        >
                          {item.community_name}
                        </Link>
                      </h5>
                    </div>

                    <div className="col-6">
                      {this.props.is_auth && (
                        <React.Fragment>
                          {!this.checkUserIsMember(item) && item.Notifications && item.Notifications.length === 0 && (
                            <button disabled={this.state.Loading}
                              //onClick={() => this.submitRequest(item)}
                              onClick={() =>
                                this.gotoGroupDetailPage(item)
                              }
                              className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                            >
                              View Group{" "}
                              {item.type === "closed" && (
                                <i className="fa fa-lock last-lock"></i>
                              )}
                            </button>
                          )}
                          {/* {item.Notifications && item.Notifications.length > 0 && (
                            <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                              View{" "}
                              {item.type === "closed" && (
                                <i className="fa fa-lock last-lock"></i>
                              )}
                            </button>
                          )} */}
                          {/* {item.Members && this.checkUserIsMember(item) && (
                            <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                              Member{" "}
                              {item.type === "closed" && (
                                <i className="fa fa-lock last-lock"></i>
                              )}
                            </button>
                          )} */}
                        </React.Fragment>

                      )}

                      {this.props.is_auth && this.props.is_create_mode &&
                        <div className="col-1 float-right top-30 p-r-40 pointer" onClick={() => { this.editGroup(item.community_name) }}><img src={constand.WEB_IMAGES + "edit-pencil.png"} /></div>
                      }
                    </div>

                  </div>

                  <div className="row m-l-10 p-t-10 p-b-10">
                    <img
                      className="img-fluid rounded-circle"
                      src={
                        item.User.profilePic
                          ? constand.PROFILE_IMAGE_PATH +
                          item.User.profilePic
                          : constand.WEB_IMAGES + "no-profile-pic.png"
                      }
                      onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                    />
                    <span className="font-book font-14 m-l-10 m-t-10 light-grey">
                      {item.User
                        ? item.User.name + " " + item.User.lastName
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

          {this.state.groupList.allgroups && this.state.groupList.allgroups.community && this.state.groupList.allgroups.community.length < this.state.groupList.allgroups.total && (
            <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-b-40">
              <button
                onClick={() => { this.showMore('allgroup', '') }}
                className="btn btn-orange m-t-40 m-b-40 font-book"
              >
                Show More Groups
              </button> &nbsp;
              <button
                onClick={() => { this.showMore('allgroup', '', true) }}
                className="btn btn-orange m-t-40 m-b-40 font-book"
              >
                Show All Groups
              </button>
            </div>
          )}
        </div>
        <div className="mobile-view">
          <div class="carousel-wrapper">
            <Carousel showArrows={false} showIndicators={false} showStatus={false} centerMode={true} showThumbs={false} onChange={(e) => { this.CarouselChange(e, '', 2) }}>
              {this.state.groupList.allgroups && this.state.groupList.allgroups.community && this.state.groupList.allgroups.community.map((item, key) => {
                return (
                  <div className="w-100 m-b-10 float-left">
                    <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 float-left"
                        key={key}
                      >
                        <div className="card h-100">
                          <div className="blog-photo">
                            <ImageTag
                              className="img-fluid"
                              src={
                                item.banner_img
                                  ? constand.GROUP_IMAGE_PATH +
                                  item.banner_img
                                  : constand.WEB_IMAGES + "orangeblock.png"
                              }
                            />
                          </div>
                          <div className="row p-l-10 p-r-10 p-t-10">
                            <div className="col-12 h-3">
                              <h5 className="float-left font-book font-15 blog_head text-left group_name_overflow">
                                <Link
                                  to={
                                    "/group/" + (this.props.params.condition.toLowerCase().includes('research') ? "feed/" : "about/") +
                                    item.community_name +
                                    "/" +
                                    this.props.params.condition
                                  }
                                >
                                  {item.community_name}
                                </Link>
                              </h5>
                            </div>
                          </div>
                          <div className="row m-l-10 p-t-10 p-b-10">
                            <img
                              className="img-fluid rounded-circle p-0"
                              src={
                                item.User.profilePic
                                  ? constand.PROFILE_IMAGE_PATH +
                                  item.User.profilePic
                                  : constand.WEB_IMAGES + "no-profile-pic.png"
                              }
                              onError={(e) => commonService.imageError(e, 'no-profile-pic.png')}
                            />
                            <div className="col-9 h-3">
                              <span className="font-book font-14 light-grey text-left group_name_overflow">
                                {item.User
                                  ? item.User.name + " " + item.User.lastName
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="row p-t-10 p-b-10">
                            <div className="col-10 mx-auto">
                              {this.props.is_auth && (
                                <React.Fragment>
                                  {!this.checkUserIsMember(item) && item.Notifications && item.Notifications.length === 0 && (
                                    <button disabled={this.state.Loading}
                                      //onClick={() => this.submitRequest(item)}
                                      onClick={() =>
                                        this.gotoGroupDetailPage(item)
                                      }
                                      className="w-100 btn float-right dblog_btn font-14 btn-purple-inverse position-relative"
                                    >
                                      View Group{" "}
                                      {item.type === "closed" && (
                                        <i className="fa fa-lock last-lock"></i>
                                      )}
                                    </button>
                                  )}
                                  {/* {item.Notifications && item.Notifications.length > 0 && (
                                    <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                                      Requested{" "}
                                      {item.type === "closed" && (
                                        <i className="fa fa-lock last-lock"></i>
                                      )}
                                    </button>
                                  )} */}
                                  {/* {item.Members && this.checkUserIsMember(item) && (
                                    <button className="w-100 btn float-right dblog_btn font-14 btn-purple position-relative">
                                      Member{" "}
                                      {item.type === "closed" && (
                                        <i className="fa fa-lock last-lock"></i>
                                      )}
                                    </button>
                                  )} */}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="">
        <Helmet>
          <title>{constand.GROUPS_TITLE}{this.props.params.condition}{constand.BEAM}</title>
          <meta property="og:title" content={constand.GROUPS_TITLE + this.props.params.condition + constand.BEAM} />
          <meta property="og:description" content={constand.GROUPS_DESC} />
          <meta property="og:image" content={constand.GROUPS_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.GROUPS_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="ondemand_groups">
          <AdminBanner condition={this.props.params.condition} />
          <div className="container-fluid mx-auto w-95 p-0">
            {!this.props.params.condition.toLowerCase().includes('research') &&
              <div className="text-center p-t-50">
                <h3 className="font-book font-24 darkblue-text p-b-20 ">
                  Welcome to Groups on Beam
                </h3>
                <div className="row font-18 content-text font-qregular ">
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Groups are places where you can connect with live class instructors as well as people like you who are doing the same types of activity and who share your goals and interests</p>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Joining a group allows you to ask questions, celebrate successes, receive feedback, seek motivated, encourage others and get additional tips on your sessions.</p>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Groups also allow instructors to get insight from you prior to running classes, so that they can make it bespoke to your needs and suggest the modifications that you require.</p>
                  </div>

                </div>
              </div>
            }
            <div className="filter-section col-md-12 m-t-10 m-b-10">
              <div className="row">
                <div className="col-md-3 col-lg-2 col-sm-12 text-right m-b-10">
                  {/* <button
                    disabled={true}
                    className="btn btn-purple-inverse group-btn d-none font-book font-14"
                  >
                    <i className="fa fa-plus m-r-5 extra-small-txt"></i>
                    Create Group
                  </button> */}
                </div>
                <div className="col-md-7 col-lg-8 col-sm-12 m-b-10">
                  <div className="input-group mx-auto w-75 search-btn">
                    <input
                      type="text"
                      name="search_key"
                      value={this.state.search_data}
                      onKeyUp={this.searchBarEnter.bind(this)}
                      onChange={this.handleChange}
                      className="form-control font-14 font-qregular"
                      placeholder="What group are you looking for?"
                    />
                    <div className="input-group-append font-14">
                      <button
                        disabled={this.state.Loading}
                        className="btn btn-secondary font-14 font-medium"
                        type="button"
                        onClick={this.searchFucntion}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2 col-sm-12 col-lg-2 text-right m-b-10">
                  <a
                    href="#"
                    className="btn btn-purple-inverse filter-btn font-medium small-txt"
                  >
                    Filter
                  </a>
                </div> */}
              </div>
            </div>
            <div className="text-center w-100">
              {(this.state.Loading) && (<AnimateLoaderComponent />)}
            </div>
            {(this.props.is_auth && this.props.is_create_mode) &&
              <div className="web-view row">
                {this.renderCreateGroupTemp()}
              </div>
            }
            {this.props.is_auth && this.renderMyGroups()}
            {(!this.props.is_auth || (this.props.is_auth && !this.props.logged_userData.isStudyLeader && !this.props.logged_userData.isGroupLeader)) && this.renderAdditionalGroups()}
            {this.props.is_auth && (this.props.logged_userData.isStudyLeader || this.props.logged_userData.isGroupLeader) &&
              this.renderAllGroups()}

          </div>
        </div>
        <JoinModalComponent closeCallback={this.reCallGroupList} />

        {this.props.is_group_cms_open && <GroupCMSComponent />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    logged_userData: state.header.logged_userData,
    is_group_cms_open: state.group.is_group_cms_open,
    is_create_mode: state.header.is_create_mode,
    is_group_mode: state.header.is_group_mode,
  };
};

const mapDispatchToProps = {
  ondemandGroupList,
  start_loader,
  loginModelOpen,
  stop_loader,
  joinrequest,
  joingroup,
  joinModelOpen,
  showMoreGroups,
  groupCMSOpen,
  fetchAboutGroup,
  clearAboutDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandGroupComponent);
