import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DashboardHeader from "./dynamicDashboarHeader/dynamicDashboardHeader";
import SessionList from "./sessionList.jsx";
import WeightAndPhysicalActivityPopUp from "./WeightAndPhysicalActivityPopup/index.jsx";
import PhysicalActivityLineChart from "./chart/physicalActivityCHat.jsx";
import WeightLineChart from "./chart/weightChart.jsx";
import WelcomePopup from "./welcomePop.jsx";
import { Cookies } from "react-cookie-consent";

import {
  getDynamicDashboardContent,
  getDynamicDashboardYourSessions,
  saveUserActivity,
  updateDynamicDashboardWeight,
  leaveProgrammeAction,
  completeSession,
} from "../../actions/DashboardActions";
import $ from "jquery";
import { joinToProgram } from "../../actions/ProgrammeActions.js";

// import './dynamicDashboard.scss'
import { convertToQueryString } from "./utlity.js";
import { completeGoal, fetchGoals } from "../../actions/GoalActions.js";
import {
  DASHBOARD_LIST_CNT,
  GOALS_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  SURVEY_PAGE_ROUTE,
} from "../../constant.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DashboardGoalsComponent from "../ProgrammesPage/GoalsPage/DashboardGoalsComponent.jsx";
import { setFormData } from "../../actions/ProgrammeSurveyActions.js";
import { fetchAdmin } from "../../actions/ProgrammeMessagesAction.js";
import { isPlayOndemand } from "../../actions";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent.jsx";
// import SessionContent from "./SessionContent.jsx";
import PlayOndemand from "../Common/PlayOndemand";

const DynamicDashboard = () => {
  let history = useHistory();
  const { condition, programId } = useParams();
  // const [showUpdateGraph, setShowUpdateGraph] = useState(false);
  const dispatch = useDispatch();
  const dashboardContent = useSelector(
    (state) => state.dashboard.dynamicDashboardContent
  );

  const dashboardSession = useSelector(
    (state) => state.dashboard.dynamicDashboardSession
  );
  const program_goal_list = useSelector(
    (state) => state.goals.dashboard_program_goal_list
  );

  const [page, setPage] = useState(1);

  const [sessionData, setSessionData] = useState([]);
  const [sessionDataCount, setSessionDataCount] = useState(null);
  const [displayWelcomePopup, setDisplayWelcomePopup] = useState(true);
  const [adminDetails, setAdminDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMembership, setMembership] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  async function getmessageAdminDetails() {
    try {
      const admin = await dispatch(fetchAdmin());
      setAdminDetails(admin.data);
    } catch (error) {
      toast.error("API Error");
      console.log(error);
    }
  }

  useEffect(() => {
    dispatch(getDynamicDashboardContent({ id: programId }));
    getmessageAdminDetails();
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (sessionData.length !== sessionDataCount) {
          setPage((prev) => prev + 1);
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [page, sessionData]);

  function updateSession(sessionProps) {
    if (sessionProps && Object.keys(sessionProps).length) {
      if (sessionProps.yourSession) {
        if (page === 1) {
          setSessionData([...sessionProps.yourSession]);
        } else {
          setSessionData([...sessionData, ...sessionProps.yourSession]);
        }
      }
      if (sessionProps.yourSessionCount)
        setSessionDataCount(sessionProps.yourSessionCount);
    }
  }
  useEffect(() => {
    updateSession(dashboardSession);
  }, [dashboardSession]);
  async function refreshSession() {
    setPage(1);
    const result = await dispatch(
      getDynamicDashboardYourSessions({
        id: programId,
        queryString: convertToQueryString({ page: 1, limit: DASHBOARD_LIST_CNT }),
      })
    );
    updateSession(result);
  }
  useEffect(() => {
    if (sessionData.length !== sessionDataCount) {
      dispatch(
        getDynamicDashboardYourSessions({
          id: programId,
          queryString: convertToQueryString({ page, limit: DASHBOARD_LIST_CNT }),
        })
      );
    }
  }, [page]);

  const goalsFetch = () => {
    dispatch(
      fetchGoals(
        {
          id: programId,
          queryString: convertToQueryString({
            page: 1,
            limit: DASHBOARD_LIST_CNT,
          }),
        },
        true
      )
    );
  };
  useEffect(() => {
    goalsFetch();
  }, [programId]);
  const updateWeightAndPhysicalActivityCallback = async (type, value) => {
    switch (type) {
      case "physicalActivity":
        let pFormValues = {
          formData: {
            activityType: "General Offline Activity",
            duration: value.value,
            status: "added",
          },
        };
        const response = await dispatch(saveUserActivity(pFormValues));
        toast.success(response.message);
        dispatch(getDynamicDashboardContent({ id: programId }));
        break;
      case "weightUpdate":
        let wFormValues = {
          weight: value.value,
          date: new Date(),
          metric: value.metric,
        };
        const responseupdateD = await dispatch(
          updateDynamicDashboardWeight(wFormValues)
        );
        toast.success(responseupdateD.message);
        dispatch(getDynamicDashboardContent({ id: programId }));
        break;
      default:
        return;
    }
  };

  const leaveProgramme = async () => {
    dispatch(
      leaveProgrammeAction({
        id: programId,
      })
    );
    toast.success("Programme successfully left");

    let condition = Cookies.get("condition");
    history.push(`/programmes/${condition}`);
  };

  const welcomePopUpGetStarted = async () => {
    try {
      setDisplayWelcomePopup(false);
      setLoading(true);
      await dispatch(
        joinToProgram({
          programId: programId,
        })
      );

      await dispatch(getDynamicDashboardContent({ id: programId }));
      await goalsFetch();
      await refreshSession();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const generateToastMessage = (
    physicalActivityHasRecords,
    weightHasRecords
  ) => {
    let message = "Please update your";

    if (!physicalActivityHasRecords && !weightHasRecords) {
      message += " physical activity and weight tracking";
    } else if (!physicalActivityHasRecords) {
      message += " physical activity tracking";
    } else if (!weightHasRecords) {
      message += " weight tracking";
    } else {
      return; // Both have records, no need to show the toast
    }

    message += " before moving to session";
    toast.info(message);
  };
  const sessionStartCb = async (type, session) => {
    if (
      dashboardContent?.chartPlotData?.physicalActivityOffBEAMChartData
        ?.hasRecords &&
      dashboardContent?.chartPlotData?.weightChatData?.hasRecords
    ) {
      let status = "start";
      if (session.status === "started") {
        status = "continue";
      } else if (session.status === "completed") {
        status = "redo";
      }
      await dispatch(
        completeSession({
          programId,
          sessionId: session.id,
          status,
        })
      );
      dispatch(setFormData({}));
      window.scrollTo(0, 0);
      history.push(
        `${SURVEY_PAGE_ROUTE}${condition}/${programId}/${session.id}`
      );
    } else {
      generateToastMessage(
        dashboardContent?.chartPlotData?.physicalActivityOffBEAMChartData
          ?.hasRecords,
        dashboardContent?.chartPlotData?.weightChatData?.hasRecords
      );
      $("#chartcustomModal").modal("show");
      setSelectedSession(session);
      setMembership(false);
    }
  };

  // const handleUpdateGraph = () => {
  //   setShowUpdateGraph(true);
  // }

  const handleGoalViewAll = () => {
    history.push(GOALS_PAGE_ROUTE + condition + "/" + programId);
  };
  const popupOnClose = () => {
    // setShowUpdateGraph(false);
    dispatch(getDynamicDashboardContent({ id: programId }));
  };

  const handleGoalClick = async (goal, status) => {
    try {
      const response = await dispatch(completeGoal(goal.id, status));
      if (!response.success) throw new Error(response.message);
      toast.success(response.message);
      goalsFetch();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const redirectToDynamicProgram = (program) => {
    //alert('Redirecting to');
    setMembership(true);
    dispatch(isPlayOndemand(false));
  };

  const membershipCheck = () => {
    console.log("membership check");
    try {
      dispatch(isPlayOndemand(true));
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <div className="text-center w-100">
        <DashboardHeader></DashboardHeader>
        <AnimateLoaderComponent />
      </div>
    );
  }
  return (
    <React.Fragment>
      {displayWelcomePopup &&
      dashboardContent?.dashBoardContent?.isDynamic &&
      !dashboardContent?.dashBoardContent?.isStarted ? (
        <WelcomePopup
          isDisplay={displayWelcomePopup}
          getStartedCb={welcomePopUpGetStarted}
          content={dashboardContent}
        />
      ) : null}
      <DashboardHeader></DashboardHeader>
      <section id="dashboard-section" className="dashboard-section mt-50 mb-80">
        <div className="container-fluid mx-auto w-90">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8 text-left programe-name">
              <h1 className="mb-2 font-24 color-black font-semibold">
                {dashboardContent?.dashBoardContent?.title}
              </h1>
              <p
                className="font-16 ash-txt fw500 font-qmedium"
                dangerouslySetInnerHTML={{
                  __html: dashboardContent?.dashBoardContent?.description,
                }}
              ></p>
            </div>
            <div className="col-12 col-md-4 col-lg-4 text-center program-progress">
              <div className="progress-percent">
                <h2 className="font-16 black-txt fw500 font-qmedium text-left mb-3">
                  Your programme progress
                </h2>
                <div className="progress">
                  <div
                    className="progress-bar bg-c-red"
                    style={{
                      width: dashboardContent?.progressContent?.percentage || 0,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="tracking-section" className="tracking-section mb-65">
        <div className="container-fluid mx-auto w-90">
          <div className="row equal-height-row">
            <div className="col-12 col-md-8 col-lg-8 text-left programe-name">
              <div className="graph-datas d-flex justify-content-between align-items-center mb-3">
                <div className="tracking-data">
                  <h1 className="font-20 black-txt font-qbold text-left fw600">
                    Tracking
                  </h1>
                  <p className="font-16 ash-txt fw500 font-qmedium mb-0">
                    We advise that you update your physical activity and weight
                    tracking at least once a week.
                  </p>
                </div>
                <div className="update-btn">
                  <a
                    href="javascript:void(0)"
                    className="font-16 font-qbold fw600 bg-lightblue bluebutton1"
                    data-toggle="modal"
                    data-target="#chartcustomModal"
                    onClick={() => selectedSession && setSelectedSession(null)}
                  >
                    Update
                  </a>
                </div>
              </div>
              <div class="graph-datas d-flex justify-content-between align-items-start activity-graphs">
                {dashboardContent?.chartPlotData
                  ?.physicalActivityOffBEAMChartData?.x_axis?.length && (
                  <div class="dashboardContent-chart">
                    <p class="font-16 fw-bolder font-qmedium">
                      Physical Activity
                    </p>
                    <div class="chart-beam">
                      <span class="font-16 ash-txt fw500 font-qmedium mb-0">
                        <span>•</span>on beam{" "}
                      </span>
                      <span class="font-16 ash-txt fw500 font-qmedium mb-0">
                        <span>•</span>outside beam{" "}
                      </span>
                    </div>

                    <PhysicalActivityLineChart
                      offBeamData={
                        dashboardContent?.chartPlotData
                          ?.physicalActivityOffBEAMChartData || {}
                      }
                      onBeamData={
                        dashboardContent?.chartPlotData
                          ?.physicalActivityONBEAMChartData || {}
                      }
                    />
                  </div>
                )}
                {dashboardContent?.chartPlotData?.weightChatData?.x_axis
                  ?.length && (
                  <div class="dashboardContent-chart">
                    <div>
                      <p class="font-16 fw-bolder font-qmedium">Weight</p>
                      <div class="chart-beam">
                        <span class="font-16 ash-txt fw500 font-qmedium mb-0">
                          <span> </span>
                        </span>
                        <span class="font-16 ash-txt fw500 font-qmedium mb-0">
                          <span> </span>
                        </span>
                      </div>
                    </div>
                    <WeightLineChart
                      weightData={
                        dashboardContent?.chartPlotData?.weightChatData || {}
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {/*desktop view goals section*/}
            <div className="col-12 col-md-4 col-lg-4 text-center goals-contents desktopview-display">
              <div className="d-flex justify-content-between align-items-center goals-list">
                <h2 className="font-20 black-txt font-qbold text-left mb-0 fw600">
                  Goals
                </h2>
                <a
                  href="#"
                  className="viewall-link font-16 font-qbold fw600"
                  onClick={handleGoalViewAll}
                >
                  View all
                </a>
              </div>
              <DashboardGoalsComponent
                goals={program_goal_list}
                handleClick={handleGoalClick}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="your-sessions" className="your-sessions mb-65">
        <div className="container-fluid mx-auto w-90">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8 text-left your-sessions-lists mb-3">
              <div className="tracking-data">
                <h1 className="font-20 black-txt font-qbold text-left mb-3 fw600">
                  Your Sessions
                </h1>
              </div>
              <SessionList
                list={sessionData}
                sessionStartCb={sessionStartCb}
                refreshSession={refreshSession}
                membershipCheck={membershipCheck}
                hasMembership={hasMembership}
              />
              {/* <div className="viewall-button mobileview-display">
                <a href="#" className="font-16 font-qbold fw600 bg-lightblue">
                  View all
                </a>
              </div> */}
            </div>
            {/*mobile view goals section*/}
            <div className="col-12 col-md-4 col-lg-4 text-center goals-contents mobileview-display">
              <div className="d-flex justify-content-between align-items-center goals-list">
                <h2 className="font-20 black-txt font-qbold text-left mb-0 fw600">
                  Goals
                </h2>
                <a
                  href="#"
                  className="viewall-link font-16 font-qbold fw600"
                  onClick={handleGoalViewAll}
                >
                  View all
                </a>
              </div>
              <DashboardGoalsComponent
                goals={program_goal_list}
                handleClick={handleGoalClick}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-4 text-left your-sessions-lists kidney-team">
              <div className="tracking-data">
                <h1 className="font-20 black-txt font-qbold text-left mb-3 fw600">
                  Speak to the KidneyBeam Team
                </h1>
              </div>
              <div className="list-datas">
                <div className="card bg-light-subtle">
                  <img
                    src={adminDetails?.avatar?.image_url}
                    className="img-fluid thumb-goal-image m-0"
                    alt="Avatar Image"
                  />
                  <div className="card-body align-items-center p-0">
                    <div className="text-section">
                      <h5 className="card-title mb-1 font-16 fw600 black-txt font-qbold">
                        {adminDetails?.name}
                      </h5>
                      <p className="font-14 fw500 ash-txt font-qmedium mb-0">
                        {adminDetails?.job_title}
                      </p>
                    </div>
                    <div className="cta-section d-flex align-items-center chats-icons">
                      <figure>
                        <img
                          src="/images/dashboard/chats.svg"
                          className="img-fluid"
                          alt="Chat"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            history.push(
                              `${MESSAGES_PAGE_ROUTE}${condition}/${programId}`
                            );
                          }}
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="leave-program">
                <a
                  href="javascript:void(0)"
                  className="session-btn btn btn-outline-dark w-100 font-16 fw600"
                  onClick={leaveProgramme}
                >
                  Leave the program
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {dashboardContent && dashboardContent.popupData ? (
        <WeightAndPhysicalActivityPopUp
          updateCallback={updateWeightAndPhysicalActivityCallback}
          data={dashboardContent.popupData}
          onBeamData={
            dashboardContent?.chartPlotData?.physicalActivityONBEAMChartData
          }
          offBeamData={
            dashboardContent?.chartPlotData?.physicalActivityOffBEAMChartData
          }
          weightChartData={dashboardContent?.chartPlotData?.weightChatData}
          onClose={popupOnClose}
          isContinueNeed={
            selectedSession &&
            dashboardContent?.chartPlotData?.physicalActivityOffBEAMChartData
              ?.hasRecords &&
            dashboardContent?.chartPlotData?.weightChatData?.hasRecords
          }
          continueOnClick={() => {
            setSelectedSession(null);
            $("#chartcustomModal").modal("hide");
            sessionStartCb("continue", selectedSession);
          }}
        />
      ) : null}

      {
        <PlayOndemand
          condition={condition}
          programId={programId}
          //workoutId={workoutId}
          history={history}
          //ondemand_detail_data={this.state.ondemand_detail_data}
          //closeWithProgram={(id) => { this.updateProgramDetails(id) }}
          //isStartProgram={this.state.isStartProgram}
          redirectToDynamicProgram={(programId) => {
            redirectToDynamicProgram(programId);
          }}
          isDynamic={1}
        />
      }
    </React.Fragment>
  );
};

export default DynamicDashboard;
