import React from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { joinrequest, checkUserExist, joinModelOpen } from "../../actions";
import { toast } from 'react-toastify';
import * as constand from "../../constant";
import { commonService } from "../../_services";

class JoinModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            loading: false,
            scheduledFor: '',
            usernameError: '',
            authData: [],
            successFlag: false,
            consent: '',
            username: '',
            note: ''
        };
        this.submitSchedule = this.submitSchedule.bind(this);
        this.submitJoin = this.submitJoin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.backToGroups = this.backToGroups.bind(this);
    }

    componentDidMount() {
        this.setState({ successFlag: false, submitted: false })
        var authData = JSON.parse(localStorage.getItem("userDetails"));
        if (authData) {
            this.setState({ username: authData.nickname, authData: authData })
        }
    }
    componentWillReceiveProps() {
        this.setState({ successFlag: false })
        var authData = JSON.parse(localStorage.getItem("userDetails"));
        if (authData) {
            this.setState({ username: authData.nickname })
        }
    }
    onCloseModal() { }
    closeModel() {

        if (this.state.successFlag) {
            this.props.closeCallback();
        }
        this.setState({
            submitted: false,
            loading: false,
            scheduledFor: '',
            usernameError: '',
            authData: [],
            successFlag: false,
            consent: '',
            username: '',
            note: ''
        });
        this.props.joinModelOpen(false)

    }
    backToGroups() {
        this.closeModel();
        this.props.closeCallback();
    }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }

    handleChange(e) //for twoway binding checkbox
    {
        const { name, value } = e.target;
        this.setState({
            usernameError: '',
            [name]: value
        });
    }
    handleCheckboxChange(e) //for twoway binding checkbox
    {
        console.log('handleCheckboxChange')
        var temp = this.state.consent;
        this.setState({
            consent: !temp
        });
    }
    submitJoin(params) {
        var groupDetails = this.props.group_details;
        this.props.joinrequest(params, this.props.group_details.id).then(
            response => {
                this.setState({ submitted: false, loading: false, successFlag: true });
            },
            error => {
                this.setState({ loading: false, successFlag: false });
            }
        )
    }
    submitSchedule() {
        const { note, consent, username, authData } = this.state;
        this.setState({ submitted: true });
        var params = { note, consent, username };

        if (!note) {
            return;
        }
        if (username) {
            var dataObj = {
                email: username,
                isEmail: 0,
                id: authData.id
            };
            console.log('dataObj', dataObj)
            this.setState({ loading: true })
            this.props.checkUserExist(dataObj).then(
                response => {
                    console.log('response', response)
                    this.submitJoin(params);
                    //this.props.nextStep();
                },
                error => {
                    this.setState({ usernameError: error })
                    this.setState({ loading: false })
                    console.log('response-error', error)

                    // toast.error(error);
                    return;
                }
            );
        } else {
            this.submitJoin(params);
        }
    }

    render() {

        return (
            <React.Fragment>
                <Modal className="removebbg-popup" open={this.props.is_join_modal} onClose={this.onCloseModal} center >
                    <div className="modal-dialog schedule_block common_design join_group modal-width--custom m-t-80" role="document">
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0">
                                <h5
                                    className="modal-title text-center col-md-12 p-0  font-book white-txt"
                                    id="exampleModalLabel"
                                >
                                    Send a request to join {this.props.group_details.community_name}

                                </h5>

                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={this.closeModel}>
                                        &times;
                </span>
                                </button>
                            </div>
                            <div className="modal-body pb-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        {!this.state.successFlag && <div>
                                            <p class="font-semibold mx-auto  font-15 text-center">The {this.props.group_details.community_name} group is a closed group for privacy or confidentiality reasons as decided by the group leader.</p>
                                            <p class="font-qregular mx-auto ">
                                                <span className="font-semibold font-15">Send a note to the group leader</span> telling them why you’d like to join this group.  Please include your full name (or the person you care for) if they are likely to recognise you:</p>
                                            <div className="form-group">
                                                <textarea className="form-control input-control"
                                                    onChange={this.handleChange}
                                                    name="note"
                                                    value={this.state.note}>
                                                </textarea>
                                            </div>
                                            {this.state.submitted && (!this.state.note) && <p className="text-danger font-semibold text-center">A note is required</p>}

                                            <p class="font-semibold mx-auto font-15 m-b-0">Your nickname (optional)</p>
                                            <p className="font-qregular">
                                                If you would prefer to stay anonymous to the other members in the group please add or confirm your account nickname below:</p>
                                            <div className="form-group">
                                                <input type="text" className="form-control input-control" name="username" onChange={this.handleChange} value={this.state.username} />
                                            </div>
                                            {this.state.submitted && (this.state.usernameError) && <p className="text-danger font-semibold text-center">{this.state.usernameError}</p>}
                                            <p class="font-qregular mx-autofont-15">We will share your full name with the organisaton/person who runs this group for authorisation purposes but additionally, if you would like us to, we can share with them your activity data collected on Beam, which classes you attended, when and your feedback so they can better support you.  </p>
                                            <div className="toggle-btns float-left w-100">
                                                <span className="font-14 font-semibold black-txt">I consent to sharing my activity data with the organisation/person who runs this group (optional) </span>
                                                <label className="switch m-l-10 m-t-10 pull-right" htmlFor="consent">
                                                    <input type="checkbox" name="consent" id="consent" onChange={this.handleCheckboxChange} value={this.state.consent} defaultChecked={this.state.consent} />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>

                                            <div className="">
                                                <button disabled={this.state.loading} className="btn btn-login  popup-btn font-medium font-14 w-75 mx-auto m-t-20 m-b-20" onClick={this.submitSchedule}> Send request
</button>
                                            </div>
                                        </div>
                                        }

                                        {this.state.successFlag &&
                                            <div>
                                                <p class="font-semibold mx-auto  font-15 text-center">Thank you!  Your request has been sent and you should hear back from the group leader us shortly…</p>
                                                <div className="">
                                                    <button className="btn btn-login  popup-btn font-medium font-14 w-75 mx-auto m-t-20 m-b-20" onClick={this.backToGroups}> Back to groups
</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_join_modal: state.group.is_join_modal,
        group_details: state.group.group_details
    };
};

const mapDispatchToProps = {
    joinrequest, checkUserExist, joinModelOpen
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinModalComponent);
