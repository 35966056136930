import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
    fetchAboutGroup, fetchGroupWorkout, leaveGroup,
    start_loader,
    stop_loader,
    joinGroup,
    loginModelOpen,
    fetchGroupOndemand,
    clearGroupOndemandList,
    joinModelOpen,
    isAdmin,
    isLeader,
    ondemandCMSModelOpen
} from "../../actions";
import { Link } from "react-router-dom";
import AboutComponent from "./AboutComponent";
import MemberComponent from "./MembersComponent";
import JoinModalComponent from "./JoinModalComponent";
import FeedComponent from "./FeedComponent";
import GroupWorkoutSchedule from "./GroupWorkoutSchedule";
import GroupOndemandComponent from "./GroupOndemandComponent";
import * as constand from "../../constant";
import * as Error from "../../Errors";
import Modal from "react-responsive-modal";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";
import _ from 'lodash';
import { commonService } from "../../_services";
import moment from 'moment';

class GroupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: this.props.match.params.group,
            condition: this.props.match.params.condition,
            aboutGroup: {},
            groupLeader: {},
            totalMembers: 0,
            isMember: false,
            hideWorkoutSchedule: false,
            notifications: [],
            is_model_open: false,
            displayMessage: '',
            share_enable: false,
            aboutSection: '',
            wallSection: '',
            liveClassSection: '',
            onDemandSection: '',
            membersSection: '',
            feedSection: '',
            canEdit: false,
        };
        this.goback = this.goback.bind(this);
        this.joingroup = this.joingroup.bind(this);
        this.reCallGroup = this.reCallGroup.bind(this);
        this.setAdminLeader = this.setAdminLeader.bind(this);
        this.showMobileListName = this.showMobileListName.bind(this);
    }
    componentWillMount() {
        console.log('willid here')
        this.fetchAboutGroup();
    }
    componentDidMount() {
        console.log('Didid here')
        this.props.joinModelOpen(false)
        this.fetchAboutGroup();
        this.fetchGroupWorkout();
        this.fetchGroupOndemand();
        console.log("Myprops", this.state)
    }
    /**
     * To check leaving user id is owner or member
     */
    setAdminLeader() {
        console.log('setAdminLeader')
        if (this.props.is_auth) {
            let authData = JSON.parse(localStorage.getItem('userDetails'));
            if (authData && authData.id) {
                console.log('this.props.user_data', authData)
                let handler = [...this.state.aboutGroup.Members];
                var findUser = _.find(handler, { 'UserId': authData.id });
                var isAdminUser = findUser && findUser.isAdmin ? true : false;
                var isLeader = this.state.aboutGroup.OwnerId === authData.id ? true : false;
                this.props.isAdmin(isAdminUser);
                this.props.isLeader(isLeader);
            }
        }
    }
    reCallGroup() {
        this.fetchAboutGroup();
    }
    goback() {
        window.location.href = '/groups/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.state.condition), false))
        //window.history.back();
    }
    /**
     * fetch details of Group
     */
    fetchAboutGroup() {
        this.props.start_loader();
        var data = { group: this.state.group };
        this.props.fetchAboutGroup(data).then(
            response => {
                if (response) {
                    this.setState({
                        aboutGroup: response.groups,
                        groupLeader: response.groups.User,
                        notifications: response.groups.Notifications,
                        totalMembers:
                            response.groups && response.groups.Members
                                ? response.groups.Members.length
                                : 0,
                        isMember: response.isMember,
                        aboutSection: response.groups.aboutSection,
                        wallSection: response.groups.wallSection,
                        liveClassSection: response.groups.liveClassSection,
                        onDemandSection: response.groups.onDemandSection,
                        membersSection: response.groups.membersSection,
                        feedSection: response.groups.feedSection,
                        canEdit: response.canEdit,
                    });
                    this.setAdminLeader();
                }
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
                this.props.stop_loader();
            }
        );
    }
    /**
     * fetch class list data
     */
    fetchGroupWorkout() {
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": constand.CONSTZERO,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition,
            "group": this.state.group
        };
        this.props.fetchGroupWorkout(dataObj).then(
            response => {
                if (response && response.list && response.list > constand.CONSTZERO) {
                    this.setState({
                        hideWorkoutSchedule: false
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false,
                    hideWorkoutSchedule: true
                });
            }
        );
    }
    /**
     * fetch class list data
     */
    fetchGroupOndemand() {
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": constand.CONSTZERO,
            "limit": constand.CONSTSIX,
            "condition": condition,
            "group": this.state.group
        };
        this.props.fetchGroupOndemand(dataObj).then(
            response => {
                if (response && response.list && response.list.length > constand.CONSTZERO) {
                    this.setState({
                        hideOndemand: false
                    });
                    this.props.clearGroupOndemandList()
                } else {
                    this.setState({
                        hideOndemand: true
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false,
                    hideOndemand: true
                });
            }
        );
    }

    joingroup() {
        this.props.start_loader();
        var data = {
            groupId: this.state.aboutGroup.id,
            groupType: this.state.aboutGroup.type
        };
        this.props.joinGroup(data).then(
            response => {
                if (response && response.status === true) {
                    this.setState({
                        notifications: [{ 'ter': 'test' }, { 'new': 'new' }],
                        isMember: this.state.aboutGroup.type === "open" ? true : false
                    });
                }
                this.fetchAboutGroup();
                toast.success(response.message);
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
                this.props.stop_loader();
            }
        );
    }

    /**
     * join in group
     */
    joinGroupMember() {
        if (this.props.is_auth) {
            if (this.state.aboutGroup.type == 'open')
                this.joingroup();
            else
                this.props.joinModelOpen(true, this.state.aboutGroup)
        } else {
            this.props.loginModelOpen(true);
        }
    }
    /**
     * To check leaving user id is owner or member
     */
    checkUserIsMember() {
        if (this.props.is_auth) {
            if (this.props.user_data && this.props.user_data.id) {
                let handler = [...this.state.aboutGroup.Members];
                var findUser = _.find(handler, { 'UserId': this.props.user_data.id });
                var isAdminUser = findUser && findUser.isAdmin ? findUser.isAdmin : false;
                var isLeader = this.state.aboutGroup.OwnerId === this.props.user_data.id ? true : false;
                this.props.isAdmin(isAdminUser);
                this.props.isLeader(isLeader);
                if (this.state.aboutGroup.OwnerId === this.props.user_data.id || isAdminUser) {
                    this.showMessagePopup(true, Error.leaving_msg);
                } else {
                    this.showMessagePopup(false, 'Are you sure want to leave this group?');
                }
            }
        } else {
            this.props.loginModelOpen(true);
        }
    }
    checkIsAdmin() {
        console.log('checkIsAdmin')
        if (this.props.is_auth) {
            if (this.props.user_data && this.props.user_data.id) {
                let handler = [...this.state.aboutGroup.Members];
                var findUser = _.find(handler, { 'UserId': this.props.user_data.id });
                var isAdminUser = findUser && findUser.isAdmin ? findUser.isAdmin : false;
                if (this.state.aboutGroup.OwnerId === this.props.user_data.id || isAdminUser) {
                    console.log('sert greefsd')
                    this.props.setGroupCreateMode(true);
                }
            }
        }
    }
    /**
     * To leave from group
     */
    leaveFromGroup() {
        var data = {
            comId: this.state.aboutGroup.id,
        };
        this.props.leaveGroup(data).then(
            response => {
                if (response && response.success) {
                    this.setState({
                        isMember: false,
                        notifications: []
                    });
                    this.closeModel();
                }
                this.fetchAboutGroup();
                toast.success(response.message);
                const { from } = { from: { pathname: "/group/about/" + this.state.group + "/" + this.state.condition } };
                this.props.history.push(from);
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
            }
        );
    }
    /**
     * To show popup
     */
    showMessagePopup(isOwner, msg) {
        this.setState({
            is_model_open: true,
            displayMessage: msg,
            isOwnerPopup: isOwner
        })
    }
    closeModel() //for close the login model
    {
        this.setState({
            is_model_open: false,
            isOwnerPopup: false
        })
    }
    onCloseModal() { }
    confirmPopup() {
        this.closeModel();
    }
    /**
     * call leave group
     */
    confirmLeaveGroup() {
        this.leaveFromGroup()
    }
    /**
     * render Modal popup for  message display
     */
    renderModalPopup() {
        if (this.state.is_model_open) {
            return (
                <Modal className="removebbg-popup" open={this.state.is_model_open} onClose={this.onCloseModal} center >
                    <div className="modal-dialog schedule_block modal-width--custom" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title" id="exampleModalLiveLabel">Message</div>
                                <button type="button" className="close" onClick={() => { this.closeModel() }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{this.state.displayMessage} </p>
                            </div>
                            {this.state.isOwnerPopup && (
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-login " onClick={() => { this.confirmPopup() }}> Ok </button>
                                </div>
                            )}
                            {!this.state.isOwnerPopup && (
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-login " onClick={() => { this.confirmLeaveGroup() }}> Continue </button>
                                    <button type="button" onClick={() => { this.closeModel() }} className="btn btn-login ">Cancel</button>
                                </div>
                            )}
                        </div>

                    </div>
                </Modal>
            )
        }
    }
    renderMetaTags() {
        var type = window.location.href.indexOf('about') != -1 ? 'about' : window.location.href.indexOf('feed') != -1 ? 'feed' : window.location.href.indexOf('liveclass') != -1 ? 'liveclass' : window.location.href.indexOf('members') != -1 ? 'members' : 'about';

        switch (type) {
            case 'about':
                return (
                    <Helmet>
                        <title>{this.state.aboutGroup.community_name}{constand.GROUP_DETAIL_ABOUT_TITLE}{constand.BEAM}</title>
                        <meta property="og:title" content={this.state.aboutGroup.community_name + constand.GROUP_DETAIL_ABOUT_TITLE + constand.BEAM} />
                        <meta property="og:description" content={this.state.aboutGroup.long_desc} />
                        <meta property="og:image" content={constand.GROUP_DETAIL_ABOUT_PAGE_IMAGE} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="og:site_name" content="Beam" />
                        <meta name="twitter:image:alt" content={this.state.groupLeader.name + ' ' + this.state.groupLeader.lastName + '\'s ' + this.state.aboutGroup.community_name + ' group'} />
                    </Helmet>
                );
            case 'liveclass':
                return (
                    <Helmet>
                        <title>{this.state.aboutGroup.community_name}{constand.GROUP_DETAIL_LIVE_TITLE}{constand.BEAM}</title>
                        <meta property="og:title" content={this.state.aboutGroup.community_name + constand.GROUP_DETAIL_LIVE_TITLE + constand.BEAM} />
                        <meta property="og:description" content={constand.GROUP_DETAIL_LIVE_DESC} />
                        <meta property="og:image" content={constand.GROUP_DETAIL_LIVE_PAGE_IMAGE} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="og:site_name" content="Beam" />
                        <meta name="twitter:image:alt" content={this.state.groupLeader.name + ' ' + this.state.groupLeader.lastName + '\'s ' + this.state.aboutGroup.community_name + ' group'} />
                    </Helmet>
                );
            case 'feed':
                return (
                    <Helmet>
                        <title>{this.state.aboutGroup.community_name}{constand.GROUP_DETAIL_FEED_TITLE}{constand.BEAM}</title>
                        <meta property="og:title" content={this.state.aboutGroup.community_name + constand.GROUP_DETAIL_FEED_TITLE + constand.BEAM} />
                        <meta property="og:description" content={constand.GROUP_DETAIL_FEED_DESC} />
                        <meta property="og:image" content={constand.GROUP_DETAIL_FEED_PAGE_IMAGE} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="og:site_name" content="Beam" />
                        <meta name="twitter:image:alt" content={this.state.groupLeader.name + ' ' + this.state.groupLeader.lastName + '\'s ' + this.state.aboutGroup.community_name + ' group'} />
                    </Helmet>
                );
            case 'members':
                return (
                    <Helmet>
                        <title>{this.state.aboutGroup.community_name}{constand.GROUP_DETAIL_MEMBER_TITLE}{constand.BEAM}</title>
                        <meta property="og:title" content={this.state.aboutGroup.community_name + constand.GROUP_DETAIL_MEMBER_TITLE + constand.BEAM} />
                        <meta property="og:description" content={constand.GROUP_DETAIL_MEMBER_DESC} />
                        <meta property="og:image" content={constand.GROUP_DETAIL_MEMBER_PAGE_IMAGE} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="og:site_name" content="Beam" />
                        <meta name="twitter:image:alt" content={this.state.groupLeader.name + ' ' + this.state.groupLeader.lastName + '\'s ' + this.state.aboutGroup.community_name + ' group'} />
                    </Helmet>
                );
            default:
                return (
                    <Helmet>
                        <title>{this.state.aboutGroup.community_name}{constand.GROUP_DETAIL_ABOUT_TITLE}{constand.BEAM}</title>
                        <meta property="og:title" content={this.state.aboutGroup.community_name + constand.GROUP_DETAIL_ABOUT_TITLE + constand.BEAM} />
                        <meta property="og:description" content={this.state.aboutGroup.long_desc} />
                        <meta property="og:image" content={constand.GROUP_DETAIL_ABOUT_PAGE_IMAGE} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="og:site_name" content="Beam" />
                        <meta name="twitter:image:alt" content={this.state.groupLeader.name + ' ' + this.state.groupLeader.lastName + '\'s ' + this.state.aboutGroup.community_name + ' group'} />
                    </Helmet>
                );
        }

    }
    showMobileListName() {
        var label;
        switch (this.props.match.path) {
            case "/group/about/:group/:condition":
                label = 'About';
                break;
            case "/group/wall/:group/:condition":
                label = 'Wall';
                break;
            case "/group/feed/:group/:condition":
                label = 'Feed';
                break;
            case "/group/liveclasses/list/:group/:condition":
                label = 'Live Class Schedule';
                break;
            case "/group/ondemand/list/:group/:condition":
                label = 'On Demand Videos';
                break;
            case "/group/members/:group/:condition":
                label = 'Members';
                break;
            default:
                label = 'Select';
                break;
        }
        return label;
    }
    render() {
        const shareUrl = window.location.href;
        const title = this.state.aboutGroup.community_name;
        var conditionName = commonService.replaceChar(Cookies.get('condition'), true);
        var banner_img = conditionName.substring(
            conditionName.lastIndexOf(" ") + 1
        ).toLowerCase();
        banner_img = banner_img.charAt(0).toUpperCase() + banner_img.slice(1) + '_Group_Banner.png';
        console.log('banner_img', banner_img)
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        return (
            <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'groupdetails new-edited-page ' : 'groupdetails new-edited-page m-t-70'} >
                {title && this.renderMetaTags()}
                <div className="group_head col-md-12">
                    <div className="  container-fluid mx-auto w-95 p-0">
                        <div className="row ">
                            <div className="col-md-6 col-8 m-t-30">
                                {!authData.isStudyUser &&
                                    <Link className="font-15 font-book  font-normal black-txt p-b-20 clearfix pull-left w-100" onClick={this.goback}>
                                        <i className="fa fa-chevron-left"></i> Back to all groups
                </Link>
                                }
                                <h3 className="large-text font-semibold black-txt">
                                    {this.state.aboutGroup.community_name}
                                </h3>
                                <p>
                                    <span className="medium-text font-semibold black-txt m-r-10">
                                        Group Leader:
                  </span>
                                    <span>
                                        <img
                                            className="img-fluid rounded-circle"
                                            src={this.state.groupLeader && this.state.groupLeader.profilePic ? constand.PROFILE_IMAGE_PATH + this.state.groupLeader.profilePic : constand.WEB_IMAGES + 'no-profile-pic.png'}
                                            alt=""
                                            width="50"
                                            height="50"

                                        />
                                        <span className="font-book m-l-5">
                                            {this.state.groupLeader.name}{" "}
                                            {this.state.groupLeader.lastName}
                                        </span>
                                    </span>
                                </p>
                                {this.state.aboutGroup.endDate && this.state.aboutGroup.endDate!='0000-00-00' && (this.props.user_data.isStudyLeader || this.props.user_data.isGroupLeader) &&
                                    <p>
                                        <span className="medium-text font-semibold black-txt m-r-10">
                                            Intervention End Date:
                  </span>
                                        <span>
                                            <span className="font-book m-l-5">
                                                {moment(this.state.aboutGroup.endDate).utc().format('Do MMMM YYYY')}
                                            </span>
                                        </span>
                                    </p>
                                }
                            </div>
                            <div className="col-md-6 col-4 m-t-20 p-r-20">

                                <span className="position-relative pointer group-share pull-right text-right">
                                    {!authData.isStudyLeader && !authData.isGroupLeader && !authData.isStudyInstructor && !authData.isStudyUser &&
                                        <span
                                            className=""
                                            onClick={() =>
                                                this.setState({
                                                    share_enable: !this.state.share_enable
                                                })
                                            }
                                        >
                                            <span className="font-qmedium font-13 black-txt m-r-10">
                                                Share
                        </span>
                                            <em className="fa fa-share pointer"></em>

                                        </span>
                                    }
                                    {this.state.share_enable && (
                                        <div className="position-absolute zindex share-value">
                                            <span>
                                                <EmailShareButton
                                                    url={shareUrl}
                                                    subject={title}
                                                    body="body"
                                                    className="pointer"
                                                >
                                                    <EmailIcon size={32} round />
                                                </EmailShareButton>
                                            </span>
                                            <span>
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                    className="pointer"
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                            </span>
                                            <span>
                                                <WhatsappShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    separator=":: "
                                                    className="pointer"
                                                >
                                                    <WhatsappIcon size={32} round />
                                                </WhatsappShareButton>
                                            </span>
                                            <span>
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    className="pointer"
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>
                                            </span>
                                            <span>
                                                <LinkedinShareButton
                                                    url={shareUrl}
                                                    windowWidth={750}
                                                    windowHeight={600}
                                                    className="pointer"
                                                >
                                                    <LinkedinIcon size={32} round />
                                                </LinkedinShareButton>
                                            </span>
                                        </div>
                                    )}
                                </span>
                                {!this.state.aboutGroup.inviteOnly &&
                                    <a
                                        className={(!this.state.isMember && this.state.notifications && this.state.notifications.length > constand.CONSTZERO) ? "btn btn-yellow font-medium m-t-20 m-b-20 pull-right position-relative member-btn " : "btn btn-yellow font-medium m-t-20 m-b-20 pull-right position-relative member-btn pointer"}
                                        onClick={() => {
                                            if (!this.state.isMember && (!this.state.notifications || this.state.notifications.length === constand.CONSTZERO)) {
                                                this.joinGroupMember();
                                            } else if (this.state.isMember) {
                                                this.checkUserIsMember();
                                            }
                                        }}
                                    >
                                        {this.state.isMember ? " Leave " : !this.state.isMember && this.state.notifications && this.state.notifications.length > constand.CONSTZERO ? " Requested " : "Join"}
                                        {this.state.aboutGroup.type === 'open' ? (
                                            ""
                                        ) : (
                                            <i className="fa fa-lock last-lock"></i>
                                        )}
                                    </a>
                                }
                            </div>



                        </div>
                    </div>
                </div>
                <div className="banner_group p-t-20 p-b-60">
                    <img src={this.state.condition.toLowerCase().indexOf('research') != -1 ? constand.WEB_IMAGES + banner_img : constand.WEB_IMAGES + 'group_banner.png'} className="w-100" />
                </div>
                {/* mobile view */}
                <div className="container-fluid mx-auto w-75 dash-drop">
                    <div className="row">
                        <div className="dropdown  tab-sm-group tab-btn d-block d-sm-block d-md-none d-lg-none">
                            <button
                                type="button"
                                className="btn btn-default font-medium font-14 tab-select dropdown-toggle"
                                data-toggle="dropdown"
                            >
                                {this.showMobileListName()}<span className="slt"></span>
                            </button>
                            <div className="dropdown-menu text-center">
                                {(this.props.user_data.isStudyLeader || this.props.user_data.isCreator || this.state.groupLeader.id === this.props.user_data.id || this.state.isMember || !this.state.isMember || this.state.canEdit) && (constand.BEAM_CREATOR.includes(this.state.aboutSection) || constand.ADMIN.includes(this.state.aboutSection) || constand.OWNER.includes(this.state.aboutSection) || constand.GROUP_MEMBER.includes(this.state.aboutSection) || constand.USER.includes(this.state.aboutSection)) &&
                                    <Link
                                        className={"dropdown-item " +
                                            ((this.props.match.path ===
                                                "/group/about/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={
                                            "/group/about/" +
                                            this.state.group +
                                            "/" +
                                            this.state.condition
                                        }
                                    >
                                        About
                </Link>
                                }
                                {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.wallSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.wallSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.wallSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.wallSection)) || constand.USER.includes(this.state.wallSection)) &&
                                    <Link
                                        className={"dropdown-item " +
                                            ((this.props.match.path === "/group/wall/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={"/group/wall/" + this.state.group + "/" + this.state.condition}
                                    >
                                        Wall
                </Link>}
                                {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.feedSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.feedSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.feedSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.feedSection)) || constand.USER.includes(this.state.feedSection)) &&
                                    <Link
                                        className={"dropdown-item " +
                                            ((this.props.match.path === "/group/feed/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={"/group/feed/" + this.state.group + "/" + this.state.condition}
                                    >
                                        Feed
                </Link>}
                                {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.liveClassSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.liveClassSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.liveClassSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.liveClassSection)) || constand.USER.includes(this.state.liveClassSection)) && (!this.state.hideWorkoutSchedule || this.props.logged_userData.isGroupLeader || this.props.logged_userData.isStudyLeader || this.props.user_data.isCreator || (this.state.groupLeader.id === this.props.user_data.id)) &&
                                    <Link
                                        className={"dropdown-item " + (
                                            (this.props.match.path ===
                                                "/group/liveclasses/list/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={
                                            "/group/liveclasses/list/" +
                                            this.state.group +
                                            "/" +
                                            this.state.condition
                                        }
                                    >
                                        Live Class Schedule
                  </Link>
                                }
                                {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.onDemandSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.onDemandSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.onDemandSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.onDemandSection)) || constand.USER.includes(this.state.onDemandSection) || this.props.logged_userData.isGroupLeader) && (!this.state.hideOndemand || this.props.user_data.isGroupLeader || this.props.logged_userData.isStudyLeader || this.props.user_data.isCreator || (this.state.groupLeader.id === this.props.user_data.id)) &&
                                    <Link
                                        className={"dropdown-item " + (
                                            (this.props.match.path ===
                                                "/group/ondemand/list/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={
                                            "/group/ondemand/list/" +
                                            this.state.group +
                                            "/" +
                                            this.state.condition
                                        }
                                    >
                                        On Demand Videos
                  </Link>
                                }
                                {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.membersSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.membersSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.membersSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.membersSection)) || constand.USER.includes(this.state.membersSection)) &&
                                    <Link
                                        className={"dropdown-item " + (
                                            (this.props.match.path === "/group/members/:group/:condition"
                                                ? "active"
                                                : "") + " nav-link font-semibold pointer")
                                        }
                                        to={"/group/members/" + this.state.group + "/" + this.state.condition}
                                    >
                                        Members ({this.state.totalMembers})
                </Link>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* web view */}
                <div className="tab_group">
                    <div className="container">
                        <div className="row">
                            <div className="mx-auto tab_full d-none d-sm-none d-md-block d-lg-block">
                                <ul
                                    className="nav nav-tabs small justify-content-left mx-auto  tab_ul"
                                    role="tablist"
                                >
                                    {(this.props.user_data.isStudyLeader || this.props.user_data.isCreator || this.state.groupLeader.id === this.props.user_data.id || this.state.isMember || !this.state.isMember || this.state.canEdit) && (constand.BEAM_CREATOR.includes(this.state.aboutSection) || constand.ADMIN.includes(this.state.aboutSection) || constand.OWNER.includes(this.state.aboutSection) || constand.GROUP_MEMBER.includes(this.state.aboutSection) || constand.USER.includes(this.state.aboutSection)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path ===
                                                        "/group/about/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={
                                                    "/group/about/" +
                                                    this.state.group +
                                                    "/" +
                                                    this.state.condition
                                                }
                                            >
                                                About
                    </Link>
                                        </li>
                                    }
                                    {(this.props.is_auth) && (((this.props.user_data.isStudyLeader || this.props.user_data.isCreator) && constand.BEAM_CREATOR.includes(this.state.wallSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.wallSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.wallSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.wallSection)) || constand.USER.includes(this.state.wallSection)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path === "/group/wall/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={"/group/wall/" + this.state.group + "/" + this.state.condition}
                                            >
                                                Wall
                    </Link>
                                        </li>
                                    }
                                    {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.feedSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.feedSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.feedSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.feedSection)) || constand.USER.includes(this.state.feedSection)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path === "/group/feed/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={"/group/feed/" + this.state.group + "/" + this.state.condition}
                                            >
                                                Feed
                    </Link>
                                        </li>
                                    }
                                    {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.liveClassSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.liveClassSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.liveClassSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.liveClassSection)) || constand.USER.includes(this.state.liveClassSection)) && (!this.state.hideWorkoutSchedule || this.props.logged_userData.isGroupLeader || this.props.logged_userData.isStudyLeader || this.props.user_data.isCreator || (this.state.groupLeader.id === this.props.user_data.id)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path ===
                                                        "/group/liveclasses/list/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={
                                                    "/group/liveclasses/list/" +
                                                    this.state.group +
                                                    "/" +
                                                    this.state.condition
                                                }
                                            >
                                                Live Class Schedule
                    </Link>
                                        </li>
                                    }
                                    {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.onDemandSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.onDemandSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.onDemandSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.onDemandSection)) || constand.USER.includes(this.state.onDemandSection)) && (!this.state.hideOndemand || this.props.logged_userData.isGroupLeader || this.props.logged_userData.isStudyLeader || this.props.user_data.isCreator || (this.state.groupLeader.id === this.props.user_data.id)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path ===
                                                        "/group/ondemand/list/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={
                                                    "/group/ondemand/list/" +
                                                    this.state.group +
                                                    "/" +
                                                    this.state.condition
                                                }
                                            >
                                                On Demand Videos
                  </Link>
                                        </li>
                                    }
                                    {(this.props.is_auth) && (((this.props.user_data.isCreator || this.props.user_data.isStudyLeader) && constand.BEAM_CREATOR.includes(this.state.membersSection)) || (this.state.groupLeader.id === this.props.user_data.id && constand.OWNER.includes(this.state.membersSection)) || (this.state.canEdit && constand.ADMIN.includes(this.state.membersSection)) || (this.state.isMember && constand.GROUP_MEMBER.includes(this.state.membersSection)) || constand.USER.includes(this.state.membersSection)) &&
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    (this.props.match.path === "/group/members/:group/:condition"
                                                        ? "active"
                                                        : "") + " nav-link font-semibold pointer"
                                                }
                                                to={"/group/members/" + this.state.group + "/" + this.state.condition}
                                            >
                                                Members ({this.state.totalMembers})
                    </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="on-liveclasses w-100 m-t-20 m-b-20">
                        {this.props.match.path === "/group/about/:group/:condition" &&
                            <AboutComponent params={this.props.match.params} />
                        }
                        {this.props.match.path === "/group/wall/:group/:condition" && this.state.wallSection && (
                            <FeedComponent params={this.props.match.params} history={this.props.history} groupState={this.state} componentType="wall" />
                        )}
                        {this.props.match.path === "/group/feed/:group/:condition" && this.state.feedSection && (
                            <FeedComponent params={this.props.match.params} history={this.props.history} groupState={this.state} componentType="feed" />
                        )}
                        {this.props.match.path ===
                            "/group/liveclasses/list/:group/:condition" && this.state.liveClassSection && (
                                <GroupWorkoutSchedule params={this.props.match.params} history={this.props.history} groupState={this.state} location={this.props.location} />
                            )}
                        {this.props.match.path ===
                            "/group/ondemand/list/:group/:condition" && this.state.onDemandSection && (
                                <GroupOndemandComponent params={this.props.match.params} history={this.props.history} groupState={this.state} ondemandCMSModelOpen={this.props.ondemandCMSModelOpen} />
                            )}
                        {this.props.match.path === "/group/members/:group/:condition" && this.state.membersSection && (
                            <MemberComponent params={this.props.match.params} history={this.props.history} groupState={this.state} />
                        )}
                    </div>

                </div>
                {this.renderModalPopup()}
                <JoinModalComponent closeCallback={this.reCallGroup} />

            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        user_data: state.header.logged_userData,
        logged_userData: state.header.logged_userData,
        close_banner: state.workout.close_banner,
        close_banner_kd: state.workout.close_banner_kd,
    };
};

const mapDispatchToProps = {
    fetchAboutGroup, fetchGroupWorkout, leaveGroup,
    start_loader,
    stop_loader,
    joinGroup,
    loginModelOpen,
    fetchGroupOndemand,
    clearGroupOndemandList,
    joinModelOpen,
    ondemandCMSModelOpen,
    isAdmin,
    isLeader
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupComponent);
