import React from "react";

const Header = ({
  days
}) => {
    return (
        <React.Fragment>
            <h5 className="black-txt font-qbold font-20 fw600 mb-1 mobile-font18 lineheight-30">
                Physical activity and weight tracker
              </h5>
              <p className="font-16 ash-txt fw500 font-qmedium mb-3 mobile-font14">
                We advise that you update your physical activity and weight
                tracking at least once a week.
              </p>
              <p className="font-14 ash-txt fw500 font-qmedium mb-32 mobile-font14 lastupdate">
                Last updated {days} day ago
              </p>
        </React.Fragment>
    )
}
export default Header;