import React from 'react';
import { Link } from 'react-router-dom';

class WelcomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            users: []
        };
    }

    render() {
        const { user, users } = this.state;
        return (
            <div className="col-md-6 col-md-offset-3">
                <span>Welcome...</span>
            </div>
        );
    }
}

export { WelcomePage };