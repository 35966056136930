import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    start_loader,
} from "../../actions";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import ContactForm from "../ContactPage/ContactFormComponent";

class KidneyPartnerships extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="work-with-us">
                    <section className="bg-contrast">
                        <div class="container-fluid w-80">
                            <div class="row">
                                <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div class="beam-for-clinicians">Beam For Clinicians, Partners and Collaborators in Kidney Disease</div>
                                    <a href="#clinicians" class="nav-button thick-orange-btn">Health and exercise professionals</a>
                                    <a href="#charities" class="nav-button blue-btn">Charities</a>
                                    <a href="#partners" class="nav-button purple-btn">Sponsors and partners</a>

                                    <div class="helping-physiotherap">We work with clinicians, exercise specialists, health organisations and charities to help people with kidney disease get the benefits of specialist exercise.</div>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2 d-none d-sm-block header-img">
                                    <img class="img-fluid" src={constand.WEB_IMAGES + "Sun.png"} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="container-fluid w-80">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-purple">Our Friends and Partners</div>
                                    <div class="row">
                                        <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0">
                                            <div class="outer-wrapper">
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/KRUK.png"} /></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "landing/NHS-right.png"} /></div>
                                                <div class="wrap"></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "landing/renal_logo_cmyk.png"} /></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/BRS-Logo.jpg"} /></div>
                                                <div class="wrap"></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/Bangor_Logo.png"} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0">
                                            <div class="outer-wrapper">
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "landing/LU-logo.png"} /></div>
                                                <div class="wrap"></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "logos/UoL.png"} /></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "landing/LKLT-logo.png"} /></div>
                                                <div class="frame"><img width="100%" src={constand.WEB_IMAGES + "landing/University-Portsmouth.jpg"} /></div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="bg-contrast">
                        <div class="container-fluid w-80">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-blue"> What is Kidney Beam? </div> 
                                    <div class="section-content">
                                    Kidney Beam is an online platform for people with kidney disease that offers live and on-demand exercise and educational classes as well as motivational and community support. Our varied classes support people at all life stages are led by specialist instructors who either live with or work in kidney disease.  
                                    <br /><br />
                                        As well as supporting people with kidney disease to stay well, we support kidney clinicians to deliver remote care. Beam has been approved by health professionals and is an Orcha approved health app.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="clinicians">
                        <section>
                            <div class="container-fluid w-80">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="partner-title-orange">Beam for Health and Exercise Professionals</div>
                                        <div class="section-content"> 
                                             Kidney Beam has been created in collaboration with specialist renal physiotherapists at Kings College Hospital, London. <br /><br />
                                             In 2023, data from 'The Kidney Beam Trial' (an independent, multicentre randomised controlled trial) showed that engagement with Kidney Beam resulted in significant and clinically important improvements in health related quality of life. Specifically, Kidney Beam was effective for improvements in mental health related quality of life, as well as physical function, symptom burden, social interaction, anxiety and depression and patient activation. For more information on 'The Kidney Beam Trial' see the results <Link to={"/blogs/kidney-beam-clinically-proven-to-improve-health"}>here.</Link>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 offset-lg-2 bundle-price">
                                         <h2 >
                                            Introducing Beam Clinics
                                        </h2>
                                    </div>
                                    <div class="section-content">
                                         Beam offers the opportunity for you to set up your own closed group on the platform, which you can invite your patients to join. In this group, you can host your own live, group classes; record and upload your classes to a private library; engage with your patients on a wall (they can use an alias to remain anonymous) and track activity levels and patient recorded outcome measures.
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="icon-title">Host a private patient/client group</div>
                                        <div class="icon-img"><img src={constand.WEB_IMAGES + "Happy_world.png"} /></div>
                                    </div> 
                                    <div class="col-lg-3">
                                        <div class="icon-title">Host live, group exercise classes</div>
                                        <div class="icon-img"><img src={constand.WEB_IMAGES + "Live_class.png"} /></div>
                                    </div> 
                                    <div class="col-lg-3">
                                        <div class="icon-title">Private, on-demand exercise video library</div>
                                        <div class="icon-img"><img src={constand.WEB_IMAGES + "Beam_OnDemand.png"} /></div>
                                    </div> 
                                    <div class="col-lg-3">
                                        <div class="icon-title">Patient/client activity tracking</div>
                                        <div class="icon-img"><img src={constand.WEB_IMAGES + "data_dashboard.png"} /></div> 
                                    </div>
                                    <div class="section-content">
                                         For more information on Beam Clinics and for pricing please click the 'Get in touch' button below or email <a href="mailto:hello@beamfeelgood.com">hello@beamfeelgood.com</a>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="section-content">
                                         We use Zoom to host our online live exercise classes.  The safety and security of the participants in our classes is of paramount importance to us.  <a class="inline-link" href="https://drive.google.com/file/d/1Arr01A7TunbfnDJQxS4jjZGEp1iC7QbN/view?usp=sharing" target="_blank">Read more here</a> about the security measure we take to ensure our classes provide a safe environment for people to exercise in.
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 offset-lg-4 col-6 offset-3 text-center">
                                        <a href="#contact-form" class="nav-button thick-orange-btn w-100 m-t-3">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section class="bg-contrast" id="charities">
                        <section>
                            <div class="container-fluid w-80">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="partner-title-blue">Beam for Charities</div>
                                        <div class="section-content">
                                             Having collaborated with Kidney Research UK we understand the immensely important role charities play and we would like to connect with kidney charities globally. <br /><br />
                                             We know that charities are often looking for ways to unite the community, improve access and equality in care, and improve physical and emotional welling. As such, we are always open to conversations and collaborations with other kidney charities.<br /><br />
                                             Ways we have supported charities in the past include building new content and sessions for their communities on Beam, hosting virtual exercise events for their community, and offering discounts on bulk purchase licenses.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 offset-lg-4 col-6 offset-3 text-center">
                                        <a href="#contact-form" class="nav-button blue-btn w-100 m-t-3">
                                            Get in touch
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section id="partners">
                        <section>
                            <div class="container-fluid w-80">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="partner-title-purple">Beam for Sponsors and Partners</div>
                                        <div class="section-content">
                                             We are constantly looking for ways to better what we can offer the kidney community. If you have an idea of how we could partner with you then please get in touch - we'd love to chat!
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 offset-lg-4 col-6 offset-3 text-center">
                                                <a href="#contact-form" class="nav-button purple-btn  w-100 m-t-3">
                                                    Get in touch
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section class="bg-contrast">
                        <div class="container-fluid w-80" id="contact-form">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-blue">Want to work with us?</div>
                                    <div class="section-content text-left">
                                        <div className="col-md-6 ml-auto mr-auto">
                                            <div><p className="purplefont font-bold text-center">Fill in the form, it's easy!</p></div>
                                            <div class="section-content p-b-30">
                                                 Not a fan of forms? Feel that you need more guidance? Drop us a note at
                                                 <a href="mailto:hello@beamfeelgood.com"> hello@beamfeelgood.com</a>. 
                                            </div>
                                            <ContactForm location={this.props.location} from="work" page="kidney" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    };
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    start_loader
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KidneyPartnerships);