import React, {useState,useEffect} from "react";
import PhysicalActivityLineChart from "../chart/physicalActivityCHat";
import { PHYSICAL_ACTIVITY_LINK } from "../../../constant";
const PhysicalActivityTab = ({
    updateCallback,
    data,
    onBeamData,
    offBeamData,
    tabSelected
}) => {
    const [physicalActivityValue,setPhysicalActivityValue] = useState({
        value:0
    })
    const physicalActivityOnchange = (type)=>(e) => {
      if (e.target.value < 0) return false;
        physicalActivityValue[type] = e.target.value;
        setPhysicalActivityValue({...physicalActivityValue})
    }
    const onUpdate = () => {
        updateCallback('physicalActivity',physicalActivityValue)
    }
    useEffect(()=>{
      if(data?.duration) {
        setPhysicalActivityValue({
          ...physicalActivityValue,
          value:data?.duration
        })
      }
    },[data?.duration])

    
const handleDownload = async () => {  
  try {
    const anchor = document.createElement("a");
    anchor.href = PHYSICAL_ACTIVITY_LINK;
    anchor.download = "document";
    anchor.click();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}
  return (
    <React.Fragment>
      <div
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
        className={"tab-pane fade "+(tabSelected==2?"show active":"") }
      >
        <p className="font-16 fw500 font-qmedium ash-txt mobile-font14">
          Enter your physical activity in MINUTES here. It can either be a total
          amount of minutes for the week (starting Monday), or you can add your
          activity daily in minutes each day you are active, and it will tally
          up your weekly total. Physical activity minutes Includes any planned
          exercise, walking or activities around the house
        </p>
        <div class="cta-section popup-cta">
          <button
            class="session-btn1 session-btn btn btn-outline-dark font-16 fw600 font-qmedium black-txt"
            onClick={() => handleDownload()}
          >
            Download Exercise Diary
          </button>
        </div>
        <div className="values-update update-btn mt-4">
          <input
            type="number"
            min={0}
            // placeholder="5"
            onChange={physicalActivityOnchange('value')}
            value={physicalActivityValue.value}
            className="font-qregular font-14 fw400 black-txt"
          />
          <div class="form-group custom-select-arrow mintstext">
            <p class="font-16 fw500 font-qmedium ash-txt mt-0">minutes</p>
          </div>
          <a href="javascript:void(0)" className="bluebutton1 session-update-btn font-16 font-qbold fw600 bg-lightblue session-update-btn"
            onClick={onUpdate}
          >
            Update
          </a>
        </div>
        {onBeamData && offBeamData ? <PhysicalActivityLineChart
          onBeamData={onBeamData}
          offBeamData={offBeamData}
        /> : null}
        {/* <figure>
          <img
            src="/images/dashboard/this-week.png"
            className="img-fluid"
            alt="Chart"
          />
        </figure> */}
      </div>
    </React.Fragment>
  );
};
export default PhysicalActivityTab;
