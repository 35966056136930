import React from 'react';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { getDynamicPage } from "../../actions"
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { RichText } from 'prismic-reactjs';
import { ImageTag, TextWrap } from "../../tags";
var _ = require('lodash');

class MapStatic extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <>
        <section className="homecontent h-auto ">
          <Helmet>
            <title>BeamVille</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <script type="text/javascript">{`//Variables
  var mapSW = [0, 16384],
      mapNE = [16384, 0];

  // Declare Map Object
  var map = L.map('map').setView([0,0],2.5);

  // reference tiles
L.tileLayer('images/BeamVilleMap tiles/{z}/{x}/{y}.png', {
  minZoom: 2,
  maxZoom: 6,
  continuousWorld: false,
  noWrap: true,
  crs: L.CRS.Simple,
}).addTo(map);

map.setMaxBounds(new L.LatLngBounds(
  map.unproject(mapSW, map.getMaxZoom()),
  map.unproject(mapNE, map.getMaxZoom())
));
  //Icons
var BeamLoc_icon = L.icon({
    iconUrl: 'images/BeamLocMarker_Small.png',
    iconSize: ['', 41],
    iconAnchor: [30, 41],
    popupAnchor: [-3, -76],
});

var Ben_icon = L.icon({
    iconUrl: 'images/Ben-bike_x2.png',
    iconSize: ['', 93],
    iconAnchor: [30, 41],
    popupAnchor: [-3, -76],
});

var Robin_icon = L.icon({
    iconUrl: 'images/Robin_x2.png',
    iconSize: ['', 94],
    iconAnchor: [30, 41],
    popupAnchor: [-3, -76],
});

var Charlotte_icon = L.icon({
  iconUrl: 'images/charlotte_icon.png',
  iconSize: ['', 94],
  iconAnchor: [30, 41],
  popupAnchor: [-3, -76],
});

  // Markers and Popups
  //Reference Marker to get Pixels for specific map locations
var refmarker = L.marker([0, 0], {
  draggable: true,
})
//.addTo(map)
.bindPopup('reference').openPopup();

refmarker.on('dragend', function(e) {
  refmarker.getPopup().setContent('Clicked' + refmarker.getLatLng().toString() + '<br />'
  + 'Pixels  ' + map.project(refmarker.getLatLng(), map.getMaxZoom().toString()))
  .openOn(map);
});

  //Location markers
var marker_red_house = L.marker(
  map.unproject([9109.333984, 9791.0761],
    map.getMaxZoom()),
    {
      title: 'My Saved Beam Classes',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/account/dashboard/saved/cystic%20fibrosis%20youth"><h1 class="map-popup-text">My Saved Beam Classes</h1></a>');
//marker_red_house.bindTooltip("red_house_page");

var marker_sun = L.marker(
  map.unproject([6439.983314, 4482.666626],
    map.getMaxZoom()),
    {
      title: 'Active on a Sunny Day',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/keeping-active-on-a-warm-summers-day"><h1 class="map-popup-text">Active on a Sunny Day</h1></a>');

//marker_sun.bindTooltip("sun_page");

var marker_water = L.marker(
  map.unproject([6959.991657, 13058],
    map.getMaxZoom()),
    {
      title: 'Watersports',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/cf-and-watersports"><h1 class="map-popup-text">Watersports</h1></a>');
//marker_water.bindTooltip("water_page");

var marker_rain = L.marker(
  map.unproject(
    [14747.824796, 10098.666748],
    map.getMaxZoom()),
    {
      title: 'Bad Weather',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/what-to-do-when-its-bad-weather-out"><h1 class="map-popup-text">Bad Weather</h1></a>');
//marker_rain.bindTooltip("rain_page");

var marker_flag = L.marker(
  map.unproject(
    [10754.08576, 4016],
    map.getMaxZoom()),
    {
      title: 'Setting Goals',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/setting-and-reaching-a-goal"><h1 class="map-popup-text">Setting Goals</h1></a>');
//marker_flag.bindTooltip("goalsetting_page");

var marker_school = L.marker(
  map.unproject(
    [12697.34323, 9004],
    map.getMaxZoom()),
    {
      title: 'Active at School',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/keeping-active-at-school"><h1 class="map-popup-text">Active at School</h1></a>');
//marker_school.bindTooltip("school_page");

var marker_blue_house = L.marker(
  map.unproject(
    [5410.723759, 9784],
    map.getMaxZoom()),
    {
      title: 'Beam Community',
      icon: BeamLoc_icon,
    })
      .addTo(map)
      .bindPopup('<a href="/groups/cystic%20fibrosis%20youth"><h1 class="map-popup-text">Beam Community</h1></a>');
//marker_blue_house.bindTooltip("blue_house_page");

var marker_beam_on_demand = L.marker(
  map.unproject(
    [13458.661064, 12008.666687],
    map.getMaxZoom()),
    {
      title: 'Beam on Demand',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/on-demand/cystic%20fibrosis%20youth"><h1 class="map-popup-text">Beam on Demand</h1></a>');

//marker_beam_on_demand.bindTooltip("beam_on_demand_page");

var marker_hospital = L.marker(
  map.unproject(
    [8470.016686, 7439.333252],
    map.getMaxZoom()),
    {
      title: 'When in Hospital',
      icon: BeamLoc_icon,
    })
    .addTo(map)
    .bindPopup('<a href="/keeping-active-when-in-hospital-or-not-feeling-well"><h1 class="map-popup-text">When in Hospital</h1></a>');


//marker_hospital.bindTooltip("hospital_page");

var marker_charlotte = L.marker(
  map.unproject(
    [8535.507944, 11930.666504],
    map.getMaxZoom()),
  {
    title: 'Charlotte',
    icon: Charlotte_icon,
  })
    .addTo(map)
    .bindPopup('<a href="/charlottes-story"><h1 class="map-popup-text">Charlotte</h1></a>');


//marker_charlotte.bindTooltip("Charlotte_page");

var marker_ben = L.marker(
  map.unproject(
    [6506.667969, 10985.137994],
    map.getMaxZoom()),
  {
    title: 'Ben',
    icon: Ben_icon,
  })
    .addTo(map)
    .bindPopup('<a href="/bens-story"><h1 class="map-popup-text">Ben</h1></a>');

//marker_ben.bindTooltip("Ben_page");

var marker_robin = L.marker(
  map.unproject(
    [6400, 7573.671488],
    map.getMaxZoom()),
  {
    title: 'Robin',
    icon: Robin_icon,
  })
    .addTo(map)
    .bindPopup('<a href="/robins-story"><h1 class="map-popup-text">Robin</h1></a>');

//marker_robin.bindTooltip("Robin_page");
    `}</script>

          </Helmet>
          <div id="map" className="mapstatic"></div>
        </section>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
  };
};

const mapDispatchToProps = {
  getDynamicPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapStatic);

