import React, { useEffect } from "react";

import ReactHtmlParser from "react-html-parser";
export default function SessionSummary({summary}) {
  // useEffect(() => {
  //   document.getElementById('sectionfour-survey').innerHTML = summary;
  // }, [summary]);
  return (
    <section id="sectionfour-survey" className="sectionOne-survey mt-50 mb-80">
      {/* <div className="summary-finish-list">
        <p className="font-18 fw600 font-semibold black-txt mb-2">Summary</p>
        <ul>
          <li className="ash-txt font-14 fw500 font-qregular">
            Make sure your goals are SMART (Specific, Measurable, Achievable,
            Realistic and have a Time frame)
          </li>
          <li className="ash-txt font-14 fw500 font-qregular">
            Write down or display your goal somewhere you can see them to remind
            yourself to keep on track
          </li>
          <li className="ash-txt font-14 fw500 font-qregular">
            Remember you want the goal to be a small stepping stone' towards
            your bigger goal. ie. challenging enough to keep you motivated, but
            not too hard or unrealistic that it will put you off!
          </li>
          <li className="ash-txt font-14 fw500 font-qregular">
            Can you identify anyone that may encourage and motivate you to
            achieve your goals?
          </li>
          <li className="ash-txt font-14 fw500 font-qregular">
            You mi ht find sharing your goal with a friend or family member
            helpful
          </li>
          <li className="ash-txt font-14 fw500 font-qregular">
            We wilt be setting SMART goals each week
          </li>
        </ul>
        <p className="font-14 fw600 black-txt font-semibold mb-32">
          Now set your own SMART goal.
        </p>
      </div>
      <div className="">
        <h3 className="font-18 fw600 font-semibold black-txt mb-2">
          Revise Goals
        </h3>
        <p className="ash-txt font-14 fw500 font-qregular mb-32">
          It is advised to review and update your goals at least once a week,
          therefore click below to complete your session and update your goals.
        </p>
      </div> */}
      <div dangerouslySetInnerHTML={{__html: summary}}></div>
      {/* {ReactHtmlParser(summary)} */}
    </section>
  );
}
