import * as constand from "../constant";
import { getData, postData } from "../_helpers/api-helper";
import handleResponse from "../_services/handle.service";
import { SET_ACTIVE_SURVEY, SET_FORMDATA } from "../utilities";

export function setActiveSurvey(type) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_SURVEY,
      payload: type,
    });
  };
}
export function setFormData(type) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_FORMDATA,
      payload: type,
    });
  };
}

export function createUpdateSurvey(data) {
  return (dispatch, getState) => {
    const requestOptions = postData(data);
    return fetch(
      constand.BACKEND_URL + "/api/programmes/session/survey/addSurvey",
      requestOptions
    )
      .then((response) => handleResponse(response, dispatch))
      .then((response) => {
       
        return response;
      });
  };
}