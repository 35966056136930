import React from "react";

export default class GoToTop extends React.Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
      thePosition: false,
    };
  }
  componentDidMount() {
      if (window.scrollY !== 0) {
        document.addEventListener("scroll", () => {
          if (window.scrollY > 170) {
            this.setState({ thePosition: true });
          } else {
            this.setState({ thePosition: false });
          }
        });
        window.scrollTo(0, 0);
      }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.state.intervalId); // Clean up interval
  }
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), "10");
    this.setState({ intervalId: intervalId });
  }
  goTopBtn() {
      if (this.state.thePosition) {
        return (
          <button
            title="Back to top"
            className="scroll"
            onClick={() => {
              this.scrollToTop();
            }}
          >
            <span className="arrow-up fa fa-chevron-up"></span>
            <span className="font-qbold white-txt font-14 text-center m-t-15 d-inline-block">
              Top
            </span>
          </button>
        );
      } else {
        return null;
      }

  }
  render() {
    return this.goTopBtn();
  }
}
