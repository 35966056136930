import React, { useState } from "react";
import DashboardPlayer from "./PlayerComponent";
import * as constand from "../../constant";
import { useDispatch } from "react-redux";
import { startVideo } from "../../actions";
import { saveTimeSpent } from "../../actions";
import { useParams } from "react-router-dom";
import { ImageTag } from "../../tags";
export const VideoMultiContent = ({ videoContent, getSessionStep }) => {
  const [isPlay, setIsPlay] = useState(false);
  const { programId, sessionId } = useParams();
  const [currentVideo, setCurrentVideo] = useState(null);
  const dispatch = useDispatch();
  const [roomId, setRoomId] = useState();
  const onCloseModal = async ({ spendTime, showAfterReview }) => {
    setIsPlay(false);

    if (spendTime > 0) {
      const payload = {
        roomId,
        time: spendTime,
      };
      await dispatch(saveTimeSpent(payload));
      getSessionStep(false);
    }
  };

  const playVideo = async (id) => {
    const video = videoContent?.find((item) => item?.foreignId === id);

    if (video) {
      setCurrentVideo(video);
      setIsPlay(true);

      const payload = {
        programId: programId,
        roomId: "0",
        workoutId: id,
        sessionId
      };
      const response = await dispatch(startVideo(payload));
      setRoomId(response.attendee.RoomId);
    }
  };
  let imgUrl = constand.WEB_IMAGES + "ondemand-placeholder.png";
  return (
    <div className="col-12 col-md-12 col-lg-12 text-left interactive-activity mb-32">
      <p className="font-18 fw600  mb-2 activity-two">
        Which video will you watch first?
      </p>
      <p className="font-14 fw500 mb-2 activity-one">
        Watch as many or as little of these optional videos as you would like.
      </p>
      <div className="col-12 col-md-12 col-lg-12 text-left interactive-activity p-0 row">
        {videoContent?.map((video) => {
          if (video?.videoData?.videoImg) {
            imgUrl =
              constand.S3_URL +
              "/api/img/workout/" +
              video?.videoData?.videoImg;
          }
          return (
            <>
              <div className="col-12 col-md-4 col-lg-4 float-left interactive-col mb-32 session-video-content">
                <figure onClick={() => playVideo(video?.foreignId)}>
                  {/* <img
                    src={imgUrl}
                    className="img-fluid pointer"
                    alt="video excersice"
                    onClick={() => playVideo(video?.foreignId)}
                  /> */}
                  <ImageTag
                    src={imgUrl}
                    className="img-fluid img-responsive pointer"
                    width="100%"
                    thumb={constand.WEB_IMAGES + "ondemand-placeholder.png"}

                  />
                </figure>
                <img class="img-fluid play-img pointer" src={constand.WEB_IMAGES + "b-play-btn.png"} alt="play" onClick={() => playVideo(video?.foreignId)}></img>
                
                <h5 className="font-16 fw600 font-semibold black-txt">
                  {video?.videoData?.title}
                </h5>
                <p className="font-12 fw500 font-qmedium your-progress">
                  Your progress
                </p>
                <div class="session-progress">
                  <div className="progress">
                    <div
                      className="progress-bar bg-c-red"
                      style={{
                        width: `${video?.videoData?.progress}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <DashboardPlayer
          videoContent={currentVideo?.videoData}
          isPlay={isPlay}
          onCloseModal={onCloseModal}
        />
      </div>
    </div>
  );
};
