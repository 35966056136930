import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { fetchClassList, start_loader, stop_loader, loginModelOpen, joinClass, fetchGroupWorkout, cancelClass, pushHistory } from "../../actions";
import moment from 'moment';
import * as constand from "../../constant";
import { commonService } from "../../_services";
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { ImageTag } from "../../tags";
import { Cookies } from "react-cookie-consent";

class GroupWorkoutScheduleCalenderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: this.props.params,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            calenderLiveClassList: [],
            selectDate: this.props.firstScheduleDate,
            isCurrentWeek: true,
            condition: this.props.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            all_levels: [],
            init_weeks_days: [],
            Liveclass_Weeks: constand.Liveclass_Weeks
        };
        this.submitStartNow = this.submitStartNow.bind(this);
        this.modelClose = this.modelClose.bind(this);
        this.joinClassService = this.joinClassService.bind(this);
    }
    submitStartNow(item) {
        if (this.props.is_auth) {
            this.joinClassService(item);
        } else {
            this.props.loginModelOpen(true);
        }
    }

    joinClassService(item) {
        var dataObj = { "roomId": item.id };
        this.props.start_loader();
        this.props.joinClass(dataObj).then(
            response => {
                this.props.stop_loader();
                if (response) {
                    this.setState({
                        modelState: true,
                        modelData: item
                    });
                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    modelState: true,
                    modelData: {}
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        //this.setState({ modelState: false, modelData: {} });
        this.setState({
            group: this.props.params,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            calenderLiveClassList: [],
            selectDate: this.props.firstScheduleDate,
            isCurrentWeek: true,
            condition: this.props.props.params.condition,
            modelState: false,
            modelData: {},
            total_tags: [],
            all_levels: []
        }, function () { this.fetchLiveClassList(); });
    }
    componentDidMount() {
        var date = new Date();
        var startDate = date;
        var endDate = moment().add(6, 'd');
        var temp = [commonService.getWeekDays(startDate, endDate)];
        for (var i = 1; i < this.state.Liveclass_Weeks; i++) {
            startDate = moment(endDate).add(1, 'd');
            endDate = moment(endDate).add(7, 'd');
            temp.push(commonService.getWeekDays(startDate, endDate));
        }
        this.setState({ init_weeks_days: temp });
        let selectedLiveClassDate = temp[0];
        let isCurrentWeek = 0;
        let liveSelectedDate = new Date();
        if (this.props.firstScheduleDate) {
            let liveClassDate = moment(this.props.firstScheduleDate).format('YYYY-MM-DD');
            for (var j = 0; j < this.state.Liveclass_Weeks; j++) {

                if (temp[j].indexOf(liveClassDate) >= constand.CONSTZERO) {
                    console.log('key', j)

                    selectedLiveClassDate = temp[j];
                    isCurrentWeek = j;
                }
                liveSelectedDate = this.props.firstScheduleDate;
            }
        }
        this.setState({
            selectDate: liveSelectedDate,
            selectedWeek: selectedLiveClassDate,
            isCurrentWeek: isCurrentWeek
        }, () => {
            this.fetchLiveClassList();
        })

    }
    /**
    * call after sign in
    */
    componentWillReceiveProps(nextProps) {
        if (!this.props.is_auth && nextProps.is_auth) {
            this.setState({ calenderLiveClassList: [] });
            this.fetchLiveClassList();
        }
        else {
            this.setState({ calenderLiveClassList: [] }, function () { this.toggleLiveClasses(); });
        }
    }

    /**
     * fetch class list data
     */
    fetchLiveClassList() {
        this.setState({ Loading: true });
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition,
            "dateChosen": moment(this.state.selectDate).format('YYYY-MM-DD'),
            "group": this.state.group
        };
        //this.props.fetchClassList(dataObj).then(
        this.props.fetchGroupWorkout(dataObj).then(
            response => {
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {
                        var liveClassData = list.liveClasses;
                        this.setState({ allLiveClassData: liveClassData });
                        this.toggleLiveClasses()

                    }
                    this.setState({
                        Loading: false,
                        count: list.count,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        all_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false
                });
                // toast.error(error);
            }
        );
    }
    toggleLiveClasses() {
        var liveClassData = this.state.allLiveClassData;
        var is_toggled = Cookies.get('is_toggled'); //this.state.is_toggled;

        if (is_toggled === 'true') {
            //show all
            console.log('show all is_toggled', is_toggled)
            this.groupListResult(liveClassData);

        } else {
            var exceptArray = []
            liveClassData.map(function (liveclass, key) {
                //liveclass.scheduledFor
                var schedule = moment(liveclass.scheduledFor).toDate();
                var midnight12 = moment(moment(liveclass.scheduledFor).hour('0').minute('01').second('0')).toDate();
                var morninig5 = moment(moment(liveclass.scheduledFor).hour('4').minute('59').second('0')).toDate();

                if (!(schedule >= midnight12 && schedule <= morninig5)) {
                    console.log('schedule ' + schedule + ' midnight12 ' + midnight12 + ' morninig5 ' + morninig5)
                    console.log(liveclass.id + '************NOT MID NIGHT*****************');
                    exceptArray.push(liveclass)
                }
            })
            this.groupListResult(exceptArray);
        }
    }
    /**
  * load more class list
  */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {
        let classList = [];
        let existingData = this.state.calenderLiveClassList;
        let newData = originalList;
        classList = [...existingData, ...newData];
        this.setState({ calenderLiveClassList: classList })
    }
    //cancel class
    cancelCalss(item, index) {
        if (!this.state.cancelLoading) {
            this.setState({ cancelLoading: true });
            var dataObj = {
                "roomId": item.id
            };
            this.props.cancelClass(dataObj).then(
                response => {
                    if (response) {
                        toast.success(response.message);
                    }
                    const newItems = [...this.state.calenderLiveClassList];
                    newItems[index].Signedup = false;

                    this.setState({
                        calenderLiveClassList: newItems,
                        cancelLoading: false
                    });
                },
                error => {
                    this.setState({
                        cancelLoading: false
                    });
                }
            );
        }
    }
    /**
     * render item list
     */
    renderSubItemList() {
        return this.state.calenderLiveClassList.map((item, index) => (
            <div className="list-group-item" key={index}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname);localStorage.setItem('group', this.props.group);localStorage.setItem('groupId',this.props.groupId)   }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname);localStorage.setItem('group', this.props.group);localStorage.setItem('groupId',this.props.groupId)   }}> {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                        {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + this.props.props.params.condition} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Discipline:
                              </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "discipline", this.state.total_tags, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Difficulty:
                            </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "level", this.state.all_levels, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {this.renderSchedule(item)}
                                {(item.Signedup) &&
                                    <a href="javascript:void(0)"
                                        className="grey-text font-14 font-qregular m-b-5" onClick={() => this.cancelCalss(item, index)}
                                    >
                                        Cancel Class
                            </a>}
                                <span
                                    className="btn btn-default-list-blue clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                <span
                                    className="btn btn-default-list-orange clearfix pad_list_btn"
                                >
                                    Live Session
                          </span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        ));
    }

    /**
    * setSelectedDate
    */
    changeSelectedDate(item) {
        this.setState({
            selectDate: item,
            calenderLiveClassList: [],
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * get day
     */
    getDate(item) {
        var weekDay = moment(item).format('DD');
        var dt = moment(item, "YYYY-MM-DD HH:mm:ss")
        var dayName = dt.format('ddd');;
        return dayName + ' ' + weekDay;
    }
    /**
     * render week days
     */
    renderWeekDays(weeks) {
        if (weeks) {
            var selectedDate = this.state.selectDate;
            var currentDate = moment(selectedDate).format('YYYY-MM-DD');
            return weeks.map((item, index) => (
                <div className="months-list float-left" key={index}>
                    <span onClick={() => { this.changeSelectedDate(item) }}
                        className={item === currentDate ? 'active' : ''}>{this.getDate(item)}
                    </span>
                </div>
            ));
        }
    }
    /**
     * selected month & day
     */
    seletedMonthDay() {
        var selectDate = this.state.selectDate;
        var monthName = moment(selectDate).format('MMMM');
        return monthName;
    }
    /**
     * goToPreviousWeek
     */
    goToPreviousWeek(week) {
        this.setState({
            selectedWeek: this.state.init_weeks_days[week],
            isCurrentWeek: week,
            calenderLiveClassList: [],
            selectDate: this.state.init_weeks_days[week][0],//new Date(),
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * goToNextWeek
     */
    goToNextWeek(week) {
        this.setState({
            selectedWeek: this.state.init_weeks_days[week],
            isCurrentWeek: week,
            calenderLiveClassList: [],
            selectDate: this.state.init_weeks_days[week][0],
            offset: 0
        }, () => {
            this.fetchLiveClassList()
        })
    }
    /**
     * render previous week icon
     */
    renderPreviousWeekIcon() {
        if (this.state.isCurrentWeek > 0) {
            return (
                <i onClick={() => { this.goToPreviousWeek(this.state.isCurrentWeek - 1) }} className="fa fa-angle-left float-left"></i>
            )
        }
    }
    /**
     *  render NextWeek Icon
     */
    renderNextWeekIcon() {
        if (this.state.isCurrentWeek < constand.Liveclass_Weeks - 1) {
            return (
                <i onClick={() => { this.goToNextWeek(this.state.isCurrentWeek + 1) }} className="fa fa-angle-right float-right"></i>
            )
        }
    }
    /**
    * render list
    */

    renderListData() {
        if (this.state.calenderLiveClassList && this.state.calenderLiveClassList.length > 0) {
            return (
                <div className="w-100">
                    {this.renderSubItemList()}
                    {/* {this.renderLoadMore()} */}
                </div>
            );
        } else {
            return (
                <h3 className="w-100 text-center m-t-20 m-b-20">
                    Oh no!  There are no live classes scheduled for this day, try selecting another day or schedule an
                    <Link to={"/on-demand/" + this.state.type}> on-demand class </Link>
                </h3>
            );
        }
    }

    /**
    * render load more option
    */
    renderLoadMore() {
        if (this.state.calenderLiveClassList && this.state.count > this.state.calenderLiveClassList.length) {
            return (
                <div className="w-100 text-center">
                    <a onClick={() => { this.incrementShowmoreCnt() }} className="btn btn-orange m-t-40 m-b-40 text-center font-book">Load more live classes</a>
                </div>

            );
        }
    }
    /**
   * render start now or go to class
   */
    renderSchedule(item) {
        // var currentDateTime = moment().format('YYYY-MM-DD hh:mm::ss');
        // var scheduleTime = moment(item.scheduledFor).format('YYYY-MM-DD hh:mm::ss');
        // if (scheduleTime < currentDateTime) {
        //     return (
        //         <a
        //             className="btn btn-purple w-100 font-medium m-b-10"
        //             href={item.reference}
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // } else {
        //     return (
        //         <a onClick={() => this.submitStartNow(item)}
        //             className="btn btn-purple w-100 font-medium m-b-10"
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // }
        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn btn-purple w-100 font-medium m-b-10"> {item.reference} </a>
            );
        } else {
            if (item.Signedup) {
                return (
                    <a className="btn btn-purple w-100 font-medium m-b-10" href={item.Attendees[0].reference || '#'} target="_blank"> Go to room </a>
                );
            }
            else {
                return (
                    <a onClick={() => this.submitStartNow(item)} className="btn btn-purple w-100 font-medium m-b-10" > Sign up </a>
                );
            }
        }
    }

    /**
   * get day
   */
    getMobileDate(item) {
        var dt = moment(item, "YYYY-MM-DD HH:mm:ss")
        var dayName = dt.format('dd');;
        return dayName.charAt(0);
    }
    getMobileDay(item) {
        var weekDay = moment(item).format('DD');
        return weekDay;
    }
    /**
    * render week days
    */
    renderMobileWeekDays(weeks) {
        if (weeks) {
            var selectedDate = this.state.selectDate;
            var currentDate = moment(selectedDate).format('YYYY-MM-DD');
            return weeks.map((item, index) => (

                <div className="months-list float-left" key={index}>
                    <span onClick={() => { this.changeSelectedDate(item) }}
                        className={item === currentDate ? 'active' : 'pointer'}><div className="text-center">{this.getMobileDate(item)}</div><div className="text-center">{this.getMobileDay(item)}</div>
                    </span>
                </div>
            ));
        }
    }
    render() {
        return (
            <div>
                <div className="row border-box m-b-50">
                    <h4 className="card-header section_header date-heading w-100 font-medium text-center text-white">{this.seletedMonthDay()}</h4>
                    <div className="listname w-100 desktop-view">
                        {this.renderPreviousWeekIcon()}
                        {this.renderWeekDays(this.state.selectedWeek)}
                        {this.renderNextWeekIcon()}
                    </div>
                    <div className="listname w-100 mobile-view">
                        {this.renderPreviousWeekIcon()}
                        {this.renderMobileWeekDays(this.state.selectedWeek)}
                        {this.renderNextWeekIcon()}
                    </div>
                    <div className="text-center w-100">
                        {(this.state.Loading) && (<AnimateLoaderComponent />)}
                    </div>

                    {(!this.state.Loading) && this.renderListData()}
                </div>
                <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />

            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth
    };
};

const mapDispatchToProps = {
    fetchClassList, start_loader, stop_loader, loginModelOpen, joinClass, fetchGroupWorkout, cancelClass, pushHistory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupWorkoutScheduleCalenderView);
