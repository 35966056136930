import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchAboutGroup, fetchGroupWorkout, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass, pushHistory } from "../../actions";
import { Link } from 'react-router-dom';
import * as constand from "../../constant";
import moment from 'moment';
import { commonService } from "../../_services";
import GroupWorkoutScheduleCalenderView from "./GroupWorkoutScheduleCalenderView";
import PastLiveClasses from "../LiveClasses/PastLiveClasses";
import UnscheduledLiveClasses from "../LiveClasses/UnscheduledLiveClasses";
import JoinClassComponent from "../LiveClasses/LiveClassJoinModel";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { ImageTag } from "../../tags";
import { Cookies } from "react-cookie-consent";
import CalendarViewComponent from '../Common/CalendarViewComponent';
import ListViewComponent from '../Common/ListViewComponent';
import ResearchPostPopup from '../WorkoutDetailPage/ResearchPostPopup';
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";
import _ from 'lodash';

class GroupWorkoutSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            scheduleList: [],
            allLiveClassData: [],
            displayLayout: true,
            group: this.props.params.group,
            condition: this.props.params.condition,
            type: this.props.params.condition,
            modelState: false,
            modelData: {},
            firstScheduleDate: '',
            total_tags: [],
            all_levels: [],
            openPostPollModel: false,
            current_attendee: {},
            openResearchModel: false,
            redirectUrl: '',
            workoutId: '',
            classType: (this.props.is_create_mode || this.props.is_group_mode) ? 'unscheduled' : 'upcoming'
        };
        this.submitStartNow = this.submitStartNow.bind(this);
        this.modelClose = this.modelClose.bind(this);
        this.joinClassService = this.joinClassService.bind(this);
        this.toggleClasses = this.toggleClasses.bind(this);
        this.renderLiveClassComponent = this.renderLiveClassComponent.bind(this);
        this.closeResearchPostModel = this.closeResearchPostModel.bind(this);
        this.beforeReview = this.beforeReview.bind(this);
        this.viewPrepoll = this.viewPrepoll.bind(this);
        this.closeResearchModel = this.closeResearchModel.bind(this);
    }
    /** fetch live class list on page did mount */
    componentDidMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
        var headerUrl = this.props.location.pathname;
        var splitter = headerUrl.split('/')[1];
        var roomid = headerUrl.split('/')[6];
        var paramUrl = headerUrl.split('/')[7];
        console.log('paramUrl', paramUrl)
        console.log('group-liveclass-props', this.props)
        console.log('group-liveclass-authData', authData)
        //intervention date calc
        var now = new Date();
        var isShowPoll = false;
        var communityDetails = '';
        if (Object.keys(authData).length) {
            var findGroup = _.find(authData.Members, { 'CommunityId': this.props.groupState.aboutGroup.id });
            console.log('findGroup', findGroup)
            if (findGroup && typeof findGroup != undefined)
                communityDetails = Object.keys(findGroup).length ? findGroup.Community : '';
            // communityDetails = authData.Members.length ? authData.Members[0].Community : '';
        }

        console.log('communityDetails', communityDetails)
        if (communityDetails && communityDetails.endDate) {
            var endDate = moment(communityDetails.endDate).utc().format('YYYY-MM-DD');
            var endDated = new Date(endDate);
            if (endDated.getTime() < now.getTime()) {
                isShowPoll = false;
            } else {
                isShowPoll = true;
            }
        } else {
            isShowPoll = true;
        }
        console.log('group-liveclass-isShowPoll', isShowPoll)

        if (this.props.logged_userData.isGroupLeader || this.props.logged_userData.isStudyLeader) {
            this.toggleClasses(true);
        }

        if (typeof Cookies.get('is_toggled') == 'undefined') {
            if (Cookies.get('CookieControl') && Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted')
                Cookies.set('is_toggled', false);
        }
        if (this.props.history.location.pathname && this.props.groupState.isMember && authData && !authData.isCreator && !this.props.groupState.canEdit && paramUrl == 'pollReview' && authData.isStudyParticipant && isShowPoll) {
            console.log('pollreviewschedule', this.props.groupState.condition)
            this.setState({ openPostPollModel: true, current_attendee: { RoomId: roomid } });
            // this.fetchAboutGroup();
        }
        else if (this.props.history.location.pathname && !this.props.groupState.isMember && (!authData || (authData && !authData.isCreator && !authData.isStudyLeader)) && !this.props.groupState.canEdit) {
            const { from } = { from: { pathname: "/group/about/" + this.props.groupState.group + "/" + this.props.groupState.condition } };
            this.props.history.push(from);
        }
        else {
            this.fetchGroupWorkout();
        }
    }

    closeResearchPostModel() {
        this.setState({ openPostPollModel: false });
    }

    beforeReview() {
        window.open(this.state.redirectUrl);
    }

    viewPrepoll(live) {
        live.Attendees.forEach((item) => {
            if (item.UserId === this.props.logged_userData.id) {
                console.log('ref', item.reference)
                this.setState({ redirectUrl: item.reference, current_attendee: item });
            }
        })
        this.setState({ openResearchModel: true, workoutId: live.WorkoutId })
    }

    closeResearchModel() {
        this.setState({ openResearchModel: false });
    }

    fetchAboutGroup() {
        this.props.start_loader();
        var data = { group: this.state.group };
        this.props.fetchAboutGroup(data).then(
            response => {
                if (response) {
                    this.setState({
                        aboutGroup: response.groups,
                        groupLeader: response.groups.User,
                        notifications: response.groups.Notifications,
                        totalMembers:
                            response.groups && response.groups.Members
                                ? response.groups.Members.length
                                : 0,
                        isMember: response.isMember,
                        aboutSection: response.groups.aboutSection,
                        wallSection: response.groups.wallSection,
                        liveClassSection: response.groups.liveClassSection,
                        onDemandSection: response.groups.onDemandSection,
                        membersSection: response.groups.membersSection,
                        feedSection: response.groups.feedSection,
                        canEdit: response.canEdit,
                    });
                    this.setAdminLeader();
                }
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
                this.props.stop_loader();
            }
        );
    }
    /**
     * call after sign in
     */
    componentWillReceiveProps(nextProps) {
        if (!this.props.is_auth && nextProps.is_auth) {
            this.setState({ scheduleList: [] });
            // this.fetchGroupWorkout();
        }
    }
    submitStartNow(item) {
        if (this.props.is_auth) {
            this.joinClassService(item);
        } else {
            this.props.loginModelOpen(true);
        }
    }

    joinClassService(item) {
        var dataObj = { "roomId": item.id };
        this.props.start_loader();
        this.props.joinClass(dataObj).then(
            response => {
                this.props.stop_loader();
                if (response) {
                    this.setState({
                        modelState: true,
                        modelData: item
                    });
                }
            },
            error => {
                this.props.stop_loader();
                this.setState({
                    modelState: false,
                    modelData: {}
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        //this.setState({ modelState: false, modelData: {} });
        this.setState({
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            scheduleList: [],
            displayLayout: true,
            group: this.props.params.group,
            condition: this.props.params.condition,
            modelState: false,
            modelData: {},
            firstScheduleDate: '',
            total_tags: [],
            all_levels: []
        }, function () { this.fetchGroupWorkout(); });
    }
    /**
     * fetch class list data
     */
    fetchGroupWorkout() {
        this.setState({ Loading: true })
        this.props.start_loader();
        var condition = commonService.replaceChar(this.state.condition, true)
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": condition,
            "group": this.state.group
        };
        this.props.fetchGroupWorkout(dataObj).then(
            response => {
                this.setState({ Loading: false })

                this.props.stop_loader();
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {
                        var liveClassData = list.liveClasses;
                        this.setState({ allLiveClassData: liveClassData });

                        this.toggleLiveClasses()
                    }
                    this.setState({
                        Loading: false,
                        count: list.count,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        all_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });

                }
            },
            error => {
                this.setState({
                    Loading: false
                });
                // toast.error(error);
                this.props.stop_loader();
            }
        );
    }
    toggleClasses(toggled) {
        if (Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted')
            Cookies.set('is_toggled', toggled);

        this.setState({ is_toggled: toggled, scheduleList: [] }, function () { this.toggleLiveClasses(); });
    }
    toggleLiveClasses() {
        var liveClassData = this.state.allLiveClassData;
        var is_toggled = Cookies.get('is_toggled'); //this.state.is_toggled;

        //if (is_toggled === 'true') {
        if (((typeof Cookies.get('is_toggled') == 'undefined' && this.state.is_toggled) || Cookies.get('is_toggled') == 'true')) {
            //show all
            this.groupListResult(liveClassData);

        } else {
            //waking hrs only
            var exceptArray = []
            liveClassData.map(function (liveclass, key) {
                var schedule = moment(liveclass.scheduledFor).toDate();
                //var midnight12 = moment(moment(liveclass.scheduledFor).startOf('day')).toDate();
                var midnight12 = moment(moment(liveclass.scheduledFor).hour('0').minute('01').second('0')).toDate();
                var morninig5 = moment(moment(liveclass.scheduledFor).hour('4').minute('59').second('0')).toDate();

                if (!(schedule >= midnight12 && schedule <= morninig5)) {
                    console.log('schedule ' + schedule + ' midnight12 ' + midnight12 + ' morninig5 ' + morninig5)
                    console.log(liveclass.id + '************NOT MID NIGHT*****************');
                    exceptArray.push(liveclass)
                }
            });
            this.groupListResult(exceptArray);
        }
    }
    /**
    * for group list view
    */
    groupListResult(originalList) {
        let classList = [];
        let existingData = this.state.scheduleList;
        let newData = originalList;
        var helper = {};
        let results = [];
        let firstScheduleDate = '';
        newData.map(function (item, key) {
            if (key === constand.CONSTZERO) {
                firstScheduleDate = item.scheduledFor;
            }
            if (existingData && existingData.length > 0 && newData && item && existingData[existingData.length - 1][0].scheduledFor === item.scheduledFor) {
                existingData[existingData.length - 1].push(item);
                classList = existingData;
            } else {
                var key = item.scheduledFor;
                if (!helper[key]) {
                    helper[key] = [];
                    helper[key].push(item)
                    results.push(helper[key]);
                }
                else {
                    helper[key].push(item)
                }
                classList = [...existingData, ...results];
            }
        });
        this.setState({ scheduleList: classList, firstScheduleDate: firstScheduleDate })
    }
    /**
    * change Layout
    */
    changeViewLayout() {
        var layout = this.state.displayLayout;
        this.setState({
            displayLayout: !layout
        })
    }

    //cancel class
    cancelCalss(item, index, key) {
        if (!this.state.cancelLoading) {
            this.setState({ cancelLoading: true });
            var dataObj = {
                "roomId": item.id
            };
            this.props.cancelClass(dataObj).then(
                response => {
                    if (response) {
                        toast.success(response.message);
                    }
                    const newItems = [...this.state.scheduleList];
                    newItems[index][key].Signedup = false;

                    this.setState({
                        scheduleList: newItems,
                        cancelLoading: false
                    });
                },
                error => {
                    this.setState({
                        cancelLoading: false
                    });
                }
            );
        }
    }
    /**
     * render sub item list
     */
    renderSubItemList(data, key) {
        console.log('renderSubItemList-history', this.props.history)
        console.log('renderSubItemList-local', this.props.location)
        return data.map((item, index) => (

            <div className="list-group-item" key={index}>
                <div className="col-md-12 desktop-view-live">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname); localStorage.setItem('group', this.props.group); localStorage.setItem('groupId', this.props.groupId) }} >
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">

                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname); localStorage.setItem('group', this.props.group); localStorage.setItem('groupId', this.props.groupId) }}>  {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                            {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + this.props.params.condition} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Discipline:
                                    </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "discipline", this.state.total_tags, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0">
                                    <span className="w-40 font-16 font-semibold black-txt float-left">
                                        Difficulty:
                                    </span>
                                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                        {commonService.returnTag(
                                            "level", this.state.all_levels, item.WorkoutId
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {this.renderSchedule(item)}
                                {(item.Signedup) &&
                                    <a href="javascript:void(0)"
                                        className="grey-text font-14 font-qregular m-b-5" onClick={() => this.cancelCalss(item, key, index)}
                                    >
                                        Cancel Class
                                    </a>}
                                <span
                                    className="btn btn-default-list-blue clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                <span
                                    className="btn btn-default-list-orange clearfix pad_list_btn"
                                >
                                    Live Session
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mobile-view-live">
                    <div className="row">
                        <div className="media col-12 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname); localStorage.setItem('group', this.props.group); localStorage.setItem('groupId', this.props.groupId) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 float-left p-0 ">
                                <div className="col-12  float-left p-0">
                                    <div className="p-0 border-0 float-left w-100 m-t-5 m-b-5">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.props.params.condition + "/" + item.Workout.id} onClick={() => { this.props.pushHistory(this.props.history.location.pathname); localStorage.setItem('prevPath', this.props.history.location.pathname); localStorage.setItem('group', this.props.group); localStorage.setItem('groupId', this.props.groupId) }}>  {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                            {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + this.props.params.condition} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium col-12 p-0 m-b-10 float-left ">
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Discipline:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "discipline", this.state.total_tags, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-12 float-left p-0">
                                        <span className="w-40 font-16 font-semibold black-txt float-left">
                                            Difficulty:
                                        </span>
                                        <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                                            {commonService.returnTag(
                                                "level", this.state.all_levels, item.WorkoutId
                                            )}
                                        </span>
                                    </div>
                                    {/* <div className="col-md-4 col-sm-4 float-left p-0">
                                  <span className="w-40 float-left font-13 font-qregular black-txt">
                                      Language:
                                      </span>
                                  <span className="w-60 float-left">
                                      {" "}
                                      <img
                                          className="img-fluid p-l-5 p-b-10"
                                          src="/images/flag.png"
                                          alt=""
                                      />
                                  </span>
                              </div> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-12 float-left p-0 text-center">
                            {this.renderSchedule(item)}
                            <span
                                className="btn btn-default-list-blue clearfix pad_list_btn"
                            >
                                {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                            </span>
                            <span
                                className="btn btn-default-list-orange clearfix pad_list_btn"
                            >
                                Live Session
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
    /**
    * render load more option
    */
    renderLoadMore() {
        if (this.state.scheduleList && this.state.count > this.state.scheduleList.length) {
            return (
                <div className="w-100 text-center">
                    <a onClick={() => { this.incrementShowmoreCnt() }} className="btn btn-orange m-t-40 m-b-40 text-center font-book">Load more live classes</a>
                </div>

            );
        }
    }

    /**
    * load more class list
    */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }

    /**
    *to render item
    **/
    renderScheduleItem() {
        return this.state.scheduleList.map((item, index) => (
            <div key={index}>
                <p className="section_header font-semibold">
                    {commonService.getDayNameFromDate(item[0].scheduledFor)}
                </p>
                {this.renderSubItemList(item, index)}
            </div>
        ));
    }
    /**
 * render start now or go to class
 */
    renderSchedule(item) {
        // var currentDateTime = moment().format('YYYY-MM-DD hh:mm::ss');
        // var scheduleTime = moment(item.scheduledFor).format('YYYY-MM-DD hh:mm::ss');
        // if (scheduleTime < currentDateTime) {
        //     return (
        //         <a
        //             className="btn btn-purple w-100 font-medium m-b-10"
        //             href={item.reference}
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // } else {
        //     return (
        //         <a onClick={() => this.submitStartNow(item)}
        //             className="btn btn-purple w-100 font-medium m-b-10"
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Start Now'}</a>
        //     );
        // }
        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn btn-purple w-100 font-medium m-b-10"> {item.reference} </a>
            );
        } else {
            var tagCondition = commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();

            if (item.Signedup && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition)) {
                return (
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.viewPrepoll(item)} > Go to class </a>
                );
            } else if ((item.Signedup && !this.props.logged_userData.isStudyParticipant) || (item.Signedup && this.props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition))) {
                return (
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={item.Attendees[0].reference || '#'} target="_blank" > Go to class </a>
                    // <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={()=> this.viewPrepoll(item)} > Go to class List view </a>                
                );
            }
            else {
                return (
                    <a onClick={() => this.submitStartNow(item)} className="btn btn-purple w-100 font-medium m-b-10" > Sign up </a>
                );
            }
        }
    }
    /**
     * renderLiveClassComponent
     */
    renderLiveClassComponent() {


        if (this.state.displayLayout) {
            return (
                <div>
                    {this.state.classType == 'upcoming' &&
                        <ListViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} group={this.state.group} location={this.props.location} componentType='Group' is_toggled={this.state.is_toggled} allLiveClassData={this.state.allLiveClassData} groupId={this.props.groupState.aboutGroup.id}
                        />
                    }
                    {this.state.classType == 'past' &&
                        <PastLiveClasses props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} group={this.state.group} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Group' groupId={this.props.groupState.aboutGroup.id} />
                    }
                    {this.props.is_auth && (this.props.is_create_mode || this.props.is_group_mode) && this.state.classType == 'unscheduled' &&
                        <UnscheduledLiveClasses props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} group={this.state.group} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Group' groupId={this.props.groupState.aboutGroup.id} />
                    }
                </div>
            );
        } else {
            return <CalendarViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} group={this.state.group} location={this.props.location} componentType='Group' is_toggled={this.state.is_toggled} allLiveClassData={this.state.allLiveClassData}
            />
        }
    }
    render() {
        return (
            <>
                <ResearchPostPopup
                    is_model_open={this.state.openPostPollModel}
                    closeResearchPostModel={this.closeResearchPostModel}
                    classType="Live"
                    beforeReview={this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                    condition={this.state.condition}
                    history={this.props.history}
                />
                <ResearchStudyPollPopup
                    is_model_open={this.state.openResearchModel}
                    closeResearchModel={this.closeResearchModel}
                    classType="Live"
                    beforeReview={this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                />
                <div className="list_section float-left w-100 p-t-20 p-b-20">
                    <div className="container">
                        <div >
                            <div className="col-md-12 p-0">
                                <div className="row">
                                    <div className="col-md-3">
                                        <p className="row">
                                            <i className={this.state.displayLayout ? 'fa fa-calendar-o' : 'fa fa-list-ul'} aria-hidden="true"></i>{" "}
                                            <span
                                                onClick={() => { this.changeViewLayout() }}
                                                className="orangefont font-medium pointer p-l-10 "
                                            >
                                                <u>{this.state.displayLayout ? 'Switch to calendar view' : 'Switch to list view'}</u>
                                            </span>
                                        </p>
                                    </div>
                                    {(!this.props.is_create_mode && !this.props.is_group_mode) &&
                                        <div className="col-md-6">
                                            <p className="w-40 font-16 font-semibold black-txt text-center">All class times shown in {" " + commonService.localTimeZoneName()} time</p>
                                        </div>
                                    }

                                    {(this.props.is_create_mode || this.props.is_group_mode) &&
                                        <div className="col-md-2 text-right mb-2">
                                            <span
                                                className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'upcoming' ? 'btn-beam-blue white-txt' : 'btn-beam-blue-inverse')}
                                                onClick={() => { this.setState({ classType: 'upcoming' }) }}
                                            > Upcoming </span>
                                        </div>
                                    }
                                    {(this.props.is_create_mode || this.props.is_group_mode) &&
                                        <div className="col-md-2 text-right mb-2">
                                            <span
                                                className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'past' ? 'btn-purple' : 'btn-purple-inverse')}
                                                onClick={() => { this.setState({ classType: 'past' }) }}
                                            > Past </span>
                                        </div>
                                    }
                                    {this.props.is_auth && (this.props.is_create_mode || this.props.is_group_mode) &&
                                        <div className="col-md-2 text-right mb-2">
                                            <span
                                                className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'unscheduled' ? 'btn-darkblue white-txt' : 'btn-darkblue-inverse')}
                                                onClick={() => { this.setState({ classType: 'unscheduled' }) }}
                                            >  Templates </span>
                                        </div>
                                    }
                                    {!this.state.condition.toLowerCase().includes('research') &&
                                        <div className="offset-9 col-md-3 text-right p-0 mb-2">
                                            {((typeof Cookies.get('is_toggled') == 'undefined' && !this.state.is_toggled) || Cookies.get('is_toggled') == 'false') &&
                                                <span
                                                    className="btn btn-orange-inverse button-filter close-btn  font-medium font-14 pointer"
                                                    onClick={() => { this.toggleClasses(true) }}
                                                >
                                                    Show all
                                                </span>
                                            }
                                            {((typeof Cookies.get('is_toggled') == 'undefined' && this.state.is_toggled) || Cookies.get('is_toggled') == 'true') &&

                                                <span
                                                    className="btn btn-orange pointer w-100"
                                                    onClick={() => { this.toggleClasses(false) }}
                                                >
                                                    Show waking hours only
                                                </span>
                                            }
                                        </div>
                                    }
                                    {(this.props.is_create_mode || this.props.is_group_mode) &&
                                        <div className="col-md-12">
                                            <p className="w-40 font-16 font-semibold black-txt text-center">All class times shown in {" " + commonService.localTimeZoneName()} time</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            {this.renderLiveClassComponent()}
                            <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        is_create_mode: state.header.is_create_mode,
        is_group_mode: state.header.is_group_mode,
        user_data: state.header.logged_userData,
    };
};

const mapDispatchToProps = {
    fetchGroupWorkout, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass, pushHistory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupWorkoutSchedule);
