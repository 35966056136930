import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  liveclassDetail,
  ondemandDetail,
  hasMembership,
  scheduleModelOpen,
  startVideo,
  saveTimeSpent,
  updateAfterModelState,
  setGoback,
  start_loader,
  stop_loader,
  joinClass,
  loginModelOpen,
  cancelClass,
  pushHistory,
  registerformUpdate,
  updateMyConditions,
  setConditionIndex,
  updateUserdataRedex,
  changePlan,
  getPlanByCountry
} from "../../actions";
import { commonService } from "../../_services";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import * as Errors from "../../Errors";
import WorkoutScheduleComponent from "../WorkoutsPage/WorkoutScheduleComponent";
import WorkoutSaveComponent from "../WorkoutsPage/workoutSaveComponent";
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";
import { ImageTag } from "../../tags";
import StarRatings from "react-star-ratings";
import JoinClassComponent from "./LiveClassJoinModel";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { Cookies } from "react-cookie-consent";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  LineShareButton,
  ViberShareButton,
  TumblrShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
  TumblrIcon,
  ViberIcon,
  LineIcon,
  TelegramIcon,
  RedditIcon
} from "react-share";
import Slider from "react-slick";
import moment from 'moment';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import WarningModal from "../Common/WarningModal";
import _ from 'lodash';

class LiveClassDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelState: false,
      modelData: {},
      Loading: false,
      play_loader: false,
      share_enable: false,
      liveclass_detail_data: {},
      room_details: {},
      likeMore: {},
      nextliveclass_details: {},
      ondemand_paging: [],
      show_more_cnt: constand.INSTRUCTOR_ONDEMAND_CNT,
      enable_video: false,
      expanded: false,
      currentVideoTime: 0,
      current_attendee: {},
      openModel: false,
      openAfterModel: false,
      totalRating: 0,
      reviewLimit: true,
      all_tags: [],
      all_levels: [],
      openResearchModel: false,
      current_attendee: {},
      redirectUrl: '',
      workoutId: '',
      condition: this.props.match.params.condition,
      currentClass: {}
    };
    this.props.history.listen((location, action) => {
      this.clearDatas();
    });
    this.clearDatas = this.clearDatas.bind(this);
    this.getLiveClassDetail = this.getLiveClassDetail.bind(this);
    this.updateSchedule = this.updateSchedule.bind(this);
    this.getMoreTextDiv = this.getMoreTextDiv.bind(this);
    this.startVideo = this.startVideo.bind(this);
    this.saveTimeSpent = this.saveTimeSpent.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.beforeReview = this.beforeReview.bind(this);
    this.closeVideoModel = this.closeVideoModel.bind(this);
    this.closeAfterModel = this.closeAfterModel.bind(this);
    this.submitStartNow = this.submitStartNow.bind(this);
    this.joinClassService = this.joinClassService.bind(this);
    this.modelClose = this.modelClose.bind(this);
    this.goback = this.goback.bind(this);
    this.closeResearchModel = this.closeResearchModel.bind(this);
    this.viewPrepoll = this.viewPrepoll.bind(this);
    this.authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

  }
  componentDidMount() {
    console.log('prevPath-history', this.props.prevPath)
    console.log('props', this.props)
    console.log('props-group', localStorage.getItem('group'))
    window.scrollTo(0, 0);
    document.addEventListener("contextmenu", this._handleContextMenu);
    this.enable_video = false;
    this.getLiveClassDetail();
    // setInterval(async () => {
    //   if(this.state.enable_video === true && this.player)
    //   {
    //   }
    // }, constand.ASYNIC_API_CALL_DURAION);
  }

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    this.setState({
      Loading: false,
      share_enable: false,
      liveclass_detail_data: {},
      room_details: {},
      likeMore: {},
      nextliveclass_details: {},
      ondemand_paging: [],
      show_more_cnt: constand.INSTRUCTOR_ONDEMAND_CNT,
      enable_video: false,
      current_attendee: {}
    });
  }
  clearDatas() {
    window.scrollTo(0, 0);
    this.setState(
      {
        Loading: false,
        share_enable: false,
        liveclass_detail_data: {},
        room_details: {},
        likeMore: {},
        nextliveclass_details: {},
        ondemand_paging: [],
        show_more_cnt: constand.INSTRUCTOR_ONDEMAND_CNT,
        enable_video: false,
        expanded: false,
        currentVideoTime: 0,
        current_attendee: {}
      },
      function () {
        this.getLiveClassDetail();
      }
    );
  }

  closeResearchModel() {
    this.setState({ openResearchModel: false });
  }

  beforeReview() {
    window.open(this.state.redirectUrl);
  }

  viewPrepoll(live) {
    live.Attendees.forEach((item) => {
      if (item.UserId === this.props.logged_userData.id) {
        this.setState({ redirectUrl: item.reference, current_attendee: item });
      }
    })
    this.setState({ openResearchModel: true, workoutId: live.WorkoutId })
  }

  _handleContextMenu = event => {
    event.preventDefault();
  };
  getLiveClassDetail() {
    this.setState({ Loading: true });
    //Cookies.get('workoutId')
    var groupId = localStorage.getItem('groupId') != 'undefined' ? localStorage.getItem('groupId') : null;
    this.props.liveclassDetail(this.props.match.params.id, this.props.match.params.condition, null, (!groupId || groupId === 'undefined' ? null : groupId)).then(
      response => {
        console.log('response', this.props.liveclass_detail_data)
        if (response) {
          this.setState({
            Loading: false,
            liveclass_detail_data: this.props.liveclass_detail_data.liveClasses.liveclass,
            room_details: this.props.liveclass_detail_data.liveClasses.liveclass ? (this.props.liveclass_detail_data.liveClasses.liveclass.Rooms.length > 0 ? this.props.liveclass_detail_data.liveClasses.liveclass.Rooms[0] : {}) : {},
            likeMore: this.props.liveclass_detail_data.liveClasses.likeMore,
            //nextliveclass_details: this.props.liveclass_detail_data.liveClasses.nextClass,
            all_tags: (response.list.tags) ? response.list.tags : [],
            all_levels: (response.list.levelTags) ? response.list.levelTags : [],

          });

          //private group live class access validation 
          this.checkPrivateLiveclassAccess()

          if (this.props.liveclass_detail_data.liveClasses.liveclass.description) {
            this.setState({ Tempdescription: (this.props.liveclass_detail_data.liveClasses.liveclass.description).substring(0, 500) })
            console.log('length', this.props.liveclass_detail_data.liveClasses.liveclass.description.length)
          }

        }
      },
      error => {
        this.setState({
          Loading: false,
          liveclass_detail_data: [],
          room_details: []
        });
        toast.error(error);
      }
    );
  }
  
  checkPrivateLiveclassAccess = () => {
    if (Object.keys(this.state.liveclass_detail_data).length > 0 &&this.state.liveclass_detail_data.Community && (Object.keys(this.state.liveclass_detail_data.Community).length > 0 )  && this.state.liveclass_detail_data.Community.type == "closed" && this.props.logged_userData.Members) {
      let checkLiveClassAccess = commonService.checkPrivateGroupAccess(this.state.liveclass_detail_data.CommunityId, this.props.logged_userData.Members)
      if (!checkLiveClassAccess.length > 0) {
        Cookies.set('condition', this.props.condition)
        const { from } = { from: { pathname: "/home" } };
        this.props.history.push(from);
        toast.error("Oops! Your not a member in this group");
      }
    }
  }

  calculateTotalRating(reviewList = []) {
    var returnValue = 0;
    if (reviewList) {
      for (var i = 0; i < reviewList.length; i++) {
        if (reviewList[i].rating && reviewList[i].rating > constand.CONSTZERO) {
          returnValue = returnValue + parseInt(reviewList[i].rating);
        }
      }
      if (returnValue > 0) {
        returnValue = returnValue / reviewList.length;
      }
    }
    return returnValue;
  }
  startVideo() {
    //Backend entry for start the video
    if (!this.state.play_loader) {
      this.setState({ play_loader: true });
      this.props.startVideo({ workoutId: this.props.match.params.id }).then(
        response => {
          if (response) {
            this.setState({
              play_loader: false,
              openModel: true,
              current_attendee: response.attendee ? response.attendee : {}
            });
          }
        },
        error => {
          this.setState({ play_loader: false, openModel: false, current_attendee: {} });
          toast.error(error);
        }
      );
    }
  }
  closeModel() {
    this.setState({ openModel: false, isConditionModalOpen: false, isWarningModal: false, isUpgradeModalOpen: false });
  }
  closeVideoModel(time, show_after_review) {
    this.saveTimeSpent(time, show_after_review);
    this.setState({ enable_video: false });
  }
  closeAfterModel() {
    this.props.updateAfterModelState({ step: 1 });
    this.setState({ openAfterModel: false });
  }
  // beforeReview() {
  //   this.setState({ openModel: false, enable_video: true });
  // this.props.startVideo({ workoutId: this.props.match.params.id }).then(
  //   response => {
  //     if (response) {
  //       this.setState({
  //         enable_video: true,
  //         current_attendee: response.attendee ? response.attendee : {}
  //       });
  //     }
  //   },
  //   error => {
  //     this.setState({ enable_video: false, current_attendee: {} });
  //     toast.error(error);
  //   }
  // );
  // }
  saveTimeSpent(time, show_after_review) {
    //Video watching time update
    if (this.state.current_attendee.RoomId) {
      var dataObj = {
        roomId: this.state.current_attendee.RoomId,
        time: time
      };
      if (time && time > 0) {
        this.props.saveTimeSpent(dataObj).then(
          response => {
            this.setState({ openAfterModel: show_after_review });
          },
          error => {
            toast.error(error);
          }
        );
      }
    }
  }
  hasMembership(item, isGoToClass = false) {

    if (this.props.is_auth) {

      var hasMembership = commonService.checkUserHasMembership(this.authData, this.state.condition);
      console.log('hasMembership-detail', hasMembership)
      if (this.state.condition.toLowerCase().includes('research')) {
        //RS
        if (hasMembership != 1) {
          this.setState({ enable_video: false, isWarningModal: true });
        } else {
          this.setState({ enable_video: false, isWarningModal: false, isGoToClass: isGoToClass, currentClass: item, }, function () {
            this.afterUpgrade();
          });
        }
      } else {
        //core

        var me = this;
        console.log('healthcondition_list', this.props.healthcondition_list)
        var selectedHealth_condition = _.filter(this.props.healthcondition_list, function (list) {
          return list.tag === (commonService.replaceChar(me.state.condition, true));
        });
        var currentPlan = commonService.returnCurrentPlan(this.authData, this.state.condition);

        console.log('selectedHealth_condition', selectedHealth_condition)
        var health_conditionId = selectedHealth_condition[0].id;
        this.props.registerFormvalues.health_condition = selectedHealth_condition;
        this.setState({ hasMembership: hasMembership, health_conditionId: health_conditionId, currentPlanId: currentPlan.length ? currentPlan[0].id : 0, currentClass: item, isGoToClass: isGoToClass })
        if (!item.isFree) {
          switch (hasMembership) {
            case 0:
              //toast.info(Errors.membership_playvideo);
              this.props.setConditionIndex(0);
              this.props.registerformUpdate(this.props.registerFormvalues)
              this.setState({ enable_video: false, isWarningModal: true });
              break;
            case 1:
              //this.startVideo();
              this.setState({ isConditionModalOpen: false, isWarningModal: false }, function () {
                this.joinClassService();
              });
              break;
            case 2:
              this.props.setConditionIndex(0);
              this.props.registerformUpdate(this.props.registerFormvalues)
              this.setState({ enable_video: false, isWarningModal: true });
              break;
          }
        } else {
          this.setState({ isConditionModalOpen: false, isWarningModal: false }, function () {
            this.joinClassService();
          });
        }
      }
    } else {
      this.props.loginModelOpen(true);
    }
  }
  updateSchedule(scheduleData) {
    var onDemandDetail = this.state.room_details;
    onDemandDetail.scheduleData = scheduleData;
    this.setState({ room_details: onDemandDetail });
  }

  goback() {
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
    if (Object.keys(authData).length) {
      var community = authData.Members.length ? authData.Members[0].Community.community_name : '';

      if (localStorage.getItem('group') && localStorage.getItem('group') != 'undefined') {
        //window.location.href = '/group/liveclasses/list/' + (localStorage.getItem('group')) + '/' + ((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition).replace(/ /g, '-'));
        const { from } = { from: { pathname: '/group/liveclasses/list/' + (localStorage.getItem('group')) + '/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false)) } };
        this.props.history.push(from);
        localStorage.removeItem('group');
        localStorage.removeItem('groupId');
      } else if (this.state.liveclass_detail_data.CommunityId) {
        var pathname = '/group/liveclasses/list/' + this.state.liveclass_detail_data.Community.community_name + '/' + this.props.match.params.condition;

        if (this.state.liveclass_detail_data.isLive) {
          pathname = '/on-demand/' + this.props.match.params.condition;
        }

        const { from } = { from: { pathname: pathname } };
        this.props.history.push(from);
      } else if (this.props.match.params.condition.toLowerCase().includes('research') && community) {
        //window.location.href = '/group/liveclasses/list/' + community + '/' + this.props.match.params.condition;
        const { from } = { from: { pathname: '/group/liveclasses/list/' + community + '/' + this.props.match.params.condition } };
        // this.props.history.push(from);
        //const { from } = { from: { pathname: '/liveClasses/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false))} };
        this.props.history.push(from);

      }
      else {
        var pathname = '/liveClasses/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false));

        if (this.state.liveclass_detail_data.isLive) {
          pathname = '/on-demand/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false));
        }

        const { from } = { from: { pathname: pathname } };
        this.props.history.push(from);
      }
    } else {
      if (localStorage.getItem('group') && localStorage.getItem('group') != 'undefined') {
        //window.location.href = '/group/liveclasses/list/' + (localStorage.getItem('group')) + '/' + ((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition).replace(/ /g, '-'));
        const { from } = { from: { pathname: '/group/liveclasses/list/' + (localStorage.getItem('group')) + '/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false)) } };
        this.props.history.push(from);
        localStorage.removeItem('group');
        localStorage.removeItem('groupId');
      } else if (this.state.liveclass_detail_data.CommunityId) {
        //window.location.href = '/group/liveclasses/list/' + this.state.liveclass_detail_data.Community.community_name + '/' + this.props.match.params.condition;
        const { from } = { from: { pathname: '/group/liveclasses/list/' + this.state.liveclass_detail_data.Community.community_name + '/' + this.props.match.params.condition } };
        this.props.history.push(from);
      }
      else {
        var pathname = '/liveClasses/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false));

        if (this.state.liveclass_detail_data.isLive) {
          pathname = '/on-demand/' + (commonService.replaceChar((typeof Cookies.get('condition') != 'undefined' ? Cookies.get('condition') : this.props.match.params.condition), false));
        }

        const { from } = { from: { pathname: pathname } };
        this.props.history.push(from);

      }
    }

  }

  getMoreTextDiv(expanded) {
    if (expanded) {
      this.setState({ expanded: expanded, Tempdescription: this.props.liveclass_detail_data.liveClasses.liveclass.description })
    } else {
      this.setState({ expanded: expanded, Tempdescription: (this.props.liveclass_detail_data.liveClasses.liveclass.description).substring(0, 500) })
    }

  }

  videoSection(div_class, type) {

    const liveclass_detail = this.state.liveclass_detail_data
      ? this.state.liveclass_detail_data
      : {};
    const ondemand_view_detail =
      Object.keys(liveclass_detail).length > 0 &&
        liveclass_detail.constructor === Object
        ? liveclass_detail
        : {};
    console.log('ondemand_view_detail', ondemand_view_detail)

    if (type == 'desktop') {
      return (<div className={div_class}>
        {ondemand_view_detail.id && (
          <ImageTag
            src={
              constand.WORKOUT_IMG_PATH +
              ondemand_view_detail.id +
              "-img.png"
            }
            className="img-fluid img-responsive"
            width="100%"
          />
        )}
        {!ondemand_view_detail.id && (
          <img
            src={constand.WEB_IMAGES + "no-image.png"}
            className="img-fluid img-responsive"
            width="100%"
          />
        )}

      </div>);
    } else {
      //mobile view
      return (<div className={div_class}>
        {ondemand_view_detail.id && (
          <ImageTag
            src={
              constand.WORKOUT_IMG_PATH +
              ondemand_view_detail.id +
              "-img.png"
            }
            className=" img-responsive"
            width="100%" height="200px"
          />
        )}
        {!ondemand_view_detail.id && (
          <img
            src={constand.WEB_IMAGES + "no-image.png"}
            className=" img-responsive"
            width="100%" height="200px"
          />
        )}

      </div>);
    }
  }
  submitStartNow(item) {
    if (this.props.is_auth) {
      this.hasMembership(item);
      //this.joinClassService(item);
    } else {
      this.props.loginModelOpen(true);
    }
  }
  //cancel class
  cancelCalss(item, type) {
    console.log('item', item)
    if (!this.state.cancelLoading) {
      this.setState({ cancelLoading: true });
      var dataObj = {
        "roomId": item.id
      };
      this.props.cancelClass(dataObj).then(
        response => {
          if (response) {
            toast.success(response.message);
          }

          //if (type) {
          //live
          const newItems = Object.assign({}, this.state.room_details);
          newItems.Signedup = false;

          this.setState({
            room_details: newItems,
            cancelLoading: false
          });
          /* } else {
            //ondemand
            const newItems = Object.assign({}, this.state.nextliveclass_details);
            newItems.Signedup = false;

            this.setState({
              nextliveclass_details: newItems,
              cancelLoading: false
            });
          } */


        },
        error => {
          this.setState({
            cancelLoading: false
          });
        }
      );
    }
  }
  /**
       *
       * Signup new class
       */
  joinClassService() {
    var item = this.state.currentClass;
    item.Workout = this.state.liveclass_detail_data  //forming data for join model
    var condition = commonService.replaceChar(this.props.match.params.condition, true);
    var dataObj = { "roomId": item.id, "condition": condition };
    this.setState({ signupLoader: true });
    //this.props.start_loader();
    this.props.joinClass(dataObj).then(
      response => {
        //this.props.stop_loader();
        if (response) {
          this.setState({
            signupLoader: false,
            modelState: true,
            modelData: item
          });
          ReactGA.event({
            category: "Live Class",
            action: "Signed Up - Detail Page",
            label: Cookies.get('condition') + '-' + this.state.liveclass_detail_data.title + '-' + this.state.liveclass_detail_data.id
          })
        }
      },
      error => {
        //this.props.stop_loader();
        this.setState({
          signupLoader: false,
          modelState: false,
          modelData: {}
        });
        toast.error(error);
      }
    );
  }
  modelClose() {
    this.setState({
      Loading: false,
      offset: 0,
      count: 0,
      show_more_cnt: constand.CLASS_LIST_CNT,
      liveClassList: [],
      displayLayout: true,
      // type: this.props.params.type,
      modelState: false,
      modelData: {}
    }, function () { this.getLiveClassDetail(); });
  }
  afterUpgrade = () => {
    if (this.state.isGoToClass) {
      //go to class
      window.open(this.state.currentClass.Attendees[0].reference);
    } else {
      //sign up class
      this.joinClassService();
    }
  }
  renderButtons(liveclass_detail) {
    console.log('nextliveclass', liveclass_detail)
    console.log('nextliveclass-DATE', new Date())
    var scheduleTime = new Date(liveclass_detail.scheduledFor).getTime();
    var currentTime = new Date().getTime();
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
    var communityDetails = '';
    if (Object.keys(authData).length) {
      communityDetails = authData.Members.length ? authData.Members[0].Community : '';
    }
    var now = new Date();
    var isShowPoll = false;
    if (communityDetails && communityDetails.endDate) {
      var endDate = moment(communityDetails.endDate).utc().format('YYYY-MM-DD');
      var endDated = new Date(endDate);
      if (endDated.getTime() < now.getTime()) {
        isShowPoll = false;
      } else {
        isShowPoll = true;
      }
    } else {
      isShowPoll = true;
    }

    var isExceedCutOffTime = false;
    if (liveclass_detail.scheduledFor) {
      var calcCutOffTime = new Date(moment(liveclass_detail.scheduledFor).subtract(this.state.liveclass_detail_data.cutoffTime, "minutes").format('YYYY-MM-DD HH:mm:ss'));
      if (now > calcCutOffTime) {
        isExceedCutOffTime = true;
      } else {
        isExceedCutOffTime = false;
      }
    }

    if (liveclass_detail && !liveclass_detail.Signedup) {
      if (isExceedCutOffTime) {
        return (
          <button disabled={true} className="btn btn-gray w-100 font-medium m-b-10 pad_list_btn" > {"Registration ended"} </button>
        );
      }
      return (
        <div className="w-100 m-b-20">
          <button disabled={this.state.signupLoader} onClick={() => this.submitStartNow(liveclass_detail)} className="btn btn-purple-inverse pad_list_btn clearfix pointer" > Sign up </button>

          {/* <button disabled={true} className="btn btn-secondary joinclass-btn pad_list_btn clearfix" > Go to room </button> */}
        </div>
      );
    } else {
      var tagCondition = commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();
      return (
        <div className="w-100 m-b-20">

          {liveclass_detail.type == 'Live' && scheduleTime > currentTime && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition) && (!isShowPoll) &&
            /* <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={liveclass_detail.Attendees[0].reference || '#'} target="_blank" > Go to class ss</a> */
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.hasMembership(liveclass_detail, true)}> Go to class </a>
          }
          {liveclass_detail.type == 'Live' && scheduleTime > currentTime && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition) && isShowPoll &&
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.viewPrepoll(liveclass_detail)} > Go to class </a>
          }
          {liveclass_detail.type == 'Live' && scheduleTime > currentTime && this.props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition) &&
            //<a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={liveclass_detail.Attendees[0].reference || '#'} target="_blank"> Go to class </a>
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.hasMembership(liveclass_detail, true)}> Go to class </a>
          }
          {/* core condition with membership check*/}
          {liveclass_detail.type == 'Live' && scheduleTime > currentTime && !this.props.logged_userData.isStudyParticipant &&
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.hasMembership(liveclass_detail, true)} > Go to class </a>
            // <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={liveclass_detail.Attendees[0].reference || '#'}> Go to class </a>
          }
          {liveclass_detail.type != 'Live' && scheduleTime > currentTime &&
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={"/detail/" + this.props.match.params.id + "/" + (this.props.match.params.condition).replace(/ /g, '-')} target="_blank"> Start now </a>
          }
          {liveclass_detail.type != 'Live' && scheduleTime < currentTime &&
            <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={"/detail/" + this.props.match.params.id + "/" + (this.props.match.params.condition).replace(/ /g, '-')} target="_blank"> Do Again </a>
          }
          {scheduleTime > currentTime &&
            <a href="javascript:void(0)"
              className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(liveclass_detail)}
            > Oops I can't make it</a>
          }
        </div>
      )
    }
  }
  render() {
    const liveclass_detail = this.state.liveclass_detail_data
      ? this.state.liveclass_detail_data
      : {};
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    let ondemand_view_detail =
      Object.keys(liveclass_detail).length > 0 &&
        liveclass_detail.constructor === Object
        ? liveclass_detail
        : {};
    const shareUrl = window.location.href;//ondemand_view_detail.videoUrl;
    const title = ondemand_view_detail.title;
    const prev_state =
      this.state.room_details && this.state.room_details.isSaved
        ? this.state.room_details.isSaved
        : false;

    const instructorName = ondemand_view_detail.Instructor ? ondemand_view_detail.Instructor.User.name + ' ' + ondemand_view_detail.Instructor.User.lastName : '';
    const classThumb = constand.WORKOUT_IMG_PATH + ondemand_view_detail.id + "-img.png";
    var videoLength = this.state.liveclass_detail_data && this.state.liveclass_detail_data ? this.state.liveclass_detail_data.length : 0;
    if (ondemand_view_detail.description)
      console.log('length', ondemand_view_detail.description.length)

    console.log('nextliveclass_details', this.state.liveclass_detail_data)
    let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
    var community = '';
    var communityDetails = '';
    if (Object.keys(authData).length) {
      community = authData.Members.length ? authData.Members[0].Community.community_name : '';
      communityDetails = authData.Members.length ? authData.Members[0].Community : '';
    }

    return (
      <React.Fragment>
        <ResearchStudyPollPopup
          is_model_open={this.state.openResearchModel}
          closeResearchModel={this.closeResearchModel}
          classType="Live"
          beforeReview={this.beforeReview}
          workoutId={this.state.workoutId}
          current_attendee={this.state.current_attendee}
        />
        <section className="demand-page m-t-100">
          <Helmet>
            <title>{title ? title : ''}{constand.ONDEMAND_DETAIL_TITLE}{constand.BEAM}</title>
            <meta property="og:title" content={title ? title : '' + constand.ONDEMAND_DETAIL_TITLE + constand.BEAM} />
            <meta property="og:description" content={ondemand_view_detail.description} />
            <meta property="og:image" content={classThumb} />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:site_name" content="Beam" />
            <meta name="twitter:image:alt" content={title + ' by ' + instructorName} />
          </Helmet>
          {/* mobile view */}
          {!this.state.Loading &&
            <div className="demand-section-mobile">
              <div className="container-fluid">
                <div className="demand-title">
                  <div className="row">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.setGoback(true);
                        this.goback()
                      }}
                      className="font-15 font-book m-b-10 font-normal black-txt p-l-10 p-r-0"
                    >
                      {" "}
                      <i className="fa fa-chevron-left"></i>
                      <span className="font-21 black-txt font-medium capitalize_text p-l-5 col-11">
                        {!liveclass_detail.isLive ? "Back to live class schedule" : "Back to On-demand Classes"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {this.videoSection('row demand-img', 'mobile')}
              <div className="container-fluid video-detail-bg">
                <div className="col-md-12">
                  <div className="row p-10">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-9 video-details-left pl-0 p-r-60">
                      <div className="w-100">
                        {/* <div className="font-semibold font-15 black-txt">
                        Duration: {ondemand_view_detail.length} minutes
                      </div> */}
                        <div className="font-semibold font-15 black-txt w-100">
                          <div>
                            Activity:
                            <span className="font-semibold font-15 black-txt m-l-5 capitalize_text">
                              {commonService.returnLabelData(
                                "discipline",
                                ondemand_view_detail.WorkoutTags
                                  ? ondemand_view_detail.WorkoutTags
                                  : []
                              )}
                            </span>
                          </div>

                          <div>
                            Ideal for:
                            <span className="font-semibold font-15 black-txt m-l-5 capitalize_text">
                              {commonService.returnLabelData(
                                "level",
                                ondemand_view_detail.WorkoutTags
                                  ? ondemand_view_detail.WorkoutTags
                                  : []
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4 col-3 pr-0 video-details-right">
                      <div className="bookmark-left m-l-20 float-right">
                        <span
                          className="pointer"
                          onClick={() =>
                            this.setState({
                              share_enable: !this.state.share_enable
                            })
                          }
                        >
                          <em className="fa fa-share pointer"></em>
                          <span className="font-qmedium font-13 black-txt m-l-10">
                            Share
                          </span>
                        </span>
                        {this.state.share_enable && (
                          <div className="position-absolute zindex">
                            <span>
                              <EmailShareButton
                                url={shareUrl}
                                subject={title}
                                body="body"
                                className="pointer"
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </span>
                            <span>
                              <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                className="pointer"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </span>
                            <span>
                              <WhatsappShareButton
                                url={shareUrl}
                                title={title}
                                separator=":: "
                                className="pointer"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </span>
                            <span>
                              <TwitterShareButton
                                url={shareUrl}
                                title={title}
                                className="pointer"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                            </span>
                            <span>
                              <LinkedinShareButton
                                url={shareUrl}
                                windowWidth={750}
                                windowHeight={600}
                                className="pointer"
                              >
                                <LinkedinIcon size={32} round />
                              </LinkedinShareButton>
                            </span>
                          </div>
                        )}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="w-100 on-demand-filter-sm-value" id="accordion">
                  {/* Next class mobile*/
                    Object.keys(this.state.room_details).length != 0 &&
                    <div className="card">
                      <div className="card-header" id="heading-4">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href="#collapse-4"
                            aria-expanded="true"
                            aria-controls="collapse-4"
                          >
                            Next Class{" "}
                            <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-4"
                        className="collapse show"
                        data-parent="#accordion"
                        aria-labelledby="heading-4"
                      >
                        <div className="w-100 m-t-15">
                          <div className="card border-0 flex-row flex-wrap">
                            <div className="card-header p-0 rounded-circle border-0">
                              <h3 className="black-txt font-20 font-qregular">{moment(this.state.room_details.scheduledFor).format('dddd Do MMMM YYYY')}</h3>
                              <h3 className="black-txt font-20 font-qregular">
                                {moment(this.state.room_details.scheduledFor).format('hh:mm a') + ' - ' +
                                  moment(this.state.room_details.scheduledFor)
                                    .add(videoLength, 'minutes')
                                    .format('hh:mm a')}</h3>
                            </div>
                            <div className="card-block px-2 w-100">
                              <div className="card-text redtext text-underline">
                                <div className="col-md-3 col-lg-3 col-sm-12">
                                  {this.state.room_details.id && this.renderButtons(this.state.room_details)}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {/* Next class  */
                    Object.keys(this.state.room_details).length == 0 &&
                    <div className="card">
                      <div className="card-header" id="heading-4">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href="#collapse-4"
                            aria-expanded="true"
                            aria-controls="collapse-4"
                          >
                            Next Class{" "}
                            <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-4"
                        className="collapse show"
                        data-parent="#accordion"
                        aria-labelledby="heading-4"
                      >
                        <div className="w-100 m-t-15">
                          <div className="card border-0 flex-row flex-wrap">
                            <div className="card-header p-0 rounded-circle border-0">
                              {authData && !authData.isStudyParticipant && !authData.isStudyLeader && !authData.isGroupLeader &&
                                <p className=" font-qregular font-14 black-txt m-0 instruct_details overflow-hidden text-left text-abbey font-21 font-regular no-nextclass">
                                  This Live Class is no longer on the schedule!  Please have a peek at the <Link to={"/liveClasses/" + this.props.match.params.condition} className="flow-text">current live class schedule</Link> to find a class that suits you and get signed up.  If you would like to request that this class or another be added back to the rotation, please <Link to="/contact-us" className="flow-text">contact us</Link> to let us know.
                                </p>
                              }
                              {authData && authData.isStudyParticipant &&
                                <p className=" font-qregular font-14 black-txt m-0 instruct_details overflow-hidden text-left text-abbey font-21 font-regular no-nextclass">
                                  This Live Class is no longer on the schedule! Please have a peek at <Link to={"/group/ondemand/list/" + community + '/' + this.props.match.params.condition} className="flow-text">classes that are available on demand.</Link>
                                </p>}
                              {authData && (authData.isStudyLeader || authData.isGroupLeader) &&
                                <p className=" font-qregular font-14 black-txt m-0 instruct_details overflow-hidden text-left text-abbey font-21 font-regular no-nextclass">
                                  This Live Class is no longer on the schedule. Please have a peek at the <Link to={"/liveClasses/" + this.props.match.params.condition} className="flow-text">current live class schedule</Link> to schedule more.
                                </p>
                              }
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="card">
                    <div className="card-header" id="heading-1">
                      <h5 className="mb-0">
                        <a
                          role="button"
                          data-toggle="collapse"
                          href="#collapse-1"
                          aria-expanded="false"
                          aria-controls="collapse-1"
                          className="collapsed"
                        >
                          Description
                          <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapse-1"
                      className="collapse"
                      data-parent="#accordion"
                      aria-labelledby="heading-1"
                    >
                      <div className="card-body">
                        <div id="accordion-1">
                          <div className="w-100 m-t-20">
                            <p
                              className="font-qregular font-14 black-txt m-0 instruct_details overflow-hidden text-left"
                              dangerouslySetInnerHTML={{
                                __html: ondemand_view_detail.description
                              }}
                            ></p>
                            {/*<div className="w-100 position-relative text-center ">
                            {ondemand_view_detail.Instructor && (
                              <Link
                                to={
                                  "/instructor/" +
                                  ondemand_view_detail.Instructor.id
                                }
                                className="redtext text-underline font-qregular font-14"
                              >
                                Readmore
                              </Link>
                            )}
                          </div>*/}
                          </div>
                          {commonService.returnTagName(
                            "condition", constand.CONDITION,
                            ondemand_view_detail.WorkoutTags
                              ? ondemand_view_detail.WorkoutTags
                              : []
                          ) && (commonService.replaceChar(this.props.match.params.condition, true) == constand.CONDITION) &&
                            <div className="w-100 m-t-20 summary_div">
                              <h3 className="heading_3 font-22 black-txt font-medium">
                                Head's Up!
                              </h3>
                              <p
                                className={`font-qregular font-14 black-txt m-0 `}
                                id="instructor_details"

                              >We hope you enjoy this and all our sessions on Beam, but please remember that we are not able to offer you personalised exercise sessions programmes and that participating in any live or on-demand Beam sessions is at your own risk. Changes to your airway clearance and exercise regime should be discussed with your clinical team.</p>


                            </div>
                          }
                          {commonService.returnTagName(
                            "condition", constand.KR_CONDITION,
                            ondemand_view_detail.WorkoutTags
                              ? ondemand_view_detail.WorkoutTags
                              : []
                          ) && (commonService.replaceChar(this.props.match.params.condition, true) == constand.KR_CONDITION) &&
                            <div className="w-100 m-t-20 summary_div">
                              <h3 className="heading_3 font-22 black-txt font-medium">
                                Head's Up!
                              </h3>
                              <p
                                className={`font-qregular font-14 black-txt m-0 `}
                                id="instructor_details"

                              >We hope you enjoy this and all our sessions on Beam, but please remember that we are not able to offer you personalised exercise programmes and that participating in any live or on-demand Beam sessions is at your own risk. </p>
                            </div>
                          }
                          <div className="w-100 m-t-20 summary_div">
                            <p
                              className="font-qregular font-14 black-txt m-0"
                              id="instructor_details"
                            >Here is some <Link to={'/blogs/safety-info-' + this.props.match.params.condition.replace(/ /g, '-')}>important safety information</Link> to make sure you keep safe and feel your best before and after your class.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" id="heading-7">
                      <h5 className="mb-0">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href="#collapse-7"
                          aria-expanded="false"
                          aria-controls="collapse-7"
                        >
                          Instructor{" "}
                          <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapse-7"
                      className="collapse"
                      data-parent="#accordion"
                      aria-labelledby="heading-7"
                    >
                      <div className="w-100 m-t-15">
                        <div className="card border-0 flex-row flex-wrap">
                          <div className="card-header p-0 rounded-circle border-0">

                            <img
                              src={
                                ondemand_view_detail.Instructor &&
                                  ondemand_view_detail.Instructor.img
                                  ? constand.USER_IMAGE_PATH +
                                  ondemand_view_detail.Instructor.img
                                  : constand.WEB_IMAGES + "no-image.png"
                              }
                              alt="NoImage"
                              className="img-fluid rounded-circle"
                            />
                          </div>
                          <div className="card-block px-2">
                            {ondemand_view_detail.Instructor &&
                              ondemand_view_detail.Instructor.User && ondemand_view_detail.Instructor.hasProfile && (
                                <Link
                                  className="orangefont font-14 font-book"
                                  to={
                                    "/instructor/" +
                                    ondemand_view_detail.InstructorId + '/' + this.props.match.params.condition
                                  }
                                >
                                  <h4 className="card-title font-15 black-txt font-semibold m-t-10">
                                    {ondemand_view_detail.Instructor.User.name +
                                      " " +
                                      ondemand_view_detail.Instructor.User.lastName}
                                  </h4>
                                </Link>
                              )}
                            {ondemand_view_detail.Instructor &&
                              ondemand_view_detail.Instructor.User && !ondemand_view_detail.Instructor.hasProfile && (
                                <span
                                  className="orangefont font-14 font-book"

                                >
                                  <h4 className="card-title font-15 black-txt font-semibold m-t-10">
                                    {ondemand_view_detail.Instructor.User.name +
                                      " " +
                                      ondemand_view_detail.Instructor.User.lastName}
                                  </h4>
                                </span>
                              )}
                            {ondemand_view_detail.Instructor && ondemand_view_detail.Instructor.title &&
                              <div className="card-text font-qregular font-18">
                                {ondemand_view_detail.Instructor.title}
                              </div>
                            }
                            <div className="card-text redtext text-underline">
                              {ondemand_view_detail.Instructor && ondemand_view_detail.Instructor.hasProfile &&
                                <Link
                                  className="orangefont font-14 font-book"
                                  to={
                                    "/instructor/" +
                                    ondemand_view_detail.InstructorId + '/' + this.props.match.params.condition
                                  }
                                  onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                                >
                                  More videos with this instructor
                                </Link>
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* equipment require */}
                  <div className="card">
                    <div className="card-header" id="heading-2">
                      <h5 className="mb-0">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href="#collapse-2"
                          aria-expanded="false"
                          aria-controls="collapse-2"
                        >
                          Equipment required
                          <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapse-2"
                      className="collapse"
                      data-parent="#accordion"
                      aria-labelledby="heading-2"
                    >
                      <div className="w-100 m-t-20">
                        <p className="font-qregular font-14 m-0">
                          {commonService.returnLabelData(
                            "equipment",
                            ondemand_view_detail.WorkoutTags
                              ? ondemand_view_detail.WorkoutTags
                              : []
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* benefits */}
                  <div className="card">
                    <div className="card-header" id="heading-3">
                      <h5 className="mb-0">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          href="#collapse-3"
                          aria-expanded="false"
                          aria-controls="collapse-3"
                        >
                          Benefits
                          <i className="fa  fa-2x pull-right small-arrow fa-angle-down"></i>
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapse-3"
                      className="collapse"
                      data-parent="#accordion"
                      aria-labelledby="heading-3"
                    >
                      <div className="w-100 m-t-20">
                        <p className="font-qregular font-14 m-0">
                          {commonService.returnLabelData(
                            "benefit",
                            ondemand_view_detail.WorkoutTags
                              ? ondemand_view_detail.WorkoutTags
                              : []
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {/* web view */}
          {!this.state.Loading &&
            <div className="container offset-lg-2 col-lg-8 offset-md-3 col-md-6 col-xs-10 col-10 mx-auto ">
              <div className="demand-section-1 m-l-30 m-r-30">
                <div className=" demand-back video-detail-desktop">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      this.props.setGoback(true);
                      this.goback()
                    }}
                    className="font-15 font-book m-b-10 font-normal black-txt capitalize_text"
                  >
                    {" "}
                    <i className="fa fa-chevron-left"></i> {!liveclass_detail.isLive ? "Back to Live Classes" : "Back to On-demand Classes"}
                  </a>
                </div>
                {this.videoSection('row demand-img video-detail-desktop', 'desktop')}
                <div className="row m-t-20 video-detail-desktop">
                  <div className="col-md-8 col-lg-7 col-sm-8 col-9 video-details-left pl-0 p-r-60">
                    <h3 className="font-30 black-txt font-book font-normal flow-text capitalize_text ">
                      {liveclass_detail ? liveclass_detail.title : ''}
                    </h3>
                  </div>
                  {/* <div className="col-md-4 col-lg-5 col-sm-4 col-3 pr-0 video-details-right">
                    <div className="bookmark-left m-l-20 float-right">
                      <span
                        className="pointer"
                        onClick={() =>
                          this.setState({
                            share_enable: !this.state.share_enable
                          })
                        }
                      >
                        <em className="fa fa-share pointer"></em>
                        <span className="font-qmedium font-13 black-txt m-l-10">
                          Share
                      </span>
                      </span>
                      {this.state.share_enable && (
                        <div className="position-absolute zindex">
                          <span>
                            <EmailShareButton
                              url={shareUrl}
                              subject={title}
                              body="body"
                              className="pointer"
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                          </span>
                          <span>
                            <FacebookShareButton
                              url={shareUrl}
                              quote={title}
                              className="pointer"
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </span>
                          <span>
                            <WhatsappShareButton
                              url={shareUrl}
                              title={title}
                              separator=":: "
                              className="pointer"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </span>
                          <span>
                            <TwitterShareButton
                              url={shareUrl}
                              title={title}
                              className="pointer"
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </span>
                          <span>
                            <LinkedinShareButton
                              url={shareUrl}
                              windowWidth={750}
                              windowHeight={600}
                              className="pointer"
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                          </span>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                {/* Next class */
                  Object.keys(this.state.room_details).length != 0 &&

                  <div className="row p-b-30 p-t-30 desktop-ondemand-view next-class">
                    <div className="col-md-8 col-lg-8 col-sm-8 pl-0">
                      <div className="w-100 m-b-20">
                        <h3 className="text-abbey font-21 font-semibold">
                          Next Class
                        </h3>


                        <h3 className="black-txt font-20 font-qregular">{moment(this.state.room_details.scheduledFor).format('dddd Do MMMM YYYY')}</h3>
                        <h3 className="black-txt font-20 font-qregular">
                          {moment(this.state.room_details.scheduledFor).format('hh:mm a') + ' - ' +
                            moment(this.state.room_details.scheduledFor)
                              .add(videoLength, 'minutes')
                              .format('hh:mm a')}</h3>
                        {ondemand_view_detail.cutoffTime != 0 &&
                          <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left p-0 m-t-10">
                            <span className="font-16 float-left font-medium " >
                              Please note that registration to this class ends 30 minutes prior to the class beginning
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                      {this.state.room_details.id && this.renderButtons(this.state.room_details)}
                    </div>
                  </div>
                }
                {Object.keys(this.state.room_details).length == 0 &&
                  <div className="row p-b-30 p-t-30 desktop-ondemand-view next-class">
                    <div className="w-100 m-b-20">
                      {authData && !authData.isStudyParticipant && !authData.isStudyLeader && !authData.isGroupLeader &&
                        <h3 className="text-abbey font-21 font-regular">
                          This Live Class is no longer on the schedule!  Please have a peek at the <Link to={"/liveClasses/" + this.props.match.params.condition} className="flow-text">current live class schedule</Link> to find a class that suits you and get signed up.  If you would like to request that this class or another be added back to the rotation, please <Link to="/contact-us" className="flow-text">contact us</Link> to let us know.
                        </h3>
                      }
                      {authData && authData.isStudyParticipant &&
                        <h3 className="text-abbey font-21 font-regular">
                          This Live Class is no longer on the schedule! Please have a peek at <Link to={"/group/ondemand/list/" + community + '/' + this.props.match.params.condition} className="flow-text">classes that are available on demand.</Link>
                        </h3>
                      }
                      {authData && (authData.isStudyLeader || authData.isGroupLeader) &&
                        <h3 className="text-abbey font-21 font-regular">
                          This Live Class is no longer on the schedule. Please have a peek at the <Link to={"/liveClasses/" + this.props.match.params.condition} className="flow-text">current live class schedule</Link> to schedule more.
                        </h3>
                      }
                    </div>
                  </div>
                }
                <div className="row m-t-30 desktop-ondemand-view">
                  {/* Instructor details */}
                  <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                    <div className="w-100 m-b-20">
                      <h3 className="black-txt font-21 font-semibold">
                        Instructor
                      </h3>
                      <div className="w-100 m-t-15 border-bottom pb-4">
                        <div className="card border-0 flex-row flex-wrap">
                          <div className="card-header p-0 rounded-circle border-0">

                            <img
                              src={
                                ondemand_view_detail.Instructor &&
                                  ondemand_view_detail.Instructor.img
                                  ? constand.USER_IMAGE_PATH +
                                  ondemand_view_detail.Instructor.img
                                  : constand.WEB_IMAGES + "no-image.png"
                              }
                              alt="NoImage"
                              className="img-fluid rounded-circle"
                            />
                          </div>
                          <div className="card-block px-2">
                            {ondemand_view_detail.Instructor &&
                              ondemand_view_detail.Instructor.User && ondemand_view_detail.Instructor.hasProfile && (
                                <Link
                                  className="orangefont font-14 font-book"
                                  to={
                                    "/instructor/" +
                                    ondemand_view_detail.InstructorId + '/' + this.props.match.params.condition
                                  }
                                  onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                                >
                                  <h4 className="card-title font-15 black-txt font-semibold m-t-10">
                                    {ondemand_view_detail.Instructor.User.name +
                                      " " +
                                      ondemand_view_detail.Instructor.User.lastName}
                                  </h4>
                                </Link>
                              )}
                            {ondemand_view_detail.Instructor &&
                              ondemand_view_detail.Instructor.User && !ondemand_view_detail.Instructor.hasProfile && (
                                <span
                                  className="orangefont font-14 font-book"
                                  onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                                >
                                  <h4 className="card-title font-15 black-txt font-semibold m-t-10">
                                    {ondemand_view_detail.Instructor.User.name +
                                      " " +
                                      ondemand_view_detail.Instructor.User.lastName}
                                  </h4>
                                </span>
                              )}
                            {ondemand_view_detail.Instructor && ondemand_view_detail.Instructor.title &&
                              <div className="card-text font-qregular font-18">
                                {ondemand_view_detail.Instructor.title}
                              </div>
                            }
                          </div>
                          {/* <div class="right-angle">
                            {ondemand_view_detail.Instructor && ondemand_view_detail.Instructor.hasProfile && <Link
                              className="orangefont font-14 font-book"
                              to={
                                "/instructor/" +
                                ondemand_view_detail.InstructorId + '/' + this.props.match.params.condition
                              }
                              onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                            >
                              <img src={constand.WEB_IMAGES + "icons8-right.png"} class="img-fluid" width="50" />
                            </Link>
                            }
                            {ondemand_view_detail.Instructor && !ondemand_view_detail.Instructor.hasProfile && <span
                              className="orangefont font-14 font-book"

                              onClick={() => { this.props.pushHistory(this.props.history.location.pathname) }}
                            >
                              <img src={constand.WEB_IMAGES + "icons8-right.png"} class="img-fluid" width="50" />
                            </span>
                            }
                          </div> */}
                        </div>
                      </div>
                    </div>

                    {/* <div className="w-100 p-t-35">
                    <h3 className=" black-txt font-21 font-semibold">
                      Equipment required
                    </h3>
                    <div className="w-100 m-t-20">
                      <p className="font-qregular font-14 m-0">
                        {commonService.returnLabelData(
                          "equipment",
                          ondemand_view_detail.WorkoutTags
                            ? ondemand_view_detail.WorkoutTags
                            : []
                        )}
                      </p>
                    </div>
                  </div> */}
                  </div>
                  {/* About this class */}
                  <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                    <div className="w-100">
                      <div className="w-100 ">
                        <h3 className=" black-txt font-21 font-semibold">
                          About this class
                        </h3>

                      </div>
                      <div className="font-medium font-15 black-txt">
                        Activity: <span className="font-qregular font-15 black-txt m-l-5 capitalize_text">
                          {commonService.returnLabelData(
                            "discipline",
                            ondemand_view_detail.WorkoutTags
                              ? ondemand_view_detail.WorkoutTags
                              : []
                          )}
                        </span>
                      </div>
                      <div className="font-medium font-15 black-txt">
                        <div>
                          Ideal For:
                          <span className="font-qregular font-15 black-txt m-l-5 capitalize_text">
                            {commonService.returnLabelData(
                              "level",
                              ondemand_view_detail.WorkoutTags
                                ? ondemand_view_detail.WorkoutTags
                                : []
                            )}
                          </span>
                        </div>

                        <div className="font-medium font-15 black-txt">
                          Equipment required:
                          <span className="font-qregular font-15 black-txt m-l-5 capitalize_text">

                            {commonService.returnLabelData(
                              "equipment",
                              ondemand_view_detail.WorkoutTags
                                ? ondemand_view_detail.WorkoutTags
                                : []
                            )}
                          </span>
                        </div>
                        <div className="font-medium font-15 black-txt">
                          Benefits:
                          <span className="font-qregular font-15 black-txt m-l-5 capitalize_text">

                            {commonService.returnLabelData(
                              "benefit",
                              ondemand_view_detail.WorkoutTags
                                ? ondemand_view_detail.WorkoutTags
                                : []
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-100 m-t-30 desktop-ondemand-view">

                      <div className="w-100 m-t-20 summary_div">
                        <p
                          className={`font-qregular font-14 black-txt m-0 ${!this.state.expanded
                            ? "overflow-hidden instruct_details"
                            : ""
                            }`}
                          id="instructor_details"
                          dangerouslySetInnerHTML={{
                            __html: ondemand_view_detail.description
                          }}
                        ></p>


                      </div>
                    </div>
                    {commonService.returnTagName(
                      "condition", constand.CONDITION,
                      ondemand_view_detail.WorkoutTags
                        ? ondemand_view_detail.WorkoutTags
                        : []
                    ) && (commonService.replaceChar(this.props.match.params.condition, true) == constand.CONDITION) &&
                      <div className="w-100 m-t-30 desktop-ondemand-view headsup-section">

                        <div className="w-100 m-t-20 summary_div">
                          <h3 className="heading_3 font-21 black-txt font-medium">
                            Head's Up!
                          </h3>
                          <p
                            className={`font-qregular font-14 black-txt m-0 `}
                            id="instructor_details"

                          >We hope you enjoy this and all our sessions on Beam, but please remember that we are not able to offer you personalised exercise sessions programmes and that participating in any live or on-demand Beam sessions is at your own risk. Changes to your airway clearance and exercise regime should be discussed with your clinical team.</p>
                        </div>
                      </div>
                    }
                    {commonService.returnTagName(
                      "condition", constand.KR_CONDITION,
                      ondemand_view_detail.WorkoutTags
                        ? ondemand_view_detail.WorkoutTags
                        : []
                    ) && (commonService.replaceChar(this.props.match.params.condition, true) == constand.KR_CONDITION) &&
                      <div className="w-100 m-t-30 desktop-ondemand-view headsup-section">

                        <div className="w-100 m-t-20 summary_div">
                          <h3 className="heading_3 font-21 black-txt font-medium">
                            Head's Up!
                          </h3>
                          <p
                            className={`font-qregular font-14 black-txt m-0 `}
                            id="instructor_details"
                          >We hope you enjoy this and all our sessions on Beam, but please remember that we are not able to offer you personalised exercise programmes and that participating in any live or on-demand Beam sessions is at your own risk. </p>
                        </div>
                      </div>
                    }
                    {/* <div className="w-100 m-t-30 desktop-ondemand-view">
                      <div className="w-100 m-t-20 summary_div">
                        <p
                          className="font-qregular font-18 black-txt m-0"
                          id="instructor_details"
                        >Here is some <Link to={'/blogs/safety-info-' + this.props.match.params.condition}>important safety information</Link> to make sure you keep safe and feel your best before and after your class.</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="demand-section--3 mobile-slider m-t-40 m-b-40 p-l-15 p-r-15">
                {this.state.likeMore.length > 0 &&
                  <div className="row">
                    <h3 className="clearfix float-left m-b-40 text-center font-semibold font-24 darkblue-text w-100">
                      Previous class recordings
                    </h3>
                    <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                      <Slider {...settings}>
                        {this.state.likeMore.length > 0 && this.state.likeMore.map(item => {
                          return (
                            <div
                              key={item.id}
                              className="col-lg-6 col-md-6 col-sm-12 float-left p-b-30 p-0"
                            >
                              <div className="card h-100">
                                <Link
                                  to={"/detail/" + item.id + "/" + this.props.match.params.condition}
                                  className="position-relative">
                                  <img
                                    className="card-img-top img-fluid"
                                    alt="noImage"
                                    src={
                                      constand.WORKOUT_IMG_PATH +
                                      item.id +
                                      "-img.png"
                                    }
                                  />
                                  <span className="position-absolute vid_time">
                                    <span>{item.length} minutes</span>
                                  </span>
                                </Link>
                                <div className="card-body">
                                  <div className="w-100 float-left m-b-10">
                                    <div className="col-10 col-md-10 col-lg-10 col-sm-10 float-left pl-0">
                                      <h3 className="heading_3 black-txt font-12 font-semibold text-uppercase">
                                        {item.title}
                                      </h3>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 col-sm-12 float-left pl-0 pr-0">
                                      <div className="bookmark-left float-left m-l-5">
                                        <WorkoutScheduleComponent
                                          ondemand_data={item}
                                          location={this.props.location}
                                          history={this.props.history}
                                        />
                                      </div>
                                      <div className="bookmark-left m-l-10 float-left">
                                        <WorkoutSaveComponent
                                          page="ondemand_detail_sub"
                                          workoutData={item}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="col-6 col-lg-6 col-sm-6 float-left m-b-10 p-0">
                                      <div className="p-0 border-0 float-left">
                                        <img
                                          className="demand-profimg img-fluid rounded-circle"
                                          src={
                                            item.Instructor && item.Instructor.img
                                              ? constand.USER_IMAGE_PATH +
                                              item.Instructor.img
                                              : constand.WEB_IMAGES + "no-image.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <p className="float-left m-t-5 font-10 black-txt font-qregular p-l-10">
                                        {item.Instructor && item.Instructor.User
                                          ? item.Instructor.User.name +
                                          " " +
                                          item.Instructor.User.lastName
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-6 col-lg-6 col-sm-6 float-left pr-0">
                                      <div className="font-medium w-100 font-light--weight">
                                        <div className="col-md-12 col-sm-12 float-left p-0">
                                          <span className="col-4 col-sm-4 float-left font-10 black-txt  font-qregular p-0">
                                            Discipline:
                                          </span>
                                          <span className="redtext col-8 col-sm-8 p-0 float-left font-10  font-qregular capitalize_text">
                                            {commonService.returnLabelData(
                                              "discipline",
                                              item.WorkoutTags
                                                ? item.WorkoutTags
                                                : []
                                            )}

                                          </span>
                                        </div>
                                        <div className="col-md-12 col-sm-12 float-left p-0">
                                          <span className="col-4 col-sm-4 p-0 float-left font-10 black-txt  font-qregular">
                                            Difficulty:
                                          </span>
                                          <span className="redtext col-8 col-sm-8 p-0 float-left font-10  font-qregular capitalize_text">
                                            {commonService.returnLabelData(
                                              "level",
                                              item.WorkoutTags
                                                ? item.WorkoutTags
                                                : []
                                            )}
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                }
              </div>

              <div className="demand-section--3 demand-section--desk m-t-80 m-l-30 m-r-30">
                {this.state.likeMore.length > 0 &&
                  <div className="row">
                    <h3 className="clearfix float-left m-b-40 text-left font-semibold font-21 black-txt w-100">
                      Previous class recordings
                    </h3>
                    <div className="col-md-12 col-lg-12 col-sm-12 p-0">
                      <div className="row">

                        {this.state.likeMore.length > 0 && this.state.likeMore.map(item => {
                          return (
                            <div
                              key={item.id}
                              className="col-lg-4 col-md-4 col-sm-12 float-left p-b-30"
                            >
                              <div className="card h-100">
                                <Link
                                  to={"/detail/" + item.id + "/" + this.props.match.params.condition}
                                  className="position-relative"
                                >
                                  <img
                                    className="card-img-top img-fluid"
                                    alt="noImage"
                                    src={
                                      constand.WORKOUT_IMG_PATH +
                                      item.id +
                                      "-img.png"
                                    }
                                  />
                                  <span className="position-absolute vid_time">
                                    <span>{item.length} minutes</span>
                                  </span>
                                </Link>
                                <div className="card-body">
                                  <div className="w-100 float-left m-b-10">
                                    <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                      <h3 className="black-txt font-16 font-semibold capitalize_text float-left w-75">
                                        {item.title}
                                      </h3>
                                      <div className="bookmark-left float-right text-right w-25">
                                        <WorkoutScheduleComponent
                                          ondemand_data={item}
                                          location={this.props.location}
                                          history={this.props.history}
                                        />
                                        <b className="m-l-10">
                                          <WorkoutSaveComponent
                                            className="m-l-10"
                                            page="liveclass_detail_sub"
                                            workoutData={item}
                                          />
                                        </b>
                                      </div>

                                    </div>

                                  </div>
                                  <div className="w-100">
                                    <div className="col-md-12 col-lg-12 col-sm-12 float-left m-b-10 p-0">
                                      <div className="left-image leftwidth-set p-0 border-0 float-left">
                                        <img
                                          className="demand-profimg img-fluid rounded-circle"
                                          src={
                                            item.Instructor && item.Instructor.img
                                              ? constand.USER_IMAGE_PATH +
                                              item.Instructor.img
                                              : constand.WEB_IMAGES + "no-image.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <p className="float-left m-t-5 font-14 black-txt font-qregular p-l-10">
                                        {item.Instructor && item.Instructor.User
                                          ? item.Instructor.User.name +
                                          " " +
                                          item.Instructor.User.lastName
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                      <div className="font-medium w-100 font-light--weight">
                                        <div className="col-md-12 col-sm-12 float-left p-0">
                                          <span className="w-45 float-left font-14 black-txt font-qbold">
                                            Discipline:
                                          </span>
                                          <span className="redtext w-50 float-left font-14 p-l-5 font-qregular capitalize_text">
                                            {commonService.returnLabelData(
                                              "discipline",
                                              item.WorkoutTags
                                                ? item.WorkoutTags
                                                : []
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-md-12 col-sm-12 float-left p-0">
                                          <span className="w-45 float-left font-14 black-txt font-qbold">
                                            Difficulty:
                                          </span>
                                          <span className="redtext w-50 float-left font-14 p-l-5 font-qregular capitalize_text">
                                            {commonService.returnLabelData(
                                              "level",
                                              item.WorkoutTags
                                                ? item.WorkoutTags
                                                : []
                                            )}
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                        <Link
                          to={"/on-demand/" + this.props.match.params.condition}
                          className="btn btn-orange joinclass-blue-btn m-t-40 m-b-40 font-medium font-14"
                        >
                          View all on demand session
                        </Link>
                      </div>
                    </div>

                  </div>
                }
              </div>

            </div>
          }
          <div className="text-center w-100">
            {(this.state.Loading) && (<AnimateLoaderComponent />)}
          </div>

        </section>

        <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} condition={commonService.replaceChar(this.props.match.params.condition, true)} />
        <WarningModal
          isConditionModalOpen={this.state.isConditionModalOpen}
          conditionName={this.props.match.params.condition}
          isWarningModal={this.state.isWarningModal}
          closeModel={this.closeModel}
          hasMembership={this.state.hasMembership}
          health_conditionId={this.state.health_conditionId}
          afterUpgrade={this.afterUpgrade}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    condition: state.auth.condition,
    liveclass_detail_data: state.workout.liveclass_detail_data,
    logged_userData: state.header.logged_userData,
    prevPath: state.workout.prevPath,
    healthcondition_list: state.register.healthcondition_list,
    registerFormvalues: state.register.registerFormValues,
    workout_detail_data: state.workout.workout_detail_data,
  };
};

const mapDispatchToProps = {
  start_loader,
  stop_loader,
  joinClass,
  cancelClass,
  liveclassDetail,
  ondemandDetail,
  scheduleModelOpen,
  hasMembership,
  loginModelOpen,
  startVideo,
  saveTimeSpent,
  updateAfterModelState,
  setGoback,
  pushHistory,
  registerformUpdate,
  updateMyConditions,
  setConditionIndex,
  updateUserdataRedex,
  changePlan,
  getPlanByCountry
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveClassDetailComponent);
