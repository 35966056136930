import React from 'react';
import { Link } from "react-router-dom";
import * as constand from "../../constant";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import {
    registerformUpdate,
    setAllUserList,
    clearRegisterFormUpdate,
    onboardingUserTrack
} from "../../actions";

class NameComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: (this.props.registerFormvalues.firstname) ? false : true,
            lastname: (this.props.registerFormvalues.lastname) ? false : true,
            submitted: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.registerSubmit_4 = this.registerSubmit_4.bind(this);
    }
    componentDidMount() {
        // this.defaultValue();
        if (this.props.registerFormvalues.masterUserID) {
            this.props.registerFormvalues.age = "";
            this.props.registerFormvalues.country = null;
            this.props.registerFormvalues.health_condition = [];
        }
    }
    defaultValue = () => { //testing purpose
        console.log('default value')
        let temp = this.props.registerFormvalues;
        temp.cf_disease_severity = "445";
        temp.userid = 0;
        temp.email = "vijimas11@yopmail.com";
        temp.password = "Optisol@123";
        temp.name = "viji mas1";
        temp.lastname = "v";
        temp.zoneName = "Asia/Calcutta";
        temp.facebookId = "";
        temp.facebookAccessToken = "";
        temp.facebookPicture = "";
        temp.nickname = "";
        temp.permission_newFeatures = 0; temp.permission_research = 0; temp.terms = 1; temp.gender = 1;
        temp.country = "231"; temp.region = "106"; temp.promo = ""; temp.usage = null; temp.dob = "1961-3-16";
        temp.howHeard = null; temp.source = "3"; temp.planId = null; temp.conditionId = [199, 200]; temp.condition_relationship = "A caregiver to someone with a health condition"; temp.caregiver_type = null; temp.clinician_type = null; temp.number = ""; temp.exp_month = ""; temp.exp_year = ""; temp.cvc = ""; temp.conditions = [{ "conditionId": 199, "clinicId": "324" }, { "conditionId": 200, "clinicId": "" }];
        temp.usUser = false; temp.krUser = false; temp.tags = []; temp.ethnicity = "White"; temp.comorbidities = []; temp.kidney_care_provider = []; temp.referee = ""; temp.beamchallenging = ""; temp.research = false; temp.research_groupname = ""; temp.research_condition = ""; temp.research_share = "";
        temp.condition_professions = [{ "conditionId": 199, "conditionRelationship": "Living with condition", "caregiverType": "", "profession": "", "clinicId": "324", "patientPopulation": "" }, { "conditionId": 200, "conditionRelationship": "A caregiver to someone with a health condition", "caregiverType": "", "profession": "", "clinicId": "", "patientPopulation": "" }];
        temp.cancer_treatment = []; temp.cancer_comordities = []; temp.condition_membership = [{ "PlanId": 26, "planStarts": "2022-08-23", "trialEnds": "2022-09-06", "UserId": 0, "current": 1, "conditionId": 199, "isConfigEmailAlert": false, "membershipType": "free-trial", "licenseId": null }]; temp.isKBResearcheUser = false; temp.tagCode = {}; temp.movement_prefs = ["416"]; temp.cf_comordities = ["431"]; temp.cf_disease_severity = "445"; temp.masterUserID = null;
        temp.masterUserID = true;

        this.props.setAllUserList(temp);
        temp = {};
        //   temp = this.props.registerFormvalues;
        temp.cf_disease_severity = "";
        temp.userid = 0;
        temp.email = "";
        temp.password = "";
        temp.name = "";
        temp.lastname = "";
        temp.zoneName = "";
        temp.facebookId = "";
        temp.facebookAccessToken = "";
        temp.facebookPicture = "";
        temp.nickname = "";
        temp.permission_newFeatures = 0; temp.permission_research = 0; temp.terms = 0; temp.gender = 0;
        temp.country = ""; temp.region = ""; temp.promo = ""; temp.usage = null; temp.dob = "";
        temp.howHeard = null; temp.source = ""; temp.planId = null; temp.conditionId = []; temp.condition_relationship = ""; temp.caregiver_type = null; temp.clinician_type = null; temp.number = ""; temp.exp_month = ""; temp.exp_year = ""; temp.cvc = ""; temp.conditions = [];
        temp.usUser = false; temp.krUser = false; temp.tags = []; temp.ethnicity = ""; temp.comorbidities = []; temp.kidney_care_provider = []; temp.referee = ""; temp.beamchallenging = ""; temp.research = false; temp.research_groupname = ""; temp.research_condition = ""; temp.research_share = "";
        temp.condition_professions = [];
        temp.cancer_treatment = []; temp.cancer_comordities = []; temp.condition_membership = []; temp.isKBResearcheUser = false; temp.tagCode = {}; temp.movement_prefs = []; temp.cf_comordities = []; temp.cf_disease_severity = "";
        temp.masterUserID = true;
        this.props.registerformUpdate(temp);
        this.props.setStep(30)

    }
    handleChange(e) //for twoway binding
    {
        let { name, value } = e.target;
        value = (value.length === 1) ? value.trim() : value;
        this.setState({ [name]: value });
        this.props.registerFormvalues[name] = value;
    }
    registerSubmit_4() {
        this.setState({ submitted: true });
        if (this.props.registerFormvalues.firstname && this.props.registerFormvalues.lastname && this.props.registerFormvalues.firstname.length <= 50 && this.props.registerFormvalues.lastname.length <= 50) {
            this.props.registerFormvalues.beamchallenging = localStorage.getItem('beamchallenging');
            this.props.registerFormvalues.fullname = this.props.registerFormvalues.firstname + ' ' + this.props.registerFormvalues.lastname;
            if (this.props.registerUserList.length > 0) {  //get master data 
                let randomNum = Math.floor(Math.random() * 100000);
                let childEmailCombin = this.props.registerFormvalues.firstname + '-' + this.props.registerFormvalues.lastname + '-' + randomNum + '-' + this.props.registerUserList[0].email;
                let childEmail = childEmailCombin.replace(/ /g, '')
                this.props.registerFormvalues.email = childEmail;
                this.props.registerFormvalues.terms = this.props.registerUserList[0].terms;
                this.props.registerFormvalues.password = 'subB3am@master';
            }
            this.props.registerformUpdate(this.props.registerFormvalues);
            this.onboardingUserTrack()
            ReactGA.event({
                category: "User Acquisition",
                action: "Sign up process",
                label: "Name"
            })
        }
    }

    onboardingUserTrack = () => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            this.props.setStep(5);
        })
    }

    render() {
        console.log('namecomp=>', this.props.registerFormvalues)
        return (
            <div className="step1">
                <h3 className="text-center "> {!this.props.registerFormvalues.masterUserID && <span className="pull-left pointer" onClick={() => this.props.setStep(2)}><img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} /></span>} {this.props.registerFormvalues.masterUserID ? "Now, let's create a profile for your child..." : "Now, let's create a profile for you"}<Link
                    to="/home"
                    className="close-register orangefont"
                >
                    X
                </Link> </h3>
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-6 m-t-10">
                        <p className="font-14 font-qmedium black-txt m-t-20 m-b-20">Please fill out the following section for {!this.props.registerFormvalues.masterUserID ? " yourself" : " your child"}. {!this.props.registerFormvalues.masterUserID && ("(You can create profiles for others later on)")}</p>
                        <div className="form-group">
                            <label htmlFor="firstname" className="text-label">First name</label>
                            <input type="text" name="firstname" id="firstname" className="form-control input-control" length="100"
                                onChange={this.handleChange} value={this.props.registerFormvalues.firstname} required />
                            {this.state.submitted && !this.props.registerFormvalues.firstname && <p className="text-danger">First name is required</p>}
                            {this.state.submitted && this.props.registerFormvalues.firstname.length > 50 && <p className="text-danger">First name is too long</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname" className="text-label">Last name</label>
                            <input type="text" name="lastname" id="lastname" className="form-control input-control" onChange={this.handleChange} value={this.props.registerFormvalues.lastname} required />
                            {this.state.submitted && !this.props.registerFormvalues.lastname && <p className="text-danger">Last name is required</p>}
                            {this.state.submitted && this.props.registerFormvalues.lastname.length > 50 && <p className="text-danger">Last name is too long</p>}
                        </div>

                        <div id="register-link" className=" w-100 m-t-80 m-b-20 float-left">
                            <span className="bluebtn float-left w-100 text-center pointer" onClick={this.registerSubmit_4}>Next</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        masterUserId: state.register.master_user_id,
        registerUserList: state.register.set_all_user_list,
    };
};

const mapDispatchToProps = {
    registerformUpdate,
    setAllUserList,
    clearRegisterFormUpdate,
    onboardingUserTrack
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NameComponent);
