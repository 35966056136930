import React from "react";
import { connect } from "react-redux";
import * as constand from "../../constant";
import { Cookies } from "react-cookie-consent";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { ImageTag, TextWrap } from "../../tags";
import {
  fetchBlogs,
} from "../../actions";

class KDAboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.yourElementRef = React.createRef();
    this.state = {
      Loading: true,
      isSaveLoading: false,
      blogList: [],
      show_more_cnt: constand.BLOG_LIST_CNT,
      search: "",
      list_count: "",
      offset: 1,
      isPopupOpen: false,
      selectedPopupItem: {},
      blogListOrder:[]
    };
    this.getBlogList = this.getBlogList.bind(this);
    this.gotoDetailAboutUsPopup = this.gotoDetailAboutUsPopup.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let condition = Cookies.get('condition');
    // if(condition != constand.KR_CONDITION && condition != constand.KD_YOUTH) {
    //   this.props.history.push('/about-us');
    // }

    if (condition === constand.KR_CONDITION || condition === constand.KD_YOUTH) {
      Cookies.set("condition", condition);
    }
    this.getBlogList();
  }
  getBlogList() {
    var dataObj = {
      offset: this.state.offset,
      limit: constand.BLOG_LIST_CNT,
      search: this.state.search,
      condition: 'kidney-beam',
      type: 'about-us'
    };
    this.setState({ Loading: true });
    this.props.fetchBlogs(dataObj).then(
      response => {
        if (response) {
          if (response.list) {
            
            this.setState({
              Loading: false,
              list_count: response.list.count,
              blogList: response.list.blogs
                ? [...this.state.blogList, ...response.list.blogs]
                : [],
            });
            const sortedData = [...this.state.blogList[0].data.section_details].sort((a, b) => {
              // You can customize the comparison based on your variable
              if (a["card_detail_order"] < b["card_detail_order"]) return -1;
              if (a["card_detail_order"] > b["card_detail_order"]) return 1;
              return 0;
            });
            this.setState({ blogListOrder: sortedData });
            console.log(this.state.blogList)
            console.log(this.state.blogList[0].data.header_image)
          }
        }
      },
      error => {
        this.setState({ Loading: false });
        toast.error(error);
      }
    );
  }
  gotoDetailAboutUsPopup() {
    const { from } = { from: { pathname: "/about-us/kidney-disease" } };
    this.props.history.push(from);
  }
  openPopup(item) {
    this.setState({ selectedPopupItem: item  });
    if(window.innerWidth > 767) {
      this.setState({ isPopupOpen: true});
    } else {
      window.scrollTo(0, 0);
    }
  };

  closePopup() {
    this.setState({ isPopupOpen: false, selectedPopupItem: {} });
  };

  aboutusDetailPopup() {
    return (
        <Modal
            classNames={{
                modal: "about-us-detail-popup m-t-50 abt-modal"
            }}
            open={this.state.isPopupOpen}
            onClose={this.closePopup}
            center
        > 
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" onClick={this.closePopup}>
                      <img src="https://dev.beamfeelgood.com/images/close-icon.png" />
                  </span>
              </button>
              </div>
              <div class="modal-body p-50">
                <div class="row">
                  <div class="col-12 col-md-5 col-lg-5 text-center">
                    {this.state.selectedPopupItem.card_detail_image && this.state.selectedPopupItem.card_detail_image.url && (
                      <div className="modal-desktop-image">
                        <img
                        src={this.state.selectedPopupItem.card_detail_image.url}
                        class="img-fluid"
                        alt="" />
                      </div>
                    )}
                    {this.state.selectedPopupItem.card_detail_url && this.state.selectedPopupItem.card_detail_url.url && (
                    <div className="info text-right float-right" >
                      <a class="" href={this.state.selectedPopupItem.card_detail_url.url} target="_blank">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </div>
                    )}
                  </div>
                  {this.state.selectedPopupItem && this.state.selectedPopupItem.card_detail_image && (
                  <div
                    class="col-12 col-md-7 col-lg-7 text-left aboutus-content p-l-20"
                  >
                    <h4
                      class="font-38 font-normal font-semibold golden-yellow-text ash-txt mb-0"
                    >
                      {this.state.selectedPopupItem.card_detail_title[0].text}
                    </h4>
                    <p class="font-28 font-normal font-book ash-txt">
                      {this.state.selectedPopupItem.card_detail_subtitle[0].text}
                    </p>
                    <p class="ash-txt font-qregular m-b-10 font-16 p-r-35">
                    {this.state.selectedPopupItem.card_detail_text[0].text}
                    </p>
                  </div>
                   )}
                </div>               
              </div>
            </div>
          </div>
       
        </Modal>);
  };
  render() {
    return (
      <div className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'aboutcontent' : 'aboutcontent m-t-80'}>
        { this.state.blogList.length > 0 && 
        <div class="aboutcontent">
          <div class="aboutcontent aboutus-page">
            <section id="aboutsection2" className={`aboutsection2-bg p-t-30 p-b-80 ${this.state.selectedPopupItem.card_detail_image ? 'display-section' : ''}`} ref={this.yourElementRef}>
              <div class="container-fluid mx-auto w-60" >
                <div class="section-header text-center">
                  <div class="row row-box">
                    <div class="col-6 abtimage-after">
                      <img src={this.state.blogList[0].data.header_image.url}
                              alt="Your Image" />
                    </div>
                    <div class="content-box text-left m-b-55 p-t-50 p-b-30 p-l-40 p-r-25 abtcontent-after">
                      <h4 class="m-b-20 font-48 font-normal font-semibold golden-yellow-text ash-txt">{this.state.blogList[0].data.header_title[0].text}</h4>
                        <p class="ash-txt font-qregular m-b-10 font-18 p-r-30">We are a team of impassioned individuals led by 2
                          fearless, female founders on a mission to improve the lives of people living with kidney disease. Formed as
                          a rapid response to COVID-19 pandemic, we set out on a journey to bring exercise and lifestyle management
                          into the hands of all people with kidney disease through a virtual solution. Combining our entreprenuerial,
                          technical and clinical expertise we have worked with patients, clinicians and national charities to develop
                          a world-leading, evidence based app solving a true problem faced by the NHS, insurers and global healthcare
                          systems.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="aboutsection3" className={`p-t-20 p-b-0 our-leadership ${this.state.selectedPopupItem.card_detail_image ? 'display-section' : ''}`}>
              <div class="container-fluid mx-auto w-80">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <h4 class="font-48 font-normal font-semibold golden-yellow-text ash-txt m-b-50">{this.state.blogList[0].data.section_title[0].section_title[0].text}
                    </h4>
                  </div>
                  {this.state.blogListOrder
                      .map((item, key) => {
                        return (
                          <div class="col-12 col-md-4 col-lg-4 m-b-60 mobile-m0" key={key}>
                            <div class="team-image" onClick={() => this.openPopup(item)} >
                              <ImageTag className="card-img-top" src={ (item.card_detail_image && item.card_detail_image.url) ?
                                item.card_detail_image.url : constand.WEB_IMAGES + "no-image.png" } />
                            </div>
                            <div class="team-content d-flex">
                              <div class="team-info">
                                <h3 class="font-24 ash-txt font-normal font-semibold mb-0">
                                  {item.card_detail_title[0].text}
                                </h3>
                                <p class="font-24 ash-txt font-normal font-regular mb-0">
                                  {item.card_detail_subtitle[0].text}
                                </p>
                              </div>
                              <div class="info">
                                <a class="" href={item.card_detail_url.url} target="_blank">
                                  <i class="fa fa-linkedin"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </section>
            <section id="mobile-popup" class="p-t-20 p-b-0 our-leadership">  
            {this.state.selectedPopupItem.card_detail_image && this.state.selectedPopupItem.card_detail_image.url && (                
              <div class="modal-top-bar" >
                <button type="button" class="back-arrow" data-dismiss="modal" aria-label="Go back" onClick={() => this.closePopup()}>
                <div class="element element-7"></div>
                        <span>Back to team</span>
                    </button>
              </div>
              )}
              {this.state.selectedPopupItem && this.state.selectedPopupItem.card_detail_image && (
              <div class="row row-box modal-reponsive">
                <div class="col-6 responsive-modal-popup">
                  <img src={this.state.selectedPopupItem.card_detail_image.url} alt="Your Image" /></div>
                  <div class="content-box text-left m-b-55 p-t-50 p-b-30 p-l-40 p-r-25">
                  <h4 class="font-48 font-normal font-semibold golden-yellow-text ash-txt">{this.state.selectedPopupItem.card_detail_title[0].text}</h4>
                  <p class="modal-position">
                    {this.state.selectedPopupItem.card_detail_subtitle[0].text}
                  </p>
                  <p class="ash-txt font-qregular m-b-10 font-18 p-r-30">
                    {this.state.selectedPopupItem.card_detail_text[0].text}            
                  </p>
                </div>
              </div>
              )}
            </section>
          </div>
      </div>
        }
        {this.aboutusDetailPopup()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
  };
};

const mapDispatchToProps = {
  fetchBlogs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KDAboutPage);
