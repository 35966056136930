import React from "react";
import Modal from "react-responsive-modal";
import moment from "moment";
import { Link } from "react-router-dom";
import * as constand from "../../constant";
import { commonService } from "../../_services";
import EventCalendarComponent from "../DashboardPage/EventCalendarComponent";
import { Cookies } from "react-cookie-consent";
class JoinClassComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCloseModal() { }

  loginSubmit(e) {
    /*e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
      return;
    }

    this.setState({ loading: true });
    let email = username;
    this.props.login(email, password).then(
      response => {
        this.setState({ error: '' });
        this.props.isAuth();
        this.closeModel();
        //const { from } = { from: { pathname: "/about-us" } };
        //this.props.history.push(from);
        toast.success(response.message);
      },
      error => {
        this.setState({ error: error,loading: false });
        //toast.error(error);
      }
    );*/
  }

  render() {
    const item = this.props.modelData;
    return (
      <Modal
        className="removebbg-popup"
        open={this.props.is_model_open}
        onClose={this.onCloseModal}
        center
      >
        <div className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) ? "modal-dialog common_design modal-width--custom live-classes--popup m-t-50" : "modal-dialog common_design modal-width--custom live-classes--popup m-t-80"} role="document">

          <div className="modal-content">
            <div className="modal-header header-styling  border-0">
              <h5
                className="modal-title1 text-center white-txt col-md-12 p-0 font-book font-24"
                id="exampleModalLabel "
              >
                You're signed up!
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => this.props.modelClose()}
                >
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body body-padding--value pb-5">
              {item && Object.keys(item).length > 0 && item.constructor === Object && (
                <div>
                  <h3 className="font-qregular font-24 black-txt heading-3 text-center text-uppercase">
                    {item.Workout.shortDescription}
                  </h3>
                  <div className="col-md-12 text-center m-t-20 m-b-30 mr-auto ml-auto">
                    <p className="font-15 font-semibold black-txt">{item.Workout.title}</p>
                    <img
                      className="img-fluid rounded-circle"
                      src={
                        item.Workout &&
                          item.Workout.Instructor &&
                          item.Workout.Instructor.img
                          ? constand.USER_IMAGE_PATH +
                          item.Workout.Instructor.img
                          : constand.WEB_IMAGES + "no-image.png"
                      }
                      ref={img => (this.img = img)}
                      onError={() =>
                        (this.img.src = constand.WEB_IMAGES + "no-image.png")
                      }
                      width="50"
                      height="50"
                    />
                    <span className="text-uppercase font-qregular font-12 black-txt name-styling p-l-10">
                      {item.Workout &&
                        item.Workout.Instructor &&
                        item.Workout.Instructor.User
                        ? commonService.bindUsername(item.Workout.Instructor.User)
                        : ""}
                    </span>
                  </div>
                  <span className="text-center w-100 float-left m-t-20 m-b-50">
                    <div className="w-100 float-left">
                      <img src={constand.WEB_IMAGES + "calendar-popup.png"} alt="Calendar" />
                    </div>
                    <div className="w-100 float-left m-t-10">
                      <span className="p-l-10 font-15 black-txt font-book dateformat-styling">
                        {moment(item.scheduledFor).format("dddd DD MMMM")}
                      </span>
                      <div className="font-13 font-book grey-text m-t-5">
                        {moment(new Date(item.scheduledFor)).format("hh:mm")} - {moment(commonService.addMintues(item.scheduledFor, item.Workout.length)).format("hh:mm")}
                      </div>
                    </div>
                    <div className="w-100 float-left m-t-10">
                      <EventCalendarComponent item={item} type={item.type} title={item.Workout.title} length={item.Workout.length} />
                    </div>
                  </span>
                  {(!this.props.condition.toLowerCase().includes('research') || (this.props.condition.toLowerCase().includes('research') && !this.props.isRejoin)) &&
                    <div className="text-center m-b-30">
                      <div className="w-100 float-left font-14 black-txt font-book">
                        You will be emailed details of how to join the live
                        session.
                    </div>
                      <div className="w-100 float-left font-14 black-txt font-book">
                        You can also familiarise yourself with the process right
                        now.
                    </div>
                    </div>
                  }
                  {(!this.props.condition.toLowerCase().includes('research') || (this.props.condition.toLowerCase().includes('research') && !this.props.isRejoin)) &&
                    <div className="buttons-read w-100 m-t-20 float-left text-center pointer">
                      <Link className="btn btn-blue-inverse font-14 read-now m-r-20 m-t-10" to="/liveClass">
                        Read now
                    </Link>
                      <a className="btn btn-blue-inverse font-14 read-later m-r-20 pointer m-t-10" onClick={() => this.props.modelClose()}>
                        Read later
                    </a>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default JoinClassComponent;
