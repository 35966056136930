import React, { useEffect, useState } from "react";
import WeightAndPhysicalHeader from "./header";
import WeightPhysicalTab from "./tab";
import WeightTab from "./weightTab";
import PhysicalActivityTab from "./physicalActivityTab";
const WeightAndPhysicalActivityPopUp = ({
  updateCallback,
  data,
  onBeamData,
  offBeamData,
  weightChartData,
  onClose,
  isContinueNeed,
  continueOnClick,
}) => {
  console.log("***Tab Selected****", data?.physicalActivityData ? 1 : 2);
  const [tabSelected, setTabSelected] = useState(
    data?.physicalActivityData ? 1 : 2
  );
  useEffect(() => {
    setTabSelected(weightChartData?.hasRecords ? 2 : 1);
  }, [weightChartData?.hasRecords,isContinueNeed]);

  const tabSelect = (tab) => (e) => {
    setTabSelected(tab);
    console.log("+++++++++++++++++++++ llllllllll +++++++++++++");
    console.log(e);
    console.log(tab);
  };
  const handleClose = () => {
    setTabSelected(1);
    onClose();
  };
  return (
    <React.Fragment>
      {/*modal*/}
      <div
        className="modal fade"
        id="chartcustomModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="chartModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-width--custom" role="document">
          <div className="modal-content">
            <div className="modal-header header-styling border-0 bg-white p-0">
              <button
                type="button"
                className="close white-txt"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={handleClose}>
                  <img
                    src="/images/dashboard/close.svg"
                    className="img-fluid"
                    alt="Close Icon"
                  />
                </span>
              </button>
            </div>
            <div
              className="modal-body body-padding--value p-32"
              id="register-box"
            >
              <WeightAndPhysicalHeader
                days={
                  tabSelected === 1
                    ? data?.weightData?.days || 0
                    : data?.physicalActivityData?.days || 0
                }
              />
              {/*tab*/}
              <div className="rounded">
                <WeightPhysicalTab
                  tabSelect={tabSelect}
                  tabSelected={tabSelected}
                />
                <div id="myTabContent" className="tab-content infocontent-tab">
                  {/*Tab one*/}
                  <WeightTab
                    updateCallback={updateCallback}
                    data={data.weightData}
                    weightChartData={weightChartData}
                    tabSelected={tabSelected}
                  />
                  {/*Tab two*/}
                  <PhysicalActivityTab
                    updateCallback={updateCallback}
                    data={data.physicalActivityData}
                    onBeamData={onBeamData}
                    offBeamData={offBeamData}
                    tabSelected={tabSelected}
                  />
                </div>
                {isContinueNeed && (
                  <div className="update-btn d-flex justify-content-center">
                    <a
                      href="javascript:void(0)"
                      className="font-16 font-qbold fw600 bg-lightblue bluebutton1"
                      onClick={() => {
                        handleClose();
                        continueOnClick();
                      }}
                    >
                      Continue
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WeightAndPhysicalActivityPopUp;
