import { SET_PROGRAM_RESOURCES_LIST } from "../utilities";

const INITIAL_STATE = {
  program_Resources_list: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROGRAM_RESOURCES_LIST:
      return {
        ...state,
        program_Resources_list: action.payload,
      };
    default:
      return state;
  }
};
