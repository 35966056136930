import React, { useState } from "react";
import ReactPlayer from "react-player";

const FAQSection = ({ faq_data }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const handleToggle = (faqKey) => {
    setActiveMenu(faqKey);
  };
  return (
    <div className="accordion mt-5 faq-content-data" id="accordionExample">
      {!faq_data.length && (
        <div className="row prggoals header-data-row align-items-center">
          <div className="col col-wide black-txt font-16 fw700 font-qbold text-center">
            No Faq found
          </div>
        </div>
      )}
      {faq_data.map((itemData, faqKey) => (
        <div
          className={`card ${activeMenu == faqKey ? "activecollapse" : ""}`}
          key={faqKey}
        >
          <div className="card-header" id={`heading${faqKey}`}>
            <h2 className="clearfix mb-0">
              <a
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target={`#collapse${faqKey}`}
                onClick={() => handleToggle(faqKey)}
                aria-expanded={faqKey == null ? "true" : "false"}
                aria-controls={`collapse${faqKey}`}
              >
                <img
                  src="/images/dashboard/minus-circle.svg"
                  className="img-fluid minus-circle"
                  alt="minus-circle"
                />
                <img
                  src="/images/dashboard/plus-circle.svg"
                  className="img-fluid plus-circle"
                  alt="plus-circle"
                />
                {itemData.page_title[0].text}
              </a>
            </h2>
          </div>
          <div
            id={`collapse${faqKey}`}
            className={`collapse ${faqKey == null ? "show" : ""}`}
            aria-labelledby={`heading${faqKey}`}
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="media">
                <div className="media-body">
                  <p>{itemData.faqs[0].video_description}</p>

                  {activeMenu == faqKey && (
                    <div
                      className="videoplayer"
                    >
                      <ReactPlayer
                        url={
                          itemData.faqs[0].embedded_video &&
                          itemData.faqs[0].embedded_video.embed_url
                        }
                        controls={false}
                        width="100%"
                        height="100%"
                        style={{
                          borderRadius: "24px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
