import React from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";

import { fetchUserDetails, start_loader, stop_loader } from "../../actions";
import * as constand from "../../constant";
import { commonService } from "../../_services";

import ScheduleCalenderView from "./ScheduleCalenderView";
import HistoryComponent from "./HistoryComponent";
import SavedComponent from "./SavedComponent";
import DashboardGroupComponent from "./GroupComponent";
import ActivityComponent from "./ActivityComponent";
import ProgramComponent from "./ProgramComponent";
import ProgressComponent from "./ProgressComponent";

class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: {},
      selectedTab: 1,
      condition: this.props.match.params.condition
    };
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
    this.savedChild = React.createRef();
    this.gotoMycodition = this.gotoMycodition.bind(this);
  }
  /** fetch user detail on page did mount */
  componentDidMount() {
  }

  componentWillReceiveProps() {
    this.conditonClassList = [...constand.CONDITION_CLASS_LIST];
  }

  getColorClass() {
    var retrunClass = this.conditonClassList.pop();
    this.conditonClassList.unshift(retrunClass);
    return retrunClass;
  }

  gotoMycodition() {
    const { from } = { from: { pathname: "/accounts/myConditions" } };
    this.props.history.push(from);
  }
  /**
   * changeCondition
   */
  changeCondition(condition) {
    if (condition == constand.KR_CONDITION.replace(" ", "-")) {
      var url = '/';
      var changeUrl = url + condition;
    } else {
      let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
      var url = (authData && (authData.isGroupLeader || authData.isStudyLeader || authData.isStudyInstructor) && constand.SL_MENU.includes('on-demand')) ? '/on-demand/' : '/liveClasses/';
      var changeUrl = url + condition;
    }

    this.props.history.push(changeUrl);
  }
  showMobileListName() {
    var label;
    switch (this.props.match.path) {
      case "/account/dashboard/schedule/:condition":
        label = 'Schedule';
        break;
      case "/account/dashboard/history/:condition":
        label = 'History';
        break;
      case "/account/dashboard/programs/:condition":
        label = 'Programmes';
        break;
      case "/account/dashboard/activity/:condition":
        label = 'Activity Diary';
        break;
      case "/account/dashboard/progress/:condition":
        label = 'Your Progress';
        break;
      case "/account/dashboard/saved/:condition":
        label = 'Saved';
        break;
      case "/account/dashboard/groups/:condition":
        label = 'Groups';
        break;
      default:
        label = 'Select';
        break;
    }
    return label;
  }
  render() {

    return (
      <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'Dashboard groupdetails' : 'Dashboard groupdetails m-t-70'}>
        <div className="banner_dasboard clearfix">
          <div className="container">
            <div className="banner_section p-t-30 p-b-10 text-left">
              <h3 className={"m-head text-white font-medium font-37 " + ((this.state.condition.toLowerCase().includes('research')) ? 'm-b-30' : '')}>
                Hello {this.props.userData.name}!
              </h3>

              {!this.props.userData.isStudyParticipant && !this.state.condition.toLowerCase().includes('research') &&

                <p className="font-medium">
                  <span className="text-white m-head font-24">My Conditions</span>
                </p>
              }
              {!this.props.userData.isStudyParticipant && !this.state.condition.toLowerCase().includes('research') &&

                <div className="m-b-40">
                  {this.props.healthcondition_list.map((item, key) => {
                    return (
                      <React.Fragment key={"conditon_" + key}>
                        {this.props.userData.conditionList &&
                          this.props.userData.conditionList.includes(item.id) && (
                            <button
                              onClick={() => {
                                this.changeCondition(item.tag.replace(/ /g, "-"));
                              }}
                              className={
                                "big-button " + commonService.getConditionColorClass(item.tag) //this.getColorClass()
                              }
                            >
                              <span className="capitalize_text font-semibold">{item.tag}</span>
                            </button>
                          )}
                      </React.Fragment>
                    );
                  })}
                  <button
                    className="btn btn-light condition_plus"
                    onClick={this.gotoMycodition}
                  >
                    +
                  </button>
                  <button
                    className="btn btn-light big-button condition_plus_sm  "
                    onClick={this.gotoMycodition}
                  >
                    Add condition
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        {/* mobile view */}
        <div className="container-fluid mx-auto w-75 dash-drop">
          <div className="row">
            <div className="dropdown  tab-sm-group tab-btn d-block d-sm-block d-md-none d-lg-none">
              <button
                type="button"
                className="btn btn-default font-medium font-14 tab-select dropdown-toggle"
                data-toggle="dropdown"
              >
                {this.showMobileListName()}<span className="slt"></span>
              </button>
              <div className="dropdown-menu text-center">
                <Link
                  className={"dropdown-item" + (
                    (this.props.match.path ===
                      "/account/dashboard/schedule/:condition"
                      ? "active"
                      : "") +
                    " nav-link font-semibold pointer font-book font-15"
                  )}
                  to={"/account/dashboard/schedule/" + this.state.condition}
                >
                  Schedule
                </Link>
                {this.props.features_list.activityTracker == 0 &&
                  <Link
                    className={"dropdown-item" + (
                      (this.props.match.path ===
                        "/account/dashboard/history/:condition"
                        ? "active"
                        : "") +
                      " nav-link font-semibold pointer font-book font-15"
                    )}
                    to={"/account/dashboard/history/" + this.state.condition}
                  >
                    History
                  </Link>}
                {!this.state.condition.toLowerCase().includes('research') && <Link
                  className={"dropdown-item" + (
                    (this.props.match.path ===
                      "/account/dashboard/programs/:condition"
                      ? "active"
                      : "") +
                    " nav-link font-semibold pointer font-book font-15"
                  )}
                  to={"/account/dashboard/programs/" + this.state.condition}
                >
                  Programmes
                </Link>}
                {this.props.features_list.activityTracker == 1 &&
                  <Link
                    className={"dropdown-item" + (
                      (this.props.match.path ===
                        "/account/dashboard/activity/:condition"
                        ? "active"
                        : "") +
                      " nav-link font-semibold pointer font-book font-15"
                    )}
                    to={"/account/dashboard/activity/" + this.state.condition}
                  >
                    Activity Diary
                  </Link>
                }
                {this.props.userData.isUserFitnessTestCondition &&<Link
                  className={"dropdown-item" + (
                    (this.props.match.path ===
                      "/account/dashboard/progress/:condition"
                      ? "active"
                      : "") +
                    " nav-link font-semibold pointer font-book font-15"
                  )}
                  to={"/account/dashboard/progress/" + this.state.condition}
                >
                  Your Progress
                </Link>}

                <Link
                  className={"dropdown-item" + (
                    (this.props.match.path ===
                      "/account/dashboard/saved/:condition"
                      ? "active"
                      : "") +
                    " nav-link font-semibold pointer font-book font-15"
                  )}
                  to={"/account/dashboard/saved/" + this.state.condition}
                >
                  Saved
                </Link>
                <Link
                  className={"dropdown-item" + (
                    (this.props.match.path ===
                      "/account/dashboard/groups/:condition"
                      ? "active"
                      : "") +
                    " nav-link font-semibold pointer  font-book font-15"
                  )}
                  to={"/account/dashboard/groups/" + this.state.condition}
                >
                  Groups
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* web view */}
        <div className="tab_group mybeampg">
          <div className="container">
            <div className="row">
              <div className=" mx-auto tab_full d-none d-sm-none d-md-block d-lg-block">
                <ul
                  className="nav nav-tabs small justify-content-left mx-auto  tab_ul"
                  role="tablist"
                >
                  <li className="nav-item font-book font-15">
                    <Link
                      className={
                        (this.props.match.path ===
                          "/account/dashboard/schedule/:condition"
                          ? "active"
                          : "") +
                        " nav-link font-semibold pointer font-book font-15"
                      }
                      to={"/account/dashboard/schedule/" + this.state.condition}
                    >
                      Schedule
                    </Link>
                  </li>
                  {this.props.features_list.activityTracker == 0 &&
                    <li className="nav-item">
                      <Link
                        className={
                          (this.props.match.path ===
                            "/account/dashboard/history/:condition"
                            ? "active"
                            : "") +
                          " nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/account/dashboard/history/" + this.state.condition}
                      >
                        History
                      </Link>
                    </li>}

                  {!this.state.condition.toLowerCase().includes('research') && <li className="nav-item">
                    <Link
                      className={
                        (this.props.match.path ===
                          "/account/dashboard/programs/:condition"
                          ? "active"
                          : "") +
                        " nav-link font-semibold pointer font-book font-15"
                      }
                      to={"/account/dashboard/programs/" + this.state.condition}
                    >
                      Programmes
                    </Link>
                  </li>}
                  {this.props.features_list.activityTracker == 1 &&
                    <li className="nav-item">
                      <Link
                        className={
                          (this.props.match.path ===
                            "/account/dashboard/activity/:condition"
                            ? "active"
                            : "") +
                          " nav-link font-semibold pointer font-book font-15"
                        }
                        to={"/account/dashboard/activity/" + this.state.condition}
                      >
                        Activity Diary
                      </Link>
                    </li>
                  }
                  {this.props.userData.isUserFitnessTestCondition && <li className="nav-item">
                    <Link
                      className={
                        (this.props.match.path ===
                          "/account/dashboard/progress/:condition"
                          ? "active"
                          : "") +
                        " nav-link font-semibold pointer font-book font-15"
                      }
                      to={"/account/dashboard/progress/" + this.state.condition}
                    >
                      Your Progress
                    </Link>
                  </li>}
                  <li className="nav-item ">
                    <Link
                      className={
                        (this.props.match.path ===
                          "/account/dashboard/saved/:condition"
                          ? "active"
                          : "") +
                        " nav-link font-semibold pointer font-book font-15"
                      }
                      to={"/account/dashboard/saved/" + this.state.condition}
                    >
                      Saved
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className={
                        (this.props.match.path ===
                          "/account/dashboard/groups/:condition"
                          ? "active"
                          : "") +
                        " nav-link font-semibold pointer  font-book font-15"
                      }
                      to={"/account/dashboard/groups/" + this.state.condition}
                    >
                      Groups
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content dash_tab  col-md-12 p-0 m-t-10 m-b-20">
            {this.props.match.path ===
              "/account/dashboard/schedule/:condition" && (
                <ScheduleCalenderView params={this.props.match.params} props={this.props} history={this.props.history}/>
              )}
            {this.props.match.path ===
              "/account/dashboard/history/:condition" && (
                <HistoryComponent params={this.props.match.params} />
              )}
            {this.props.match.path ===
              "/account/dashboard/programs/:condition" && (
                <ProgramComponent params={this.props.match.params} props={this.props} history={this.props.history} />
              )}
            {this.props.match.path ===
              "/account/dashboard/activity/:condition" && (
                <ActivityComponent params={this.props.match.params} />
              )}
            {this.props.match.path ===
              "/account/dashboard/progress/:condition" && (
                <ProgressComponent params={this.props.match.params} />
              )}
            {this.props.match.path ===
              "/account/dashboard/saved/:condition" && (
                <SavedComponent params={this.props.match.params} />
              )}
            {this.props.match.path ===
              "/account/dashboard/groups/:condition" && (
                <DashboardGroupComponent
                  params={this.props.match.params}
                  location={this.props.location}
                  history={this.props.history}
                />
              )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    condition: state.auth.condition,
    healthcondition_list: state.register.healthcondition_list,
    userData: state.header.logged_userData,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    features_list: state.header.features_list,

  };
};

const mapDispatchToProps = {
  fetchUserDetails,
  start_loader,
  stop_loader
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
