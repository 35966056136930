import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as constand from "../../constant";
import WorkoutsComponent from "../WorkoutsPage/WorkoutsComponent";
import LiveClassesComponent from "../LiveClasses/LiveClassesComponent";
import OndemandAboutComponent from "../OndemandAboutPage/OndemandAboutComponent";
import InstructorsComponent from "../InstructorPage/InstructorsComponent";
import OnDemandGroupComponent from "../OnDemandGroupPage/OnDemandGroupComponent";
import ProgrammesListComponent from "../ProgrammesPage/ProgrammesListsComponent";
import { fetchProgrammeList, ondemandList, fetchClassList, fetchRecentBlogs, ondemandGroupList, getInstructorsList } from "../../actions";
import { Cookies } from "react-cookie-consent";
import { commonService } from "../../_services";
import OndemandListComponent from "../WorkoutsPage/OndemandListComponent";

class OnDemandComponent extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      ondemand_tap: 1,
      is_show_ondeman: false,
      is_show_liveclass: false,
      is_show_about: false,
      is_show_group: false,
      is_show_instructor: false,
      is_show_programme: false,
      type: this.props.match.params.condition,
      isProgrammeExist: false,
      offset: constand.CONSTZERO,
      limit: constand.CONSTSIX,
      current_state: this.props.match.params.condition
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.match.params.condition) {
      var formatCondition = this.props.match.params.condition.replace(" ", "-");
      Cookies.set("condition", formatCondition);
      console.log('*****************Set****************',formatCondition)
    }
    //this.currentStateService();
    this.unlisten = this.props.history.listen((location, action) => {
      console.log("coming")
      if (this.state.current_state !== this.props.match.params.condition) {
        this.setState({
          is_show_ondeman: false,
          is_show_liveclass: false,
          is_show_about: false,
          is_show_group: false,
          is_show_instructor: false,
          is_show_programme: false
        }, function () {
          //   this.currentStateService();
          this.setState({ current_state: this.props.match.params.condition })
        });
      }
    });

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentWillReceiveProps(props) {
    this.setState({ type: this.props.match.params.condition });
  }

  currentStateService() {
    switch (this.props.match.path) {
      case "/on-demand/:condition":
        this.setState({ is_show_ondeman: true });
        this.fetchLiveClassList();
        this.getGroupList();
        this.getBlogList();
        this.getInstructorList();
        this.fetchProgrammeList();
        break;
      case "/liveClasses/:condition":
        this.setState({ is_show_liveclass: true });
        this.getOnDemandList();
        this.getGroupList();
        this.getBlogList();
        this.getInstructorList();
        this.fetchProgrammeList();
        break;
      case "/about/:condition":
        this.setState({ is_show_about: true });
        this.getOnDemandList();
        this.fetchLiveClassList();
        this.getGroupList();
        this.getInstructorList();
        this.fetchProgrammeList();
        break;
      case "/groups/:condition":
        this.setState({ is_show_group: true });
        this.getOnDemandList();
        this.fetchLiveClassList();
        this.getBlogList();
        this.getInstructorList();
        this.fetchProgrammeList();
        break;
      case "/instructor/:condition":
        this.setState({ is_show_instructor: true });
        this.getOnDemandList();
        this.fetchLiveClassList();
        this.getGroupList();
        this.getBlogList();
        this.fetchProgrammeList();
        break;
      case "/programs/:condition":
        this.setState({ is_show_programme: true });
        this.getOnDemandList();
        this.fetchLiveClassList();
        this.getGroupList();
        this.getBlogList();
        this.getInstructorList();
        break;
      default:
        break;
    }
  }

  /* fetch ondemang list */
  getOnDemandList() {
    var dataObj = {
      offset: 0,
      limit: constand.ONDEMAND_LIST_CNT,
      sortby: "",
      keywords: "",
      filters: {}
    };
    var typeFilter = commonService.replaceChar(this.props.match.params.condition, true);
    this.props.ondemandList(dataObj, typeFilter).then(
      response => {
        if (response) {
          this.setState({ is_show_ondeman: true });
        }
      },
      error => {
        this.setState({ is_show_ondeman: false });
      }
    );
  }
  /* fetch liveclass list */
  fetchLiveClassList() {
    var dataObj = {
      "offset": 0,
      "limit": constand.CLASS_LIST_CNT,
      "condition": commonService.replaceChar(this.props.match.params.condition, true)
    };
    this.props.fetchClassList(dataObj).then(
      response => {
        if (response) {
          this.setState({ is_show_liveclass: true });
        }
      },
      error => {
        this.setState({ is_show_liveclass: false });
      }
    );
  }
  /* fetch blog list */
  getBlogList() {
    var param = (this.props.match.params.condition).replace("'", "");
    this.props.fetchRecentBlogs(param).then(
      response => {
        if (response) {
          this.setState({ is_show_about: true });
        }
      },
      error => {
        this.setState({ is_show_about: false });
      }
    );
  }
  /* fetch group list */
  getGroupList() {
    var dataObj = {
      offset: 0,
      limit: this.state.limit,
      search: ''
    };
    var typeFilter = commonService.replaceChar(this.props.match.params.condition, true);
    this.props.ondemandGroupList(dataObj, typeFilter).then(
      response => {
        if (response) {
          this.setState({ is_show_group: true });
        }
      },
      error => {
        this.setState({ is_show_group: false });
      }
    );
  }
  /* fetch instructor list */
  getInstructorList() {
    var dataObj = {
      offset: 0,
      limit: constand.INSTRUCTOR_LIST_CNT,
      sortby: '',
      keywords: '',
      filters: {}
    };
    var typeFilter = commonService.replaceChar(this.props.match.params.condition, true);
    this.props.getInstructorsList(dataObj, typeFilter).then(
      response => {
        if (response) {
          this.setState({ is_show_instructor: true });
        }
      },
      error => {
        this.setState({ is_show_instructor: false });
      }
    )
  }
  /* fetch programme list */
  fetchProgrammeList() {
    var condition = this.props.match.params.condition;
    var data = { condition: commonService.replaceChar(condition, true), offset: 0, limit: this.state.limit }
    this.props.fetchProgrammeList(data).then(
      response => {
        if (response) {
          this.setState({ is_show_programme: true })
        }
      },
      error => {
        this.setState({ is_show_programme: false });
      }
    );
  }

  showMobileListName() {
    var label;
    switch (this.props.match.path) {
      case "/on-demand/:condition":
        label = 'On Demand';
        break;
      case "/liveClasses/:condition":
        label = 'Live Classes';
        break;
      case "/about/:condition":
        label = 'About';
        break;
      case "/groups/:condition":
        label = 'Groups';
        break;
      case "/instructor/:condition":
        label = 'Instructors';
        break;
      case "/programs/:type":
        label = 'Programmes';
        break;
      default:
        label = 'Select';
        break;
    }
    return label;
  }
  
  render() {
    let conditionName = (Cookies.get('condition') != 'undefined' && Cookies.get('condition')) ? Cookies.get('condition').toLowerCase() : constand.CONDITION;
    let searchStr="research"
    return (
      <section className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'condemand' : 'condemand m-t-80'}>

        <div className={(Cookies.get('closeBanner') == 'false' && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'tab_group m-t-20' : 'tab_group m-t-50'}>

          <div className="tab-content  col-md-12 p-0">
              {/* RS condition ondemand page */}
              {this.props.match.path.includes("/on-demand/:condition") && conditionName.includes('research')  && <WorkoutsComponent
                params={this.props.match.params}
                history={this.props.history}
              />}
            <div>
              {this.props.match.path ==="/on-demand/:condition/:roomId?/:workoutId?/:expectedCount?"  && !conditionName.includes('research') && (
                <OndemandListComponent
                  params={this.props.match.params}
                  history={this.props.history}
                />
              )}</div>
            {this.props.match.path === "/liveClasses/:condition" && (
              <LiveClassesComponent
                params={this.props.match.params}
                location={this.props.location}
              />
            )}
            {this.props.match.path === "/about/:condition" && (
              <OndemandAboutComponent params={this.props.match.params}
                location={this.props.location}
                history={this.props.history} />
            )}
            {this.props.match.path === "/groups/:condition" && (
              <OnDemandGroupComponent
                params={this.props.match.params}
                location={this.props.location}
                history={this.props.history}
              />
            )}
            {this.props.match.path === "/instructor/:condition" && (
              <InstructorsComponent params={this.props.match.params} location={this.props.location}
                history={this.props.history} />
            )}
            {this.props.match.path === "/programmes/:condition" && (
              <ProgrammesListComponent params={this.props.match.params} location={this.props.location}
                history={this.props.history} {...this.props} />
            )}
          </div>
        </div>

      </section>
    );
  }
}

export { OnDemandComponent };
const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
    condition: state.auth.condition,
    close_banner: state.header.close_banner,
    close_banner_kd: state.header.close_banner_kd,
    init_condition: state.auth.initial_condition,
  };
};

const mapDispatchToProps = { fetchProgrammeList, ondemandList, fetchClassList, fetchRecentBlogs, ondemandGroupList, getInstructorsList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandComponent);
