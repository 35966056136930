import React from "react";
import { connect } from "react-redux";
import {
  ondemandList,
  clearOndemandList,
  start_loader,
  stop_loader,
  fetchRecentBlogs,
  saveBlog,
  loginModelOpen
} from "../../actions";
import { Link } from "react-router-dom";
import { commonService } from "../../_services";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import { ImageTag, TextWrap } from "../../tags";
import { Helmet } from "react-helmet";

class OndemandAboutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { blogList: [], saveLoader: false };
  }
  componentDidMount() {
    this.getBlogList();
    window.scrollTo(0, 0);
  }
  getBlogList() {
    //this.props.start_loader();
    var param = (this.props.params.condition).replace("'", "");
    this.props.fetchRecentBlogs(param).then(
      response => {
        if (response) {
          if (response.list) {
            this.setState({
              blogList: response.list.blogs ? response.list.blogs : []
            });
          }
          //this.props.stop_loader();
        }
      },
      error => {
        //this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  saveBlog(key) {
    //single method for save and unsave
    if (!this.state.saveLoader)//Avoid multiple api call
    {
      if (this.props.is_auth) {
        this.setState({ saveLoader: true }, function () {
          var item = this.state.blogList[key];
          var dataObj = { blogId: item.uid };
          this.props.saveBlog(dataObj, item.isSaved).then(
            response => {
              if (response) {
                var buffer = [...this.state.blogList];
                buffer[key].isSaved = !buffer[key].isSaved;
                this.setState({ blogList: buffer, saveLoader: false });
                toast.success(response.message);
              }
            },
            error => {
              this.setState({ saveLoader: false });
              toast.error(error);
            }
          )
        });
      } else {
        //not authorized
        this.props.loginModelOpen(true);
      }
    }
  }
  render() {
    return (
      <div className="tab-content  col-md-12 p-0">
        <Helmet>
          <title>{constand.ABOUT_TITLE}{this.props.params.condition}{constand.BEAM}</title>
          <meta property="og:title" content={constand.ABOUT_TITLE + this.props.params.condition + constand.BEAM} />
          <meta property="og:description" content={this.props.params.condition} />
          <meta property="og:image" content={constand.ABOUT_PAGE_IMAGE} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="Beam" />
          <meta name="twitter:image:alt" content={constand.ABOUT_PAGE_IMAGE_ALT} />
        </Helmet>
        <div className="about-demand">
          <div className="about_ondemand">
            <div className="container-fluid mx-auto w-75">
              <div className="text-center p-t-60 p-b-60">
                <h3 className="font-book font-24 darkblue-text m-b-80 ">
                  <span>Physical Activity for</span>{" "}
                  <span className="capitalize_text">
                    {commonService.condition_explode(this.props.params.condition)}
                  </span>
                </h3>
                <div className="row">
                  <div className="col-12 col-md-7 col-lg-7 text-left">
                    <h4 className="m-b-10 brown-txt font-book font-24 p-b-30">
                      Why is physical activity beneficial for people with CF?
                    </h4>
                    <p className="font-18 content-text font-qregular brown-txt m-b-15">
                      <strong>The “textbook” answer:</strong> To improve health
                      of body and mind
                      <br />
                      <br />
                      Doctors tell us all the time that exercise is good for us.
                      In CF that means improving strength and cardiovascular fitness, getting mucus moving from our lungs, building better posture and stronger bones, and so much more.
                      <br />
                      <br />
                      <strong>Our answer:</strong> To feel good <br />
                      <br />
                      Life gets busy, and the simple truth is that it can be
                      hard for us to be physically active. But we always feel
                      good afterwards⁠. If we focus on what makes us feel good,
                      it motivates us to keep going. <br />
                      With activity we find ourselves more energetic, in a better mental state,
                      and beaming with pride at even our smallest accomplishments.
                      Plus, the endorphins you get from exercise can give you an immediate mood boost!

                    </p>
                  </div>
                  <div className="col-12 col-md-5 col-lg-5 text-center p-t-30">
                    <figure>
                      <img
                        src={constand.WEB_IMAGES + "Floor_mat_pair_3_RGB.png"}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ice-bg">
            <div className="container mx-auto w-75">
              <div className="text-center p-t-60 p-b-60">
                <div className="row">
                  <div className="col-12 col-md-8 offset-md-2  col-lg-8 offset-lg-2">
                    <h4 className="m-b-10 brown-txt font-book font-24 p-b-30">
                      People with CF aren’t all the same… are there sessions
                      that meet my needs?
                    </h4>
                    <p className="font-18 content-text font-qregular brown-txt m-b-20">
                      Beam offers a variety of on-demand content and live classes for people
                    with different interests, needs and levels of ability.<br /><br />
                      We are here to help you get started with activity plan, set reachable
                      goals, and motivate you on your path toward achieving them!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container  mx-auto w-75">
            <div className="text-center p-t-60 p-b-30">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2  col-lg-8 offset-lg-2">
                  <h4 className="m-b-10 brown-txt font-book font-24">
                    Try it out today!
                  </h4>
                  <Link
                    to="/register"
                    className="btn btn-yellow font-14 font-book m-t-20 m-b-20"
                  >
                    Start your free trial
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*
              <div className="about2 p-t-60 p-b-60">
                 <div className="container">
                    <div className="row">
                       <div className="col-12 col-md-6 col-lg-6 text-center">
                          <figure><img src="/images/About_blog_img2.png" className="img-fluid" alt=""/></figure>
                       </div>
                       <div className="col-12 col-md-6 col-lg-6 text-left  m-t-60">
                          <h4 className="m-b-10 brown-txt font-book font-24">Lorem Ipsum</h4>
                        <p className="font-18 content-text font-qregular brown-txt m-b-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra enim nec quam pulvinar, nec tincidunt nisi blandit. Nunc a nibh tincidunt, luctus ipsum vel, suscipit dui. Curabitur at mollis ligula, eu blandit lacus. </p>
                        <p className="font-18 content-text font-qregular brown-txt ">
Sed tempus pharetra lorem. Nulla vestibulum interdum odio, sit amet luctus orci elementum et. Curabitur mattis mauris in mauris suscipit sodales. Integer tempor ante nec eros hendrerit, ut congue lorem commodo. Morbi laoreet tincidunt massa.

                        </p>
                       </div>
                    </div>
                 </div>
              </div>
              <div className="lightpurple-bg">
                <div className="container">
                  <div className="text-center p-t-60 p-b-60">

                    <div className="row">
                     <div className="col-12 col-md-8 offset-md-1  col-lg-8 offset-lg-1 text-left  ">
                        <h4 className="m-b-10 content-text brown-txt font-book font-24">Lorem Ipsum</h4>
                        <p className="font-18 content-text font-qregular brown-txt m-b-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce viverra enim nec quam pulvinar, nec tincidunt nisi blandit. Nunc a nibh tincidunt, luctus ipsum vel, suscipit dui. Curabitur at mollis ligula, eu blandit lacus. Fusce euismod mauris non ultricies consequat. Quisque auctor enim eget nulla vestibulum eleifend. In id nisi quis mauris maximus iaculis. Vivamus a nisi nisi. Nunc non ultrices leo. Nunc condimentum vel nulla in vulputate. Sed vitae lacinia erat. In et mi quam. Donec molestie lacinia finibus. Sed non orci vehicula, iaculis mi in, interdum mi. Cras sed vulputate elit. Morbi viverra elit orci, nec tincidunt erat tincidunt non. </p>
                        <p className="font-18 content-text font-qregular brown-txt ">
Sed tempus pharetra lorem. Nulla vestibulum interdum odio, sit amet luctus orci elementum et. Curabitur mattis mauris in mauris suscipit sodales. Integer tempor ante nec eros hendrerit, ut congue lorem commodo. Morbi laoreet tincidunt massa.

                        </p>
                       </div>
                        <div className="col-12 col-md-3  col-lg-3 text-left "></div>
                     </div>
                  </div>
                </div>
              </div>*/}
          <div className="Recent_blog clearfix">
            <div className="container">
              <div className="text-center p-t-60 p-b-60">
                <h3 className="font-book font-24 darkblue-text">
                  Recent blog posts
                </h3>
                <div className="col-md-12 mx-auto m-t-40 m-b-20 p-0 clearfix">
                  {this.state.blogList
                    .filter(e => e.data)
                    .map((item, key) => {
                      return (
                        <div key={key} className="col-lg-4 col-md-4 col-sm-12 float-left m-b-10">
                          <div className="card h-100">
                            <Link to={"/blogs/" + item.uid}>
                              <ImageTag
                                className="card-img-top"
                                src={
                                  item.data.thumbnail_img
                                    ? item.data.thumbnail_img.url
                                    : ""
                                }
                              />
                            </Link>
                            <div className="card-body">
                              <div className="col-md-12 col-lg-12 col-sm-12 float-left p-0">
                                <p className="dblog_head black-txt font-10 font-qregular float-left ">
                                  {(item.tags.toString()).replace(',', '/ ')}
                                </p>
                                <div className="bookmark-left float-right text-right">
                                  <em className={"fa fa-bookmark-o pointer" + ((item.isSaved === true) ? " orangefont" : "")} onClick={() => this.saveBlog(key)}></em>
                                </div>
                                <p className="dblog_title font-semibold font-15 black-txt pull-left text-left m-b-5">
                                  <Link to={"/blogs/" + item.uid}><TextWrap text={(item.data.title && item.data.title.length > 0) ? item.data.title[0].text : ''} limit={constand.BLOGLIST_TITLE_LIMIT} /></Link>
                                </p>
                                <p className="font-qmedium dblog_text m-b-0 pull-left text-left font-13">
                                  <TextWrap text={(item.data.preview_text && item.data.preview_text.length > 0) ? item.data.preview_text[0].text : ''} limit={constand.BLOGLIST_MSG_LIMIT} />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12 text-center clearfix m-t-40 m-b-40">
                  <Link
                    to={"/blog/" + this.props.params.condition}
                    className="btn-voliet font-14  font-book pointer"
                  >
                    View more blog posts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth
  };
};

const mapDispatchToProps = {
  ondemandList,
  clearOndemandList,
  start_loader,
  stop_loader,
  fetchRecentBlogs,
  saveBlog,
  loginModelOpen
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OndemandAboutComponent);
