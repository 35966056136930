import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchAllFeeds, loadMoreFeeds, start_loader, stop_loader, likeThePost, likeTheComments, pinnedPost, followPost, deletePostItem, updateComments, createComments, loadComments, loginModelOpen } from "../../actions";
import * as constand from "../../constant";
import moment from 'moment';
import axios from 'axios';
import Modal from "react-responsive-modal";
import ReactHtmlParser from 'react-html-parser';
import { ImageTag } from "../../tags";
import { Player } from "video-react";
import ExifOrientationImg from 'react-exif-orientation-img';
import Img from 'react-fix-image-orientation';
import * as loadImage from 'blueimp-load-image';
import CanvasImageUploader from 'canvas-image-uploader';
import { pi, max, floor } from 'mathjs';
import LoaderComponent from '../../_layoutComponents/loaderComponent';
import ReactPlayer from 'react-player';
import { commonService } from '../../_services/common.service';
import { MentionsInput, Mention } from "react-mentions";
import Linkify from '../../_helpers/Linkify';


var memberNameWithId;
var memberNameArray = [];

class FeedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allOriginal: [],
            feedList: [],
            offset: constand.CONSTZERO,
            commentsOffset: constand.CONSTZERO,
            condition: this.props.params.condition,
            group: this.props.params.group,
            groupDetail: {},
            cuurentDate: new Date(),
            commentsData: [],
            postComment: '',
            userDetails: {},
            postImage: '',
            editCommentsData: [],
            postFile: {},
            editPostComment: [],
            editPostImage: [],
            editPostFile: [],
            totalPost: constand.CONSTZERO,
            is_model_open: false,
            is_postmodel_open: false,
            postErr: false,
            fileTypeErr: false,
            editFileTypeErr: false,
            replyImage: [],
            replyFile: [],
            editReplyImage: [],
            editReplyFile: [],
            feedMessage: '',
            feedErrMessage: '',
            textAreaRows: 5,
            percentCompleted: 0,
            isEditForm: false,
            isEditPost: false,
            showUserList: null,
            memberName: '',
            showPinned: false,
            limit: 3,
            comments: [],
            comment: ""
        };
        this.onImageChange = this.onImageChange.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentWillMount() {
        if (!this.props.is_auth) {
            const { from } = { from: { pathname: "/home" } };
            this.props.history.push(from);
        }
    }
    componentDidMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
        /* if(this.props.user_data){
            authData = this.props.user_data;
        } */
        //if (this.props.history.location.pathname && (!this.props.groupState.isMember && !this.props.user_data.isCreator) && (!this.props.groupState.isMember && !this.props.groupState.canEdit)) {
        if (this.props.history.location.pathname && !this.props.groupState.isMember && (!authData || (authData && !authData.isCreator && !authData.isStudyLeader)) && !this.props.groupState.canEdit) {
            const { from } = { from: { pathname: "/group/about/" + this.props.groupState.group + "/" + this.props.groupState.condition } };
            this.props.history.push(from);
        } else {
            var user = localStorage.getItem('user');
            user = JSON.parse(user);
            this.setState({ userDetails: user }, () => {
                this.fetchAllFeeds()
            })
        }
    }

    /**
    * Handles fetching all feeds against group
    **/
    fetchAllFeeds() {
        this.props.start_loader();
        var memberName = []
        var data = { group: this.state.group, offset: this.state.offset, type: this.props.componentType, limit: this.state.limit }
        this.props.fetchAllFeeds(data).then(
            response => {
                if (response) {
                    console.log('response', response)
                    memberName = response.groups.Members
                    memberName.forEach((val, i) => {
                        if (val.User)
                            memberNameArray.push(val.User.name + ' ' + val.User.lastName)
                    })
                    function setID(item, index) {
                        var fullname = { id: index, display: item };
                        return fullname;
                    }
                    memberNameWithId = memberNameArray.map(setID);

                    this.groupListResult(response);
                }
                this.props.stop_loader();


            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
                this.props.stop_loader();
            }
        );
    }
    /**

    **/
    groupListResult(originalList) {
        let existingData = this.state.feedList;
        let newData = originalList.post;
        let results = [];
        let helper = [];
        if (this.state.offset != 0) {
            newData.map(function (item, key) {
                var existPost = existingData.filter(val => val.id === item.id)
                if (existPost && existPost.length > 0) {
                    let index = existingData.findIndex(val => val.id === item.id);
                    existingData[index] = item;
                } else {
                    helper.push(item)
                }

            });
            console.log('existingData', existingData)
            console.log('helper', helper)
            var newList = [...existingData, ...helper]
        } else {
            var newList = newData;
        }
        console.log('newList', newList)
        this.setState({
            allOriginal: originalList,
            feedList: newList,
            groupDetail: originalList.groups,
            totalPost: originalList.totalpost
        })
        if (this.state.feedMessage) {
            toast.success(this.state.feedMessage);
            this.setState({ feedMessage: '' });
        }
        if (this.state.feedErrMessage) {
            toast.error(this.state.feedErrMessage);
            this.setState({ feedErrMessage: '' });
        }
    }
    /**
    *loadMorePosts
    **/
    loadMorePosts() {
        this.setState({
            offset: 0,
            limit: 100,
        }, () => {
            this.fetchAllFeeds();

        });

    }

    /**
    * like or unlike the post
    **/
    likePost(item, isLike) {
        if (this.props.is_auth) {
            this.props.start_loader();
            var data = { postId: item.id, isLike: isLike }
            this.props.likeThePost(data).then(
                response => {
                    if (response) {
                        this.setState({
                            offset: constand.CONSTZERO,
                            feedMessage: response.message
                        }, () => {
                            this.fetchAllFeeds();
                        });
                    }
                    this.props.stop_loader();
                },
                error => {
                    this.setState({
                        Loading: false,
                        feedErrMessage: error
                    });
                    this.props.stop_loader();
                }
            );
        } else {
            this.props.loginModelOpen(true);
        }
    }
    /**
    render like buton
    **/
    renderLikeButton(item) {
        var wallUsers = item.wallUsers;
        var userExist = {};
        console.log('wallUsers', wallUsers)
        if (this.props.user_data && this.props.user_data.id) {
            userExist = wallUsers.filter(item => item.UserId === this.props.user_data.id)
        }
        if (item && (!item.wallUsers || item.wallUsers.length < 1 || !userExist || (userExist && userExist[0] && userExist[0].hasLiked === 'no'))) {
            return (
                <span onClick={() => {
                    this.likePost(item, 'like')
                }} className={"m-r-30 graytext pointer" + (this.state.isEditPost ? ' not-active' : '')} ><img src={constand.WEB_IMAGES + "like.png"} alt="" /> Like</span>
            )
        } else {
            return (<span onClick={() => {
                this.likePost(item, 'unlike')
            }} className={"m-r-30 graytext pointer" + (this.state.isEditPost ? ' not-active' : '')}><img src={constand.WEB_IMAGES + "like.png"} alt="" /> Unlike</span>
            )
        }
    }


    // like or unlike comments
    likeComments(item, isLike, key) {
        if (this.props.is_auth) {
            this.props.start_loader();
            console.log("items", item);
            var data = { postId: item.PostId, commentId: item.id, isLike: isLike }
            console.log("data", data);
            this.props.likeTheComments(data).then(
                response => {
                    if (response) {
                        this.setState({
                            offset: constand.CONSTZERO,
                            feedMessage: response.message
                        }, () => {
                            this.fetchAllFeeds();
                        });
                    }
                    this.props.stop_loader();
                },
                error => {
                    this.setState({
                        Loading: false,
                        feedErrMessage: error
                    });
                    this.props.stop_loader();
                }
            );
        } else {
            this.props.loginModelOpen(true);
        }
    }


    // command like button render function
    renderCommentsLikeButton(item, key) {
        console.log("myitem", item);
        console.log("this.props.user_data", this.props.user_data);
        var CommentUsers = item.CommentUsers; //item.Comments && item.Comments[0].CommentUsers; 
        var userExist = {};
        if (this.props.user_data && this.props.user_data.id) {
            userExist = CommentUsers.filter(item => item.UserId === this.props.user_data.id)
            console.log("userExist", userExist)
        }
        if (item && (!item.CommentUsers || item.CommentUsers.length < 1 || !userExist || (userExist && userExist[0] && userExist[0].hasCommentLiked === 'no'))) {
            return (
                <span onClick={() => {
                    this.likeComments(item, 'like', key)
                }} className={"m-r-30 graytext pointer" + (this.state.isEditPost ? ' not-active' : '')} ><img src={constand.WEB_IMAGES + "like.png"} alt="" /> Like</span>
            )
        } else {
            return (<span onClick={() => {
                this.likeComments(item, 'unlike', key)
            }} className={"m-r-30 graytext pointer" + (this.state.isEditPost ? ' not-active' : '')}><img src={constand.WEB_IMAGES + "like.png"} alt="" /> Unlike</span>
            )
        }
    }

    //isPinned
    pinPost(item, val, key) {
        this.setState({
            showPinned: !this.state.showPinned
        })

        if (this.props.is_auth) {
            this.props.start_loader();
            var data = { postId: item.id, value: val }
            this.props.pinnedPost(data).then(
                response => {
                    if (response) {
                        this.setState({
                            offset: constand.CONSTZERO,
                            feedMessage: response.message
                        }, () => {
                            this.fetchAllFeeds();
                        });
                    }
                    this.props.stop_loader();
                },
                error => {
                    this.setState({
                        Loading: false,
                        feedErrMessage: error
                    });
                    this.props.stop_loader();
                }
            );
        } else {
            this.props.loginModelOpen(true);
        }
    }

    //pin button return function
    renderPinButton(item, key) {
        var wallUsers = item.wallUsers;
        var userExist = {};
        var pinData = {
            pin: 1,
            unpin: 0,
        };

        if (this.props.user_data && this.props.user_data.id) {
            userExist = wallUsers.filter(item => item.UserId === this.props.user_data.id)
        }
        if (item && (!item.User || item.User.length < 1 || !item.User || (item.pinnedPost === false))) {
            return (
                <span onClick={() => {
                    this.pinPost(item, pinData.pin, key)
                }} className={" graytext pointer" + (this.state.isEditPost ? ' not-active' : '')} ><img src={constand.WEB_IMAGES + "pin-image.png"} alt="" className='w-30' /> Pin</span>
            )
        } else {
            return (<span onClick={() => {
                this.pinPost(item, pinData.unpin, key)
            }} className={" graytext pointer" + (this.state.isEditPost ? ' not-active' : '')}><img src={constand.WEB_IMAGES + "pin-image.png"} alt="" className='w-30 ' /> Unpin</span>
            )
        }
    }
    /**
    render follow buton
    **/
    renderFollowButton(item) {
        var wallUsers = item.wallUsers;
        var userExist = {};
        if (this.props.user_data && this.props.user_data.id) {
            userExist = wallUsers.filter(item => item.UserId === this.props.user_data.id)
        }
        if (this.props.is_auth && item.postedBy !== this.props.user_data.id) {
            if (item && (!item.wallUsers || item.wallUsers.length < 1 || !userExist || (userExist && userExist[0] && userExist[0].hasFollowed === 'no'))) {
                return (
                    <span onClick={() => {
                        this.followThePost(item, 'follow')
                    }}
                        className="graytext pointer"><img src={constand.WEB_IMAGES + "follow-plus.png"} alt="" /> Follow Post</span>

                )
            } else {
                return (<span onClick={() => {
                    this.followThePost(item, 'unfollow')
                }} className="graytext pointer"><img src={constand.WEB_IMAGES + "follow-plus.png"} alt="" /> Unfollow Post</span>
                )
            }
        }
    }

    /**
    follow the post
    **/
    followThePost(item, isFollow) {
        if (this.props.is_auth) {
            this.props.start_loader();
            var data = { postId: item.id, isFollow: isFollow }
            this.props.followPost(data).then(
                response => {
                    if (response) {
                        this.setState({
                            offset: constand.CONSTZERO,
                            feedMessage: response.message
                        }, () => {
                            this.fetchAllFeeds();
                        });
                    }
                    this.props.stop_loader();
                },
                error => {
                    this.setState({
                        Loading: false,
                        feedErrMessage: error
                    });
                    this.props.stop_loader();
                }
            );
        } else {
            this.props.loginModelOpen(true);

        }
    }

    /**
    * delete Comment from post
    */
    deleteItem(item, itemType, data) {
        this.props.start_loader();
        if (itemType === 'comment') {
            var data = { postId: item.id, itemType: itemType, commentId: data.id }
        } else {
            data = { postId: item.id, itemType: itemType }
        }
        this.props.deletePostItem(data).then(
            response => {
                if (response) {
                    this.setState({
                        offset: constand.CONSTZERO,
                        feedMessage: response.message
                    }, () => {
                        this.fetchAllFeeds();
                    });
                }
                this.props.stop_loader();

            },
            error => {
                this.setState({
                    Loading: false,
                    feedErrMessage: error
                });
                this.props.stop_loader();
            }
        );
    }
    /**
   * edit Comment from post
   */
    editComment(postId, commentId) {
        this.props.start_loader();
        this.setState({ editRplyFileTypeErr: false });
        var data = this.state.editCommentsData[commentId] ? this.state.editCommentsData[commentId] : '';
        let postData = new FormData();
        postData.append('file', this.state.editReplyFile[commentId]);
        postData.append('commentId', commentId);
        postData.append('postId', postId);
        var urlText = this.isUrlValid(data);
        /*var urlText = data;
        if (validateString) {
            urlText = "<a href='https://" + urlText + "'>" + urlText + "</a>"
        }*/
        postData.append('commentData', urlText);
        let url = constand.BACKEND_URL + '/api/groups/feed/editcomment';
        let authData = JSON.parse(localStorage.getItem('user'));
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': authData.token
            }
        }
        this.postUpdateAction(url, postData, config, postId);

    }

    /**
    createComments
    **/
    createPostComments(postId) {
        this.props.start_loader();
        this.setState({ replyFileTypeErr: false });
        var data = this.state.commentsData[postId] ? this.state.commentsData[postId] : '';
        let postData = new FormData();
        postData.append('file', this.state.replyFile[postId]);
        postData.append('postId', postId);
        var urlText = this.isUrlValid(data);
        /*  var urlText = data;
          if (validateString) {
              urlText = "<a href='https://" + urlText + "'>" + urlText + "</a>"
          }*/
        postData.append('commentData', urlText);
        let url = constand.BACKEND_URL + '/api/groups/feed/comment';
        let authData = JSON.parse(localStorage.getItem('user'));
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': authData.token
            }
        }
        this.postUpdateAction(url, postData, config, postId);

    }
    handleChange = (event, i, newValue, newPlainTextValue, mentions) => //for twoway binding
    {
        var comment = newPlainTextValue; //event.target.value
        this.setState({
            commentsData: { ...this.state.commentsData, [i]: comment },
            memberName: ''
        });
    }
    /**
    load more comments
    **/
    loadMoreComments(item) {
        var data = {
            postId: item.id,
            offset: this.state.commentsOffset,
        }
        this.props.start_loader();
        this.props.loadComments(data).then(
            response => {
                if (response) {
                    var newComments = response.comments;
                    var existingComments = item.Comments;
                    if (newComments && newComments.length > 0) {
                        item.Comments = [...existingComments, ...newComments];
                    }
                }
                this.props.stop_loader();
            },
            error => {
                this.setState({
                    Loading: false
                });
                toast.error(error);
                this.props.stop_loader();
            }
        );
    }
    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    _canvasRotate(file, orientation, cb) {

        console.log('orientation', orientation)
        var ctx = this.refs.canvas.getContext('2d');
        var url = URL.createObjectURL(file);
        var me = this; // keep reference for inner call
        var img = new Image();
        img.onload = function () {

            const maxDim = max(img.height, img.width);
            console.log('maxDim', maxDim)
            /* if ([90, 270].indexOf(rotation) > -1) {
                me.refs.canvas.width = img.height;
                me.refs.canvas.height = img.width;
            } else {
                me.refs.canvas.width = img.width;
                me.refs.canvas.height = img.height;
            } */
            if (!orientation || orientation > 8) {
                me.refs.canvas.width = img.width;
                me.refs.canvas.height = img.height;
            }
            if (orientation > 4) {
                me.refs.canvas.width = img.height;
                me.refs.canvas.height = img.width;
            }
            var width = img.width;
            var height = img.height;
            //ctx.rotate(90 * (pi / 180));

            switch (orientation) {
                case 0:
                    console.log('oth case')
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -maxDim / 2, -maxDim / 2);
                    break;
                case 2:
                    // horizontal flip
                    ctx.translate(width, 0)
                    ctx.scale(-1, 1)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
                case 3:
                    // 180° rotate left
                    ctx.translate(width, height)
                    ctx.rotate(pi)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
                case 4:
                    // vertical flip
                    ctx.translate(0, height)
                    ctx.scale(1, -1)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
                case 5:
                    // vertical flip + 90 rotate right
                    ctx.rotate(0.5 * pi)
                    ctx.scale(1, -1)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
                case 6:
                    console.log('6th orinetation')
                    ctx.translate(me.refs.canvas.width / 2, me.refs.canvas.height / 2);
                    ctx.rotate(pi / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    // 90° rotate right
                    //ctx.rotate(0.5 * pi)
                    //ctx.translate(0, -height)
                    break
                case 7:
                    // horizontal flip + 90 rotate right
                    ctx.rotate(0.5 * pi)
                    ctx.translate(width, -height)
                    ctx.scale(-1, 1)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
                case 8:
                    // 90° rotate left
                    ctx.rotate(-0.5 * pi)
                    ctx.translate(-width, 0)
                    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
                    ctx.drawImage(this, -this.width / 2, -this.height / 2);
                    break
            }

            var imageData = ctx.getImageData(0, 0, me.refs.canvas.width, me.refs.canvas.height);
            // this line needs to go here
            var dataImg = me.refs.canvas.toDataURL("image/png"); // note `me` being used here
            //dataImg = dataImg.replace(/^data:image\/(png|jpg);base64,/, "")

            console.log('dataImg', dataImg);
            //Usage example:
            var file = me.dataURLtoFile(dataImg, 'hello.png');
            console.log(file);
            cb(file);
            // consider a callback to pass result to next function in chain
        }
        // set src last
        img.src = url;
    }
    /**
    on image change event
    **/
    onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            console.log('onImageChange-functionscall')
            //if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/gif') {

            this.setState({
                fileTypeErr: false,
                postImage: URL.createObjectURL(event.target.files[0]),
                postFile: event.target.files[0]
            });
            /*} else {
                this.setState({
                    fileTypeErr: true,
                    postImage: '',
                    postFile: {}
                });
            }*/
        }
    }

    /**
    to submit comments on enter
    **/
    _handleKeyDown = (postId, val, e) => {
        var value = e.key;
        var atChar = value.charAt(0)
        switch (value) {
            case 'Enter':
                if (this.state.commentsData[postId] || (this.state.replyImage && this.state.replyImage[postId])) {
                    this.createPostComments(postId)
                }
                break;
            case '@':
                if (atChar === '@') {
                    console.log("@ key pressed")
                    this.setState({
                        showUserList: val,
                        memberName: ''
                    });
                }
                break;
            case "Backspace":
                console.log("BackSpace space pressed");
                this.setState({
                    showUserList: null,
                    memberName: ''
                });
                break;

            case "Delete":
                console.log("Delete space pressed");
                this.setState({
                    showUserList: null,
                    memberName: ''
                });
                break;


        }

    }

    bindUserName = (event, i) => {
        console.log("bindUserName-commentsData", this.state.commentsData);
        console.log("i=", i);
        console.log("Member Name=", event.target.value);
        var member = event.target.value
        this.setState({
            memberName: member,
            showUserList: null,
            commentsData: { ...this.state.commentsData, [i]: this.state.commentsData[i] + member },
        })
    }
    /**
    * loadEditComment
    */
    loadEditComment(item, comment) {
        comment.showTextInput = !comment.showTextInput;
        if (comment.showTextInput) {
            var imag = comment.commentImg ? constand.BACKEND_URL + constand.POST_IMG_PATH + comment.commentImg : constand.WEB_IMAGES + 'no-image.png';
            this.setState({
                editCommentsData: { ...this.state.editCommentsData, [comment.id]: comment.commentMsg },
                editReplyImage: { ...this.state.editReplyImage, [comment.id]: imag }
            });
        }

    }
    /**
    edit comments input change
    **/
    handleEditCommentChange = (event, i, newValue, newPlainTextValue, mentions) => {
        this.setState({
            editCommentsData: { ...this.state.editCommentsData, [i]: newPlainTextValue }
        });
    }
    /**
    edit comments key down option
    **/
    _handleCommentsKeyDown(commentId, postId, e) {
        if (e.key === 'Enter') {
            if (this.state.editCommentsData[commentId] || (this.state.editReplyImage && this.state.editReplyImage[commentId])) {
                this.editComment(postId, commentId)
            }
        }
    }
    /**
    new post input change
    **/
    handlePostChange(e) {
        this.setState({ postComment: e.target.value, postErr: false })
    }
    //check type string is url or not
    isUrlValid(userInput) {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        var urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        console.log(userInput.replace(urlRegex, function (url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        }));
        if (userInput.match(urlRegex)) {
            return userInput.replace(urlRegex, function (url) {
                return '<a target="_blank" href="' + url + '">' + url + '</a>';
            });
        } else {
            return userInput;
        }
        /*        if (res == null)
                    return false;
                else
                    return true;*/
    }
    preCreatePost() {
        this.setState({ isEditForm: false })
        console.log('precreatepost')
        if (this.state.allOriginal.canEdit && this.props.componentType == 'wall') {
            //admin post
            this.setState({ is_postmodel_open: true });
        } else {
            this.createPost();
        }
    }
    /**
    create post against on group
    **/
    createPost() {
        if (this.state.postComment || (this.state.postFile && this.state.postFile.name)) {
            this.setState({ postErr: false, fileTypeErr: false })
            let postData = new FormData();
            var me = this;
            console.log('typesof', this.state.postFile)
            console.log('this.state.postFile', (this.state.postFile))
            if (this.state.postFile && (this.state.postFile.type === 'image/png' || this.state.postFile.type === 'image/jpg' || this.state.postFile.type === 'image/jpeg' || this.state.postFile.type === 'image/gif')
            ) {
                console.log('object exist-img')
                const loadImageOptions = { canvas: true, orientation: true }
                loadImage.parseMetaData(this.state.postFile, (data) => {
                    var orientation = 0;
                    if (data.exif && data.exif.get('Orientation')) {
                        orientation = data.exif.get('Orientation')
                        loadImageOptions.orientation = orientation;
                        console.log('orientation', loadImageOptions.orientation)
                    }
                    //loadImage(this.state.postFile, (canvas) => {
                    this._canvasRotate(this.state.postFile, orientation, function (dataimge) {
                        console.log('dataimgeload', dataimge)
                        //me.setState({ postFile: dataimge })
                        postData.append('file', dataimge);
                        //postData.append('file', this.state.postFile);
                        postData.append('comId', me.state.groupDetail.id);
                        var urlText = me.isUrlValid(me.state.postComment);
                        postData.append('postData', urlText);
                        postData.append('type', me.props.componentType);
                        postData.append('condition', me.props.groupState.condition);

                        console.log('postData')
                        let url = constand.BACKEND_URL + '/api/groups/feed/post';
                        let authData = JSON.parse(localStorage.getItem('user'));
                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data',
                                'Authorization': authData.token
                            },
                            onUploadProgress: progressEvent => {
                                console.log('progressEvent', progressEvent)

                                let percentCompleted = floor((progressEvent.loaded * 100) / progressEvent.total);
                                me.setState({ percentCompleted: percentCompleted })
                                console.log('percentCompleted', percentCompleted)
                                // do whatever you like with the percentage complete
                                // maybe dispatch an action that will update a progress bar or something
                            }
                        }
                        me.postUpdateAction(url, postData, config);

                    });

                    //}, loadImageOptions)
                });


            } else {
                if (this.state.postFile && this.state.postFile.type && !(this.state.postFile.type === 'image/png' || this.state.postFile.type === 'image/jpg' || this.state.postFile.type === 'image/jpeg' || this.state.postFile.type === 'image/gif')) {
                    console.log('test postfile', this.state.postFile.type)
                    postData.append('file', this.state.postFile);
                }
                postData.append('comId', this.state.groupDetail.id);
                var urlText = this.isUrlValid(this.state.postComment);
                postData.append('postData', urlText);
                postData.append('type', this.props.componentType);
                postData.append('condition', this.props.groupState.condition);

                // console.log('postData', postData)
                let url = constand.BACKEND_URL + '/api/groups/feed/post';
                let authData = JSON.parse(localStorage.getItem('user'));
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': authData.token
                    },
                    onUploadProgress: progressEvent => {
                        console.log('progressEvent', progressEvent)

                        let percentCompleted = floor((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({ percentCompleted: percentCompleted })
                        console.log('percentCompleted', percentCompleted)
                        // do whatever you like with the percentage complete
                        // maybe dispatch an action that will update a progress bar or something
                    }
                }
                this.postUpdateAction(url, postData, config);
            }

        } else {
            this.setState({ postErr: true })
        }
    }
    /**
    create or edit post
    **/
    postUpdateAction(url, postData, config, postId = null) {
        console.log('postUpdateAction start')
        this.setState({ isLoading: true })
        this.props.start_loader();
        axios.post(url, postData, config)
            .then(response => {
                console.log('postUpdateAction end')
                this.fileInput.value = "";
                this.setState({
                    isLoading: false,
                    offset: constand.CONSTZERO,
                    postComment: '',
                    postImage: '',
                    postFile: {},
                    feedMessage: response.data.message,
                    percentCompleted: '100'
                }, () => {
                    if (postId) {
                        this.replyCallback(postId);
                    }
                    this.fetchAllFeeds();
                })
                this.props.stop_loader();
            })
            .catch(error => {
                this.setState({ feedErrMessage: error })
                this.props.stop_loader();
            });
    }
    replyCallback(postId) {
        this.setState({ commentsData: { ...this.state.commentsData, [postId]: '' }, replyImage: [], replyFile: [] });
    }
    /**
    loadEditPost
    **/
    loadEditPost(item) {
        item.showEditPost = !item.showEditPost;
        let handler = [...this.state.feedList];
        console.log('Loadeditpost', handler)
        var postmsg = ReactHtmlParser(item.postMsg)
        var index = handler.findIndex(x => x.id === item.id);
        if (index > -1) {
            handler.splice(index, 1);
        }
        this.setState({ isEditPost: !this.state.isEditPost, feedList: [...this.state.feedList] });
        if (item.showEditPost) {
            var imag = item.postImg ? constand.BACKEND_URL + constand.POST_IMG_PATH + item.postImg : item.postImg; //: '/images/no-image.png';
            this.setState({
                editPostComment: { ...this.state.editPostComment, [item.id]: postmsg[0] },
                editPostImage: { ...this.state.editPostImage, [item.id]: imag }
            }, function () {
                this.setFilledTextareaHeight(item.id);
            });
        }
    }
    handleEditPostChange(i, event) {

        this.setState({
            editPostComment: { ...this.state.editPostComment, [i]: event.target.value },

        });
    }
    editPostSubmit(postId) {
        this.setState({ isEditForm: true })
        if (this.state.editPostComment[postId] || (this.state.editPostFile[postId] && this.state.editPostFile[postId].name)) {
            this.setState({ editFileTypeErr: false })
            let postData = new FormData();
            //image rotate
            var me = this;
            var imageEditFile = this.state.editPostFile[postId];
            console.log('edit typesof', imageEditFile)
            if (imageEditFile && (imageEditFile.type === 'image/png' || imageEditFile.type === 'image/jpg' || imageEditFile.type === 'image/jpeg' || imageEditFile.type === 'image/gif')
            ) {
                console.log('edit object exist-img')
                const loadImageOptions = { canvas: true, orientation: true }
                loadImage.parseMetaData(imageEditFile, (data) => {
                    var orientation = 0;
                    if (data.exif && data.exif.get('Orientation')) {
                        orientation = data.exif.get('Orientation')
                        loadImageOptions.orientation = orientation;
                        console.log('edit orientation', loadImageOptions.orientation)
                    }
                    this._canvasRotate(imageEditFile, orientation, function (dataimge) {
                        postData.append('comId', me.state.groupDetail.id);
                        postData.append('postId', postId);
                        var urlText = me.isUrlValid(me.state.editPostComment[postId]);
                        postData.append('postData', urlText);
                        postData.append('file', dataimge);
                        postData.append('condition', me.props.groupState.condition);

                        let url = constand.BACKEND_URL + '/api/groups/feed/editpost';
                        let authData = JSON.parse(localStorage.getItem('user'));
                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data',
                                'Authorization': authData.token
                            },
                            onUploadProgress: progressEvent => {
                                console.log('progressEvent', progressEvent)

                                let percentCompleted = floor((progressEvent.loaded * 100) / progressEvent.total);
                                me.setState({ percentCompleted: percentCompleted })
                                console.log('percentCompleted', percentCompleted)
                                // do whatever you like with the percentage complete
                                // maybe dispatch an action that will update a progress bar or something
                            }

                        }
                        me.postUpdateAction(url, postData, config);

                    });
                });
            }
            else {

                postData.append('comId', this.state.groupDetail.id);
                postData.append('postId', postId);
                var urlText = this.isUrlValid(this.state.editPostComment[postId]);
                postData.append('postData', urlText);
                if (imageEditFile && !(imageEditFile.type === 'image/png' || imageEditFile.type === 'image/jpg' || imageEditFile.type === 'image/jpeg' || imageEditFile.type === 'image/gif')
                ) {
                    console.log('edit file')
                    postData.append('file', this.state.editPostFile[postId]);
                }
                postData.append('condition', this.props.groupState.condition);

                let url = constand.BACKEND_URL + '/api/groups/feed/editpost';
                let authData = JSON.parse(localStorage.getItem('user'));
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': authData.token
                    },
                    onUploadProgress: progressEvent => {
                        console.log('progressEvent', progressEvent)

                        let percentCompleted = floor((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({ percentCompleted: percentCompleted })
                        console.log('percentCompleted', percentCompleted)
                        // do whatever you like with the percentage complete
                        // maybe dispatch an action that will update a progress bar or something
                    }
                }
                this.postUpdateAction(url, postData, config);
            }
        }
    }
    _handleEditPostKeyDown(postId, isbutton, e) {

        if (e.key === 'Enter' || isbutton) {
            if (this.state.editPostComment[postId] || (this.state.editPostFile[postId] && this.state.editPostFile[postId].name)) {
                this.setState({ editFileTypeErr: false })
                let postData = new FormData();
                postData.append('file', this.state.editPostFile[postId]);
                postData.append('comId', this.state.groupDetail.id);
                postData.append('postId', postId);
                var urlText = this.isUrlValid(this.state.editPostComment[postId]);
                /*var urlText = this.state.editPostComment[postId];
                if (validateString) {
                    urlText = "<a href='https://" + urlText + "'>" + urlText + "</a>"
                }*/
                postData.append('postData', urlText);
                postData.append('condition', this.props.groupState.condition);

                let url = constand.BACKEND_URL + '/api/groups/feed/editpost';
                let authData = JSON.parse(localStorage.getItem('user'));
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': authData.token
                    }
                }
                this.postUpdateAction(url, postData, config);
            }
        }
    }

    setFilledTextareaHeight(itemid) {

        if (document.getElementsByClassName('textarea-' + itemid).length > 0) {
            console.log('height', document.getElementsByClassName('textarea-' + itemid))
            var textHt = document.getElementsByClassName('textarea-' + itemid)[0].getBoundingClientRect().height;
            console.log('height', textHt)
            this.setState({ ["textAreaRows" + itemid]: textHt + 80 })

            /* this.setState({
                textAreaRows: textHt+80,
            }); */
        }
    }
    /**
    on post edit Image Change
    **/
    onEditImageChange(i, event) {
        if (event.target.files && event.target.files[0]) {
            //if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/gif') {
            this.setState({
                editFileTypeErr: false,
                editPostImage: { ...this.state.editPostImage, [i]: URL.createObjectURL(event.target.files[0]) },
                editPostFile: { ...this.state.editPostFile, [i]: event.target.files[0] },
            });
            /*} else {
                this.setState({
                    editFileTypeErr: true,
                    editPostImage: [],
                    editPostFile: []
                });
            }*/
        }
    }

    /**
    * to open modal popup
    */
    openModalPopup(item, itemType, data) {
        this.setState({
            is_model_open: true,
            deleteItem: item,
            deleteItemType: itemType,
            deleteData: data
        })
    }
    closeModel() //for close the login model
    {
        this.setState({
            is_model_open: false,
            is_postmodel_open: false
        })
    }
    onCloseModal() { }
    confirmPopup() {
        const { deleteItem, deleteItemType, deleteData } = this.state;
        this.closeModel();
        this.deleteItem(deleteItem, deleteItemType, deleteData);
    }
    confirmPostPopup() {
        this.createPost();
        this.closeModel();
    }
    //when admin post in wall 
    renderAdminPostModal() {
        console.log('renderAdminPostModal', this.state.is_postmodel_open)
        if (this.state.is_postmodel_open) {
            return (
                <Modal
                    className="removebbg-popup"
                    open={this.state.is_postmodel_open}
                    onClose={this.onCloseModal}
                    center
                >
                    <div
                        className="modal-dialog  start-popup forgot-pass common_design modal-width--custom"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header header-styling  border-0">
                                <h5
                                    className="modal-title1 text-center col-md-12 p-0  font-semibold white-txt"
                                    id="exampleModalLabel font-medium"
                                >
                                    Wait!
                                </h5>

                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() => this.closeModel()}
                                    >
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body pb-0">
                                <div className="mx-auto w-80">
                                    <p className="font-book black-txt text-center font-15 m-t-60 font-normal">
                                        Are you sure you want to post this message to the wall?</p>
                                    <p className="font-book black-txt text-center font-15 m-t-60 font-normal">
                                        Note that all {this.state.groupDetail.Members.length} members of your group will be informed of your post by email. (We don't send this email for another 5 minutes yet so you will have time to edit if needs be)!
                                    </p>

                                </div>

                                <div className="mx-auto w-80">

                                    <button type="button" className="w-50 btn btn-login  " onClick={() => { this.confirmPostPopup() }}>
                                        I'm sure</button>
                                    <button type="button" onClick={() => { this.closeModel() }} className="btn btn-login m-t-20 m-b-20 m-l-5">I'd better not</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    }

    /**
    * render Modal popup for success message
    */
    renderModalPopup() {
        if (this.state.is_model_open) {
            return (
                <Modal className="removebbg-popup" open={this.state.is_model_open} onClose={this.onCloseModal} center >
                    <div className="modal-dialog schedule_block modal-width--custom" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title" id="exampleModalLiveLabel">Delete</div>
                                <button type="button" className="close" onClick={() => { this.closeModel() }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p> Are you sure want to delete? </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-login " onClick={() => { this.confirmPopup() }}> Continue </button>
                                <button type="button" onClick={() => { this.closeModel() }} className="btn btn-login ">Cancel</button>
                            </div>
                        </div>

                    </div>
                </Modal>
            )
        }
    }
    /**
    on reply  image change event
    **/
    onReplyImageChange = (i, event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/gif') {
                this.setState({
                    replyFileTypeErr: false,
                    replyImage: { ...this.state.replyImage, [i]: URL.createObjectURL(event.target.files[0]) },
                    replyFile: { ...this.state.replyFile, [i]: event.target.files[0] },
                });
                event.target.value = null;
            } else {
                this.setState({
                    replyFileTypeErr: true,
                    replyImage: [],
                    replyFile: []
                });
            }
        }
    }
    /**
     * on edit reply image change
     */
    onReplyEditImageChange(i, event) {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/gif') {
                this.setState({
                    editRplyFileTypeErr: false,
                    editReplyImage: { ...this.state.editReplyImage, [i]: URL.createObjectURL(event.target.files[0]) },
                    editReplyFile: { ...this.state.editReplyFile, [i]: event.target.files[0] },
                });
            } else {
                this.setState({
                    editRplyFileTypeErr: true,
                    editReplyImage: [],
                    editReplyFile: []
                });
            }
        }
    }

    /* render image data */
    _renderImageContent(item) {
        const source_url = (item.postImg) ? constand.POST_IMG_PATH + item.postImg : constand.WEB_IMAGES + 'no-image.png';
        if (source_url.match(/\.(jpeg|jpg|gif|png)$/)) {
            /*return (<Img
                src={source_url} className="size-fix img-fluid img-responsive"
            />)
              return(<ExifOrientationImg
                 src={source_url} className="size-fix img-fluid img-responsive"
               />) */
            return (<ImageTag src={source_url} className="size-fix img-fluid img-responsive" />);
        } else if (source_url.includes('pdf')) {
            return (<div><a href={source_url} target="_blank"><ImageTag src={constand.S3_API_IMG + '/pdf-icon.png'} className="pdfImage" /></a>{commonService.removeTimestampFilename(item.postImg)}</div>);
        } else {
            /* return (
            <Player
                ref={player => {
                    this.player = player;
                }}
                className="w-100 border-0"
                height="400"
                playsInline
                src={source_url}
            ></Player>); */
            return (<ReactPlayer url={source_url} playing={false} controls={true} style={{
                width: '100%',
                height: '439px'
            }} />);
            //return (<iframe src={source_url} className="overflow_hidden"></iframe>);
        }
    }

    /* render image data */
    _renderEditImageContent(imageUrl, raw_file) {
        if (raw_file && raw_file.type) //blob
        {
            return (this._renderThumbnailContent(imageUrl, raw_file));
        } else { //url
            const source_url = imageUrl;
            if (source_url.match(/\.(jpeg|jpg|gif|png)$/)) {
                return (<img src={source_url} height="100" width="100" />);
            } else if (source_url.match('pdf')) {
                return (<ImageTag src={constand.S3_API_IMG + '/pdf-icon.png'} className="pdfImage" />);
            } else {
                return (<ReactPlayer url={source_url} playing controls={false} width='30%' height='50%' style={{
                    width: '100%',
                    height: '100%'
                }} />);
                //return (<iframe src={source_url} height="100" width="100" className="overflow_hidden"></iframe>);
            }
        }
    }

    /* render image data */
    _renderThumbnailContent(source_url, videoObj) {
        let type = videoObj.type;
        if (type.includes('image/')) {
            return (<Img
                src={source_url} height="100" width="100"
            />);
            /*  return (<ExifOrientationImg
                 src={source_url} height="100" width="100"
             />); */
            //return (<img src={source_url} height="100" width="100" />);
        } else if (type.includes('video/')) {
            /* return (
            <Player
                className="w-100 border-0 thumbvideo"
                height="400"
                width="200"
                playsInline
                src={source_url}
            ></Player>); */


            return (<ReactPlayer url={source_url} playing controls={false} width='30%' height='50%' style={{ width: '100%', height: '100%' }} />);
            //return (<iframe src={source_url} height="100" width="100" className="overflow_hidden"></iframe>);
        } else if (type.includes('application/pdf')) {
            return (<div><br /><ImageTag src={constand.S3_API_IMG + '/pdf-icon.png'} className="pdfImage" /> </div>);
        } else {
            alert("here came");
            return;
        }
    }

    render() {

        console.log('userdata=>>>', this.props.user_data.id)
        console.log('aboutdata=>>>', this.props.groupState.aboutGroup.OwnerId)
        console.log('this.props.is_group_admin=>>>', this.props.is_group_admin)
        const { commentsData } = this.state;
        const { comments, comment } = this.state;
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        return (
            <div className="col-md-8 col-lg-8 col-sm-10 m-auto">
                {/* <LoaderComponent /> */}
                {/* <canvas ref={canvasA => this.canvasA = canvasA} width="100" height="100" />  */}
                <canvas ref='canvas' width="100" height="100" style={{ display: 'none' }} />
                {this.renderModalPopup()}
                {this.renderAdminPostModal()}
                {this.props.is_auth && (((this.props.is_group_admin || (authData && authData.isStudyLeader)) && this.props.componentType == 'feed') || this.props.componentType == 'wall') &&
                    <div className="card">
                        <div className="card-body">
                            <h3 className="m-b-20 text-body font-mregular"> Create a post </h3>
                            <div className="inputbox-field">
                                <textarea col="5" rows="5" styles="resize:none" value={this.state.postComment} name="postComment" onChange={this.handlePostChange.bind(this)} className="form-control input-style" placeholder="What's on your mind?" ></textarea>
                                {/* <input value={this.state.postComment} name="postComment" onChange={this.handlePostChange.bind(this)} className="form-control input-style" placeholder="What's on your mind?" type="text" /> */}
                            </div>
                            <div className="file-bg">
                                <input ref={ref => this.fileInput = ref} type="file" onChange={this.onImageChange} accept="image/*, video/*, application/pdf" className="filetype pointer post_image" id="post_image" />
                            </div>
                            {this.state.postImage && (
                                this._renderThumbnailContent(this.state.postImage, this.state.postFile)
                                /*  <button className="file-image" >
                                     </button> */
                            )}

                            {this.state.postErr && <div className="text-danger" >Please write something</div>}
                            {this.state.fileTypeErr && <div className="text-danger" >Please select png, jpg, jpeg, gif files</div>}

                            <div className="text-center m-t-30 m-b-10 buttonstyle-align">
                                <a onClick={() => {
                                    this.preCreatePost()
                                }} className={"btn-blue-inverse font-book text-center pointer post-progress-bar" + (this.state.isLoading ? ' not-active' : '')}> Post
                                    {this.state.percentCompleted != "0" && this.state.percentCompleted != "100" && !this.state.isEditForm &&
                                        <div className={"c100 p" + this.state.percentCompleted + " small"}>
                                            <span>{this.state.percentCompleted}%</span>
                                            <div class="slice">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    }
                                </a>
                            </div>

                        </div>
                    </div>
                }
                {this.state.feedList && this.state.feedList.length > 0 && (
                    <div className="w-100 m-t-20 card-body pl-0 pr-0">
                        {this.state.feedList.map((item, key) => {

                            return (
                                <div key={key} className="col-lg-12 col-md-12 m-t-30 col-sm-12 pl-0 pr-0">
                                    <div className="card mb-5 mb-lg-0">
                                        <div className="card-body">
                                            <div className="w-100">
                                                <div className="card border-0 flex-row flex-wrap">
                                                    <div className="pull-left border-0  main-comment image-size">
                                                        <img
                                                            className="img-fluid"
                                                            src={item.User && item.User.profilePic ? constand.PROFILE_IMAGE_PATH + item.User.profilePic : constand.WEB_IMAGES + 'no-profile-pic.png'}
                                                            alt="" width="50" height="50"
                                                        />

                                                    </div>
                                                    <div className="card-block p-l-10 m-t-5 pr-0">
                                                        <div className="card-text font-SFregular  font-14 float-left"> {item.User.nickname ? item.User.nickname : item.User.name + ' ' + item.User.lastName}
                                                        </div>
                                                        <div className="card-text font-SFregular graytext m-l-30 font-14 float-left clearfix p-l-0">{moment(item.updatedAt).fromNow()} </div>
                                                        <i class="fa-solid fa-thumbtack"></i>
                                                    </div>
                                                    {this.state.showPinned == false && item.pinnedPost == true && (
                                                        <div className="card-block p-l-10 m-t-5 pr-0">{this.renderPinButton(item, key)}
                                                        </div>
                                                    )}
                                                    {this.state.showPinned && item.pinnedPost == true && (
                                                        <div className="card-block p-l-10 m-t-5 pr-0">{this.renderPinButton(item, key)}
                                                        </div>
                                                    )}
                                                    {this.state.showPinned == false && item.pinnedPost == false && (
                                                        <div className="card-block p-l-10 m-t-5 pr-0">{this.renderPinButton(item, key)}
                                                        </div>
                                                    )}
                                                    {this.props.is_auth && item.postedBy === this.props.user_data.id && (
                                                        <div className="card-block p-l-10 m-t-10 pr-0">
                                                            <a className="card-text graytext m-l-30 font-SFregular float-left pointer" onClick={() => {
                                                                this.loadEditPost(item)
                                                            }}><i className="fa fa-edit"></i></a>
                                                            <a className="card-text graytext m-l-20 font-SFregular float-left pointer" onClick={() => {
                                                                this.openModalPopup(item, 'post')
                                                            }}><i className="fa fa-trash" aria-hidden="true"></i></a>
                                                        </div>
                                                    )}
                                                </div>
                                                {item.postImg && !item.showEditPost && (
                                                    <div className="w-100 m-t-20 overflow-hidden">
                                                        {this._renderImageContent(item)}
                                                    </div>
                                                )}
                                                {!item.showEditPost && (
                                                    <p className="allow-white-space paragraph-style font-SFregular font-14 m-t-20">
                                                        {ReactHtmlParser(item.postMsg)}
                                                    </p>
                                                )}
                                                {item.showEditPost && (
                                                    <div>
                                                        <div className="inputbox-field">
                                                            <textarea rows={this.state['textAreaRows' + item.id] > 5 ? '' : 5} value={(this.state.editPostComment[item.id]).replace(/<{1}[^<>]{1,}>{1}/g, "")} name={this.state.editPostComment[item.id]}
                                                                // onKeyDown={this._handleEditPostKeyDown.bind(this, item.id, false)}
                                                                onChange={this.handleEditPostChange.bind(this, item.id)}
                                                                style={{ height: this.state['textAreaRows' + item.id] > 5 ? this.state['textAreaRows' + item.id] : '' }}
                                                                className={"form-control input-style "} placeholder="What's on your mind?"></textarea>
                                                            <p className={"textarea-" + item.id + " hidden-textarea-p"}>{(this.state.editPostComment[item.id]).replace(/<{1}[^<>]{1,}>{1}/g, "")}</p>
                                                            {/* <input value={(this.state.editPostComment[item.id]).replace(/<{1}[^<>]{1,}>{1}/g, "")} name={this.state.editPostComment[item.id]}
                                                                onKeyDown={this._handleEditPostKeyDown.bind(this, item.id, false)}
                                                                onChange={this.handleEditPostChange.bind(this, item.id)} className="form-control input-style" placeholder="What's on your mind?" type="text" /> */}
                                                        </div>
                                                        <div className="file-editpost-bg">
                                                            <input type="file" onChange={this.onEditImageChange.bind(this, item.id)} accept="image/*, video/*, application/pdf" className="filetype" id="post_image" />
                                                        </div>
                                                        <div className="up_img_sec position-relative">
                                                            {this.state.editPostImage[item.id] && (
                                                                this._renderEditImageContent(this.state.editPostImage[item.id], this.state.editPostFile[item.id])
                                                            )}
                                                            {false && this.state.editPostImage[item.id] && !this.state.editPostComment[item.id] &&
                                                                <button className="btn btn-purple p-l-10 p-r-10  font-medium font-14" onClick={() => this._handleEditPostKeyDown.bind(this, item.id, true)}>
                                                                    <i className="fa fa-pencil"></i></button>
                                                            }
                                                        </div>
                                                        <div className="text-center m-t-30 m-b-10 buttonstyle-align">
                                                            <a onClick={() => {
                                                                this.editPostSubmit(item.id)
                                                            }} className={"btn-blue-inverse font-book text-center pointer post-progress-bar" + (this.state.isLoading ? ' not-active' : '')}>Save
                                                                {this.state.percentCompleted != '0' && this.state.isEditForm && this.state.percentCompleted != '100' && <div className={"c100 p" + this.state.percentCompleted + " small"}>
                                                                    <span>{this.state.percentCompleted}%</span>
                                                                    <div class="slice">
                                                                        <div class="bar"></div>
                                                                        <div class="fill"></div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>

                                                )}
                                                {this.state.editFileTypeErr && <span className="text-danger" >Please select png, jpg, jpeg, gif files</span>}
                                                <hr className="linestyle" />
                                                <div className="w-100 like-share">
                                                    {this.renderLikeButton(item)}
                                                    {this.props.componentType == 'wall' && this.renderFollowButton(item)}

                                                </div>
                                                {this.props.is_auth && (this.props.componentType == 'wall') &&
                                                    <div>
                                                        <div className="w-100 m-t-10 inputbox-field">
                                                            {comments.map(comment => <Linkify>{comment}</Linkify>)}
                                                            <MentionsInput
                                                                className="form-control input-style"
                                                                value={this.state.commentsData[item.id]}
                                                                markup="@name"
                                                                name={this.state.commentsData[item.id]}
                                                                onKeyDown={this._handleKeyDown.bind(this, item.id, key)}
                                                                onChange={(e, newValue, newPlainTextValue, mentions) => this.handleChange(e, item.id, newValue, newPlainTextValue, mentions)}
                                                                placeholder="Write your reply"
                                                                type="text">
                                                                <Mention
                                                                    className="testMention"
                                                                    trigger="@"
                                                                    data={memberNameWithId}
                                                                />
                                                            </MentionsInput>
                                                        </div>
                                                        <input type="file" onChange={this.onReplyImageChange.bind(this, item.id)} accept="image/*, video/*" className="filetype post_image" id="post_image" />
                                                    </div>
                                                }
                                                {this.state.replyImage && this.state.replyImage[item.id] && (
                                                    this._renderEditImageContent(this.state.replyImage[item.id], this.state.replyFile[item.id])
                                                )}
                                                {this.state.replyFileTypeErr && <div className="text-danger" >Please select png, jpg, jpeg, gif files</div>}
                                                {item.Comments.map((comment, index) => {
                                                    return (
                                                        <div key={"comments_" + index} className="w-100 m-t-30 reply">
                                                            <div className="card border-0 flex-row flex-wrap">
                                                                <div className="pull-left border-0  main-comment image-size">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={comment.User && comment.User.profilePic ? constand.PROFILE_IMAGE_PATH + comment.User.profilePic : constand.WEB_IMAGES + 'no-profile-pic.png'}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="card-block p-l-20 m-t-10 pr-0">
                                                                    <div className="card-text font-SFregular font-14 float-left">{comment.User.nickname ? comment.User.nickname : comment.User.name + ' ' + comment.User.lastName}  </div>
                                                                    <span className="card-text graytext m-l-30 font-SFregular font-14 float-left">{moment(comment.updatedAt).fromNow()}</span>
                                                                </div>
                                                                {this.props.is_auth && comment.commentedBy === this.props.user_data.id && (
                                                                    <div className="card-block p-l-20 m-t-10 pr-0">
                                                                        <a className="card-text graytext m-l-30 font-qregular float-left pointer" onClick={() => {
                                                                            this.loadEditComment(item, comment)
                                                                        }}><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                                        <a className="card-text graytext m-l-20 font-qregular float-left pointer" onClick={() => {
                                                                            this.openModalPopup(item, 'comment', comment)
                                                                        }}><i className="fa fa-trash" aria-hidden="true"></i></a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {!comment.showTextInput && (
                                                                <div>
                                                                    <p className="allow-white-space paragraph-style font-SFregular font-14 m-t-20">
                                                                        {ReactHtmlParser(comment.commentMsg)}
                                                                    </p>
                                                                    <div>
                                                                        {this.renderCommentsLikeButton(comment, index)}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {comment.commentImg && !comment.showTextInput && (
                                                                <div className="w-100 m-t-20">
                                                                    <img src={comment.commentImg ? constand.POST_IMG_PATH + comment.commentImg : constand.WEB_IMAGES + 'no-image.png'} className="size-fix img-fluid img-responsive" />
                                                                </div>
                                                            )}
                                                            {comment.showTextInput && (
                                                                <div>
                                                                    <div className="w-100 inputbox-field">
                                                                        {comments.map(comment => <Linkify>{comment}</Linkify>)}
                                                                        <MentionsInput
                                                                            className="form-control input-style"
                                                                            value={this.state.editCommentsData[comment.id]}
                                                                            markup="@name"
                                                                            name={this.state.editCommentsData[comment.id]}
                                                                            onKeyDown={this._handleCommentsKeyDown.bind(this, comment.id, item.id)}
                                                                            onChange={(e, newValue, newPlainTextValue, mentions) => this.handleEditCommentChange(e, comment.id, newValue, newPlainTextValue, mentions)}
                                                                            placeholder="Update Comment"
                                                                            type="text">
                                                                            <Mention
                                                                                className="testMention"
                                                                                trigger="@"
                                                                                data={memberNameWithId}
                                                                            />
                                                                        </MentionsInput>
                                                                    </div>
                                                                    <input type="file" onChange={this.onReplyEditImageChange.bind(this, comment.id)} accept="image/*, video/*" className="filetype post_image" id="reply_image" />
                                                                    {this.state.editRplyFileTypeErr && <div className="text-danger" >Please select png, jpg, jpeg, gif files</div>}
                                                                    {this.state.editReplyImage[comment.id] && (this._renderEditImageContent(this.state.editReplyImage[comment.id], this.state.editReplyFile[comment.id])
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                                {item.Comments.length > 0 && (
                                                    <hr />
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            )
                        })}

                        {this.state.totalPost && this.state.totalPost > this.state.feedList.length && (
                            <div className="text-center m-t-80 m-b-40 buttonstyle-align">
                                <a onClick={() => {
                                    this.loadMorePosts()
                                }} className="btn-blue-inverse font-book text-center pointer">Load more posts</a>
                            </div>
                        )
                        }
                    </div>
                )}
                {this.state.feedList.length == 0 &&
                    <div className="w-100 m-t-20 card-body pl-0 pr-0 ">
                        <p className="font-qbold text-center">
                            There are no posts here yet</p>
                    </div>
                }
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        is_detail: state.group.group_details,
        is_auth: state.auth.is_auth,
        user_data: state.header.logged_userData,
        is_group_admin: state.group.is_group_admin
    };
};

const mapDispatchToProps = {
    fetchAllFeeds, loadMoreFeeds, start_loader, stop_loader, likeThePost, likeTheComments, pinnedPost, followPost,
    deletePostItem, updateComments, loadComments, createComments, loginModelOpen
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedComponent);
