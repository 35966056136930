import React, { useCallback, useEffect, useState } from "react";
import DashboardHeader from "../../dynamicDashboard/dynamicDashboarHeader/dynamicDashboardHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchFaq } from "../../../actions";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";
import FAQSection from "./FaqSection";

export default function FaqProgrammeComponent() {
  const dispatch = useDispatch();
  const { programId } = useParams();
  const [Loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredNewList, setFilteredNewList] = useState([]);
  async function fetchFaqList() {
    try {
      setLoading(true);
      const response = await dispatch(fetchFaq(programId));
      if (!response.status) throw new Error(`Failed to fetch FAQ`);
      const newList = response.list.faq || [];
      setFaqList(newList);
      setFilteredNewList(newList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchFaqList();
  }, []);

  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  // Filter function
  const filterList = (searchTerm) => {
    try {
      if (!searchTerm) {
        setFilteredNewList(faqList);
      } else {
        setLoading(true);
        const filteredList = faqList.filter(
          (itemData) =>
            itemData?.page_title.length > 0 &&
            itemData?.page_title[0].text
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
        setLoading(false);
        setFilteredNewList(filteredList);
      }
    } catch (error) {
      console.log(error);
      setFilteredNewList(faqList);
    }
  };

  // Debounced filter function
  const debouncedFilterList = useCallback(debounce(filterList, 300), [faqList]);

  // Effect to call debounced filter on search change
  useEffect(() => {
    debouncedFilterList(search);
  }, [search, debouncedFilterList]);
  return (
    <>
      <DashboardHeader />

      <section id="faqpage-section" className="faqpage-section mt-50 mb-80">
        <div className="container-fluid mx-auto w-90">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8 text-left ">
              <h1 className="mb-2 font-30 color-black font-semibold">
                Frequently asked questions
              </h1>
              <p className="font-16 ash-txt fw500 font-qmedium details-faqtext">
                Here are some frequently asked questions about the program. If
                you have any other questions, please feel free to reach out to
                us.
              </p>
              {/*Mobile View Search*/}
              <div className="search-faq mobileview-display">
                <form class="form-search form-inline">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    class="search-query"
                    placeholder="Search for questions here..."
                  />
                </form>
              </div>
              {/*accordian*/}
              {Loading ? (
                <div className="text-center w-100">
                  <AnimateLoaderComponent />
                </div>
              ) : (
                <FAQSection faq_data={filteredNewList} />
              )}
            </div>
            {/*Desktop View Search*/}
            <div className="col-12 col-md-4 col-lg-4 text-center desktopview-display">
              <div className="search-faq">
                <form class="form-search form-inline">
                  <input
                    type="text"
                    class="search-query"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search for questions here..."
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
