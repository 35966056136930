import { SecretsManager } from 'aws-sdk';
import * as constand from "../constant";
export const getSecrets = async () => {
    try {
        let SecretsManagerClient = new SecretsManager({
            region: constand.awsRegion,
          });
        
        const SecretsManagerResult = await SecretsManagerClient
            .getSecretValue({
                SecretId: constand.secretManagerKey
            })
            .promise();

        const secrets = JSON.parse(SecretsManagerResult.SecretString);

        return secrets;
    } catch (err) {
        console.log('Error on secret manager', err);
    }
}