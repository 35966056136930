import React from "react";
import { commonService } from "../../_services"
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      phone: '',
      submitted: false,
      Loading: false,
      type: this.props.from
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  handleChange(e) //for twoway binding
  {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  submitForm() {
    this.setState({ submitted: true });
    if (this.state.firstname && this.state.lastname && this.state.email && this.state.message) {
      console.log('**contact form submitted**')
      this.setState({ Loading: true });
      commonService.sendFeedback(this.state)
        .then(
          response => {
            switch (this.props.page) {
              case 'cystic':
                ReactGA.event({
                  category: "Clinician Acquisition",
                  action: "Completed contact form",
                  label: "Cystic Fibrosis - Completed - Clinician Enquiry"
                })
                break;
              case 'cancer':
                ReactGA.event({
                  category: "Clinician Acquisition",
                  action: "Completed contact form",
                  label: "Prostate Cancer - Completed - Clinician Enquiry"
                })
                break;
              case 'kidney':
                ReactGA.event({
                  category: "Clinician Acquisition",
                  action: "Completed contact form",
                  label: "Kidney Disease - Completed - Clinician Enquiry"
                })
                break;
            }

            var resetData = {
              firstname: '',
              lastname: '',
              email: '',
              message: '',
              submitted: false,
              Loading: false
            };
            this.setState(resetData);
            toast.success(response.message);
          },
          error => { this.setState({ Loading: false }); toast.error(error); }
        );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 m-b-10">
            <label
              className="control-label font-book font-16 col-sm-12 pb-10 pl-0"
              htmlFor="fname"
            >
              First Name
            </label>
            {(this.props.location.pathname === '/contact-us') &&
              <input
                className="form-control"
                name="firstname"
                placeholder="First name"
                type="text"
                onChange={this.handleChange} value={this.state.firstname}
                autoFocus
              />}
            {(this.props.location.pathname !== '/contact-us') &&
              <input
                className="form-control"
                name="firstname"
                placeholder="First name"
                type="text"
                onChange={this.handleChange} value={this.state.firstname}
              />}
            {(this.state.submitted && !this.state.firstname) && <p className="text-danger">Firstname required</p>}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 m-b-10">
            <label
              className="control-label font-book font-16 col-sm-12 pb-10 pl-0"
              htmlFor="fname"
            >
              Last Name
            </label>
            <input
              className="form-control input-control"
              name="lastname"
              placeholder="Last name"
              onChange={this.handleChange} value={this.state.lastname}
              type="text"
            />
            {(this.state.submitted && !this.state.lastname) && <p className="text-danger">Lastname required</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 m-b-10">
            <label
              className="control-label font-book font-16 col-sm-12 pb-10 pl-0"
              htmlFor="fname"
            >
              Email
            </label>
            <input
              className="form-control input-control"
              name="email"
              placeholder="E-mail"
              onChange={this.handleChange} value={this.state.email}
              type="text"
            />
            {(this.state.submitted && !this.state.email) && <p className="text-danger">Email required</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 m-b-10">
            <label
              className="control-label font-book font-16 col-sm-12 pb-10 pl-0"
              htmlFor="fname"
            >
              Message
            </label>
            <textarea
              className="form-control input-control"
              placeholder="Message..."
              rows="6"
              name="message"
              onChange={this.handleChange} value={this.state.message}
            ></textarea>
            {(this.state.submitted && !this.state.message) && <p className="text-danger">Message required</p>}
          </div>
        </div>
        <div className="row header-custom">
          <div className="navbar-text ml-auto mr-auto m-t-10">
            <button disabled={this.state.Loading} onClick={this.submitForm}
              className="btn send-btn navbar-btn btn-shadow btn-gradient font-14 font-medium btn-send"
            >
              Send
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactForm;
