import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as constand from "../../constant";
import { setConditionIndex, setPageTitle, onboardingUserTrack } from "../../actions";
import _ from 'lodash';
import ReactGA from 'react-ga';
import { commonService } from "../../_services";

class ProfessionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profession: this.props.registerFormvalues.profession,
      submitted: false,
      profession_localtab: this.props.registerFormvalues.profession_localtab,
      conditionRelationshipWithAlias: [],
      conditionRelationshipKD: [],
      RegisterConditionRelationship: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.registerSubmit_6 = this.registerSubmit_6.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getColor = this.getColor.bind(this);

    this.health_conditionId = this.props.registerFormvalues.health_condition[
      this.props.condition_index
    ].id;
    this.condition_professions = _.find(this.props.registerFormvalues.condition_professions, { 'conditionId': this.health_conditionId });
    this.selected_condition_list = this.props.registerFormvalues.health_condition[
      this.props.condition_index
    ];
    console.log('this.condition_professions', this.condition_professions)
    console.log('this.this.selected_condition_list', this.selected_condition_list)
  }

  componentDidMount() {
    let AK_Cond_temp = [];
    AK_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
      return (item.tag == constand.ASTHMA_CONDITION)
    })
    if (AK_Cond_temp.length > 0 && this.props.registerFormvalues.health_condition && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.ASTHMA_CONDITION && !Object.keys(this.props.registerFormvalues.tagCode).length) {
      this.props.setStep(25)
    }

    let KDY_Cond_temp = [];
    KDY_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
      return (item.tag == constand.KD_YOUTH)
    });
    if (KDY_Cond_temp.length > 0 && this.props.registerFormvalues.health_condition && this.props.registerFormvalues.health_condition[this.props.condition_index].tag == constand.KD_YOUTH && this.props.registerFormvalues.tagKDYCode && !Object.keys(this.props.registerFormvalues.tagKDYCode).length) {
      this.props.setStep(31);
    }
    let CFY_Cond_temp = [];
    CFY_Cond_temp = this.props.registerFormvalues.health_condition.filter(function (item) {
      return (item.tag == constand.CFY_CONDITION)
    });
    if(CFY_Cond_temp.length > 0 && this.props.registerFormvalues.masterUserID){
      this.props.setStep(29);
    }
    this.props.setPageTitle('Are you...');
    let firstConditionRealtionship;
    let secondConditionRealtionship;
    if (this.selected_condition_list.tag.includes(constand.CFY_CONDITION)) {
      firstConditionRealtionship = "I am a child living with " + constand.CONDITION;
      secondConditionRealtionship = "I am a caregiver to a child with " + constand.CONDITION;
    } else if(this.selected_condition_list.tag.includes(constand.KD_YOUTH)){
      firstConditionRealtionship = "I am a child living with " + constand.KR_CONDITION;
      secondConditionRealtionship = "I am a caregiver to a child with " + constand.KR_CONDITION;
    }
    else {
      firstConditionRealtionship = "Living with " + this.selected_condition_list.tag;
      secondConditionRealtionship = "A caregiver to someone with " + this.selected_condition_list.tag;
    }
    // //conditionRelationshipList only for popup placeholder
    // let conditionRelationshipWithAlias = [firstConditionRealtionship, secondConditionRealtionship, ... this.props.conditionRelationshipList.slice(2)];

    let conditionRelationshipWithAlias;
    if (this.selected_condition_list.tag === 'kidney disease' || this.selected_condition_list.tag === 'kidney kids' || this.selected_condition_list.tag === 'kidney youth') {
      let orgonDonorCondition = 'I am a kidney donor';
      conditionRelationshipWithAlias = [firstConditionRealtionship, orgonDonorCondition, secondConditionRealtionship, ...this.props.conditionRelationshipList.slice(2)];
      this.state.conditionRelationshipKD = [
        ...this.props.conditionRelationshipList.slice(0, 1),
        'Organ donor',
        ...this.props.conditionRelationshipList.slice(1),
      ];
    } else {
      conditionRelationshipWithAlias = [firstConditionRealtionship, secondConditionRealtionship, ...this.props.conditionRelationshipList.slice(2)];
    }

    this.setState({ conditionRelationshipWithAlias: conditionRelationshipWithAlias })

  }
  getColor(cond) {
    var index = constand.CONDITION_CLASS_LIST.indexOf('cond_' + cond);
    if (index > -1) {
      return constand.CONDITION_CLASS_LIST[index]
    } else {
      return constand.CONDITION_CLASS_LIST[0]
    }
  }
  handleChange(e) {
    //for twoway binding
    const { name, value } = e.target;
    this.setState({ profession: value });
    console.log('handlechange-profession-name', name)
    console.log('handlechange-profession-value', value)

    if (name === 'profession') {
      if (this.selected_condition_list.tag === 'kidney disease' || this.selected_condition_list.tag === 'kidney kids' || this.selected_condition_list.tag === 'kidney youth') {
        if (value == 2) this.props.registerFormvalues[name] = String(5);
        else if (value > 2) this.props.registerFormvalues[name] = String(value - 1);
        else this.props.registerFormvalues[name] = String(value);
      } else {
        this.props.registerFormvalues[name] = value;
      }
    }
  }

  registerSubmit_6() {
    console.log('registerSubmit_6', this.props.registerFormvalues.profession)
    console.log('registerSubmit_6-index', this.props.condition_index)
    this.setState({ submitted: true });

    //this.props.registerFormvalues.profession = this.condition_professions ? constand.Resgister_condition_relationship.indexOf(this.condition_professions.conditionRelationship) + "" : this.props.registerFormvalues.profession;

    switch (this.props.registerFormvalues.profession) {
      case "1":
        if (this.props.registerFormvalues.profession) {
          console.log('case-1')
          this.setState({
            healthcondition_livewith: "",
            midicalcondition_livewith: ""
          });
          this.props.registerFormvalues.healthcondition_livewith = "";
          this.props.registerFormvalues.midicalcondition_livewith = "";
          if (this.selected_condition_list.tag == constand.CONDITION) {
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(28) : this.props.setStep(28);//Go to severity 
          } else {
            this.props.pageFrom == 'register' ? this.onboardingUserTrack(13) : this.props.setStep(13)//clinic
          }
          if (this.condition_professions) {
            this.condition_professions.conditionRelationship = constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)];
          } else {
            this.props.registerFormvalues.condition_professions.push({
              conditionId: this.health_conditionId,
              conditionRelationship: constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)],
              caregiverType: '', profession: '', clinicId: '', patientPopulation: ''
            })
          }
        }
        break;
      case "2":
        console.log('case-2')
        if (this.props.registerFormvalues.healthcondition_livewith) {
          console.log('case-2-in')
          this.setState({ midicalcondition_livewith: "" });
          this.props.registerFormvalues.midicalcondition_livewith = "";
        }
        if (this.condition_professions) {
          this.condition_professions.conditionRelationship = constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)];
        } else {
          this.props.registerFormvalues.condition_professions.push({
            conditionId: this.health_conditionId,
            conditionRelationship: constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)],
            caregiverType: '',
            profession: '',
            clinicId: '',
            patientPopulation: ''
          })
        }
        this.props.pageFrom == 'register' ? this.onboardingUserTrack(13) : this.props.setStep(13)
        // this.props.gotoStep(13);//clinic
        break;
      case "3":
        console.log('case-3');
        if (this.props.registerFormvalues.midicalcondition_livewith) {
          this.setState({ healthcondition_livewith: "" });
          this.props.registerFormvalues.healthcondition_livewith = "";
        }
        if (this.condition_professions) {
          this.condition_professions.conditionRelationship = constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)];
        } else {
          this.props.registerFormvalues.condition_professions.push({
            conditionId: this.health_conditionId,
            conditionRelationship: constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)],
            caregiverType: '',
            profession: '',
            clinicId: '',
            patientPopulation: ''
          })
        }
        this.props.pageFrom == 'register' ? this.onboardingUserTrack() : this.props.nextStep()
        break;
      case "4":
        console.log('case-4');
        this.setState({
          healthcondition_livewith: "",
          midicalcondition_livewith: ""
        });
        this.props.registerFormvalues.healthcondition_livewith = "";
        this.props.registerFormvalues.midicalcondition_livewith = "";
        this.props.pageFrom == 'register' ? this.onboardingUserTrack(13) : this.props.setStep(13)

        if (this.condition_professions) {
          this.condition_professions.conditionRelationship = constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)];
        } else {
          this.props.registerFormvalues.condition_professions.push({
            conditionId: this.health_conditionId,
            conditionRelationship: constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)],
            caregiverType: '', profession: '', clinicId: '', patientPopulation: ''
          })
        }
        break;
      case "5":
        console.log('case-5')
        if (this.props.registerFormvalues.healthcondition_livewith) {
          console.log('case-5-in')
          this.setState({ midicalcondition_livewith: "" });
          this.props.registerFormvalues.midicalcondition_livewith = "";
        }
        if (this.condition_professions) {
          this.condition_professions.conditionRelationship = constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)];
        } else {
          this.props.registerFormvalues.condition_professions.push({
            conditionId: this.health_conditionId,
            conditionRelationship: constand.Resgister_condition_relationship[parseInt(this.props.registerFormvalues.profession)],
            caregiverType: '',
            profession: '',
            clinicId: '',
            patientPopulation: ''
          })
        }
        
        this.props.pageFrom == 'register' ?  this.props.gotoStep(23) : this.onboardingUserTrack(13);
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "User Acquisition",
      action: "Sign up process",
      label: "Relationship to condition"
    })
  }
  goBack() {
    if (this.props.registerFormvalues.country == 230)
      this.props.setStep(17, 'backward');
    else
      this.props.prevStep();
  }

  onboardingUserTrack = (step) => {
    let registerData = {
      registerEmail: this.props.registerFormvalues.email,
      formValues: this.props.registerFormvalues
    }
    this.props.onboardingUserTrack(registerData).then((res) => {
      if (step) {
        this.props.setStep(step)
      } else {
        this.props.nextStep();
      }
    })
  }
  
  render() {

    console.log('healthcondition_list', this.props.healthcondition_list)
    return (
      <div className="step6">
        {this.props.pageFrom != 'mycondition' &&
          <h3 className="text-center ">
            <span
              className="pull-left pointer"
              onClick={this.goBack}
            >
              <img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} />
            </span>{this.props.registerFormvalues.firstname ? this.props.registerFormvalues.firstname + ': ' : ''}
            Are you...<Link
              to="/home"
              className="close-register orangefont"
            >
              X
            </Link>
          </h3>
        }
        {this.state.conditionRelationshipWithAlias && <div className="row justify-content-center align-items-center">
          <div className="input_section col-md-7 m-t-10">
            <button className={"capitalize_text con-btn position-relative float-left font-semibold font-15 m-b-10 " + commonService.getConditionColorClass(this.selected_condition_list.tag) //this.getColor(this.selected_condition_list.tag.replace(' ', '-').toLowerCase())
          } >{this.selected_condition_list.tag}
            </button>
            <p className="text-label">
              <b>Please select one</b>
            </p>
            <div>
              {
                this.selected_condition_list.tag === 'kidney disease' || this.selected_condition_list.tag === 'kidney kids' || this.selected_condition_list.tag === 'kidney youth' ? (
                  this.state.conditionRelationshipKD.map((item, key) => {
                    return this.state.conditionRelationshipWithAlias[key] !== undefined && (
                        <div key={key} className="row radio-section">
                        <span className="text-label col-10">
                          {this.state.conditionRelationshipWithAlias[key]}
                        </span>
                        <div className="radio pull-right text-right col-2">
                          <input
                            id={"radio-" + (key + 1)}
                            name="profession"
                            value={key + 1}
                            type="radio"
                            defaultChecked={
                              (this.condition_professions && this.condition_professions.conditionRelationship === (item || 'Organ donor'))
                            }
                            onChange={this.handleChange}
                          />
                          <label htmlFor={"radio-" + (key + 1)} className="radio-label"></label>
                        </div>
                      </div>
                  )
                })
                ) : (
                  this.props.conditionRelationshipList.map((item, key) => {
                    return this.state.conditionRelationshipWithAlias[key] !== undefined && (
                        <div key={key} className="row radio-section">
                        <span className="text-label col-10">
                          {this.state.conditionRelationshipWithAlias[key]}
                        </span>
                        <div className="radio pull-right text-right col-2">
                          <input
                            id={"radio-" + (key + 1)}
                            name="profession"
                            value={key + 1}
                            type="radio"
                            defaultChecked={
                              (this.condition_professions && this.condition_professions.conditionRelationship === (item))
                            }
                            onChange={this.handleChange}
                          />
                          <label htmlFor={"radio-" + (key + 1)} className="radio-label"></label>
                        </div>
                      </div>
                  )
                })
                )
              }
              {this.state.submitted &&
                this.props.registerFormvalues.profession === "" && (
                  <p className="text-danger">This is required</p>
                )}
            </div>

            <div id="register-link" className=" w-100 m-t-80 m-b-20 float-left">
              <span
                onClick={() => this.registerSubmit_6()}
                className="bluebtn float-left w-100 text-center pointer"
              >
                Next
              </span>
            </div>

          </div>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    clinic_list: state.register.clinic_list,
    condition_index: state.register.condition_index
  };
};

const mapDispatchToProps = { setConditionIndex, setPageTitle, onboardingUserTrack };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionComponent);
