import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Errors from "../../Errors";
import { getPromocodePaln, start_loader, stop_loader, registerNewUser, setStep, getConditionBasedInstructor, getPlanByCountry, setConditionIndex, validateTagCodes, setPageTitle, onboardingUserTrack } from '../../actions';
import { toast } from "react-toastify";
import * as constand from "../../constant";
import ReactGA from 'react-ga';
import { json } from 'mathjs';

class ResearchParticipantComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            know_via: this.props.registerFormvalues.know_via,
            promocode: this.props.registerFormvalues.promocode,
            submitted: false,
            promoCodeErr: false,
            subscribedErr: false,
            showPromo: false,
            KR_Cond: [],
            instructors: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.selected_condition_list = this.props.registerFormvalues.health_condition[
            this.props.condition_index
        ];
        this.getColor = this.getColor.bind(this);
    }

    componentDidMount() {
        if (this.props.pageFrom == 'mycondition') {
            this.props.setPageTitle('Are you a research participant')
        }
    }

    handleChange(e) //for twoway binding
    {
        const { name, value } = e.target;
        this.setState({ promocode: value, subscribedErr: false });
        this.props.registerFormvalues[name] = value;

        if (constand.KD_BEAM_RESEARCH == value) {
            this.props.registerFormvalues.isKBResearcheUser = true; //kidney beam research user
        }
    }

    getColor(cond) {
        var index = constand.CONDITION_CLASS_LIST.indexOf('cond_' + cond);
        if (index > -1) {
            return constand.CONDITION_CLASS_LIST[index]
        } else {
            return constand.CONDITION_CLASS_LIST[0]
        }
    }

    tagCodeSubmit = () => {
        let promocode = this.props.registerFormvalues.promocode;
        let conditionId = this.props.registerFormvalues.health_condition[0].id;
        console.log('tagcodesubmit=health_condition', this.props.registerFormvalues.health_condition)
        console.log('condindex=' + this.props.condition_index + "< healthcondit=" + this.props.registerFormvalues.health_condition.length)
        let id = this.selected_condition_list.id;
        if (this.props.registerFormvalues.promocode.trim()) {
            let isChild = this.props.registerFormvalues.masterUserID ? 1 : 0;
            let pageFrom = this.props.pageFrom;
            console.log('isChild=', isChild)
            this.props.validateTagCodes(this.props.registerFormvalues.promocode.trim(), id, isChild, pageFrom).then(
                response => {
                    this.props.stop_loader();
                    this.props.registerFormvalues.promocode = '';

                    if (response) {
                        this.props.registerFormvalues.tagCode = response.tagCode;
                        if (!this.props.registerFormvalues.tagCodeList['cond_' + id])
                            this.props.registerFormvalues.tagCodeList['cond_' + id] = response.tagCode;

                        this.props.registerformUpdate(this.props.registerFormvalues);
                        if (promocode && response.tagCode.modalContent) {
                            this.props.pageFrom == 'register' ? this.onboardingUserTrack(22, false, true) : this.props.gotoStep(22) //research niceone page
                        } else {
                            if (this.props.condition_index <
                                this.props.registerFormvalues.health_condition.length - 1) {
                                console.log('IFFFFFFFFFFFFFFFFFFFFFFFFF')
                                this.props.setConditionIndex(this.props.condition_index + 1)
                                this.props.pageFrom == 'register' ? this.onboardingUserTrack(11) : this.props.gotoStep(11) //profession
                            } else {
                                console.log('ELSESSSSSSSSSSSSs')
                                if (this.props.pageFrom == "register") {
                                    this.onboardingUserTrack(14, 'forward') //hear aboutus
                                } else {
                                    this.props.setStep(23) // membership options
                                }
                            }
                        }
                        //this.props.nextStep();
                    } else {
                        this.setState({ promoCodeErr: true })
                    }
                },
                error => {
                    this.props.stop_loader();
                    toast.error(error);
                }
            );
        } else {
            if (this.props.condition_index <
                this.props.registerFormvalues.health_condition.length - 1) {
                console.log('IFFFFFFFFFFFFFFFFFFFFFFFFF')
                this.props.setConditionIndex(this.props.condition_index + 1)
                this.props.pageFrom == 'register' ? this.onboardingUserTrack(11) : this.props.gotoStep(11) //profession
            } else {
                console.log('ELSESSSSSSSSSSSSs')
                if (this.props.pageFrom == "register") {
                    this.onboardingUserTrack(14, 'forward') //hear aboutus 
                } else {
                    this.props.setStep(23) // membership options
                }
            }
        }
        ReactGA.event({
            category: "User Acquisition",
            action: "Sign up process",
            label: "How they heard"
        })
        ReactGA.event({
            category: "User Acquisition",
            action: "Sign up flow completed ",
        })
    }

    onboardingUserTrack = (step, flow = false, goto = false) => {
        let registerData = {
            registerEmail: this.props.registerFormvalues.email,
            formValues: this.props.registerFormvalues
        }
        this.props.onboardingUserTrack(registerData).then((res) => {
            let direction = flow ? flow : '';
            if (goto) {
                this.props.gotoStep(22)
            } else {
                this.props.setStep(step, direction)
            }
        })
    }

    gotoBack = () => {
        if (this.selected_condition_list && this.selected_condition_list.tag && this.selected_condition_list.tag == constand.KR_CONDITION) {
            this.props.setStep(19)
        } else {
            this.props.setStep(11, 'backward')
        }
    }

    render() {
        return (
            <div className="step12">
                {this.props.pageFrom != 'mycondition' &&
                    <h3 className="text-center "><a className="pull-left" href="javascript:void(0)" onClick={() => this.gotoBack()}><img className="arrow-img" src={constand.WEB_IMAGES + "arrow-left.png"} /></a>{this.props.registerFormvalues.firstname ? this.props.registerFormvalues.firstname + ': ' : ''}Are you a research participant<Link
                        to="/home"
                        className="close-register orangefont"
                    >
                        X
                    </Link></h3>
                }
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-6 m-t-20">
                        <button className={"capitalize_text con-btn position-relative float-left font-semibold font-15 m-b-10 " + this.getColor(this.selected_condition_list.tag.replace(' ', '-').toLowerCase())} >
                            {this.selected_condition_list.tag}
                        </button>
                        {this.props.isConditionHasCode &&
                            <div className="form-group">
                                <label htmlFor="promocode" className="text-label">If you have a research code please enter it below</label>
                                <input type="text" name="promocode" id="promocode" className="form-control input-control" onChange={this.handleChange} value={this.props.registerFormvalues.promocode} />
                            </div>
                        }
                        {this.state.submitted && (this.state.promoCodeErr) &&
                            <p className="text-danger">Please enter correct promo or access code</p>}
                        {this.state.subscribedErr &&
                            <p className="text-danger">{Errors.subscribed_user_1}</p>}


                        <div id="register-link" className=" w-100 m-t-80 m-b-20 float-left">
                            <a href="javascript:void(0)" onClick={this.tagCodeSubmit} className="bluebtn float-left w-100 text-center">Next</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        isConditionHasCode: state.register.isConditionHasCode,
        condition_index: state.register.condition_index
    };
};

const mapDispatchToProps = { getPromocodePaln, start_loader, stop_loader, registerNewUser, setStep, getConditionBasedInstructor, getPlanByCountry, setConditionIndex, validateTagCodes, setPageTitle, onboardingUserTrack };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResearchParticipantComponent);
