import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    start_loader,
    getAWSImages,
    getTestimonials
} from "../../actions";
import { toast } from "react-toastify";
import * as constand from "../../constant";
import ReactPlayer from 'react-player';
import CommonForm from './CommonForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import { commonService } from '../../_services';
import { Cookies } from "react-cookie-consent";
SwiperCore.use([Autoplay, Navigation]);
class KidneyLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl: 'https://youtu.be/lRTu1RqiKQM',
            howToSignup: 'https://youtu.be/h826zEJLeIk',
            KDLogos: [],
            kd_testimonials: [],
            kd_testimonial_title: "",
        }
        this.swiperRef = React.createRef();
    };


    componentDidMount() {
        this.getAWSImages();
        let condition = commonService.replaceChar(this.props.params.condition);
        this.props.getTestimonials(condition).then((response) => {
            if (response && response.data) {
                const testimonialTitle = Object.keys(response.data)[0];
                if (testimonialTitle) {
                    let testimonials = response.data[testimonialTitle] && response.data[testimonialTitle].length > 0 ? response.data[testimonialTitle] : []
                    this.setState({ kd_testimonials: testimonials, kd_testimonial_title: testimonialTitle });
                }
            }
        })
    }

    getAWSImages = () => {
        var conditionName = Cookies.get('condition') ? Cookies.get('condition') : this.props.menu_condition;
        let data = { folderName: conditionName };
        this.props.getAWSImages(data).then((res) => {
            if (res && res.data) {
                let awsImgObjects = commonService.getAWSImages(res);
                this.setState({ KDLogos: awsImgObjects });
            }
        });
    }

    getStarted = () => {
        if (this.props.is_auth) {
            this.props.history.push('on-demand/kidney-disease')
        } else {
            this.props.history.push('/register')
        }
    }


    handleSlideChange = () => {
        if (this.swiperRef.current) {
            this.swiperRef.current.swiper.autoplay.start();
        }
    }

    renderTestimonials = () => {
        return (
            <div className="row testimonials-scroll slider-scroll-div">
                <Swiper
                    centeredSlides={true}
                    loop={true}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    breakpoints={{ 0: { slidesPerView: 1 }, 768: { slidesPerView: 1 }, 992: { slidesPerView: 1 }, 1000: { slidesPerView: 1 }, 2560: { slidesPerView: 1 } }}
                    speed={1000}
                    autoplay={{ delay: 8000, disableOnInteraction: false }}
                    onSlideChange={this.handleSlideChange}
                    ref={this.swiperRef}
                    observeParents={true}
                    observer={true}
                    observeSlideChildren={true}
                    watchSlidesProgress={true}
                    className="mySwiper"
                    mousewheel={true}
                    grabCursor={true}
                    autoHeight={true}
                >
                    {this.state.kd_testimonials.length > 0 ? (
                        this.state.kd_testimonials.map((val, i) => (
                            <SwiperSlide key={i}>
                                <div className="col-md-12">
                                    <div className="card mb-4 testimonial-card">
                                        {val.image && (
                                            <img
                                                className="card-img-top rounded-circle circle"
                                                src={constand.TESTIMONIALS + val.image}
                                                alt={val.title}
                                            />
                                        )}
                                        <div className="card-body font-qmedium">
                                            <h3 className={`card-title ${!val.image ? "text-center" : ""}`}>{val.title}</h3>
                                            <p className="card-text ash-txt">{val.testimonial}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        null
                    )}
                    <div className="swiper-buttons">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </Swiper>
            </div >
        );
    };


    render() {
        let condition = commonService.replaceChar(this.props.params.condition, true);
        Cookies.set('condition', condition);

        return (
            <React.Fragment >
                <div className="kd_landing_page">
                    <section className="bg-contrast1">
                        <div class="container-fluid w-80 fullwidth">
                            <div class="row">
                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 text-center welcome-container mx-auto">
                                    <div className="welcome-kidney font-bold white-txt">Welcome to Kidney Beam</div>

                                    <div class="helping-physiotherap white-txt font-qbold col-12 mx-auto">Helping people living with kidney disease to feel good through movement, education and wellbeing support.</div>
                                    <Link class="btn bluebtn m-t-50 font-18 w-45 p-t-10 p-b-10" to={'#'} onClick={() => this.getStarted()}>Get Started</Link>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-3 col-sm-3 d-sm-block header-img">
                                    <img class="img-fluid" src={constand.WEB_IMAGES + "Sun-landing.png"} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {this.state.KDLogos && this.state.KDLogos.length ?
                        <section>
                            <div class="container-fluid fullwidth kd-scrolling">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 text-center">
                                        <p className="font-medium proud-text scroll-title text-abbey">Who we collaborate with</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div class="row mt-3 mb-3">
                                            <div class="col-lg-12 offset-lg-0 col-md-12 offset-md-0 col-sm-8 offset-sm-2 col-12 offset-0 slider-scroll-div">
                                                <Swiper
                                                    centeredSlides={true}
                                                    loop={true}
                                                    navigation={{
                                                        nextEl: '.swiper-button-next',
                                                        prevEl: '.swiper-button-prev',
                                                    }}
                                                    containerModifierClass="w-90 "
                                                    breakpoints={{ 1: { slidesPerView: 1, spaceBetweenSlides: 0 }, 1000: { slidesPerView: 5, spaceBetweenSlides: 50 } }}
                                                    spaceBetween={20}
                                                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                                                    onSlideChange={this.handleSlideChange}
                                                    ref={this.swiperRef}
                                                    observeParents={true}
                                                    observer={true}
                                                    observeSlideChildren={true}
                                                    watchSlidesProgress={true}
                                                    className="mySwiper"
                                                >
                                                    {this.state.KDLogos.length && this.state.KDLogos.map((slide, i) => (
                                                        <>
                                                            {slide &&
                                                                <SwiperSlide key={i}>
                                                                    <img src={constand.S3_API_IMAGES + '/' + slide.Key} alt={`Slide ${i}`} className="scrolling-img" />
                                                                </SwiperSlide>}
                                                        </>

                                                    ))}
                                                    <div className="swiper-buttons">
                                                        <div className="swiper-button-prev"></div>
                                                        <div className="swiper-button-next"></div>
                                                    </div>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> : null}
                    <section className="bg-contrast2">
                        <div class="container-fluid w-80 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">What we offer</div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="icon-desc m-t-20 font-qmedium offer-text" >
                                        Here at Beam we aim to create a space that will support and inspire you. We develop sessions designed to improve your physical and emotional wellbeing. Our instructors are specialists working in or living with kidney disease so you can be confident they'll understand your needs.
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-50 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "On-demand.png"} /></div>
                                    <div class="icon-title icon-title-height">On-demand Video Library</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'on-demand/kidney-disease'}>Explore</Link>

                                </div>

                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-50 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "Asset-60.png"} /></div>
                                    <div class="icon-title icon-title-height">Live Group Classes</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'liveClasses/kidney-disease'}>Explore</Link>
                                </div>

                                <div class="col-lg-4 col-md-4 icon-img">
                                    <div class="icon-img w-55 mx-auto condition-landing-img"><img src={constand.WEB_IMAGES + "Character-pair.png"} /></div>
                                    <div class="icon-title icon-title-height"><span></span>Progressive Programmes</div>
                                    <Link class="btn btn-purple m-t-30 p-t-10 p-b-10" to={'programs/kidney-disease'}>Explore</Link>
                                </div>

                            </div>
                        </div>
                    </section>
                    {this.state.kd_testimonials.length ? <section className="bg-contrast3">
                        <div class="container-fluid w-100 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">{this.state.kd_testimonial_title}</div>
                                </div>

                            </div>
                            {this.renderTestimonials()}
                        </div>
                    </section> : null}
                    <section class="">
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">
                                        How do I get started?
                                    </div>
                                </div>
                                <div class="section-content font-qmedium p-b-30">
                                    Kidney Beam is a subscription service available to people living with kidney disease, their clinicians and carers worldwide.  Kidney Beam may be free in your area courtesy of your healthcare provider or other sponsor.  Click here to see if you are currently eligible for free access.

                                </div>
                            </div>
                            <div className='row'>
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center p-b-30">
                                    <Link class="btn btn-orange w-100" to={'blogs/kidney-beam-am-i-eligible'}>Am I eligible for free access?</Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div class="section-content font-qmedium p-b-30">
                                    We are always working hard to secure ongoing support to make it free for everyone, so watch this space! If your area is not covered you are welcome to join our 2 week free trial and then subscribe monthly.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center p-b-30">
                                    <Link class="btn bluebtn w-100" to={'register'}>
                                        Sign up
                                    </Link>
                                </div>
                            </div>

                        </div>

                    </section>
                    <section className="bg-contrast3">
                        <div className="container-fluid mx-auto w-75 fullwidth">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8 align-section align-items-center">
                                    <div className="">
                                        <h4 className="m-b-20 text-abbey take-tour font-semibold card-block">How to sign up</h4>
                                        <p className="text-abbey before-start">
                                            If you need a little guidance to help you get set up with an account on Beam, follow along with the video!
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 col-lg-4 text-left p-b-30 video-player-order">
                                    <ReactPlayer url={this.state.howToSignup} controls={false} width='100%' height='100%' style={{
                                        width: '100%',
                                        height: '100%'
                                    }} />
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="bg-white">
                        <div className="container-fluid mx-auto w-75 fullwidth">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4 text-left p-b-30 video-player-order">
                                    <ReactPlayer url={this.state.videoUrl} controls={false} width='100%' height='100%' style={{
                                        width: '100%',
                                        height: '100%'
                                    }} />

                                    {/* <figure className="text-left"><img src="/images/blog_img_044.png" className="img-fluid" alt="" /></figure> */}
                                </div>

                                <div className="col-12 col-md-8 col-lg-8 pl-5 align-section align-items-center">
                                    <div className="">
                                        <h4 className="m-b-20 text-abbey take-tour font-semibold card-block">Take a tour</h4>

                                        <p className="text-abbey before-start">
                                            Grab a cuppa and enjoy this full site demonstration as Natasha Wynn, Beam Kidney Studio Manager, talks you through the ins and outs of the Beam Kidney website and tells you all you need to know to get started in this 12 minute demo video...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-contrast2 section-3">
                        <div className="container-fluid m-auto w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">Frequently asked questions</div>
                                </div>
                                <div class="section-content col-lg-12 pb-3 font-qmedium">
                                    If you still have questions you'll find a lot of them answered on our FAQ page.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center">
                                    <Link class="btn btn-orange col-md-5 col-8 p-t-10 p-b-10" to={'/faq'}>FAQ</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white section-3 p-t-30 p-b-30">
                        <div className="container-fluid m-auto w-75 fullwidth">
                            <div className="row position-relative">
                                <div className="col-12 d-flex align-items-center  col-md-9 col-lg-8 text-left  p-b-30">
                                    <div className="card cardnew border-0 align-section bg-white">
                                        <div className="card-block">
                                            <h4 className="clearfix text-abbey take-tour font-medium float-left w-100">Important safety information</h4>
                                        </div>
                                        <div className="m-t-30">
                                            <p className="before-start text-abbey ">
                                                Before you start exercising online we ask you to read these top recommendations from the Kidney Beam experts to ensure that you keep safe and feel your best during and after you get physically active.
                                            </p>
                                        </div>
                                        <div className="readnow">
                                            <Link class="btn btn-darkblue col-md-5 col-8 p-t-10 p-b-10" to={'blogs/safety-information-for-people-living-with-kidney-disease'}>Read Now</Link></div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-4 text-center align-self-center glass-image">
                                    <figure><img src={constand.WEB_IMAGES + "landing/Beam_Character_glass.png"} className="important-asset w-50" alt="" /></figure>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="bg-contrast3">
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="partner-title-black" id="clinicians">For clinicians, exercise specialists, charities and partners</div>
                                </div>
                                <div class="section-content font-qmedium">
                                    Kidney Beam has been developed in collaboration with renal clinicians and charities. We strive to be a platform that you can trust and rely on and which will support you to care for your community. To learn more please head over to our partnerships page.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 offset-lg-4 col-8 mx-auto text-center">
                                    <Link class="btn bluebtn w-100 m-t-30 m-b-30 p-t-10 p-b-10" to={'partnerships/kidney-disease'}>Partner with us</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="container-fluid w-80 p-b-20 fullwidth">
                            <div class="row">
                                <div class="section-content font-qmedium">
                                    If you are interested in becoming an instructor on Beam please <a href="https://forms.gle/Md6g5y7ufEetYvfM7" className="purplefont"><u>fill out this form</u>.</a>  We look forward to hearing from you!
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="bg-contrast4">
                        <div class="container-fluid w-80 fullwidth" id="contact-form">
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <p class="font-medium take-tour text-abbey">Get in touch</p>
                                    <p class="font-qmedium text-abbey m-t-30 section-content">If you need help with anything else or want to get in touch for any other reason then complete the form below and someone from the Beam Team will get in touch. Not a fan of forms? Drop us a note to <a href='mailto:hello@beamfeelgood.com'> hello@beamfeelgood.com</a>.</p>
                                    <div class="section-content text-left">
                                        <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                                            <CommonForm location={this.props.location} from="kidney" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment >
        )
    };
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        menu_condition: state.auth.menu_condition

    };
};

const mapDispatchToProps = {
    start_loader,
    getAWSImages,
    getTestimonials
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KidneyLandingPage);
