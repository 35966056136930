import React, { useState } from "react";
import { goalsModalOpen } from "../../../actions/GoalActions";
import GoalsList from "./GoalsList";
import GoalsModal from "../../Common/GoalsModal";
import { useDispatch } from "react-redux";
import AnimateLoaderComponent from "../../../_layoutComponents/AnimateLoaderComponent";
import DashboardHeader from "../../dynamicDashboard/dynamicDashboarHeader/dynamicDashboardHeader";
import { isPlayOndemand } from "../../../actions";
import PlayOndemand from '../../Common/PlayOndemand';
import { useHistory } from "react-router-dom";

export const GoalListComponent = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [programId] = useState(props?.match?.params?.programId);
  const [condition] = useState(props?.match?.params?.condition);
  const [Loading, setLoading] = useState(false);
 
  function onClose() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  const redirectToDynamicProgram = (program) => {
    //setMembership(true);
    dispatch(goalsModalOpen(true, null))
    dispatch(isPlayOndemand(false));
  };

  const createGoal = () => {
    console.log('goal membership check');
    try {
      dispatch(isPlayOndemand(true));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <DashboardHeader />
      <section
        id="dashboard-section"
        className="dashboard-section mt-50 mb-80 goalspage-section"
      >
        <div className="container-fluid mx-auto w-90">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-9 text-left goals-contents prgdata">
              <h1 className="font-30 color-black font-semibold mb-32">
                Programme Goals
              </h1>
              {Loading ? (
                <div className="text-center w-100">
                  <AnimateLoaderComponent />
                </div>
              ) : (
                <GoalsList programId={programId} {...props} onClose={onClose} />
              )}
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-center create-goal">
              <div className="goal-create-data">
                <figure>
                  <img
                    src="/images/goals/plus-icon.svg"
                    className="img-fluid"
                    alt="activity chart"
                  />
                </figure>
                <h4 className="font-16 fw600 font-qbold mb-1">
                  Create a new goal
                </h4>
                <p className="font-qmedium ash-txt font-14 fw500 create-data-para">
                  Set a new goal for you to achieve in the program to help you
                  reach your desired outcome.
                </p>
                <a
                  href="#"
                  className="completed-button black-txt bg-white font-16 font-qbold fw600 bg-lightblue createagoalhover" 
                  data-toggle="modal"
                  onClick={() => createGoal()}
                  data-target="#goalcustomModal"
                >
                  Create a goal
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GoalsModal programId={programId} onClose={onClose} />

      {<PlayOndemand
        condition={condition}
        programId={programId}
        //workoutId={workoutId}
        history={history}
        //ondemand_detail_data={this.state.ondemand_detail_data}
        //closeWithProgram={(id) => { this.updateProgramDetails(id) }}
        //isStartProgram={this.state.isStartProgram}
        redirectToDynamicProgram={(programId) => { redirectToDynamicProgram(programId) }}
        isDynamic={1}
      />}

    </React.Fragment>
  );
};

export default GoalListComponent;
